import { FaqHero, FaqResults, GetInTouch } from "components";
import { Helmet } from "react-helmet";

const FaqView = () => {
  return (
    <>
      <Helmet>
        <title>FAQs for Black Mental Health Matters</title>
        <meta
          name="description"
          content="Check out our Black Mental Health Matters FAQs page to learn more about common myths surrounding Black mental health and how we dispel them."
        />
        <meta name="keywords" content="FAQ, black mental health" />
      </Helmet>
      <FaqHero />
      <FaqResults />
      <GetInTouch />
    </>
  );
};

export default FaqView;
