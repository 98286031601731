const FaqHero = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-[#FFFBE8] to-orange h-[50vh] flex justify-center items-center py-32 md:px-28 px-5">
        <div className=" md:px-28 px-0">
          <h1 className="text-center font-semibold text-xl md:text-2xl md:leading-loose">
            Looking for answers? Check out our frequently asked questions (FAQs) below.
          </h1>
        </div>
      </div>
    </>
  );
};

export default FaqHero;
