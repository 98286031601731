import { chevrondown } from "assets/icons";
import React, { useState } from "react";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  questionTextColor: string;
  bgColor: string;
  rounded: string;
  iconBg: string;
  border: string;
  childColor: string;
}

const Accordion = ({
  title,
  children,
  questionTextColor,
  bgColor,
  rounded,
  iconBg,
  border,
  childColor
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${bgColor} ${rounded} w-full ${border} border-2 md:p-4 p-2 mb-4`}>
      <button
        className={`w-full md:py-2 py-1 md:px-4 px-2 md:text-2xl text-base font-bold focus:outline-none flex items-center justify-between gap-2  ${questionTextColor}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-left md:w-full w-[250px]">{title}</span>
        <span
          className={`rounded-full shadow-md p-2 transition-all ease-out duration-500 w-[30px] h-[30px]  ${iconBg}  ${
            isOpen ? "transform rotate-90" : ""
          }`}
        >
          <img src={chevrondown} />
        </span>
      </button>
      <div
        className={`duration-500 transition-all ease-out ${
          isOpen ? "block" : "hidden"
        } py-4 ${childColor} md:text-xl text-sm`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
