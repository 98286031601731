/* eslint-disable react/prop-types */
import { FormInput, SRInput, TextareaInput } from "components/modules";
import { Form, Formik } from "formik";
import * as Yup from "yup";
interface ModalProps {
  modalOpen: boolean;
  modalClose: () => void;
}
const SubmitResources: React.FC<ModalProps> = ({ modalOpen, modalClose }) => {
  const formikInitialValues = {
    fullname: "",
    email: "",
    organization: "",
    campaign: "",
    website: "",
    instagram: "",
    twitter: "",
    resource: ""
  };

  const SubmitResources = Yup.object({
    fullname: Yup.string().required("Please enter your First Name").min(2, "Invalid Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
    organization: Yup.string()
      .required("Please enter your organization")
      .min(2, "Invalid organization"),
    campaign: Yup.string()
      .required("Please enter your campaign description")
      .min(2, "Invalid campaign description"),
    website: Yup.string().required("Please enter your website").min(2, "Invalid website"),
    instagram: Yup.string()
      .required("Please enter your instagram Handle")
      .min(2, "Invalid instagram Handle"),
    twitter: Yup.string()
      .required("Please enter your twitter Handle")
      .min(2, "Invalid twitter Handle"),
    resource: Yup.string().required("Please enter your resource URL").min(2, "Invalid resource URL")
  });

  const handleSubmit = (values: {
    fullname: string;
    email: string;
    organization: string;
    campaign: string;
    website: string;
    instagram: string;
    twitter: string;
    resource: string;
  }) => {
    console.log(values);
  };
  return modalOpen ? (
    <div className="fixed inset-0 bg-[#000] bg-opacity-70 z-[1000] flex items-center justify-center md:p-5 p-3">
      <div className=" flex items-center justify-center max-h-[100%] lg:max-w-[700px] pt-96 w-full bg-[#B2ECC4] rounded-md overflow-y-auto">
        <div>
          <h2 className="font-semibold text-2xl md:text-4xl md:pt-32 leading-[40px] text-center text-[#005028]">
            Submit Resources
          </h2>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={SubmitResources}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form className="flex flex-col md:gap-4 gap-2 py-5">
              <SRInput nameAndId="fullname" placeholder="Full Name" type="text" />
              <SRInput nameAndId="email" placeholder="Email Address" type="email" />
              <SRInput nameAndId="organization" placeholder="Organization" type="text" />
              <TextareaInput
                nameAndId="campaign"
                placeholder="Campaign Description"
                label=""
                className="bg-white placeholder-[#667085] min-w-[300px] md:w-[600px] text-[#667085] text-base font-normal py-4 px-6 md:h-[180px] h-[120px] focus:ring-0 border-2 border-[#667085] rounded-[8px] outline-none"
              />
              <SRInput nameAndId="website" placeholder="Website Link" type="text" />
              <SRInput nameAndId="instagram" placeholder="Instagram Handle" type="email" />
              <SRInput nameAndId="twitter" placeholder="Twitter Handle" type="text" />
              <FormInput
                nameAndId="resource"
                placeholder="Resource URL"
                label=""
                type="text"
                className="rounded-[8px] border-[#667085] text-[#667085] placeholder-[#667085] pl-6 border-2 h-14 focus:ring-0 outline-none"
              />
              <div className="flex flex-col gap-4 mt-4">
                <button
                  type="submit"
                  className="text-white text-base md:text-xl bg-green-100 hover:bg-green py-3 px-4 md:h-14 flex items-center justify-center rounded-md w-full"
                >
                  SUBMIT
                </button>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-black sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={modalClose}
                >
                  Close
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  ) : null;
};

export default SubmitResources;
