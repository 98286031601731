import { arrowLeft, google, linkedin, microsoft } from "assets/icons";
import { logo } from "assets/images";
import { FormInput, PwdInput } from "components/modules";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD, HOME, LOGIN, TERMS } from "routes/CONSTANTS";
import { GOOGLE_END_POINT, LINKEDIN_END_POINT, MICROSOFT_END_POINT } from "services/CONSTANTS";
import { loginSuccess, register } from "redux/slices/auth.slice";
import { useAppDispatch, useQuery } from "hooks";
import { CenterLoader } from "components/widgets";
import { signupSchema } from "validations";
import { SignupProps } from "types";
import { useEffect, useState } from "react";
import SignupText from "components/layout/Signup/SignupText";
import { clearMessage } from "redux/slices/message.slice";
import { toast } from "react-toastify";

const SignupUserForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const formikInitialValues = {
    full_name: "",
    email: "",
    password: "",
    terms: false
  };

  useEffect(() => {
    const queryString = query.get("redirect");
    if (queryString) {
      if (queryString === "success") {
        void dispatch(loginSuccess());
      } else {
        const errorMessage = query.get("error");
        if (errorMessage) {
          if (errorMessage?.length > 0 && errorMessage !== "undefined") {
            toast.error(errorMessage);
          }
        }
      }
    }
  }, []);

  const handleSubmit = async (details: SignupProps) => {
    setSuccessful(false);
    setIsLoading(true);

    await dispatch(register(details))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        navigate(DASHBOARD);
      })
      .catch(() => {
        setIsLoading(false);
        setSuccessful(false);
      });
  };

  const googleLogin = () => {
    window.open(GOOGLE_END_POINT, "_self");
  };

  const microsoftLogin = () => {
    window.open(MICROSOFT_END_POINT, "_self");
  };
  const linkedinLogin = () => {
    window.open(LINKEDIN_END_POINT, "_self");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto">
      {isLoading && <CenterLoader />}
      {/* {message && (
        <div className="form-group">
          <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
            {message}
          </div>
        </div>
      )} */}
      <SignupText
        headline="Let’s Get Started"
        text="Create an account today to access mental health services and speak to a licensed professional."
      />

      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <div className="flex items-center md:justify-between justify-center">
          <img
            src={arrowLeft}
            onClick={() => navigate(-1)}
            className="cursor-pointer md:block hidden"
          />
          <Link to={HOME}>
            <img src={logo} />
          </Link>
        </div>
        <p className="font-bold text-xl md:text-3xl text-green py-5">User Sign Up</p>

        <Formik
          initialValues={formikInitialValues}
          onSubmit={handleSubmit}
          validationSchema={signupSchema}
          enableReinitialize={true}
        >
          <Form className="flex flex-col md:gap-3 gap-1 md:pr-28">
            {!successful && (
              <>
                {" "}
                <FormInput
                  nameAndId="full_name"
                  placeholder="John Doe"
                  label="Full Name"
                  type="text"
                />
                <FormInput
                  nameAndId="email"
                  placeholder="johndoe@gmail.com"
                  label="Email"
                  type="email"
                />
                <PwdInput
                  nameAndId="password"
                  placeholder="*****************************"
                  label="Password"
                />
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 md:gap-4 items-center justify-start">
                    <Field type="checkbox" name="terms" className="accent-green" />
                    <label htmlFor="terms" className="font-normal text-xs md:text-base text-ash">
                      I agree to all the statements in
                      <span className="text-green hover:text-green-100">
                        <Link to={TERMS}>Terms of Service</Link>
                      </span>
                    </label>
                  </div>
                  <ErrorMessage
                    component="label"
                    name="terms"
                    className="text-sm w-full text-[#FF0000]"
                  />
                </div>
                <div className="bg-green flex items-center justify-center w-56 rounded-md mx-auto mt-2">
                  <button
                    type="submit"
                    className="bg-orange hover:bg-green-100 py-3 px-8 text-white w-full rounded-md"
                  >
                    Sign Up
                  </button>
                </div>
                <div className="flex flex-col gap-4 pt-2">
                  <div className="flex gap-2 items-center justify-center pt-2">
                    <div className="w-44 h-[0.5px] bg-black"></div>
                    <div>or</div>
                    <div className="w-44 h-[0.5px] bg-black"></div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </Formik>
        <div className="flex items-center justify-center md:gap-8 gap-4 pt-2">
          <button onClick={microsoftLogin} className="cursor-pointer">
            <img src={microsoft} alt="" className="h-8 w-8" />
          </button>
          <button onClick={linkedinLogin} className="cursor-pointer">
            <img src={linkedin} alt="" className="h-8 w-8" />
          </button>
          <button onClick={googleLogin} className="cursor-pointer">
            <img src={google} alt="" className="h-8 w-8" />
          </button>
        </div>

        <div className="text-green-100 text-xs md:text-lg text-center hover:text-green cursor-pointer pt-2">
          <Link to={LOGIN}>Have an account already?</Link>
        </div>
      </div>
    </div>
  );
};

export default SignupUserForm;
