import {
  ResourceBmmh,
  ResourceButtons,
  ResourceContacts,
  ResourceContactUs,
  ResourceHero
} from "components";
import { Helmet } from "react-helmet";

const ResourcesView = () => {
  return (
    <>
      <Helmet>
        <title>Explore Resources for Black Mental Health Matters</title>
        <meta
          name="description"
          content="Explore our resources to help you destigmatize and heal from racial trauma - Black Mental Health Matters"
        />
        <meta name="keywords" content="mental health matters, resources" />
      </Helmet>
      <ResourceHero />
      <ResourceContacts />
      <ResourceBmmh />
      <ResourceButtons />
      <ResourceContactUs />
    </>
  );
};

export default ResourcesView;
