import { DonateHero, Empower, HowtoDonate, MonthlyDonors, WDonate } from "components";
import { Helmet } from "react-helmet";

const DonateView = () => {
  return (
    <>
      <Helmet>
        <title>Donate to Black Mental Health Matters</title>
        <meta
          name="description"
          content="Join us in our mission to provide healing, mental health services, and address the impacts of racism, police brutality, and intergenerational traumas in underserved Black communities. Your donation can make a significant difference. Donate now"
        />
        <meta name="keywords" content="donate, black mental health, support" />
      </Helmet>
      <DonateHero />
      <WDonate />
      <MonthlyDonors />
      <Empower />
      <HowtoDonate />
    </>
  );
};

export default DonateView;
