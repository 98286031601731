import { dashboardlogo, logout, quiz } from "assets/images";
import { Link, useLocation } from "react-router-dom";

import {
  DASHBOARD,
  APPOINTMENTS,
  AUDIOCASTS,
  TRENDS,
  CHATS,
  GROUPS,
  EVENTS,
  REFER_A_FRIEND,
  SETTINGS,
  QUIZ
} from "routes/CONSTANTS";
import {
  BMappointments,
  BMaudiocasts,
  BMevents,
  BMgroups,
  BMhome,
  BMrefer,
  BMsettings,
  BMtrends
} from "./icons";
import BMchats from "./icons/BMchats";

const sidebar = [
  {
    Icon: BMhome,
    name: "Home",
    to: DASHBOARD
  },
  {
    Icon: BMappointments,
    name: "Appointments",
    to: APPOINTMENTS
  },
  {
    Icon: BMchats,
    name: "Chats",
    to: CHATS
  },
  {
    Icon: BMaudiocasts,
    name: "Audio casts",
    to: AUDIOCASTS
  },
  {
    Icon: BMtrends,
    name: "Trends",
    to: TRENDS
  },
  {
    Icon: BMgroups,
    name: "Groups",
    to: GROUPS
  },
  {
    Icon: BMevents,
    name: "Events",
    to: EVENTS
  },
  {
    Icon: BMrefer,
    name: "Refer a Friend",
    to: REFER_A_FRIEND
  },
  {
    Icon: BMsettings,
    name: "Settings",
    to: SETTINGS
  }
];

const DashboardSidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="md:w-1/3 lg:w-1/4 xl:w-1/5 pt-5 md:pt-8  pb-[4rem] px-3 md:px-8 flex flex-col gap-4 xl:gap-6  bg-white shadow-lg">
      <div className="flex justify-center ">
        <img src={dashboardlogo} alt="dashboardlogo" />
      </div>
      {sidebar.map(({ Icon, name, to }, key) => (
        <Link
          key={key}
          to={to}
          className={`${
            pathname === to
              ? "rounded-md text-orange p-2 flex items-center gap-2"
              : "text-green p-2 flex items-center gap-2"
          } flex items-center`}
        >
          <div className="w-5">
            <Icon size={20} />
          </div>
          <div className="w-full">
            <span className="text-lg">{name}</span>
          </div>
        </Link>
      ))}
      <div className="text-gray-200 p-2 flex items-center gap-2 cursor-pointer">
        <div className="w-full  hidden md:block items-center">
          <span className="inline-block">
            <img src={logout} />
          </span>
          <span className="text-lg inline-block">Logout</span>
        </div>
      </div>
      <div className="bg-[#F6F9FB]  p-4 rounded-lg">
        <div className=" flex justify-center text-center">
          <img src={quiz} alt="quiz-img" />
        </div>
        <p className="text-[12px]  text-center">
          Take a quiz to access your knowledge of mental health
        </p>
        <div className="mt-5 flex w-[100%]">
          <Link to={QUIZ} className="bg-white w-full text-center text-green p-3 rounded-lg">
            Take Quiz
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
