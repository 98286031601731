import { PageWrapper } from "components";
import CampaignFormView from "./CampaignFormView";

export const CampaignFormContainer = () => {
  return (
    <PageWrapper>
      <CampaignFormView />
    </PageWrapper>
  );
};
