import axios from "axios";
import { GroupFormInput, GroupSelectInput, Success } from "components/modules";
import { CenterLoader } from "components/widgets";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

const JournalForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const formikInitialValues = {
    firstname: "",
    lastname: "",
    email: ""
  };

  const journalValidation = Yup.object({
    firstname: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    lastname: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address")
  });

  const handleSubmit = async (values: { firstname: string; lastname: string; email: string }) => {
    console.log(values);
    setIsLoading(true);
    setIsSuccess(false);

    try {
      const res = await axios.post("/api/journalform", values);

      if (res.status >= 200 && res.status < 300) {
        setIsSuccess(true);
      } else {
        setErrorMessage(`Request failed with status code: ${res.status}`);
        setIsSuccess(false);
        toast.error(errorMessage);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsSuccess(false);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      {isSuccess && <Success response="" />}
      {!isSuccess && (
        <>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={journalValidation}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form>
                {isLoading ? (
                  <div className="text-center mt-8">
                    <CenterLoader />
                  </div>
                ) : (
                  <>
                    {/* <div></div> */}
                    <div className="bg-green rounded-md md:p-8 p-3 flex flex-col md:gap-8 gap-4">
                      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
                        <GroupFormInput
                          nameAndId="firstname"
                          label="First Name"
                          placeholder=""
                          type="text"
                        />
                        <GroupFormInput
                          nameAndId="lastname"
                          label="Last Name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <GroupFormInput nameAndId="email" label="Email" placeholder="" type="email" />
                      <GroupSelectInput label="Type of Research" nameAndId="research">
                        <option value="">Select an option</option>
                        <option value="manuscript">Manuscript</option>
                        <option value="articles">Articles</option>
                        <option value="journals">Journals</option>
                      </GroupSelectInput>

                      <div className="h-[197px] border-dashed border-2 border-gray-200 rounded-md my-12"></div>

                      <div className="flex flex-col gap-4">
                        <button
                          type="submit"
                          className="text-white text-base md:text-2xl bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:h-16 flex items-center justify-center rounded-md w-full"
                        >
                          Upload
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          disabled={isSubmitting}
                          className="text-orange text-base md:text-2xl bg-green hover:bg-[#FFAC2A] hover:text-white py-3 px-4 w-full md:h-16 flex items-center justify-center rounded-md border-2 border-orange"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default JournalForm;
