import { SignupTherapistForm } from "components";

const SignupTherapistView = () => {
  return (
    <div>
      <SignupTherapistForm />
    </div>
  );
};

export default SignupTherapistView;
