import * as Yup from "yup";

const eduExpSchema = Yup.object({
  education: Yup.array()
    .of(
      Yup.object().shape({
        institution: Yup.string()
          .required("Please enter the institution")
          .min(2, "Must be a minimum of 2 characters"),
        degree: Yup.string()
          .required("Please enter the degree")
          .min(2, "Must be a minimum of 2 characters"),
        year: Yup.string()
          .required("Please enter the year")
          .min(2, "Must be a minimum of 2 characters")
      })
    )
    .required("Please enter at least one education"),

  license_type: Yup.string()
    .required("Please enter your License Type")
    .min(2, "Must be a minimum of 2 characters"),

  occupation_title: Yup.string()
    .required("Please Enter Your Occupation Title")
    .min(3, "Must be a minimum of 3 characters"),

  years_of_experience: Yup.string()
    .required("Please Enter Your Years of experience")
    .min(2, "Must be a minimum of 2 characters"),

  areas_of_experience: Yup.string()
    .required("Please Enter Your Areas of Experience")
    .min(2, "Must be a minimum of 2 characters")
});

export default eduExpSchema;
