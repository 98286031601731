import { LoginUserForm } from "components";

const LoginUserView = () => {
  return (
    <div>
      <LoginUserForm />
    </div>
  );
};

export default LoginUserView;
