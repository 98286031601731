import { caution } from "assets/icons";
import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  type?: "text" | "number" | "email" | "password";
  className?: string;
  sublabel?: string;
  subtext?: string;
}

function CInput({ nameAndId, label, type, className, sublabel, subtext }: FormInputProps) {
  return (
    <div className="flex flex-col">
      <label className="mb-2" htmlFor={nameAndId}>
        <span className="text-[#555253] text-base md:text-2xl font-light">{label}</span>
        <span className="text-[#555253] text-xs md:text-base font-light">{sublabel}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        type={type ?? "text"}
        className={
          className ??
          "bg-white placeholder-gray text-black md:text-2xl text-sm font-normal p-2 h-[44px] md:h-[72px] focus:ring-0 border-2 border-[#A1A1A1] outline-none rounded-md"
        }
      />

      <div className="flex items-center gap-2 md:mt-4 mt-2">
        <img src={caution} />
        <p className="text-[#A1A1A1] text-xs md:text-base">{subtext}</p>
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default CInput;
