import {
  testifier1,
  testifier2,
  testifier3,
  testifier4,
  testifier5,
  testifier6,
  testifier7,
  testifier8,
  testifier9,
  testifier10,
  testifier11,
  testifier12,
  testifier13,
  testifier14,
  testifier15,
  testifier16,
  testifier17
} from "assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMemo } from "react";

const Testimonials = () => {
  const peoplesTestimonies = [
    {
      id: 1,
      pic: testifier1,
      testimony:
        "The session was a friendly, interactive one. We were all given equal opportunities for contributions and questions. I really do appreciate the opportunity to be on Black Mental Health Matters",
      name: "Abigail Oni",
      occupation: "Psychotherapist",
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 2,
      pic: testifier2,
      testimony:
        "I became involved with Black Mental Health Show as a mental health advocate because I have a passion for improving the mental health of Black people, and it aligns with my mission. I am committed to raising awareness and providing resources to support this community.",
      name: "Carissa A. Brown",
      occupation: "Mental Health Advocate",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 3,
      pic: testifier3,
      testimony:
        "I truly enjoy this space that has been created for our community to explore and engage inconversation about Mental Health. It has been a great experience to be able to give information while also learning from different cultural perspectives around the world. This is a phenomenal organisation!",
      name: "Jasmyn R. James",
      occupation: "Hollistic Wellness Practitioner",
      // position: "left-[-22px] top-[-28px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 4,
      pic: testifier4,
      testimony:
        "I am grateful to have had the opportunity to serve as a guest speaker on the BMHM Show. Itiyopiya and Mekaya are so awesome, insightful, thoughtful, and fun to work with! The experience wassmooth, collaborative, and straightforward from thestart to finish.",
      name: "Dr Tonicia Freeman-Foster",
      occupation: "Co-Founder of Kusudi Consulting Group",
      // position: "right-[-25px] bottom-[125px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 5,
      pic: testifier5,
      testimony:
        "It was a great honor and privilege to be a Guest Speaker. Black Mental Health Matters created a welcoming space that made me comfortable and prepared for the recording. I look forward to working with BMHM in the future!",
      name: "Terri Floyd",
      occupation: "Behavioural Scientist",
      // position: "left-[-30px] top-[-25px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 6,
      pic: testifier6,
      testimony:
        "It was a wonderful experience being able to talk about family mental health among peers that operate in similar professions.",
      name: "Deonte Carter",
      occupation: "Victim Advocate/M.Ed of Counselling Student",
      // position: "left-[-25px] bottom-[125px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 7,
      pic: testifier7,
      testimony:
        "I can't say enough about how great it is to work for BMHM. We continue to grow and serve the diaspora community. We have a thriving community of professionals who continue to support our community, along with a research committee and engaging topic talks equals to a trifecta of sustainable success",
      name: "Dr. Dia Pfleger ",
      occupation: "Vice President Black Mental Health Matters Inc",
      // position: "left-[-25px] bottom-[125px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 8,
      pic: testifier8,
      testimony:
        "Working with BMHM has not only been my first  LinkedIn opportunity but an opportunity that I will cherish forever. Hearing perspectives beyond a western point of view is always refreshing and being involved with BMHM is a breath of fresh air.",
      name: "Mekaya Williams-Franklin ",
      occupation: "Digital Wellbeing Advocate and Holistic Health Practitioner",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 9,
      pic: testifier9,
      testimony:
        "I had a great experience speaking on the podcast and engaging passionately in conversation with Mekaya Williams. Constructively, I would suggest that for future podcasts, it will be more like a flowing conversation and not an interview, and I would have liked to engage with the other guest speaker in conversation rather than us having separate questions. I am grateful for the opportunity of being able to speak with Black Mental Health Matters, thank you!",
      name: "Natalie Ventura",
      occupation: "Holistic Practitioner",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 10,
      pic: testifier10,
      testimony:
        "’BMHM has created a space for us to be vulnerable and work together for the future of those in the African Diaspora.",
      name: "Dr. DQuayvion Cloud",
      occupation: " Licensed Social Worker",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 11,
      pic: testifier11,
      testimony:
        "’’I enjoyed being included in this interview. I thought it was a good way to speak some real light into people and to give people an inside of who I am.",
      name: "Taylor Gravesande ",
      occupation: " Student Blogger",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 12,
      pic: testifier12,
      testimony:
        "’’The BMHM show is very informative. What I like most about the show is the diverse professionals who bring various perspectives on the topic of mental health. Not only are we diverse professionally, BMHM diversity is also in ethnicity, culture and religion. ‘",
      name: "Sonia Harte",
      occupation: "  Licensed Spiritual Practitioner of Religious Science",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 13,
      pic: testifier13,
      testimony:
        "’'I was a guest once with BMHM and I really enjoyed it. I learnt a lot and it was an educative show for me.",
      name: "Ashley Baye    ",
      occupation: " Guest Speaker",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 14,
      pic: testifier14,
      testimony: "’’I enjoyed providing education & information about substance abuse.",
      name: "Kesshia Brown",
      occupation:
        " Licensed Professional Counselor & Licensed Substance Abuse Treatment Practitioner",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 15,
      pic: testifier15,
      testimony:
        "’’I have loved hosting shows for BMHM, it has allowed me to be more educated on the state of mental health within Black communities across the globe, raise awareness of issues the directly impact Black people of all kinds and created a safe space to explore what mental health can look like from research, lived experience and practitioner perspectives. It has been an eye opening journey so far and I truly believe in the BMHM mission.",
      name: "Rhyana Ebanks-Babb  ",
      occupation: " Equality, Diversity and Inclusion Manager",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 16,
      pic: testifier16,
      testimony:
        "’’Mental health is a very important topic to me so when Mekaya asked if I would like to be a guest speaker, I was thrilled. Because it’s a topic I’m passionate about, discussing it with others gave me new  perspectives and that was something I truly appreciated.’",
      name: "Kaylin Strahan ",
      occupation: "Author and Public Figure ",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    },
    {
      id: 17,
      pic: testifier17,
      testimony:
        "’’Since I started hosting the Black Mental Health Matters talk show in February 2021. My aim is to provide a platform for people to freely talk about mental health without holding back.Through listening to the different stories, I have as well learnt how to open up to my mental health struggles and seek help’",
      name: "Claire Nasasira",
      occupation: "Journalist ",
      // position: "right-[-30px] bottom-[90px]"
      position: "right-[-25px] top-[-30px]"
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const testimonialsCarousel = useMemo(() => {
    return (
      <Carousel responsive={responsive} showDots={true} itemClass="py-12">
        {peoplesTestimonies.map((testimony) => {
          return (
            <div
              key={testimony.id}
              className="bg-[#FFFBE8] h-[400px] md:h-[450px] p-8 md:p-10 shadow-md relative"
            >
              <img
                src={testimony.pic}
                alt=""
                className="absolute right-[0px] top-[-30px] z-20 b "
              />
              <div className="flex flex-col justify-between gap-4 md:gap-16 h-full">
                <p className="text-base leading-8 font-medium text-[#2A3342]">
                  {testimony.testimony}
                </p>
                <div>
                  <p className=" text-xs font-semibold leading-7 text-[#333F51]">
                    {testimony.name}
                  </p>
                  <p className="text-xs font-normal leading-7 text-[#8896AB]">
                    {testimony.occupation}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }, []);

  return (
    <>
      <div className="md:px-12 xl:px-28 px-5 py-4 mt-10 md:mt-0 md:py-28 flex flex-col justify-center items-center md:items-start">
        <h2 className="text-2xl md:text-5xl font-bold md:font-medium leading-[23px] md:leading-[50px] text-[black] md:text-[#2A3342] mb-4">
          Testimonial
        </h2>
        <p className="text-base md:text-2xl font-medium leading-[30px] text-[#556987] mb-10 md:mb-20 text-center md:text-left">
          Listen to their voices, and become a voice today!
        </p>
        <div className="hidden md:grid grid-cols-3 gap-10 mb-4 mx-auto">
          {peoplesTestimonies.map((testimony) => {
            return (
              <div
                key={testimony.id}
                className="bg-[#FFFBE8] lg:min-h-[404px] p-10 shadow-md relative"
              >
                <img
                  src={testimony.pic}
                  alt=""
                  className={`absolute ${testimony.position}`}
                  style={{ borderRadius: "50%" }}
                />
                <div className="flex flex-col justify-between h-full">
                  <p className="text-base font-medium text-[#2A3342]">{testimony.testimony}</p>
                  <div>
                    <p className="text-lg font-semibold leading-7 text-[#333F51]">
                      {testimony.name}
                    </p>
                    <p className="text-lg font-normal leading-7 text-[#8896AB]">
                      {testimony.occupation}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="w-full md:hidden">{testimonialsCarousel}</div>
      </div>
    </>
  );
};

export default Testimonials;
