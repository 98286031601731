import { PageWrapper } from "components";
import HomeView from "./HomeView";

export const HomeContainer = () => {
  return (
    <PageWrapper>
      <HomeView />
    </PageWrapper>
  );
};
