import { checkBox, longArrowRight } from "assets/icons";
import { mental, skillAssessment, therapist, therapistForSomeone } from "assets/images";
import { Link } from "react-router-dom";
import { LOGIN } from "routes/CONSTANTS";

const OurServices = () => {
  return (
    <div className="bg-green md:py-16 py-12">
      <div className="text-center w-full hidden md:block">
        <h3 className="text-5xl font-bold leading-[68px] text-white mb-6">Our Services</h3>
        <p className="lg:text-2xl text-xl font-normal leading-[34px] text-white lg:w-[908px] mx-auto">
          Experience culturally responsive mental health care tailored to your unique needs with our
          comprehensive range of personalized services.
        </p>
      </div>

      <div className=" flex items-center w-full mt-16 lg:gap-0  gap-4  md:px-12 xl:px-28 px-5">
        <div className="w-full lg:w-[50%] flex lg:gap-0 gap-5">
          <div className="lg:mt-0  ">
            <h1 className="text-4xl font-bold text-white py-4 lg:w-[475px] leading-[80px]">
              Start Your <span className="text-orange">Free</span> 30 Day Trial
            </h1>
            <div className="md:flex justify-between lg:mt-0 mt-12 hidden">
              <span>
                <img src={checkBox} alt="" />
              </span>
              <span className="text-2xl font-normal text-[#C4C4C4]">Free 30 day trial</span>
              <span>
                <img src={checkBox} alt="" />
              </span>
              <span className="text-2xl font-normal leading-[34px] text-[#C4C4C4]">
                No card required
              </span>
            </div>
            <Link
              to={LOGIN}
              className="md:py-3 md:px-4 p-2 md:w-72  md:w-50 w-full md:h-16 h-12 md:text-2xl text-base bg-orange flex items-center justify-center rounded  my-8"
            >
              <span className="font-medium leading-[18.5px] text-white mr-2">Start Now</span>
              <img src={longArrowRight} alt="" />
            </Link>
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-5 gap-5 justify-center lg:content-between  w-full lg:w-[50%] md:mt-0 mt-0">
          <div className="md:w-[269px] w-full md:h-[210px] h-[150px] bg-white rounded-2xl flex flex-col  items-center justify-center">
            <img src={mental} alt="" className="lg:w-[50%] w-[50%]" />
            <p className="md:text-[20px] text-xs font-semibold leading-6 text-center  text-[#00A552]">
              Mental Health Help
            </p>
          </div>
          <div className="md:w-[269px] w-full md:h-[210px] h-[150px] bg-white rounded-2xl flex flex-col items-center justify-center">
            <img src={therapist} alt="" className="md:w-[50%] w-[50%]" />
            <p className="md:text-[20px] text-xs font-semibold leading-6 text-center  text-[#00A552]">
              I need a therapist
            </p>
          </div>
          <div className="md:w-[269px] w-full md:h-[210px] h-[150px] bg-white rounded-2xl flex flex-col items-center justify-center">
            <img src={therapistForSomeone} alt="" className="md:w-[50%] w-[50%]" />
            <p className="md:text-[20px] text-xs font-semibold text-center leading-6 text-[#00A552]">
              Therapist for someone
            </p>
          </div>
          <div className="md:w-[269px] w-full md:h-[210px] h-[150px] bg-white rounded-2xl  md:flex flex-col items-center justify-center">
            <img src={skillAssessment} alt="" className="md:w-[50] w-[50%]" />
            <p className="md:text-[20px] text-xs  text-center font-semibold leading-6 text-[#00A552]">
              Self Assessment
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
