import { dashboardimg, NotificationIcon, dashsearchIcon, profilePic } from "assets/images";
import { useSelector } from "react-redux";

const DashboardHeader = () => {
  const user = useSelector((state: any) => state.auth.user.user);
  console.log(user);
  return (
    <div
      className="bg-[#F6F9FB] h-[6rem] items-center flex  px-[5rem] py-2 "
      style={{ borderBottom: "2px solid #EFEFEF", borderLeft: "2px solid #EFEFEF" }}
    >
      <div className="flex  items-center gap-5 w-[70%]">
        <img src={dashboardimg} alt="dashboardimg" />
        <div className="">
          <span className="text-[22px]">Good Morning </span>
          <span className="text-orange text-[22px]">{user.full_name}</span>
          <p>How are you feeling today?</p>
        </div>

        <img src={dashsearchIcon} className="relative left-[50px]" />
        <input
          type="text"
          placeholder="Search"
          className=" h-[2rem]   w-[300px] px-10 rounded-md p-5 shadow-sm "
          style={{ background: "#EFF2F5" }}
        />
      </div>
      <div className="flex justify-end gap-10 items-center  w-[30%]">
        <img src={NotificationIcon} className="cursor-pointer" />
        <img src={profilePic} className="cursor-pointer" />
      </div>
      <div></div>
    </div>
  );
};

export default DashboardHeader;
