import { useState } from "react";
import { SMSelect, GroupFormInput, Success, GroupFormTextarea } from "components/modules";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { CenterLoader } from "components/widgets";
import { HostFormProps } from "types";
import { cloudfill, linkedin, trash, twitter } from "assets/icons";
import { hostSchema } from "validations";
import { HOST_FORM } from "services/CONSTANTS";
import env from "configs";
import axios from "axios";

interface AvailableDayOption {
  label: string;
  value: string;
}
const HostForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topicList, setTopicList] = useState<string[]>([]);
  const [currentTopic, setCurrentTopic] = useState<string>("");

  const formikInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    postal_code: "",
    home_address: "",
    professional_title: "",
    about_you: "",
    topics_of_discussion: [],
    reason: "",
    available_days: [],
    preferred_social_media: {
      name: "",
      url: ""
    },
    has_product: false,
    products: "",
    details: ""
  };

  const handleRemoveTopic = (index: number) => {
    const updatedTopicList = [...topicList];
    const newTopicList = updatedTopicList.filter((_, i) => i !== index);
    setTopicList(newTopicList);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTopic(e.target.value);
  };

  const availableDaysOptions: AvailableDayOption[] = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" }
  ].map((option) => ({ ...option, isMulti: true }));

  const options = [
    {
      value: "linkedin",
      icon: linkedin
    },
    {
      value: "twitter",
      icon: twitter
    }
  ];

  const optionStyle = {
    control: (base: any) => ({
      ...base,
      backgroundColor: "#005028",
      border: "1px solid #005028",
      color: "white"
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: "white",
      color: "#005028"
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: "#B2ECC4",
      color: "#005028",
      height: "40px",
      fontSize: "24px"
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: "#005028"
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: "white",
      ":hover": {
        backgroundColor: "red-500"
      }
    })
  };

  const handleSubmit = async (formData: HostFormProps) => {
    setIsLoading(true);
    setIsSuccess(false);

    try {
      await axios.post(`${env.API_BASE_URL}/${HOST_FORM}`, formData);
      setIsSuccess(true);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-green rounded-md md:p-8 p-3 mt-12">
      {isSuccess && (
        <Success response="You have successfully submitted your request as a host, We will contact you shortly" />
      )}
      {!isSuccess && (
        <>
          <p className="md:text-2xl text-sm font-medium text-[#B2ECC4] text-center">
            Thank you for your interest in being a host on our Black Mental Health Matters Show.
            Please fill out the form below and we'll reach out to you via email if we think you'd be
            great for an upcoming episode.
          </p>
          <Formik
            initialValues={formikInitialValues}
            onSubmit={handleSubmit}
            validationSchema={hostSchema}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {isLoading ? (
                  <div className="text-center mt-8">
                    <CenterLoader />
                  </div>
                ) : (
                  <>
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 md:pt-16 pt-12">
                      <GroupFormInput
                        nameAndId="first_name"
                        type="text"
                        placeholder=""
                        label="First Name*"
                      />
                      <GroupFormInput
                        nameAndId="last_name"
                        type="text"
                        placeholder=""
                        label="Last Name*"
                      />
                      <GroupFormInput
                        nameAndId="email"
                        type="email"
                        placeholder=""
                        label="Email*"
                      />
                      <GroupFormInput
                        nameAndId="phone"
                        type="number"
                        placeholder=""
                        label="Phone Number*"
                      />
                      <GroupFormInput
                        nameAndId="country"
                        type="text"
                        placeholder=""
                        label="Country*"
                      />
                      <GroupFormInput
                        nameAndId="city"
                        type="text"
                        placeholder=""
                        label="State/ Province/ Region*"
                      />
                      <GroupFormInput
                        nameAndId="postal_code"
                        type="text"
                        placeholder=""
                        label="Postal Code*"
                      />
                      <GroupFormInput
                        nameAndId="home_address"
                        type="text"
                        placeholder=""
                        label="Street Address*"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:gap-8 gap-4 pt-8">
                      <GroupFormInput
                        nameAndId="professional_title"
                        type="text"
                        placeholder=""
                        label="Professional title (E.G Licensed Clinical Psychologist)*"
                      />
                      <div className="flex md:flex-row flex-col gap-4 items-center justify-between">
                        <GroupFormTextarea
                          nameAndId="about_you"
                          label="Tell us about yourself and why you want to be a Host?*"
                        />
                        <div className="flex md:flex-row flex-col justify-between items-center ">
                          <p className="text-white md:text-2xl text-base">Upload Resume</p>
                          <div className="mt-4 md:ml-16">
                            <img src={cloudfill} />
                          </div>
                          <GroupFormInput
                            nameAndId="resume"
                            type="file"
                            placeholder=""
                            label=""
                            accept=".pdf,.doc,.docx"
                            className="bg-green placeholder-gray text-white md:text-2xl text-base font-normal p-1 md:h-[73px] h-[44px] focus:ring-0 border-none rounded-md outline-none"
                          />
                        </div>
                      </div>
                      <GroupFormInput
                        nameAndId="photo"
                        type="file"
                        placeholder=""
                        accept=".jpg,.jpeg,.png"
                        className="relative m-0 block w-full min-w-0 flex-auto rounded-md border-2 border-gray border-neutral-300 bg-clip-padding  text-white md:text-2xl text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary file:md:h-[77px] file:h-[44px] "
                        label="Upload your photo (We'll use the image to promote your episode. Use a High-Resolution Head shot.)*"
                      />

                      <div className="flex flex-col gap-4">
                        <label className="text-white font-bold text-base md:text-2xl">
                          What topics on mental health are you interested in discussing?*
                        </label>
                        <div className="flex items-center justify-between md:p-2 p-1 h-[44px] md:h-[73px] border-2 border-gray rounded-md">
                          <Field
                            type="text"
                            name="topics_of_discussion"
                            value={currentTopic}
                            onChange={handleChange}
                            className="bg-green placeholder-gray w-full text-white md:text-2xl text-base font-normal p-1 md:h-[53px] h-[34px] focus:ring-0 outline-none"
                          />
                          <button
                            type="button"
                            className="bg-orange text-white rounded-md md:py-2 md:px-4 p-1 text-base h-full cursor-pointer md:w-[200px] w-full"
                            onClick={() => {
                              if (currentTopic.trim() !== "") {
                                setTopicList((prevTopicList) => [...prevTopicList, currentTopic]);
                                setFieldValue("topics_of_discussion", [
                                  ...values.topics_of_discussion,
                                  currentTopic
                                ]);
                                setCurrentTopic("");
                              }
                            }}
                          >
                            Create New topic
                          </button>
                        </div>

                        <div className="flex gap-2 md:gap-4">
                          {topicList.map((topic, index) => (
                            <div
                              key={index}
                              className="bg-[#B2ECC4] text-green text-base p-2 rounded-md flex items-center gap-2 md:gap-4 justify-between"
                              onClick={() => handleRemoveTopic(index)}
                            >
                              {topic}
                              <button type="button">
                                <img src={trash} alt="Delete" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <GroupFormTextarea
                        nameAndId="reason"
                        label="Why Do You Want To Be A Host?*"
                      />
                      <div className="flex flex-col gap-4">
                        <label className="text-white font-bold text-base md:text-2xl">
                          What days are you available?*
                        </label>

                        <Select
                          name="available_days"
                          options={availableDaysOptions}
                          isMulti
                          placeholder="Choose available days"
                          classNamePrefix="react-select"
                          className="md:py-2 md:px-4 p-1 rounded-md border md:h-[73px] h-[44px] border-gray focus:outline-none background-green placeholder:text-white placeholder:text-2xl my-auto"
                          styles={optionStyle}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions.map((option) => option.value);
                            setFieldValue("available_days", selectedValues);
                          }}
                          value={availableDaysOptions.filter((option) =>
                            values.available_days.some((value) => value === option.value)
                          )}
                        />
                      </div>

                      <div className="flex flex-col gap-4">
                        <label
                          htmlFor="preferred_social_media"
                          className="md:text-2xl text-base font-bold text-white"
                        >
                          Preferred Social Media URL
                        </label>
                        <div className="flex w-full items-center">
                          <SMSelect nameAndId="preferred_social_media.name">
                            {options.map((option) => (
                              <option key={option.icon}>{option.value}</option>
                            ))}
                          </SMSelect>
                          <Field
                            name="preferred_social_media.url"
                            type="text"
                            placeholder="https://"
                            className="bg-green placeholder-gray w-full text-white md:text-2xl text-base font-normal p-1 md:h-[73px] h-[44px] focus:ring-0 border-2 border-gray rounded-r-md outline-none"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-4">
                        <div className="text-white md:text-2xl text-base">
                          Do you have a products or services you would like to promote during the
                          show?
                        </div>
                        <div className="flex gap-36 items-center">
                          <label className="text-white md:text-2xl text-base flex items-center gap-2">
                            <Field
                              type="radio"
                              name="has_product"
                              value="Yes"
                              className="accent-green-100 h-4 w-4"
                            />
                            Yes
                          </label>
                          <label className="text-white md:text-2xl text-base flex items-center gap-2">
                            <Field
                              type="radio"
                              name="has_product"
                              value="No"
                              className="accent-green-100 h-4 w-4"
                            />
                            No
                          </label>
                        </div>
                      </div>

                      <GroupFormInput
                        nameAndId="products"
                        type="text"
                        placeholder=""
                        label="If Yes, List Them"
                      />
                      <div className=" text-white md:text-2xl md:mt-8 mt-4">
                        <input type="radio" className="h-4 w-4 md:mr-4 mr-2 accent-green-100" />
                        <label className="font-normal md:text-2xl text-sm">
                          Media/Audio/Video Release* – I authorize Black Mental Health Matters Inc
                          to use, reproduce, and distribute the voice and/or video recordings of me,
                          including my name and submitted photo(s). I understand that this
                          organization will use my name, likeness, and voice or video recordings
                          primarily for commercial use, education, and/or promotion. I have read and
                          understand the preceding and consent to use my picture and/or voice or
                          video as specified for the above-described purpose(s). I further
                          understand that no royalty, fee, or other compensation of any character
                          shall become payable to me. I agree to release Black Mental Health Matters
                          Inc from any claims, damages, or liability arising from such use or
                          distribution.
                        </label>
                      </div>

                      <div className=" text-white md:text-2xl md:mt-8 mt-4">
                        <input type="radio" className="h-4 w-4 md:mr-4 mr-2 accent-green-100" />
                        <label htmlFor="privacy_policy" className="font-normal md:text-2xl text-sm">
                          Privacy Policy* – I understand that personal information collected will
                          not be shared with any third parties and will only use solely to advance
                          the organization’s objectives. I grant Black Mental Health Matters Inc
                          permission to collect and store my data as per it’s Privacy Policy.
                        </label>
                      </div>
                    </div>
                    <div className="mt-16 mb-8 flex items-center justify-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="text-white md:text-2xl text-base bg-orange py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default HostForm;
