import { SearchHero, SearchPage } from "components";

const SearchArticleView = () => {
  return (
    <div>
      <SearchHero />
      <SearchPage />
    </div>
  );
};

export default SearchArticleView;
