const AbstractList = () => {
  return (
    <div className="hidden sm:block text-gray-200 sm:text-lg lg:text-2xl font-light">
      <h1 className="font-normal border-b-2 border-orange mr-14">Abstract</h1>
      <ul>
        <li>Introduction</li>
        <li>Methods</li>
        <li>Results</li>
        <li>Discussion</li>
        <li>References</li>
        <li>Abbreviations </li>
        <li>Copyright</li>
      </ul>
    </div>
  );
};

export default AbstractList;
