import {
  lg1,
  lg2,
  lg3,
  lg4,
  lg5,
  lg6,
  lg7,
  sn1,
  sn2,
  sn3,
  sn31,
  sn4,
  sn5,
  sn6
} from "assets/images";

interface SignupTextProps {
  headline: string;
  text: string;
  subtext?: string;
}
const SignupText = ({ headline, text, subtext }: SignupTextProps) => {
  return (
    <div className="w-full bg-green flex md:flex-col flex-row justify-between md:gap-0 gap-2 h-[250px] md:h-full  md:overflow-hidden">
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6 ">
        <img src={sn1} alt="" />
        <img src={sn2} alt="" />
        <img src={sn3} alt="" />
        <img src={sn31} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg1} alt="" />
        <img src={lg2} alt="" />
        <img src={lg3} alt="" />
      </div>
      <div className="flex flex-col md:gap-8 gap-4 items-center justify-center text-center md:text-start">
        <p className="font-bold text-xl md:text-7xl text-white md:w-[482px] w-full">{headline}</p>
        <p className="text-xm md:text-xl font-normal text-white w-full md:w-[450px] text-center md:text-start">
          {text}
        </p>
        <p className="text-orange text-sm md:text-base font-normal w-full md:w-[450px] text-center md:text-start">
          {subtext}
        </p>
      </div>
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6">
        <img src={sn4} alt="" />
        <img src={sn5} alt="" />
        <img src={sn6} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg4} alt="" className="pl-4" />
        <img src={lg5} alt="" />
        <img src={lg6} alt="" />
        <img src={lg7} alt="" />
      </div>
    </div>
  );
};

export default SignupText;
