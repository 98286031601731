import { PageWrapper } from "components";
import BeAVoiceView from "./BaAVoiceView";

export const BeAVoiceContainer = () => {
  return (
    <PageWrapper>
      <BeAVoiceView />
    </PageWrapper>
  );
};
