import { configureStore } from "@reduxjs/toolkit";
import reducer from "redux/slices";

// const reducer = {
//   auth: authReduce,
//   message: messageReducer
// }

export const store = configureStore({
  reducer,
  devTools: true
});
