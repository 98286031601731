import { useNavigate, Link } from "react-router-dom";
import { BackArrow, CatPhoto } from "assets/images";
import { LinkButton } from "components/widgets";
import { HOME } from "routes/CONSTANTS";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="flex  items-center justify-center  sm:px-10">
        <div className="text-center sm:text-left w-[330px] md:w-[350px] xl:w-[450px]">
          <p className=" text-base text-green font-semibold pb-4">404 error</p>
          <div className=" text-4xl xl:text-6xl font-semibold pb-10">Page not found</div>
          <p className=" text-base sm:text-xl font-normal text-gray-200 pb-10">
            Sorry, the page you are looking for doesn't exist. Here are some helpful links:
          </p>
          <div className=" sm:hidden  h-[80%] w-[80%] ml-10">
            <img src={CatPhoto} alt={CatPhoto} />
          </div>
          <div className="flex items-center justify-center">
            <Link
              to=""
              onClick={() => navigate(-1)}
              className="flex items-center justify-center gap-2 md:px-6  py-4 h-16 w-64 md:w-44 border text-gray-400 border-[#D0D5DD] hover:border-green hover:text-green rounded-lg mr-4"
            >
              <img src={BackArrow} alt={BackArrow} className="self-center" />
              Go back
            </Link>

            <LinkButton className=" text-base sm:text-lg xl:text-2xl" to={HOME}>
              Take Me Home
            </LinkButton>
          </div>
        </div>
        <div className="hidden sm:block h-[54%] w-[54%]">
          <img src={CatPhoto} alt={CatPhoto} />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
