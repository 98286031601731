import Carousel from "react-multi-carousel";
import { campaigns } from "utils";
import Petition from "./Petition";
import { PetitionFormValues } from "./PetitionForm";

const CampaignHero = () => {
  const handleFormSubmit = (id: number, values: PetitionFormValues) => {
    console.log(id, values);
    // handle form submission
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className="w-full">
      <Carousel responsive={responsive}>
        {campaigns.map((campaign) => (
          <Petition
            key={campaign.id}
            id={campaign.id}
            image={campaign.image}
            title={campaign.title}
            description={campaign.description}
            numSignatures={campaign.numSignatures}
            petitionnote={campaign.petitionnote}
            totalSignatures={campaign.totalSignatures}
            onFormSubmit={handleFormSubmit}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CampaignHero;
