import { PageWrapper } from "components";
import DonateView from "./DonateView";

export const DonateContainer = () => {
  return (
    <PageWrapper>
      <DonateView />
    </PageWrapper>
  );
};
