import { mentalStability } from "assets/images";

export const campaigns = [
  {
    id: 1,
    title: "Global Free Mental Health Services for All",
    image: mentalStability,
    description:
      "Mental health is a fundamental aspect of our overall well-being and is essential for a healthy, fulfilling life. Despite its importance, access to quality mental health services remains a challenge for many people worldwide, particularly for those in low- and middle-income countries. It's time to take action and ensure that everyone has access to the mental health services they need, regardless of where they live or their financial situation.",
    numSignatures: 20,
    petitionnote:
      "We, the undersigned, call on governments and decision-makers worldwide to take immediate action to provide free mental health services for all people. We demand the following:",
    totalSignatures: 50,
    Paragraphone:
      "Adequate funding for mental health services: Governments must allocate sufficient funds to provide mental health services that are accessible, affordable, and of high quality.",
    paragraphtwo:
      "Accessibility: Mental health services must be available in all communities, including rural and remote areas, to ensure that everyone has access to the care they need.",
    paragraphthree:
      "Affordability: Mental health services must be free of charge, or provided at a low cost, to ensure that everyone can access the care they need, regardless of their financial situation.",
    paragraphfour:
      "Quality: Mental health services must be of high quality, provided by trained and experienced professionals, and based on evidence-based practices.",
    paragraphfive:
      "Integration: Mental health services must be integrated into primary healthcare services to ensure that mental health is recognized as an essential aspect of overall health and well-being",
    paragraphsix:
      "We call on governments, international organizations, and all stakeholders to take action to make free mental health services a reality for all people, everywhere."
    // readmore:
  },
  {
    id: 2,
    title: "Demand for Trauma-Informed Policies in Schools and Universities",
    image: mentalStability,
    description:
      "Trauma can have a profound impact on a person's life and well-being, and it's crucial that schools and universities are equipped to support students who have experienced trauma. A trauma-informed approach recognizes the prevalence of trauma, the impact it can have on individuals, and the need to provide safe and supportive environments for students. It's time for states to take action and implement trauma-informed policies in all schools and universities      ",
    numSignatures: 20,
    petitionnote:
      "We, the undersigned, call on state governments to implement trauma-informed policies in all schools and universities. We demand the following:",
    totalSignatures: 50,
    Paragraphone:
      "Awareness and training: All school and university staff, including teachers, administrators, and support staff, must receive training on trauma, its impact, and how to support students who have experienced trauma.    ",
    paragraphtwo:
      "Safe and supportive environment: Schools and universities must create safe and supportive environments that promote healing and resilience for students who have experienced trauma. This includes implementing policies and procedures to address bullying, harassment, and discrimination.",
    paragraphthree:
      "Access to mental health services: Schools and universities must provide access to quality mental health services, including counseling and support groups, for students who have experienced trauma.",
    paragraphfour:
      "Trauma-informed discipline: Schools and universities must implement trauma-informed discipline policies that address the root causes of disruptive behavior and provide support for students instead of punishment.",
    paragraphfive:
      "Collaboration with community partners: Schools and universities must work with community organizations and mental health providers to support students who have experienced trauma and provide resources for families.    ",
    paragraphsix:
      "We call on state governments to take immediate action to implement these policies and provide a safe and supportive environment for all students in schools and universities.    "
  },
  {
    id: 3,
    title: "Decriminalize Attempted Suicide",
    image: mentalStability,
    description:
      "Attempted suicide is a cry for help, not a criminal act. People who attempt suicide are often struggling with mental health problems, and they need support, not punishment. The criminalization of attempted suicide sends a message that people with mental health problems are to be punished, rather than helped. It's time for the laws to reflect a more compassionate and evidence-based approach to mental health      ",
    petitionnote:
      "We, the undersigned, call on governments worldwide to decriminalize attempted suicide. We demand the following",

    numSignatures: 20,
    totalSignatures: 50,
    Paragraphone:
      "Decriminalization: Laws criminalizing attempted suicide must be repealed, and individuals who attempt suicide must no longer be subject to criminal prosecution.    ",
    paragraphtwo:
      "Mental health support: Governments must provide adequate funding for mental health services, including crisis hotlines, counseling, and support groups, to support individuals who are struggling with mental health problems and suicidal thoughts.    ",
    paragraphthree:
      "Awareness and education: Governments must invest in public education and awareness campaigns to promote understanding of mental health problems and reduce the stigma associated with suicide and mental illness.",
    paragraphfour:
      "Access to mental health services: Governments must ensure that mental health services are accessible, affordable, and of high quality, and that they are integrated into primary healthcare services.",
    paragraphfive:
      "Collaboration with mental health organizations: Governments must collaborate with mental health organizations and advocacy groups to develop and implement effective mental health policies and programs.    ",
    paragraphsix:
      "We call on governments to take immediate action to decriminalize attempted suicide and provide support and care for individuals who are struggling with mental health problems.    "
  },
  {
    id: 4,
    title: "Working towards Everyone's Mental Stability",
    image: mentalStability,
    description:
      "Mental health is a fundamental aspect of our overall well-being, and it's essential for a healthy, fulfilling life. Unfortunately, mental health problems are prevalent in many communities and often go unaddressed due to stigma and lack of access to quality mental health services. It's time for everyone to work together to promote mental stability for all people      ",
    numSignatures: 20,
    petitionnote:
      "We, the undersigned, call on governments, businesses, and individuals to take action to promote mental stability for all people. We demand the following",
    totalSignatures: 50,
    Paragraphone:
      "Investment in mental health: Governments must allocate adequate funding for mental health services, research, and education to promote mental stability for all people.    ",
    paragraphtwo:
      "Mental health education: Schools, universities, and businesses must provide education on mental health and wellness, including the recognition and management of mental health problems.    ",
    paragraphthree:
      "Reduction of stigma: Governments, businesses, and individuals must work to reduce the stigma associated with mental health problems and promote understanding and acceptance of mental illness.    ",
    paragraphfour:
      "Access to mental health services: Governments must ensure that mental health services are accessible, affordable, and of high quality, and that they are integrated into primary healthcare services.    ",
    paragraphfive:
      "Support for mental health research: Governments must support research into the causes, prevention, and treatment of mental health problems, and promote the development of effective and evidence-based mental health practices.    ",
    paragraphsix:
      "We call on everyone to take action to promote mental stability for all people, and to work towards a world where mental health is recognized as a fundamental aspect of overall health and well-being."
  }
];
