const IndividualFormHero = () => {
  return (
    <div className="flex items-center justify-center">
      <p className="font-bold text-green md:text-6xl text-2xl md:border-b-8 border-b-4 border-b-orange md:pb-4 pb-2 md:w-[758px] w-[300px] text-center md:leading-[75px]">
        Organization <br />
        Partner <span className="text-orange">Application</span> Form{" "}
      </p>
    </div>
  );
};

export default IndividualFormHero;
