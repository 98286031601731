import React from "react";
import { Field, ErrorMessage } from "formik";

interface GroupSelectProps {
  nameAndId: string;
  label: string;
  labelClassName?: string;
  children: React.ReactNode;
}

function GroupSelectInput({ nameAndId, label, children, labelClassName }: GroupSelectProps) {
  return (
    <div className="flex flex-col">
      <label
        className={`text-white md:text-2xl text-base font-bold pb-3 ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        as={"select"}
        className="bg-green placeholder-gray text-white md:text-2xl text-base font-normal py-1 md:h-[73px] h-[44px] focus:ring-0 border-2 border-gray rounded-md outline-none"
      >
        {children}
      </Field>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default GroupSelectInput;
