import { GroupFormInput, TextareaInput } from "components/modules";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import env from "configs";
import { STORIES } from "services/CONSTANTS";
import { StoriesFormProps } from "types";
import * as Yup from "yup";
import { useState } from "react";
import { CenterLoader } from "components/widgets";
import axios from "axios";
import { toast } from "react-toastify";

const StoriesForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formikInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    story: ""
  };

  const storiesValidation = Yup.object({
    first_name: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    last_name: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address")
  });

  const handleSubmit = async (formData: StoriesFormProps) => {
    setIsLoading(true);

    try {
      await axios.post(`${env.API_BASE_URL}/${STORIES}`, formData);
      toast.success("Your story has been submitted successfully!");
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <Formik
        initialValues={formikInitialValues}
        validationSchema={storiesValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          {isLoading ? (
            <div className="text-center mt-8">
              <CenterLoader />
            </div>
          ) : (
            <>
              <div className="bg-green rounded-md md:p-8 p-3 flex flex-col md:gap-8 gap-4">
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
                  <GroupFormInput
                    nameAndId="first_name"
                    label="First Name"
                    placeholder=""
                    type="text"
                  />
                  <GroupFormInput
                    nameAndId="last_name"
                    label="Last Name"
                    placeholder=""
                    type="text"
                  />
                </div>
                <GroupFormInput nameAndId="email" label="Email" placeholder="" type="email" />
                <TextareaInput
                  nameAndId="story"
                  label="Your Story"
                  placeholder="Enter your story..."
                />

                <div className="h-[197px] border-dashed border-2 border-gray-200 rounded-md my-12"></div>

                <div className="flex flex-col gap-4">
                  <button
                    type="submit"
                    className="text-white text-base md:text-2xl bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:h-16 flex items-center justify-center rounded-md w-full"
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => navigate(-1)}
                    className="text-orange text-base md:text-2xl bg-green hover:bg-[#FFAC2A] hover:text-white py-3 px-4 w-full md:h-16 flex items-center justify-center rounded-md border-2 border-orange"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default StoriesForm;
