import { useState, useEffect } from "react";
import { scroll } from "assets/icons";

function ScrollToTopButton() {
  const [showTopButton, setShowTopButton] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
  }, []);
  return (
    <div>
      {showTopButton && (
        <div
          className="fixed z-90 bottom-16 md:right-20 right-8 w-12 h-12 rounded-full drop-shadow-lg flex justify-center items-center hover:drop-shadow-2xl"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <img src={scroll} />
        </div>
      )}
    </div>
  );
}

export default ScrollToTopButton;
