import { Apply, Entail, Skills, VolunteerHero } from "components";
import { Helmet } from "react-helmet";

const VolunteerView = () => {
  return (
    <>
      <Helmet>
        <title>Volunteer with Black mental health matters to make a difference</title>
        <meta
          name="description"
          content="Be a part of the solution and make an impact in Black mental health by volunteering with us"
        />
        <meta name="keywords" content="volunteer, careers, black mental health" />
      </Helmet>
      <VolunteerHero />
      <Entail />
      <Skills />
      <Apply />
    </>
  );
};

export default VolunteerView;
