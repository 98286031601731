import { PageWrapper } from "components";
import PublicationView from "./PublicationView";

export const PublicationContainer = () => {
  return (
    <PageWrapper>
      <PublicationView />
    </PageWrapper>
  );
};
