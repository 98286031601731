import { researchGroup } from "assets/images";

const PartnerHero = () => {
  return (
    <div
      className="h-[60vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center"
      style={{ backgroundImage: `url(${researchGroup})` }}
    >
      <div className=" bg-[rgba(0,80,40,0.76)] w-screen h-[60vh] flex items-center justify-center">
        <h1 className=" text-white md:text-6xl text-4xl font-semibold">Partner With Us</h1>
      </div>
    </div>
  );
};

export default PartnerHero;
