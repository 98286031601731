import { Link } from "react-router-dom";
import { PRIVACY, TERMS } from "routes/CONSTANTS";
import Newsletters from "./ Newsletters";
import ContactInfo from "./ContactInfo";
import Sections from "./Sections";

const Footer = () => {
  return (
    <div className=" bg-green text-white py-12 gap-2 md:px-12 xl:px-28 px-5">
      <div className=" md:flex justify-between">
        <ContactInfo />
        <Sections />
        <Newsletters />
      </div>
      <div className="lg:flex items-center justify-between text-xs gap-4 text-gray pt-12 hidden ">
        <p className="justify-self-end">
          5900 Balcones Drive STE 100 Austin, TX 78731, United states.
        </p>
        <p>© {new Date().getFullYear()} Black Mental Health Matters. All rights reserved.</p>
        <div className="flex gap-8">
          <Link to={PRIVACY} className="justify-self-end hover:text-orange">
            Privacy Policy
          </Link>
          <Link to={TERMS} className="hover:text-orange">
            Term and Conditions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
