import { dashCertified, GoBack, GoForward, Journal, Moodboards, TrendingPost } from "assets/images";
const DashboardTrend = () => {
  return (
    <div className="bg-white rounded-[10px] p-5">
      <div>
        <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-4">
          <p> Journal</p>
          <div className="flex gap-2 items-center">
            <button>
              <img src={GoBack} />
            </button>
            <button>
              <img src={GoForward} />
            </button>
          </div>
        </div>
        <div className="rounded-[12px] bg-[#fffbe8] p-4">
          <div className="flex items-center justify-between text-[#005028] ">
            <p className="#ff9c00 text-[14px]"> 20/11/2022</p>
            <button>
              <img src={Journal} className="w-[22px] h-[22px]" />
            </button>
          </div>
          <p className="text-[14px] py-2">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
            consequat ...
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-6">
          <p> Moodboards</p>
          <div className="flex gap-2 items-center">
            <button>
              <img src={GoBack} />
            </button>
            <button>
              <img src={GoForward} />
            </button>
          </div>
        </div>
        <div className="rounded-[12px]">
          <img src={Moodboards} />
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-6">
          <p> Trending Posts</p>
          <div className="flex gap-2 items-center">
            <button>
              <img src={GoBack} />
            </button>
            <button>
              <img src={GoForward} />
            </button>
          </div>
        </div>
        <div className="rounded-[12px] bg-[#eef3f7] p-4">
          <div className="flex items-center gap-2 text-[#005028] ">
            <img src={TrendingPost} className="w-[60px] h-[60px]" />
            <div>
              <div className="flex items-center gap-2">
                <p className="text-[#667085] text-[14px]"> Sandra M.</p>
                <img src={dashCertified} />
              </div>
              <p className="text-[#707070] text-[14px]">6 mins ago</p>
            </div>
          </div>
          <p className="text-[14px] text-[#3c4e40] py-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Enim diam vulputate ut pharetra sit. Duis
            at consectetur lorem donec massa...{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardTrend;
