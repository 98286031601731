import { Gvolunteer } from "assets/images";
import { LinkButton } from "components/widgets";
import { BE_A_VOICE } from "routes/CONSTANTS";

const Volunteer = () => {
  return (
    <>
      <header
        className=" md:bg-cover h-[67vh] bg-center bg-no-repeat bg-fixed flex align-center justify-center py-36 "
        style={{ backgroundImage: `url(${Gvolunteer})` }}
      >
        <div className="flex flex-col justify-center items-center px-8 md:px-14 lg:px-20 max-sm:px-5">
          <div className="text-white font-semibold text-3xl md:text-6xl text-center leading-[80px] lg:px-40">
            {/* Become a Volunteer */}
            Be a Voice
          </div>
          <p className="text-white text-base text-justify md:text-xl md:font-bold md:leading-10 leading-8 md:w-[750px] md:pt-6">
            {/* Join our mission as a BMHM volunteer to gain valuable professional experience, raise
            awareness on mental health, collaborate on community service projects, and make a
            difference while learning new skills, all while being a part of a diverse community */}
            Are you passionate about eradicating the stigma surrounding mental health in the global
            Black community? Join our dynamic network of mental health professionals, speakers, show
            hosts, and advocates. Take part in meaningful conversations that aim to shed light on
            the mental health challenges faced by Black individuals worldwide.
          </p>

          <div className="pt-8 md:pt-16 w-full flex items-center justify-center">
            <LinkButton to={BE_A_VOICE} className="text-[#1E291E] font-bold text-[24px]">
              Apply Now
            </LinkButton>
          </div>
        </div>
      </header>
    </>
  );
};

export default Volunteer;
