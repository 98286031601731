import React from "react";
import { Navbar, Footer } from "components/modules";
import { Scroll } from "components/widgets";

interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: PageWrapperProps) => {
  return (
    <div>
      <Navbar />
      <div className="md:mt-28 mt-16">{children}</div>
      <Footer />
      <Scroll />
    </div>
  );
};

export default PageWrapper;
