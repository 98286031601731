import { dottedPattern, grayWave, playButton, thumbPrint, voiceAddressing } from "assets/images";

const BeAVoiceHero = () => {
  return (
    <div className="">
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-16 gap-8 justify-between md:px-12 xl:px-28 px-5 bg-[#F7F8F9]">
        <div className=" flex flex-col justify-center items-center md:items-start pt-5 md:pt-0">
          <h1 className="md:text-4xl text-2xl md:text-[60px] font-bold leading-[23px] md:leading-[72px] md:mt-0 mt-12 text-black ">
            Be a voice
          </h1>
          <p className="text-lg md:text-2xl text-center md:text-left font-medium leading-8 md:leading-10 w-full  mt-4 text-black">
            Join our global network of mental health professionals,speakers,show hosts and advocates
            as we strive to eradicate the stigma associated with mental health in the global Black
            community. Be a voice for your community on the "Black Mental Health Matters" show,
            where we engage in conversations to deepen our understanding of the mental health
            challenges faced by the Black community worldwide.
          </p>
          <div className="mt-12 flex md:gap-8 lg:gap-10 gap-4 ">
            <a
              href="/hostform"
              className="text-white text-base mb:text-[18px] bg-orange hover:bg-[#FFAC2A] w-[155px] md:w-[171px] lg:w-[181px] px-0 md:px-2 h-14 flex items-center justify-center rounded-md"
            >
              Become a Host
            </a>

            <a
              href="guestform"
              className="text-[#FF9C00] md:text-black text-[16px] mb:text-[18px] bg-white hover:bg-[#fafafa] w-[155px] md:w-[171px] lg:w-[191px] px-0 md:px-2 h-14 flex items-center justify-center rounded-md border-[1px] border-[#FF9C00] md:border-[#005028]"
            >
              Become a Guest
            </a>
          </div>
        </div>
        <div className="relative w-full h-[450px] sm:h-[550px] md:h-[720px] lg:w-[5/6] lg:max-w-[600px]">
          <img
            src={thumbPrint}
            alt=""
            className="relative left-[-35px] md:left-[-50px] top-[80px] w-[76px] h-[76px] md:w-[129px] md:h-[129px]"
          />
          <div
            className="w-full h-[350px] sm:h-[450px] md:w-6/6 md:min-h-[464px]  lg:w-[5/6] lg:max-w-[600px]  bg-cover bg-no-repeat flex items-center justify-center"
            style={{ backgroundImage: `url(${voiceAddressing})` }}
          >
            <a
              href="https://www.youtube.com/live/DeaTfe4UtzI?feature=share"
              target="_blank"
              rel="noreferrer"
            >
              <button>
                <img src={playButton} alt="" />
              </button>
            </a>
          </div>
          <img
            src={dottedPattern}
            alt=""
            className="absolute right-[-10px] bottom-[17px] md:right-[-50px] lg:right-[-80px] md:bottom-[100px] w-[87px] h-[53px] md:w-[149px] md:h-[91px]"
          />
        </div>
      </div>
      <div className="w-full">
        <img src={grayWave} alt="" />
      </div>
    </div>
  );
};

export default BeAVoiceHero;
