import { researchHero } from "assets/images";
import { Link } from "react-router-dom";

const ResearchHero = () => {
  return (
    <>
      <header
        className="bg-cover bg-fixed flex h-[60vh] md:h-[80vh] bg-center w-full "
        style={{ backgroundImage: `url(${researchHero})` }}
      >
        <div className="bg-[rgba(0,0,0,0.6)] w-screen h-[60vh] md:h-[80vh] flex items-center justify-start">
          <div className="absolute md:px-40 px-5 w-full">
            <h1 className="text-white font-semibold md:leading-[80px] leading-[40px] md:text-6xl text-2xl text-center md:text-left ">
              Take a look at our team of <br />
              amazing <span className="text-orange">researchers</span>
            </h1>
            <Link to="">
              <button className="text-white bg-green px-20 text-base  mt-5  opacity-1 md:py-4 py-2 rounded">
                {" "}
                Click Here{" "}
              </button>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default ResearchHero;
