interface SingleProgramProps {
  name: string;
  description: string;
  img: string;
}

export const SingleProgram = ({ name, description, img }: SingleProgramProps) => {
  return (
    <div className="single-program">
      <img className="md:h-96 w-full" src={img} alt={name} />
      <div className="flex flex-row">
        <div>
          <h2 className="text-lg font-semibold pb-2">{name}</h2>
          <p>{description}</p>
        </div>
        <button className="text-green text-xl font-medium p-3">&#62;</button>
      </div>
    </div>
  );
};
