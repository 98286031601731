import { PageWrapper } from "components";
import GuestFormView from "./GuestFormView";

export const GuestFormContainer = () => {
  return (
    <PageWrapper>
      <GuestFormView />
    </PageWrapper>
  );
};
