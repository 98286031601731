import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "assets/images";
import { Link } from "react-router-dom";
import { PRIVACY, TERMS } from "routes/CONSTANTS";

const Newsletters = () => {
  const socialMediaIcons = [
    {
      id: 1,
      icon: Facebook,
      url: "https://web.facebook.com/bmhminc"
    },
    {
      id: 2,
      icon: Twitter,
      url: "https://twitter.com/BMHMINC?t=j14EyEHkS9U3H7dB4D7yMQ&s=09"
    },
    {
      id: 3,
      icon: Linkedin,
      url: "https://www.linkedin.com/company/black-mental-health-matters-bmhm"
    },
    {
      id: 4,
      icon: Instagram,
      url: "https://instagram.com/bmhminc"
    },
    {
      id: 5,
      icon: Youtube,
      url: "https://www.youtube.com/channel/UCm7g0xsxaqnoR8lLdLWgfaw"
    }
  ];
  return (
    <div className=" text-base md:w-[266px] w-full pr-8 md:mr-0">
      <div className="text-center block md:hidden my-8 ">
        <div className="flex gap-4 items-center justify-center ">
          {socialMediaIcons.map((icon) => (
            <a key={icon.id} href={icon.url}>
              <img src={icon.icon} className="h-{20} w-{20}" />
            </a>
          ))}
        </div>
      </div>

      <div className="text-right">
        <div className=" pb-6 text-sm md:text-base  ">Sign up and receive monthly newsletters</div>
        <div className="border-2 border-orange rounded-lg flex items-end md:items-center bg-white w-[335px] md:w-full h-12">
          <input type="text" className="rounded-md text-black w-full h-full" />
          <button className="bg-orange rounded-md px-4 py-2 h-full">Subscribe</button>
        </div>
      </div>

      <div className="flex gap-8 pt-7 mb-3 md:hidden clear-both items-center justify-center">
        <Link to={PRIVACY} className="justify-self-end text-sm md:text-base ">
          Privacy Policy
        </Link>
        <Link to={TERMS} className="text-sm md:text-base ">
          Terms and Conditions
        </Link>
      </div>
    </div>
  );
};

export default Newsletters;
