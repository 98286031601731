import { outline, Researchfile } from "assets/images";
import axios from "axios";
import { GroupFormInput, GroupSelectInput } from "components/modules";
import { CenterLoader } from "components/widgets";
import env from "configs";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { REASEARCHER } from "services/CONSTANTS";
import * as Yup from "yup";

const Journals = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formikInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    type_of_research: ""
  };

  const storiesValidation = Yup.object({
    first_name: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    last_name: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address")
  });

  const handleSubmit = async (values: {
    first_name: string;
    last_name: string;
    email: string;
    type_of_research: string;
  }) => {
    setIsLoading(true);

    try {
      await axios.post(`${env.API_BASE_URL}/${REASEARCHER}`, values);
      toast.success("Thank you for submitting your research interest");
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    // <div className="md:px-28 px-5 max-md:px-2 py-10 bg-green">
    //   <div className="flex container flex-col justify-center items-center  mx-20 max-md:mx-2">
    //     <h2 className="md:text-5xl font-semibold text-3xl text-white">Journal Articles</h2>
    //     <h5 className="mt-2 text-white">Search and submit your research Paper</h5>
    //     <div className="border text-white rounded-lg flex items-center justify-center w-full h-12 my-10">
    //       <input
    //         type="text"
    //         className="rounded-l-md bg-transparent text-white w-full h-full px-5 placeholder:text-white placeholder:text-xs"
    //         placeholder="Explore more than 1000 articles from our  30+ open acces Jornals - All free to read and downlaod"
    //       />
    //       <button className="bg-orange text-white rounded-r-md px-4 py-2 h-full">
    //         <img src={ReasrchSearchImg} alt="search icon" />
    //       </button>
    //     </div>
    //     <div className="flex md:flex-row flex-col gap-8 md:gap-16 justify-center items-center mt-10">
    //       <div className="flex gap-2 justify-center items-center">
    //         <img src={ResearchAttachImg} alt="attach file" className="md:h-6 h-4 md:w-6 w-4" />{" "}
    //         <p className="mx-2 text-white md:text-2xl text-base">Submit your Research paper</p>
    //       </div>
    //       <div>
    //         <form>
    //           <label>
    //             <input
    //               type="file"
    //               className="text-sm text-white
    //         file:mr-5 file:py-2 file:px-6
    //         file:rounded file:border-0
    //         file:text-sm file:font-medium
    //         file:bg-orange file:text-white
    //         hover:file:cursor-pointer hover:file:bg-orange
    //         hover:file:text-white"
    //             />
    //           </label>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={storiesValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          {isLoading ? (
            <div className="text-center mt-8">
              <CenterLoader />
            </div>
          ) : (
            <>
              <div className="bg-green md:p-8 lg:p-16 p-3 flex flex-col md:gap-8 gap-4">
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
                  <GroupFormInput
                    nameAndId="first_name"
                    label="First Name"
                    placeholder="First name"
                    type="text"
                  />
                  <GroupFormInput
                    nameAndId="last_name"
                    label="Last Name"
                    placeholder="Last name"
                    type="text"
                  />
                </div>
                <GroupFormInput nameAndId="email" label="Email" placeholder="Email" type="email" />
                <GroupSelectInput label="Type of Research" nameAndId="type_of_research">
                  <option value="">Select an option</option>
                  <option value="reviewArticles">Review Articles</option>
                  <option value="researchPaper">Research Paper</option>
                  <option value="shortCommunication">Short Communication</option>
                  <option value="caseStudies">Case Studies</option>
                  <option value="methodPaper">Method Paper</option>
                </GroupSelectInput>

                <div className="border-dashed border-2 border-gray-200 rounded-md my-12">
                  <div className="flex justify-center items-center h-[197px] ">
                    <div>
                      <div className="flex justify-center">
                        <button>
                          <img src={outline} />
                        </button>
                      </div>
                      <p className="text-[#556987] font-[500] text-[16px]">
                        Drag and drop an file, or <span className="text-[#ffac2a]">Browse</span>
                      </p>
                      <p className="text-[#bbc3cf] font-[500] text-[16px]">
                        File must not be larger that 5 MB
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-white h-24 flex items-center rounded z-0">
                    <div className="w-[95%]">
                      <div className="flex justify-between ml-6 pb-2 text-[16px] font-[500]">
                        <div className="flex gap-2">
                          <img src={Researchfile} />
                          <span className="text-[#333f51]">Unknown_file.jpg</span>
                        </div>
                        <span className="text-[#bbc3cf]">1.5 MB of 3.6 MB</span>
                      </div>
                      <div className="h-2.5 w-full bg-[#edeef4] z-40 mx-6  rounded">
                        <div
                          className="h-2.5 bg-[#ffac2a]  rounded-l-md rounded-r-md"
                          style={{ width: `${45}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <button
                    type="submit"
                    className="text-white text-base md:text-2xl bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:h-16 flex items-center justify-center rounded-md w-full"
                  >
                    Send
                  </button>
                  <button
                    onClick={() => navigate(-1)}
                    className="text-orange text-base md:text-2xl bg-green hover:bg-[#FFAC2A] hover:text-white py-3 px-4 w-full md:h-16 flex items-center justify-center rounded-md border-2 border-orange"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default Journals;
