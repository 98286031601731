import { ContactForm, ContactHero, ContactInfo, Map } from "components";
import { Helmet } from "react-helmet";

const ContactView = () => {
  return (
    <div className="">
      <Helmet>
        <title>Contact us at Black Mental Health Matters</title>
        <meta
          name="description"
          content="Join us in prioritizing Black mental wellness. Contact us to learn more"
        />
        <meta name="keywords" content="Black mental health, contact, learn" />
      </Helmet>
      <ContactHero />
      <Map />
      <ContactInfo />
      <ContactForm />
    </div>
  );
};

export default ContactView;
