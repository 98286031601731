import { Job } from "types";

export const jobList: Job[] = [
  {
    id: "1",
    vacancy: "Systems Engineer",
    type: "Volunteer",
    location: "Remote",
    experience: "2+ Years",
    summary:
      "Black Mental Health Matters Inc is seeking a talented and experienced Systems Engineer to join our team as a volunteer. As a volunteer Systems Engineer, you will be responsible for designing, implementing, and maintaining the technical infrastructure that supports our organization's mission of promoting mental health and wellbeing in Black communities. You will work closely with our team of developers, network engineers, and security specialists to ensure that our systems are secure, reliable, and scalable.",
    responsibilities: [
      "Design, develop, and maintain the technical infrastructure that supports our organization's mission.",
      "Collaborate with other members of the team to ensure that our systems are secure, reliable, and scalable.",
      "Monitor our systems to identify and resolve issues in a timely manner.",
      "Provide technical support to team members as needed."
    ],
    qualifications: [
      "Bachelor's degree in Computer Science, Computer Engineering, or a related field.",
      "2+ years of experience in systems engineering. Strong knowledge of Linux and Windows operating systems.",
      "Familiarity with cloud computing platforms such as AWS, Azure, or Google Cloud.",
      "Experience with containerization technologies such as Docker and Kubernetes.",
      "Knowledge of scripting languages such as Python and Bash.",
      "Excellent problem-solving skills and attention to detail.",
      "Strong communication skills and ability to work effectively in a team environment."
    ],
    benefits: [
      "Opportunity to make a meaningful impact in promoting mental health and wellbeing in Black communities.",
      "Gain hands-on experience in designing, implementing, and maintaining a technical infrastructure.",
      "Work with a team of passionate individuals dedicated to making a difference.",
      "Flexible schedule and the ability to work remotely."
    ]
  },
  {
    id: "2",
    vacancy: "Scrum Master",
    type: "Volunteer",
    location: "Remote",
    experience: "2+ Years",
    summary:
      "Black Mental Health Matters Inc is seeking a talented and experienced Scrum Master to join our team as a volunteer. As a volunteer Scrum Master, you will be responsible for ensuring that our development team is following the Scrum framework and delivering high-quality products that meet our organization's needs. You will work closely with our developers, product owners, and other stakeholders to ensure that our projects are completed on time, within budget, and to the satisfaction of our clients.",
    responsibilities: [
      "Facilitate the Scrum process, including daily stand-up meetings, sprint planning meetings, sprint reviews, and sprint retrospectives.",
      "Help the development team to stay focused and on track by removing impediments and resolving conflicts.",
      "Work closely with the product owner to ensure that the product backlog is well-maintained and prioritized.",
      "Ensure that the team is following Scrum best practices and is continuously improving."
    ],
    qualifications: [
      "Certified Scrum Master (CSM) certification.",
      "2+ years of experience as a Scrum Master.",
      "Experience working in an Agile development environment.",
      "Excellent communication, interpersonal, and leadership skills.",
      "Strong problem-solving skills and ability to think creatively.",
      "Knowledge of project management tools such as Jira or Trello.",
      "Passionate about promoting mental health and wellbeing in Black communities."
    ],
    benefits: [
      "Opportunity to make a meaningful impact in promoting mental health and wellbeing in Black communities.",
      "Gain hands-on experience in designing, implementing, and maintaining a technical infrastructure.",
      "Work with a team of passionate individuals dedicated to making a difference.",
      "Flexible schedule and the ability to work remotely."
    ]
  },
  {
    id: "3",
    vacancy: "Research Assistant",
    type: "Volunteer",
    location: "Remote",
    experience: "2+ Years",
    summary:
      "Black Mental Health Matters Inc is seeking a dedicated and motivated Research Assistant to join our team as a volunteer. As a volunteer Research Assistant, you will support our research initiatives focused on understanding and addressing mental health disparities in Black communities. You will work closely with our research team to collect and analyze data, develop research reports, and contribute to the development of policies and programs that support mental health in Black communities.",
    responsibilities: [
      "Collect and analyze data using a variety of research methods, including surveys, focus groups, and interviews.",
      "Contribute to the development of research reports, policy briefs, and other publications.",
      "Assist with literature reviews and data entry.",
      "Attend research team meetings and contribute to discussions on research design, data analysis, and interpretation."
    ],
    qualifications: [
      "Bachelor's degree in Psychology, Sociology, Public Health, or a related field.",
      "Strong interest in mental health research and addressing health disparities.",
      "Experience with research methods and data analysis.",
      "Strong analytical and critical thinking skills.",
      "Excellent communication and writing skills.",
      "Proficient in Microsoft Office and Google Suite.",
      "Passionate about promoting mental health and wellbeing in Black communities."
    ],
    benefits: [
      "Opportunity to make a meaningful impact in promoting mental health and wellbeing in Black communities.",
      "Gain hands-on experience in designing, implementing, and maintaining a technical infrastructure.",
      "Work with a team of passionate individuals dedicated to making a difference.",
      "Flexible schedule and the ability to work remotely."
    ]
  },
  {
    id: "4",
    vacancy: "Show Producer",
    type: "Volunteer",
    location: "Remote",
    experience: "2+ Years",
    summary:
      "Black Mental Health Matters Inc is seeking a creative and passionate Show Producer to join our team as a volunteer. As a volunteer Show Producer, you will be responsible for developing and producing engaging content that promotes mental health and wellbeing in Black communities. You will work closely with our host, guests, and technical team to produce high-quality shows that inspire and educate our viewers.",
    responsibilities: [
      "Develop show concepts and pitch ideas to the team.",
      "Coordinate with guests, hosts, and technical team to ensure smooth production.",
      "Manage production schedules and budgets.",
      "Edit and post-produce shows for distribution."
    ],
    qualifications: [
      "Bachelor's degree in Media Production, Communications, or a related field.",
      "2+ years of experience in show producing or media production.",
      "Strong knowledge of video production tools and software.",
      "Experience in developing and pitching show concepts.",
      "Excellent communication and collaboration skills.",
      "Ability to manage multiple projects and deadlines.",
      "Passionate about promoting mental health and wellbeing in Black communities."
    ],
    benefits: [
      "Opportunity to make a meaningful impact in promoting mental health and wellbeing in Black communities.",
      "Gain hands-on experience in designing, implementing, and maintaining a technical infrastructure.",
      "Work with a team of passionate individuals dedicated to making a difference.",
      "Flexible schedule and the ability to work remotely."
    ]
  }
];
