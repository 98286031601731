import { campaign } from "assets/images";
import { LinkButton } from "components/widgets";
import { CAMPAIGN } from "routes/CONSTANTS";

const Campaign = () => {
  return (
    <section>
      <div className=" flex justify-between py-10 md:py-24 px-8 md:px-14 lg:px-20 max-sm:px-5 flex-col md:flex-row">
        <div className="md:w-3/6 md:mr-4 max-sm:w-auto max-sm:mr-0">
          <hr className="w-50 h-1 md:h-2 bg-orange border-2 border-orange text-orange w-1/4 ml-10 md:ml-0" />
          <h2 className="font-bold md:font-semibold text-3xl md:text-5xl lg:text-6xl text-[#667085] leading-tight pr-10 py-2 px-10 md:px-0">
            Let your voice be heard
          </h2>
          <hr className="w-50 h-1 md:h-2 bg-orange border-2 border-orange text-orange w-1/4 md:float-right md:mr-28 ml-10 md:ml-0" />
          <p className="pt-4 md:pt-8 text-base md:text-2xl leading-8 md:leading-10 text-justify">
            Start a mental health campaign today and create positive change in your community. Your
            campaign can educate others to seek support and resources, promoting well-being and
            compassion.
          </p>
          <div className="pt-6 md:pt-8">
            <LinkButton to={CAMPAIGN}>Campaign</LinkButton>
          </div>
        </div>
        <div className="flex justify-center md:block md:w-3/6 lg:ml-4 lg:pl-20 w-auto max-sm:mt-5">
          <img src={campaign} width="380px" />
        </div>
      </div>
    </section>
  );
};

export default Campaign;
