import * as Yup from "yup";

const backgroundInfoSchema = Yup.object({
  full_name: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
  email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
  phone: Yup.string().required("Please enter your Phone Number").min(3, "Invalid phone number"),
  country: Yup.string().required("Please enter your Country").min(2, "Invalid country"),
  city: Yup.string().required("Please enter your City").min(2, "Invalid city")
});

export default backgroundInfoSchema;
