import { PageWrapper } from "components";
import CommunityView from "./CommunityView";

export const CommunityContainer = () => {
  return (
    <PageWrapper>
      <CommunityView />
    </PageWrapper>
  );
};
