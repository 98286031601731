import { Formik, Form } from "formik";

import StepButton from "./StepButton";
import { FormCheckbox } from "components/modules";

interface RevAndSubmitProps {
  onSubmit: (values: any) => void;
}

const ReviewAndSubmit = ({ onSubmit }: RevAndSubmitProps) => {
  const step5InitialValues = {
    accept: true
  };
  return (
    <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
      <Formik initialValues={step5InitialValues} onSubmit={onSubmit}>
        <Form>
          <div className="flex flex-col gap-8 text-base text-black">
            <p>
              By submitting this form, you confirm all information provided is true and accurate to
              the best of your knowledge. You understand that Black Mental Health Matters Inc. may
              verify the information provided and contact you for further information or to schedule
              an interview.
            </p>
            <p>
              After you submit this form, you will receive a confirmation to your email and someone
              from our team will be in touch in the next 3-5 business days.
            </p>

            <FormCheckbox nameAndId="accept" label="click to accept" type="checkbox" />
          </div>

          <div className="w-full md:px-16 px-5 flex items-center justify-center mt-4">
            <StepButton>Submit</StepButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ReviewAndSubmit;
