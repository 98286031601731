import { PageWrapper } from "components";
import CampaignView from "./CampaignView";

export const CampaignContainer = () => {
  return (
    <PageWrapper>
      <CampaignView />
    </PageWrapper>
  );
};
