import Cover from "components/layout/Services/Cover";
import BMHMServices from "components/layout/Services/BMHMServices";
import CardsView from "components/layout/Services/CardsView";
import Partners from "components/layout/Services/Partners";
import { Helmet } from "react-helmet";

const ServicesView = () => {
  return (
    <>
      <Helmet>
        <title>
          Discover the services that black mental health matters offers to the mental health needs
          of the black community
        </title>
        <meta
          name="description"
          content="Discover culturally sensitive mental health care tailored to the unique needs of the Black community. Explore our services page to learn more"
        />
        <meta name="keywords" content="services, black mental health" />
      </Helmet>
      <Cover />
      <BMHMServices />
      <CardsView />
      <Partners />
    </>
  );
};

export default ServicesView;
