import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const DashboardCheckIn = () => {
  return (
    <div className="bg-[#667085] rounded-[10px] flex justify-between items-center p-4">
      <div>
        <p className="text-white font-bold text-[24px]">Daily Check-in Questions</p>
        <p className="text-white text-base font-normal">Complete your daily check-in questions</p>
      </div>
      <div style={{ width: 100, height: 100 }}>
        <CircularProgressbar
          value={0.2}
          maxValue={1}
          text={`${2}/10`}
          styles={buildStyles({
            textColor: "#fff",
            trailColor: "#FAE0B8",
            pathColor: "#FCBD5B",
            textSize: "26px"
          })}
        />
      </div>
    </div>
  );
};

export default DashboardCheckIn;
