import { Moodboard } from "assets/images";

const MoodBoard = () => {
  return (
    <div className="bg-[#EDF9FE] rounded-[10px] lg:w-[236px] h-[154px]">
      <div>
        <div className="flex justify-center py-2 ">
          <img src={Moodboard} />
        </div>
        <p className="text-[24px] text-[#3C4E40] text-center ">Moodboard</p>
        <p className="text-[15px] text-[#3C4E40] text-center py-2">
          Book an appointment with our Professional Therapists
        </p>
      </div>
    </div>
  );
};

export default MoodBoard;
