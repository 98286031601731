import {
  line1,
  line2,
  line3,
  line4,
  line5,
  line6,
  resourceFB,
  resourceInstagram,
  resourceNet,
  resourceTwitter
} from "assets/images";

const ResourceContacts = () => {
  return (
    <div className="md:px-20 px-5 md:py-16 py-12">
      <div className="py-5">
        <div className="border-l-4 md:border-l-8 border-l-[#FF9C00] pl-5">
          <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-6xl font-[600] text-[#005028] ">
            Social Media and Website Resources
          </h1>
        </div>
      </div>
      <div className="lg:flex justify-between w-full md:gap-16 gap-8">
        <p className="text-base md:text-2xl  md:leading-[50.4px] leading-10 text-[#3c4e40]">
          Discover an extensive range of social media and website tools provided by Black Mental
          Health Matters (BMHM) to support your mental health and wellness journey. From informative
          mental health articles and self-care tips to virtual support groups and therapy services,
          BMHM offers a comprehensive suite of resources to help you achieve and maintain optimal
          mental health and wellness. Our educational materials cover a wide range of topics related
          to mental health, and our expert team is dedicated to providing culturally responsive care
          that meets your unique needs. Explore our platform today and take the first step towards a
          happier, healthier you
        </p>
        <div className="w-full mx-auto">
          <div className="py-5 relative ">
            <img src={line2} alt="line" className="absolute top-[50px] left-[50px]" />
            <img src={line5} alt="line" className="absolute top-[68px] left-[150px]" />
            <img src={line1} alt="line" className="absolute top-[138px] left-[58px]" />
            <img src={line6} alt="line" className="absolute top-[180px] left-[60px]" />
            <img src={line3} alt="line" className="absolute top-[140px] left-[152px]" />
            <img src={line4} alt="line" className="absolute top-[72px] left-[182px]" />
            <a href="resources">
              <img
                src={resourceNet}
                alt="Internet Icon"
                className=" transform  translate-x-40 translate-y-1"
              />
            </a>
            <a href="resources">
              <img
                src={resourceTwitter}
                alt="Twitter Icon"
                className="transform  translate-x-28  translate-y-10"
              />
            </a>
            <a href="resources">
              <img
                src={resourceInstagram}
                alt="Instagram Icon"
                className="transform  translate-y-12 translate-x-2"
              />
            </a>
            <a href="resources">
              <img src={resourceFB} alt="Facebook Icon" className="transform  translate-x-52 " />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceContacts;
