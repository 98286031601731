import { whydonate1, whydonate2, whydonate3 } from "assets/images";

const WDonate = () => {
  const why = [
    {
      id: 1,
      image: whydonate1,
      name: "Make a difference",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    },
    {
      id: 2,
      image: whydonate3,
      name: "Donate to change lives",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    },
    {
      id: 3,
      image: whydonate2,
      name: "Give the gift of hope",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    }
  ];
  return (
    <div className=" flex flex-col items-center justify-center md:gap-20 gap-8 px-5 md:py-16 pb-12 pt-16">
      <p className="text-3xl md:text-6xl font-bold text-[#3C4E40] mt-8">Why Donate?</p>

      <div className="flex md:flex-row flex-col gap-8 ">
        {why.map((donate) => (
          <div
            key={donate.id}
            className="flex flex-col w-full  xl:-[400px] rounded-xl py-5 px-2  bg-white items-center text-center justify-center cursor-pointer md:shadow-none shadow-xl  md:hover:scale-105 md:hover:shadow-2xl ease-in-out duration-300"
            style={{
              boxShadow: "",
              border: "0.5px solid #B2ECC4"
            }}
          >
            <img src={donate.image} />
            <p className="text-2xl font-bold mt-5 text-[#3C4E40]">{donate.name}</p>
            <div className="mt-3 md:px-12 px-3">
              <p className="font-light text-base mt-3 text-center leading-8 md:leading-10">
                {donate.story}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WDonate;
