import { PageWrapper } from "components";
import PrivacyView from "./PrivacyView";

export const PrivacyContainer = () => {
  return (
    <PageWrapper>
      <PrivacyView />
    </PageWrapper>
  );
};
