import { chevrondown } from "assets/icons";
import { useState } from "react";
import { Link } from "react-router-dom";

interface DropdownProps {
  title: { label: string; href: string };
  items: Array<{ label: string; href: string }>;
}

const Dropdown = ({ title, items }: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  return (
    <div
      className="relative "
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Link
        to={title.href}
        className={`px-4 py-6 md:py-2 text-[#3C4E40] hover:text-green-100 flex items-center md:font-normal font-semibold  ${
          activeItem === title.label ? "bg-blue-600 text-green-100" : ""
        }`}
        style={{ color: activeItem !== "" ? "green" : "" }}
        onClick={() => setActiveItem(title.label)}
      >
        <span className="flex gap-2 md:pl-0 pl-4 items-center justify-center ">
          {title.label}{" "}
          <img
            src={chevrondown}
            className={`ml-2 h-3 w-3 md:block hidden  ${
              open
                ? "rotate-90 transition-all duration-200 ease-in"
                : "transition-all duration-200 ease-in"
            }`}
          />
        </span>
      </Link>

      {open && (
        <div className=" static md:absolute md:block hidden md:py-3 pb-3  bg-white z-50  text-[#3C4E40] shadow md:w-[200px] w-full">
          {items.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className={`px-4 pl-8 py-2 hover:text-green-100 block text-left md:text-left   ${
                activeItem === item.href ? "text-green-100" : ""
              }`}
              style={{ color: activeItem === item.label ? "text-green-100" : "" }}
              onClick={() => setActiveItem(item.href)}
            >
              {item.label}
            </a>
          ))}
        </div>
      )}

      <div className=" static md:hidden block md:py-3 pb-3  bg-white z-50  text-[#3C4E40] w-full">
        {items.map((item) => (
          <a
            key={item.label}
            href={item.href}
            className={`px-4 pl-8 py-2 hover:text-green-100 block text-left md:text-left   ${
              activeItem === item.href ? "text-green-100" : ""
            }`}
            style={{ color: activeItem === item.label ? "green" : "" }}
            onClick={() => setActiveItem(item.href)}
          >
            {item.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
