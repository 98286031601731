import { AuthorsLinksBg } from "assets/images";
// import { useParams } from "react-router-dom";
// import { journals } from "utils";
import ActiveButton from "./ActiveButton";

const AuthorsLinks = () => {
  // const params = useParams();
  // const journalAuthor = journals.find((j) => j.id === params.id);

  // if (!journalAuthor) {
  //   return <div>Journal Author not found</div>;
  // }
  return (
    <div className=" p-10 xl:p-20 ">
      <div className=" sm:grid sm:grid-cols-[0.5fr,1.5fr] items-center justify-center xl:px-24 mb-10">
        <div
          className="bg-no-repeat bg-contain bg-right h-[100%]"
          style={{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            backgroundImage: `url(${AuthorsLinksBg})`
          }}
        ></div>

        <p className="text-orange md:text-2xl xl:text-4xl  text-center sm:text-left font-bold sm:leading-10 ml-4 ">
          Effect of Torsemide vs Furosemide After Discharge on All-Cause Mortality in Patients
          Hospitalized With Heart FailureThe TRANSFORM-HF Randomized Clinical Trial
          {/* {journalAuthor.paragraph} */}
        </p>
      </div>
      <div className="  xl:px-24 text-gray-200 font-light text-xs sm:text-xl xl:text-2xl text-center lg:ml-72 ">
        <a
          className="underline"
          href="https://jamanetwork.com/searchresults?author=Robert+J.+Mentz&q=Robert+J.+Mentz"
          target="_blank"
          rel="noreferrer"
        >
          Robert J. Mentz, MD1,2;
        </a>
        <a
          className="underline"
          href="https://jamanetwork.com/searchresults?author=Kevin+J.+Anstrom&q=Kevin+J.+Anstrom"
          target="_blank"
          rel="noreferrer"
        >
          Kevin J. Anstrom, PhD3;
        </a>
        <a
          className="underline"
          href="https://jamanetwork.com/searchresults?author=Eric+L.+Eisenstein&q=Eric+L.+Eisenstein"
          target="_blank"
          rel="noreferrer"
        >
          Eric L. Eisenstein, DBA1
        </a>
        <p className="mt-4 ">JAMA. 2023;329(3):214-223. doi:10.1001/jama.2022.23924</p>
      </div>
      <div className="  sm:hidden mt-6 w-[100%]">
        <ActiveButton />
      </div>
    </div>
  );
};

export default AuthorsLinks;
