import { journal, mic, profile, smile, therapist, videochat } from "assets/icons";
import { Link } from "react-router-dom";

const ProductServices = () => {
  const cards = [
    {
      id: 1,
      icon: journal,
      title: "JOURNALING",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    },
    {
      id: 2,
      icon: smile,
      title: "MOOD BOARD",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    },
    {
      id: 3,
      icon: mic,
      title: "AUDIOCASTS",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    },
    {
      id: 4,
      icon: therapist,
      title: "THERAPIST MATCHING",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    },
    {
      id: 5,
      icon: profile,
      title: "PERSONALISED PROFILES",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    },
    {
      id: 6,
      icon: videochat,
      title: "VIDEO & CHAT FEATURES",
      text: "Lorem ipsum dolor sit amet connecteur",
      link: ""
    }
  ];
  return (
    <div className="pb-16 md:py-36">
      <div>
        <h2 className="font-bold text-green md:text-4xl text-2xl md:ml-12 text-center md:text-left">Popular Services We Offer</h2>
        <div className="bg-orange h-4 md:h-8 w-full md:w-1/2 mt-3 md:mt-6 mb-6 md:mb-12"></div>
      </div>
      <div className="bg-green flex flex-wrap gap-12 p-5 md:p-32">
        {cards.map(({ id, icon, title, text, link }) => (
          <div key={id} className="bg-white rounded-lg px-8 py-12 flex flex-col items-center gap-6 text-green w-full md:w-[30%]">
            <img src={icon} alt={title} className="w-16 h-16"/>
            <p className="font-bold text-3xl text-center">{title}</p>
            <p className="text-center">{text}</p>
            <Link to={link} className="bg-green hover:bg-green-100 text-white p-3 rounded-sm w-2/3 text-center">Learn More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductServices;
