const SVG_PATH = "/assets/svg";
export const eye = `${SVG_PATH}/eye.svg`;
export const eyeslash = `${SVG_PATH}/eye-slash.svg`;
export const microsoft = `${SVG_PATH}/microsoft.svg`;
export const google = `${SVG_PATH}/google.svg`;
export const linkedin = `${SVG_PATH}/linkedin.svg`;
export const arrowdown = `${SVG_PATH}/arrowdown.svg`;
export const instagram = `${SVG_PATH}/instagram.svg`;
export const twitter = `${SVG_PATH}/twitter.svg`;
export const web = `${SVG_PATH}/web.svg`;
export const download = `${SVG_PATH}/download.svg`;
export const cloud = `${SVG_PATH}/cloud.svg`;
export const arrowRight = `${SVG_PATH}/arrowRight.svg`;
export const arrowLeft = `${SVG_PATH}/arrowLeft.svg`;
export const checkBox = `${SVG_PATH}/checkBox.svg`;
export const longArrowRight = `${SVG_PATH}/longArrowRight.svg`;
export const rightArrow = `${SVG_PATH}/rightArrow.svg`;
export const chevrondown = `${SVG_PATH}/chevron-down.svg`;
export const close = `${SVG_PATH}/close.svg`;
export const menu = `${SVG_PATH}/menu.svg`;
export const trash = `${SVG_PATH}/trash.svg`;
export const dot = `${SVG_PATH}/dot.svg`;
export const scroll = `${SVG_PATH}/arrowScroll.svg`;
export const people = `${SVG_PATH}/people.svg`;
export const bookmark = `${SVG_PATH}/bookmark.svg`;
export const mail = `${SVG_PATH}/mail.svg`;
export const phone = `${SVG_PATH}/phone.svg`;
export const location = `${SVG_PATH}/location.svg`;
export const linkedinDark = `${SVG_PATH}/linkedinDark.svg`;
export const star = `${SVG_PATH}/star.svg`;
export const caution = `${SVG_PATH}/caution.svg`;
export const linkedinBlack = `${SVG_PATH}/linkedinBlack.svg`;
export const messageIcon = `${SVG_PATH}/messageIcon.svg`;
export const InstagramIcon = `${SVG_PATH}/instagramIcon.svg`;
export const linkedlnIcon = `${SVG_PATH}/linkedln-icon.svg`;
export const YoutubeIcon = `${SVG_PATH}/youtube-icon.svg`;
export const cloudfill = `${SVG_PATH}/cloudfill.svg`;
export const plus = `${SVG_PATH}/plus.svg`;
export const x = `${SVG_PATH}/remove.svg`;
export const prev = `${SVG_PATH}/prev.svg`;
export const journal = `${SVG_PATH}/journal.svg`;
export const mic = `${SVG_PATH}/mic.svg`;
export const smile = `${SVG_PATH}/smile.svg`;
export const therapist = `${SVG_PATH}/therapist.svg`;
export const profile = `${SVG_PATH}/profile.svg`;
export const videochat = `${SVG_PATH}/videochat.svg`;
