import { ReactNode } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
interface Props {
  children: ReactNode;
}

const OnboardingLayout = ({ children }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white w-full shadow-lg rounded-3xl p-5">
      <div className="flex justify-end font-bold text-green text-2xl">
        <AiOutlineClose className="cursor-pointer" onClick={() => navigate(-1)} />
      </div>
      <div className="p-8">{children}</div>
    </div>
  );
};

export default OnboardingLayout;
