import { useState, useEffect } from "react";
import axios from "axios";
import { Story } from "types";
import CommentList from "./CommentList";

interface Props {
  match: {
    params: {
      storyId: number;
    };
  };
}

export const StoryPage = ({
  match: {
    params: { storyId }
  }
}: Props) => {
  const [story, setStory] = useState<Story | null>(null);

  const fetchStory = () => {
    axios
      .get(`/api/stories/${storyId}`)
      .then((response) => {
        setStory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (storyId) {
      fetchStory();
    }
  }, [storyId]);

  return (
    <div>
      {story ? (
        <>
          <h1>{story.title}</h1>
          <p>{story.content}</p>
          <CommentList storyId={story.id} />
        </>
      ) : (
        <p>Loading story...</p>
      )}
    </div>
  );
};
