import { serviceCover } from "assets/images";

const Cover = () => {
  return (
    <>
      <header
        className="h-[60vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center "
        style={{ backgroundImage: `url(${serviceCover})` }}
      >
        <div className="flex flex-col gap-8 justify-center items-center px-6  lg:px-96">
          <div className="text-white font-bold md:font-semibold text-4xl md:text-6xl text-center md:leading-tight">
            Our Services
          </div>
        </div>
      </header>
    </>
  );
};

export default Cover;
