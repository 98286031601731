import { StoriesForm } from "components";

const StoriesFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <StoriesForm />
    </div>
  );
};

export default StoriesFormView;
