// import { partner1, partner2, partner3, partner4, Usaf } from "assets/images";

// const OurTrustedPartners = () => {
//   return (
//     <div>
//       <h2 className="text-center font-bold md:text-5xl text-2xl text-[#667085]">
//         Our Trusted Partners
//       </h2>
//       <div className="flex md:flex-row items-center justify-center md:gap-12 gap-4 md:py-16 py-12">
//         {[partner1, partner2, partner3, partner4, Usaf].map((image, index) => {
//           return (
//             <img
//               className="md:w-36 md:h-36 w-12 h-12"
//               key={index}
//               src={image}
//               alt={`partner ${index + 1}`}
//             />
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default OurTrustedPartners;

import { MHlogo, PartnerOne, PartnerTwo, SakaiVms, Usaf, WestPaua } from "assets/images";
import { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurPartners = () => {
  const Partner = [
    {
      id: 1,
      Image: PartnerOne,
      alt: "PartnerOne logo"
    },
    {
      id: 2,
      Image: PartnerTwo,
      alt: "PartnerTwo logo"
    },
    {
      id: 3,
      Image: SakaiVms,
      alt: "SakaiVms logo"
    },
    {
      id: 4,
      Image: Usaf,
      alt: "Usaf logo"
    },
    {
      id: 5,
      Image: WestPaua,
      alt: "WestPaua logo"
    },

    {
      id: 6,
      Image: MHlogo,
      alt: "Mental Health Action Day logo"
    }
  ];
  const downResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    smallScreen: {
      breakpoint: { max: 464, min: 360 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 360, min: 0 },
      items: 1
    }
  };

  return (
    <div className=" pb-16 ">
      <div className=" bg-[#005028] sm:bg-white px-4 py-16 md:px-20">
        <p className="text-2xl  text-[#ffa820] md:text-black  md:text-5xl font-bold text-center py-2 ">
          Our Trusted Partners
        </p>
        <p className="md:text-2xl text-base text-white sm:text-gray-100 text-center mb-16 lg:mx-28 ">
          Collaborating with our trusted partners to address disparities in access to mental
          healthcare, particularly in BIPOC communities, and promote well-being for all
        </p>

        <Carousel responsive={downResponsive}>
          {Partner.map((resource) => {
            return (
              <div className="" key={resource.id}>
                <div>
                  <div className="flex items-center justify-evenly mx-auto">
                    <img
                      src={resource.Image}
                      alt="Carousel image"
                      className="max-w-[100%] h-[22vh]"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default memo(OurPartners);
