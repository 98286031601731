import { PageWrapper } from "components";
import InternshipFormView from "./InternshipFormView";

export const IndividualFormContainer = () => {
  return (
    <PageWrapper>
      <InternshipFormView />
    </PageWrapper>
  );
};
