import { HostForm, HostFormHero } from "components";

const HostFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <HostFormHero />
      <HostForm />
    </div>
  );
};

export default HostFormView;
