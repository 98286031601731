import { MentalEverydayImg } from "assets/images";
import Carousel from "react-multi-carousel";

const MentalEveryday = () => {
  const mentalcarousel = [
    {
      id: 1,
      image: MentalEverydayImg,
      title: "The Mental Health of everyday Life",
      text: "Lorem ipsum dolor sit amet consectetur. Et tristique urna dui tempus aliquam. Dui nulla diam consequat praesent egestas. Pellentesque massa amet mauris in. Mauris convallis ut vel massa.",
      link: "see more",
      name: "Dr. Ben Dune",
      time: "2days ago"
    },
    {
      id: 1,
      image: MentalEverydayImg,
      title: "The Mental Health of everyday Life",
      text: "Lorem ipsum dolor sit amet consectetur. Et tristique urna dui tempus aliquam. Dui nulla diam consequat praesent egestas. Pellentesque massa amet mauris in. Mauris convallis ut vel massa.",
      link: "see more",
      name: "Dr. Ben Dune",
      time: "2days ago"
    },
    {
      id: 1,
      image: MentalEverydayImg,
      title: "The Mental Health of everyday Life",
      text: "Lorem ipsum dolor sit amet consectetur. Et tristique urna dui tempus aliquam. Dui nulla diam consequat praesent egestas. Pellentesque massa amet mauris in. Mauris convallis ut vel massa.",
      link: "see more",
      name: "Dr. Ben Dune",
      time: "2days ago"
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="bg-green md:px-28 px-5 md:py-16 py-12">
      <div className="">
        <Carousel responsive={responsive} showDots={true} itemClass="md:py-16 py-12">
          {mentalcarousel.map((item, id) => {
            return (
              <div key={id} className=" text-white">
                <div className="flex md:flex-row flex-col gap-8 items-center" key={id}>
                  <div className="w-2/5">
                    <img src={item.image} alt="image" />
                  </div>
                  <div className=" px-5">
                    <div className="flex justify-between mb-5">
                      <p className="md:text-2xl text-lg font-medium">{item.title}</p>
                      <p className="text-xs md:text-base">{item.time}</p>
                    </div>
                    <p className="md:text-2xl text-base font-medium md:leading-10 leading-8 ">
                      {item.text}
                    </p>
                    <div className="flex justify-between mt-6">
                      <p className=" text-[20px] font-semibold">{item.name}</p>
                      <p className="cursor-pointer underline">{item.link}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default MentalEveryday;
