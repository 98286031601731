import { PageWrapper } from "components";
import AboutUsView from "./AboutUsView";

export const AboutUsContainer = () => {
  return (
    <PageWrapper>
      <AboutUsView />
    </PageWrapper>
  );
};
