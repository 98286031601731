import { researchGroup } from "assets/images";
import { useState } from "react";
import Patients from "./Patients";
import Therapists from "./Therapists";

const Community = () => {
  const [toggle, setToggle] = useState<boolean>(true);
  return (
    <div className="mb-20 text-black ">
      <div
        className=" md:h-[80vh] h-[40vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center"
        style={{
          backgroundImage: `url(${researchGroup})`
        }}
      >
        <div className="bg-black bg-opacity-70 text-white md:h-[80vh] h-[40vh] flex flex-col items-center justify-center gap-8">
          <h1 className="text-2xl md:text-5xl font-semibold">Community Standards</h1>
          <p className="hidden md:block md:w-[730px]">
            We understand the unique challenges and barriers our community may face when discussing
            and addressing mental health issues. Our goal is to create a safe, open, and
            non-judgmental space for our members to share their experiences, emotions, and struggles
            without fear. To maintain this safe and respectful environment, we ask that all members
            adhere to the following guidelines.
          </p>
        </div>
      </div>
      <p className="md:text-xl text-base leading-7 font-normal text-black px-6 md:hidden pt-8 text-justify">
        We understand the unique challenges and barriers our community may face when discussing and
        addressing mental health issues. Our goal is to create a safe, open, and non-judgmental
        space for our members to share their experiences, emotions, and struggles without fear. To
        maintain this safe and respectful environment, we ask that all members adhere to the
        following guidelines.
      </p>
      <div className=" text-sm md:text-2xl lg:mx-80 font-bold  grid grid-cols-2  my-10 ">
        <button
          onClick={() => setToggle(!toggle)}
          className={` ${!toggle ? "bg-orange" : "bg-[#F5F5F5]"}
           ${!toggle ? "text-white" : "text-black"}
            rounded py-2 px-6 justify-self-end shadow-black `}
        >
          For Patients
        </button>

        <button
          onClick={() => setToggle(!toggle)}
          className={` ${toggle ? "bg-orange" : "bg-[#F5F5F5]"}
           ${
             !toggle ? "text-black" : "text-white"
           } rounded py-2 px-4 justify-self-start shadow-black`}
        >
          For Therapists
        </button>
      </div>
      <div className="  px-8 md:px-28">
        {toggle ? <Therapists /> : <Patients />}

        <h1 className="text-lg md:text-3xl font-bold	text-orange mb-4">Safety</h1>
        <ul className=" list-disc font-normal md:text-xl text-base leading-8 md:leading-10 ml-6 mb-14 text-justify flex flex-col gap-8 mt-8">
          <li>
            No harmful or dangerous advice: We do not allow any advice or information that could be
            harmful or dangerous to be shared within the community.
          </li>
          <li>
            Avoid any racist, sexist, or discriminatory language or behavior. This includes but is
            not limited to: hate speech, microaggressions, bullying, and stereotyping.
          </li>
          <li>
            Respect members’ privacy and do not share personal information outside of the community
            without the consent of the person who shared it.
          </li>
        </ul>

        <p className="border-t-[1px] border-[rgba(0, 0, 0, 0.2)] pt-2 mt-20 md:text-xl text-base leading-8 md:leading-10 text-justify">
          By following these guidelines, we can create a community that supports and empowers one
          another in addressing mental health and breaking down the barriers that prevent us from
          seeking the help we need.
        </p>
      </div>
    </div>
  );
};

export default Community;
