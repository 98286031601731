import {
  About,
  AppointmentHeader,
  Insurance,
  ProfileInfo,
  Services,
  Specialization
} from "components";
import { Link } from "react-router-dom";
import { HOME } from "routes/CONSTANTS";

const AppointmentsView = () => {
  return (
    <div>
      <ProfileInfo />
      <div className="flex lg:justify-center items-center gap-8 lg:gap-52 py-12">
        <Link
          to={HOME}
          className="text-[20px] font-bold text-[#00A552] border-b-[3px] border-[#00A552] px-36"
        >
          INFO
        </Link>
        <Link to={HOME} className="text-[20px] font-bold text-[#667085]">
          Reviews (13)
        </Link>
      </div>
      <Services />
      <About />
      <Specialization />
      <AppointmentHeader />
      <Insurance />
    </div>
  );
};

export default AppointmentsView;
