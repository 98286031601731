import { Link } from "react-router-dom";
import { LOGIN_THERAPIST, LOGIN_USER, SIGNUP } from "routes/CONSTANTS";

import { rightArrow } from "assets/icons";
import { logo } from "assets/images";
import { SignupText } from "components/layout/Signup";

const Login = () => {
  return (
    <div className=" grid md:grid-cols-2 grid-cols-1 md:h-screen h-auto">
      <SignupText
        headline="Let’s Get Started"
        text="Create an account today to access mental health services and speak to a licensed professional."
      />
      <div className="flex flex-col justify-between gap-4 md:h-screen h-auto pt-4 pb-16 px-16">
        <div className="flex justify-end">
          <img src={logo} />
        </div>
        <div className="flex gap-8 items-center justify-center">
          <Link
            to={LOGIN_USER}
            className="bg-orange rounded-2xl py-8 px-12 w-72 h-72  font-bold text-white flex flex-col justify-between gap-4"
          >
            <div className="text-4xl flex flex-col gap-4">
              <span>User </span>
              Login
            </div>
            <div className="flex justify-end">
              <img src={rightArrow} />
            </div>
          </Link>
          <Link
            to={LOGIN_THERAPIST}
            className="bg-orange rounded-2xl py-8 px-12 w-72 h-72 text-4xl font-bold text-white flex flex-col justify-between gap-4"
          >
            <div className="text-4xl flex flex-col gap-4">
              <span>Therapist </span>
              Login
            </div>
            <div className="flex justify-end">
              <img src={rightArrow} />
            </div>
          </Link>
        </div>
        <div className="text-lg text-black text-center">
          Don't have an account? Sign Up{" "}
          <span className="hover:text-orange text-green-100">
            <Link to={SIGNUP}>Here</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
