const WhyUs = () => {
  const issues = [
    {
      id: 1,
      name: "Anxiety",
      description:
        "a nervous disorder characterized by a state of excessive uneasiness and apprehension, typically with compulsive behavior or panic attacks."
    },
    {
      id: 2,
      name: "Phobia",
      description: "Phobia an extreme or irrational fear of or aversion to something."
    },
    {
      id: 3,
      name: "Addiction",
      description:
        "Addiction is a neuropsychological disorder characterized by a persistent and intense urge to engage in certain behaviors, despite substantial harm"
    },
    {
      id: 4,
      name: "Depression",
      description:
        "A mental health disorder characterized by persistently depressed mood or loss of interest in activities, causing significant impairment in daily life."
    },
    {
      id: 5,
      name: "PTSD",
      description:
        "A disorder in which a person has difficulty recovering after experiencing or witnessing a terrifying event."
    },
    {
      id: 6,
      name: "Trauma",
      description:
        "Trauma is the lasting emotional response that often results from living through a distressing event."
    }
  ];
  return (
    <div className=" bg-green text-whiteBright items-center flex flex-col justify-items-center md:px-16 px-5 md:py-16 py-6">
      <div className="md:text-6xl text-3xl font-semibold">
        Why <span className="text-orange">Us</span>
      </div>
      <div className=" md:pt-12 pt-8 ">
        <p className="md:text-2xl text-base text-whiteLight text-center border-b-8 border-b-orange md:pb-5 pb-3">
          Black Mental Health Matters can help with open dialogue around for people suffering from
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-2 md:gap-16 gap-6 md:px-12 md:pt-28 pt-12">
        {issues.map((issue) => {
          return (
            <div className="w-full" key={issue.id}>
              <h1 className="font-semibold text-2xl md:text-4xl md:pb-6 pb-4 ">{issue.name}</h1>
              <div className="text-left text-base md:text-2xl text-whiteLight">
                {issue.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyUs;
