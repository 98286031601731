import { Donate, JoinUs, GetInvolvedHero, Campaign, Testimonial, Volunteer, FAQ } from "components";
import { Helmet } from "react-helmet";

const GetInvolvedView = () => {
  return (
    <>
      <Helmet>
        <title>Get involved with Black Mental Health Matters</title>
        <meta
          name="description"
          content="Get involved with BMHM, a web based application that connects users who need mental health services with mental health providers and provides an enabling environment to heal from depression, anxiety, and other forms of mental illness. Learn about how you can help and make a difference."
        />
        <meta name="keywords" content="mental health services, get involved, make a difference" />
      </Helmet>
      <GetInvolvedHero />
      <JoinUs />
      <Donate />
      <Campaign />
      <Testimonial />
      <Volunteer />
      <FAQ />
    </>
  );
};

export default GetInvolvedView;
