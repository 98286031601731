import {
  Appointment,
  DashboardAppointments,
  DashboardCheckIn,
  DashboardJournal,
  DashboardTrend,
  MoodBoard
} from "components";
const DashboardHomeView = () => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row gap-4 lg:h-[803px]">
        <div className="lg:basis-1/2">
          <DashboardCheckIn />
          <div className="mt-4 py-4 px-4 bg-white rounded-[10px] ">
            <div className="grid lg:grid-rows-2 lg:grid-flow-col gap-4">
              <Appointment />
              <DashboardJournal />
              <MoodBoard />
            </div>
            <DashboardAppointments />
          </div>
        </div>
        <div className="lg:basis-1/3 ">
          <DashboardTrend />
        </div>
      </div>
    </div>
  );
};

export default DashboardHomeView;
