import {
  OurStory,
  ResearchHero,
  WhatWeDo,
  MentalEveryday,
  ResearchTeam,
  ContactUs,
  Journals,
  SharedStories
} from "components";
import { Helmet } from "react-helmet";

const ResearchView = () => {
  return (
    <>
      <Helmet>
        <title>Research by Black Mental Health Matters</title>
        <meta
          name="description"
          content="Bridging the gap and harnessing the power of AI, our research department is advancing Black Mental Health by creating positive outcomes for those struggling with mental health"
        />
        <meta name="keywords" content="mental health matters , research, depression, anxiety" />
      </Helmet>
      <ResearchHero />
      <WhatWeDo />
      <OurStory />
      <SharedStories />
      <Journals />
      <MentalEveryday />
      <ResearchTeam />
      <ContactUs />
    </>
  );
};

export default ResearchView;
