import { Signup } from "components";
import { Helmet } from "react-helmet";

const SignupView = () => {
  return (
    <div>
      <Helmet>
        <title>BMHM | Sign Up</title>
        <meta
          name="description"
          content="This refers to the mental health issues that affect the Black community as a whole. It encompasses the social, cultural, and economic factors that contribute to mental health disparities within this community."
        />
        <meta
          name="keywords"
          content="Black mental health, Mental health support, Community guidelines, Safe space, Non-judgmental, Emotional support, Mental health stigma, Community rules, Respectful environment, Cultural sensitivity
"
        />
      </Helmet>
      <Signup />
    </div>
  );
};

export default SignupView;
