import { Gvolunteer } from "assets/images";

const InternshipFormHero = () => {
  return (
    <div
      className=" h-[40vh] md:h-[80vh] w-full bg-fixed bg-no-repeat relative bg-cover bg-center"
      style={{ backgroundImage: `url(${Gvolunteer})` }}
    >
      <div className="w-full h-[40vh] md:h-[80vh] flex items-center justify-start md:px-28 px-2">
        <h1 className="text-white text-3xl md:text-7xl font-bold text-center">
          Apply now and join our internship program
        </h1>
      </div>
    </div>
  );
};

export default InternshipFormHero;
