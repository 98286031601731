import { PageWrapper } from "components";
import StoriesFormView from "./StoriesFormView";

export const StoriesFormContainer = () => {
  return (
    <PageWrapper>
      <StoriesFormView />
    </PageWrapper>
  );
};
