import { caution } from "assets/icons";
import { ErrorMessage, Field } from "formik";

interface TextareaInputProps {
  nameAndId: string;
  label: string;
  sublabel?: string;
  subtext?: string;
}

function CTextarea({ nameAndId, label, sublabel, subtext }: TextareaInputProps) {
  return (
    <div className="flex flex-col">
      <label className="flex flex-col gap-2 mb-2" htmlFor={nameAndId}>
        <span className="text-[#555253] text-base md:text-2xl">{label}</span>
        <span className="text-[#555253] text-xs md:text-base">{sublabel}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        as={"textarea"}
        className="placeholder-gray text-black md:text-2xl text-sm font-normal py-1 px-2 md:h-[180px] h-[120px] focus:ring-0 border-2 border-gray rounded-md outline-none"
      />

      <div className="flex items-center gap-2 md:mt-4 mt-2">
        <img src={caution} />
        <p className="text-[#A1A1A1] text-xs md:text-base">{subtext}</p>
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default CTextarea;
