import { star } from "assets/icons";
import { certified, DrDawn, DrMarsha, DrPia } from "assets/images";
import { LinkButton } from "components/widgets";
import { SIGNUP } from "routes/CONSTANTS";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Help = () => {
  const doctors = [
    {
      id: 1,
      name: "Dr. Marsha N Harris",
      description: "Certified/Licensed School Psychologist",
      img: DrMarsha
    },
    {
      id: 2,
      name: "Dr. Pia L. Scott, LPC, GCDF, CLC",
      description: "Licensed Professional Counselor",
      img: DrPia
    },
    {
      id: 3,
      name: "Dr. Dawn G. Crosson, Psy.D",
      description: "Licensed Psychologist, Certified Trauma Therapist",
      img: DrDawn
    }
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className=" lg:mt-0 mt-10 md:px-12 xl:px-28 px-5 md:py-16 py-12">
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
        <div className=" w-full md:bg-white bg-green mb:p-0 py-14 px-2 md:rounded-none rounded-lg">
          <div className=" md:pl-10  pl-2 md:pt-6 pt-4 md:border-l-8 border-none   md:border-solid border-[#005028] md:mb-14 mb-5">
            <p className="md:text-2xl text-base font-normal md:mb-6 mb-2 md:text-black text-white">
              Need some help?
            </p>
            <h2 className="md:text-5xl text-2xl font-semibold text-orange ">
              Professional Therapist
            </h2>
          </div>
          <p className="w-full md:text-justify md:text-xl text-base font-normal md:text-black text-white  text-center md:leading-10 leading-8">
            Experience a holistic and culturally sensitive approach to mental health with our
            accessible telehealth platform. Our licensed therapists specialize in treating a wide
            range of mental health conditions, including PTSD, depression, anxiety, bipolar
            disorder, postpartum depression, dyslexia, and intergenerational traumas resulting from
            systemic issues like racism and police brutality. At our organization, we recognize that
            Black mental health matters and we are dedicated to providing specialized support to
            underserved Black communities who have experienced these conditions and still face
            inadequate access to services. Our virtual or in-person sessions are tailored to meet
            the unique needs of our clients and to promote healing, growth, and empowerment. Kindly
            Connect with us to receive the mental care and support you deserve.
          </p>
          <div className=" lg:mt-12  mt-12 hidden md:block">
            <LinkButton to={SIGNUP}>Get Started</LinkButton>
          </div>
        </div>

        <Carousel responsive={responsive} className="max-w-[100%] ">
          {doctors.map((doctor) => {
            return (
              <div
                key={doctor.id}
                className=" flex justify-center gap-8 relative w-full lg:mt-0 mt-4 md:bg-transparent bg-white"
              >
                <img
                  src={doctor.img}
                  alt=""
                  className="shadow w-[380px] h-[380px] rounded-full sm:w-[450px] sm:h-[450px] "
                  style={{ boxShadow: "none" }}
                />
                <div className="absolute bg-white shadow-2xl rounded-2xl w-[300px] lg:w-[350px] bottom-1 lg:bottom-4 left-[16px]  px-5  py-3">
                  <div className="flex justify-between items-center">
                    <p className=" text-[#00A552] font-bold text-base md:text-xl">{doctor.name}</p>
                    <img src={certified} alt="" className="h-8 w-8 md:w-12 md:h-12" />
                  </div>

                  <p className="text-sm md:text-base">{doctor.description}</p>
                  <div className="flex gap-3 mt-4 underline">
                    <img src={star} alt="" className="h-4 w-4 md:h-6 md:w-6" />
                    <span className=" font-normal text-xs md:text-xl">4.3</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Help;
