const Sections = () => {
  return (
    <div className="grid grid-cols-3 md:gap-8 gap-4 my-7 md:my-0">
      <div>
        <h1 className="text-[18px] font md:text-lg pb-6 font-[600] md:font-bold"> About Us</h1>
        <ul className="text-sm grid gap-4 font-semibold">
          <a href="/careers" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Careers
          </a>
          <a href="research" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Research
          </a>
          <a href="faqs" className="text-[14px] md:text-base font-[500] hover:text-orange">
            FAQs
          </a>
          <a href="contact" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Contact Us
          </a>
        </ul>
      </div>
      <div>
        <h1 className="text-[18px] font md:text-lg pb-6 font-[600] md:font-bold"> Services</h1>
        <ul className="text-sm grid gap-4 font-semibold">
          <a href="/community" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Community standards
          </a>
          <a href="partner" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Partners
          </a>
          <li className="text-[14px] md:text-base font-[500] hover:text-orange">Products</li>
        </ul>
      </div>
      <div>
        <h1 className="text-[18px] font md:text-lg pb-6 font-[600] md:font-bold"> Resources </h1>
        <ul className="text-sm grid gap-4 font-semibold">
          <a href="/stories" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Our stories
          </a>
          <a href="/resources" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Submit Resources
          </a>
          <a href="/resources" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Customizable resources
          </a>
          <a href="/resources" className="text-[14px] md:text-base font-[500] hover:text-orange">
            Downloads
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Sections;
