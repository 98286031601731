import { DashboardWrapper } from "components";
import QuizView from "./QuizView";

export const QuizContainer = () => {
  return (
    <DashboardWrapper>
      <QuizView />
    </DashboardWrapper>
  );
};
