import { AdditionalServices, ProductHero, ProductServices } from "components";

const ProductView = () => {
  return (
    <div>
      <ProductHero />
      <ProductServices />
      <AdditionalServices />
    </div>
  );
};

export default ProductView;
