import { searchArticleIMG } from "assets/images";

const SearchHero = () => {
  return (
    <>
      <header
        className="bg-center flex justify-center items-center py-28"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        style={{ backgroundImage: `url(${searchArticleIMG})` }}
      >
        <div className="">
          <h2 className=" text-center text-white font-semibold text-3xl">
            Search Journal Articles
          </h2>
        </div>
      </header>
    </>
  );
};

export default SearchHero;
