const Services = () => {
  return (
    <div className="flex lg:justify-center">
      <div className=" bg-white p-4 lg:w-[848px]">
        <h2 className="text-[#3C4E40] font-bold text-[24px] py-4">Services</h2>
        <div className="grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-2">
          <button className="bg-[#005028] text-white w-[196px]  rounded-[10px]  py-[8px]">
            <h1 className="text-[24px]">Individual</h1>
            <p className="text-[16px]">For myself</p>
          </button>
          <button className="bg-[#005028] text-white w-[196px]   rounded-[10px]  py-[8px]">
            <h1 className="text-[24px]">Couples</h1>
            <p className="text-[16px]">For me and my partner</p>
          </button>
          <button className="bg-[#005028] text-white w-[196px]  rounded-[10px] py-[8px]">
            <h1 className="text-[24px]">Teens</h1>
            <p className="text-[16px]">For my child</p>
          </button>
          <button className="bg-[#005028] text-white w-[196px]  rounded-[10px] py-[8px]">
            <h1 className="text-[24px]">Family</h1>
            <p className="text-[16px]">For my family</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;
