import { howtoDonateimg } from "assets/images";
import { LinkButton } from "components/widgets";
import { DONATEFORM } from "routes/CONSTANTS";

const HowtoDonate = () => {
  const HowtoDonate = [
    {
      id: 1,
      image: howtoDonateimg,
      name: "Select a plan",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    },
    {
      id: 2,
      image: howtoDonateimg,
      name: "Select Donation fund",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    },
    {
      id: 3,
      image: howtoDonateimg,
      name: "Make a Donation",
      story:
        "Your contribution supports our mission to promote mental health and wellness in the black community. We couldn't do it without your support"
    }
  ];
  return (
    <div className="mb-12 md:px-12 xl:px-28 px-5 md:py-16 py-12">
      <div className="flex flex-col items-center justify-center md:gap-20 gap-8">
        <p className="text-3xl md:text-6xl font-bold text-[#3C4E40]">How to Donate</p>

        <div className="flex md:flex-row flex-col gap-8">
          {HowtoDonate.map((donate) => (
            <div
              key={donate.id}
              className="flex flex-col w-full md:w-[400px] py-10 rounded-xl  bg-white items-center text-center justify-center cursor-pointer "
              style={{ border: "0.5px solid #B2ECC4" }}
            >
              <img src={donate.image} />
              <p className="text-2xl font-bold text-[#3C4E40] mt-5">{donate.name}</p>
              <div className="mt-3 xl:px-24 md:px-12 px-3">
                <p className="font-light text-base text-center leading-8 md:leading-10">
                  {donate.story}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="md:mt-12 mt-4 w-full flex items-center justify-center">
          <LinkButton to={DONATEFORM}>Donate Now</LinkButton>
        </div>
      </div>
    </div>
  );
};

export default HowtoDonate;
