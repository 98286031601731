interface ServiceProps {
  img: string;
  header: string;
  text: string;
}

const ServiceCard = ({ img, header, text }: ServiceProps) => {
  return (
    <div className="w-full sm:h-[330px] h-[302px] p-5 bg-green flex flex-col gap-3 rounded-md text-white items-center text-center">
      <div className="w-14 h-14 flex justify-center items-center border-2 rounded-full">
        <img className="w-6" src={img} alt={header} />
      </div>
      <h1 className="md:text-2xl text-xl text-white font-semibold">{header}</h1>
      <p className=" md:text-xl text-base text-white font-normal">{text}</p>
    </div>
  );
};

export default ServiceCard;
