import { location, mail, phone } from "assets/icons";

const ContactInfo = () => {
  const contact = [
    { id: 1, image: mail, text: "Email", info: "info@bmhm.com" },
    { id: 2, image: location, text: "Address", info: "5900 Balcones Dr, Austin, Texas 78731, US" },
    { id: 3, image: phone, text: "Phone", info: "+1(825) 735-6799" }
  ];
  return (
    <div className="flex items-center justify-center gap-4 md:gap-16 md:py-16 py-12 md:px-12 xl:px-28 px-5">
      {contact.map((item) => (
        <div
          key={item.id}
          className="flex flex-col md:gap-12 gap-2 w-full md:w-[350px] md:h-[350px] h-[180px] items-center justify-center text-center"
        >
          <div className="rounded-full p-2 md:p-8 md:h-36 h-12 w-12 md:w-36 bg-orange flex items-center justify-center">
            <img src={item.image} className="h-4 md:h-full w-4 md:w-full" />
          </div>
          <p className="text-xl md:text-5xl text-black mt-6 h-12 md:h-20">{item.text}</p>
          <a className="text-sm md:text-3xl text-orange font-medium md:font-bold">{item.info}</a>
        </div>
      ))}
    </div>
  );
};

export default ContactInfo;
