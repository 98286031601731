import { additionalservices } from "assets/images";
import { Link } from "react-router-dom";

const AdditionalServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center pb-16 md:pb-40 md:pl-32 px-5">
      <div className="">
        <img src={additionalservices} alt="two people hugging" className="md:ml-20 ml-3" />
        <div className="grid grid-cols-4 gap-4 absolute md:mr-[365px] md:mt-[-140px] -z-10 ml-0 mt-[-130px]">
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
        </div>
      </div>
      <div className=" mt-12 md:mt-0">
        <div>
          <h2 className="font-bold text-green md:text-4xl text-3xl">Additional Services</h2>
          <div className="bg-orange h-4 md:h-8 w-full mt-3 md:mt-6 mb-6 md:mb-12"></div>
        </div>
        <div className=" flex flex-col gap-12 mt-8">
          <p className="text-black text-lg md:text-2xl md:pr-32 px-5">
            In addition to our core services, Black Mental Health Matters offers a range of
            supplementary services to enhance your mental health journey. Our additional services
            are designed to provide you with even more tools and support, including personalized
            assessment tools, workshops, and educational resources on various mental health topics.
            Explore these valuable resources and take full control of your well-being
          </p>
          <Link to="" className="bg-orange text-white p-3 rounded-sm w-full md:w-1/4 text-base text-center">
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServices;
