import { PageWrapper } from "components";
import PetitionView from "./PetitionView";

export const PetitionContainer = () => {
  return (
    <PageWrapper>
      <PetitionView />
    </PageWrapper>
  );
};
