import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { HostFormProps } from "types";

export const hostform = createAsyncThunk(
  "show/request_to_host_show",
  async (formData: HostFormProps, thunkAPI) => {
    try {
      const res = await axios.post("", formData);
      if (res.data.STATUS === "SUCCESS") {
        return res.data.BODY;
      } else {
        throw new Error(res.data.MESSAGE);
      }
    } catch (error: any) {
      const message = error.response?.data?.MESSAGE || "Something went wrong";
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Initial state of the form
const initialState = {
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  formData: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    postal_code: "",
    home_address: "",
    professional_title: "",
    about_you: "",
    topics_of_discussion: [],
    reason: "",
    available_days: [],
    preferred_social_media: {
      name: "",
      url: ""
    },
    has_products: false,
    products: "",
    details: ""
  }
};

// Slice
const formSlice = createSlice({
  name: "hostform",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    resetForm: (state) => {
      state.formData = initialState.formData;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(hostform.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorMessage = "";
    });
    builder.addCase(hostform.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = "";
    });
    builder.addCase(hostform.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action.error.message ?? "Something went wrong";
    });
  }
});

export const { setFormData } = formSlice.actions;
export default formSlice.reducer;
