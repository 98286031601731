/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import axios from "axios";
import env from "configs";
import authHeader from "./auth-header";
import {
  FORGOT_PASSWORD,
  LOGIN,
  LOG_OUT,
  REGISTER,
  RESET_PASSWORD,
  BMHM_USER_DATA,
  LOGIN_SUCCESS_URL
  // CHANGE_PASSWORD
} from "./CONSTANTS";
import { GOOGLE_END_POINT } from "services/CONSTANTS";
// import { formatErrorResponse } from "utils";

interface LoginData {
  username: string;
  password: string;
}

interface ApiResponse {
  STATUS: string;
  MESSAGE: string;
  STATUS_CODE: number;
  BODY: {
    authToken: string;
    user: {
      email: string;
      full_name: string;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export const login = async ({ username, password }: LoginData) => {
  const response = await axios.post<ApiResponse>(`${env.API_BASE_URL}/${LOGIN}`, {
    username,
    password
  });

  if (response?.data?.BODY?.authToken) {
    localStorage.setItem(BMHM_USER_DATA, JSON.stringify(response.data.BODY));
  }

  return response.data;
};

export const loginSuccess = async () => {
  try {
    const config = {
      url: `${env.API_BASE_URL}/${LOGIN_SUCCESS_URL}`,
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    };
    const response = await axios(config);
    if (response.data?.BODY?.authToken) {
      localStorage.setItem(BMHM_USER_DATA, JSON.stringify(response.data.BODY));
    }
    return response.data;
  } catch (err) {
    return err;
  }
};

export const register = async (details: { full_name: string; email: string; password: string }) => {
  const response = await axios.post(`${env.API_BASE_URL}/${REGISTER}`, details);

  if (response?.data?.BODY?.authToken) {
    localStorage.setItem("BMHM_USER_DATA", JSON.stringify(response.data.BODY));
  }

  return response;
};

// export const googleSignup = async () => {
//   const response = await axios.get(`${env.API_BASE_URL}/${GOOGLE_END_POINT}`);

//   if (response?.data?.BODY?.authToken) {
//     localStorage.setItem("BMHM_USER_DATA", JSON.stringify(response.data.BODY));
//   }
// };

export const googleSignup = async () => {
  const response = await axios.post(`${env.API_BASE_URL}/${GOOGLE_END_POINT}`);
  if (response?.data?.STATUS === "SUCCESS") {
    const authToken = response.data.BODY.authToken;
    localStorage.setItem("BMHM_USER_DATA", JSON.stringify(response.data.BODY));
    return authToken;
  } else {
    throw new Error(response?.data?.MESSAGE || "Google signup failed");
  }
};

export const checkUserExists = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${env.API_BASE_URL}/${REGISTER}`, {
      email
    });
    return response.data.exists;
  } catch (error) {
    console.error("Error checking user existence:", error);
    throw error;
  }
};

export const sendVerificationLink = async (email: string) => {
  const response = await axios.post(`${env.API_BASE_URL}/send-verification-link`, { email });
  return response.data;
};

export const confirmAccount = async (confirmationCode: string) => {
  const response = await axios.get(env.API_BASE_URL + `/auth/confirm/${confirmationCode}`);
  return response.data;
};

export const logout = async () => {
  return await axios
    .get(`${env.API_BASE_URL}/${LOG_OUT}`, { headers: authHeader() })
    .then((response) => {
      if (response) {
        localStorage.removeItem(BMHM_USER_DATA);
      }
    })
    .catch((err) => {
      console.log("logout err", err);
    });
};

export const forgotPassword = async (details: { email: string }) => {
  const response = await axios.post(`${env.API_BASE_URL}/${FORGOT_PASSWORD}`, details);
  return response.data;
};

export const resetPassword = async (details: {
  token?: string;
  userId?: string;
  password: string;
  confirmPassword: string;
}) => {
  const response = await axios.post(`${env.API_BASE_URL}/${RESET_PASSWORD}`, details);
  return response.data;
};
