import { CInput, CSelect } from "components/modules";
import { Formik, Form } from "formik";

interface StepOneProps {
  onNext: (values: any) => void;
}

const StepOne = ({ onNext }: StepOneProps) => {
  const formikInitialValues = {
    title: "",
    audience: ""
  };
  const handleSubmit = (values: any) => {
    onNext(values);
  };
  return (
    <Formik initialValues={formikInitialValues} onSubmit={handleSubmit}>
      <Form className="flex flex-col gap-12">
        <CInput
          nameAndId="title"
          label="What will you Title your petition?"
          type="text"
          subtext="Get attention with a succinct headline for your petition. Try to make it urgent, short, and solution-oriented."
        />
        <CSelect
          nameAndId="audience"
          label="Who can make it happen?"
          sublabel="Petitions only work if they address the people who have the power to solve your problem or make change."
          subtext="Be as specific as possible: a specific representative is better than Congress. Try to choose someone who might be persuaded to take your side."
        >
          <option>Choose One</option>
          <option>Congress</option>
          <option>Legislative</option>
          <option>Executive</option>
          <option>Judiciary</option>
          <option>Business Owner</option>
          <option>CEO</option>
          <option>Local Community</option>
        </CSelect>
      </Form>
    </Formik>
  );
};

export default StepOne;
