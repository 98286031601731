import { PageWrapper } from "components";
import DonateFormView from "./DonateFormView";

export const DonateFormContainer = () => {
  return (
    <PageWrapper>
      <DonateFormView />
    </PageWrapper>
  );
};
