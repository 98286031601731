import React, { useState } from "react";
import { toPng, toBlob } from "html-to-image";
import { logo } from "assets/images";

const Banner = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(logo);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result as string);
    };
  };

  const handleDownloadClick = () => {
    toPng(document.getElementById("banner-form") as HTMLElement)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${name}_banner.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to download banner");
      });
  };

  const handleShareClick = () => {
    toBlob(document.getElementById("banner-form") as HTMLElement)
      .then(async (blob) => {
        if (!blob) {
          alert("Failed to create image");
          return;
        }
        const file = new File([blob], `${name}_banner.png`, { type: "image/png" });
        const fileArray = [file];
        const shareData = {
          title: "Banner",
          files: fileArray
        };
        return await navigator.share(shareData);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to share banner");
      });
  };

  return (
    <form id="banner-form" className="md:p-6 p-1 flex flex-col items-center space-y-4">
      <div className="bg-[rgba(0,80,40,0.76)] bg-opacity-50 rounded-md md:h-[400px] h-[300px]">
        <div className="p-1">
          <div className="flex justify-between items-center">
            <p className="text-lg md:text-3xl font-bold text-white">Black Mental Health Matters</p>
            <img src={logo} className="h-8 w-8" />
          </div>
          <div className="md:w-48 w-24 md:h-48 h-24 bg-gray-200 rounded-full overflow-hidden">
            <img src={image} alt="Banner Image" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>

      <label
        className="ml-2 bg-[#ADB3BC] cursor-pointer h-14 text-white text-base relative md:w-[250px] w-full rounded-sm flex items-center justify-center"
        htmlFor="photo"
      >
        Upload Photo [Optional]
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer"
        />
      </label>

      <input
        type="text"
        name="name"
        placeholder="Enter your name"
        value={name}
        onChange={handleNameChange}
        className="rounded-md border-[#667085] text-[#667085] placeholder-[#667085] pl-6 border-2 h-10 md:h-14  w-full focus:ring-0 outline-none"
      />

      <div className="flex flex-col gap-4 w-full">
        <button
          type="button"
          onClick={handleShareClick}
          className="text-white text-sm md:text-xl bg-[#FF9C00] hover:bg-[#FF9C03] py-2 px-4 md:h-14 flex items-center justify-center rounded-md w-full"
        >
          SHARE
        </button>
        <button
          type="button"
          onClick={handleDownloadClick}
          className="text-white text-sm md:text-xl bg-[#00A552] hover:bg-[#00A550] py-2 px-4 md:h-14 flex items-center justify-center rounded-md w-full"
        >
          DOWNLOAD
        </button>
      </div>
    </form>
  );
};

export default Banner;
