import { Accordion } from "components/modules";

const faqResults = () => {
  const questions = [
    {
      question: "What is Black mental health?",
      answer:
        "Black mental health refers to the mental health issues experienced by individuals who identify as Black or of African descent. This can include a range of mental health conditions such as anxiety, depression, PTSD, bipolar disorder, and others."
    },
    {
      question: "What are some common mental health challenges faced by Black people?",
      answer:
        "Black people may experience mental health challenges such as anxiety, depression, trauma, and PTSD, often as a result of systemic racism, discrimination, and other forms of oppression. Other challenges include stigma surrounding mental health, lack of access to mental health services, and difficulty finding culturally competent therapists."
    },
    {
      question: "How does racism affect Black mental health?",
      answer:
        "Racism has a significant impact on Black mental health, as it can lead to experiences of trauma, chronic stress, and depression. Black people may also experience racial trauma, which is a form of psychological harm that is caused by experiencing or witnessing racism."
    },
    {
      question: "What are some resources available for Black people seeking mental health support?",
      answer:
        "There are a number of resources available for Black people seeking mental health support, including therapy specifically for Black individuals, support groups, crisis hotlines, and online resources."
    },
    {
      question: "What can allies do to support Black mental health?",
      answer:
        "Allies can support Black mental health by educating themselves about the impact of systemic racism on mental health, advocating for policies that support mental health access and equity, and amplifying the voices of Black mental health professionals. They can also support Black-owned mental health businesses and donate to organizations that work to support Black mental health."
    }
  ];

  return (
    <div className=" text-base py-16 md:12 grid gap-8 items-center justify-items-center md:px-12 xl:px-28 px-5">
      <div className="w-full ">
        {questions.map(({ question, answer }, index) => (
          <Accordion
            key={index}
            title={question}
            questionTextColor="text-white"
            bgColor="bg-green"
            rounded="rounded-md"
            iconBg="bg-orange"
            border="border-gray"
            childColor="text-gray"
          >
            {answer}
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default faqResults;
