interface ProgressBarProps {
  currentStep: number;
}

const ProgressBar = ({ currentStep }: ProgressBarProps) => {
  const getProgressBarColor = (step: number) => {
    if (step === currentStep) {
      return "orange";
    }
    if (step < currentStep) {
      return "orange";
    }
    return "gray-300";
  };
  return (
    <div className="md:pl-12 py-2 md:pr-28 px-5">
      <div className=" h-2 grid grid-cols-5 gap-[2px] items-center bg-gray-300 ">
        <div
          className={`w-full h-2 bg-${getProgressBarColor(1)} transition-all duration-300`}
        ></div>
        <div
          className={`w-full h-2 bg-${getProgressBarColor(2)} transition-all duration-300`}
        ></div>
        <div
          className={`w-full h-2 bg-${getProgressBarColor(3)} transition-all duration-300`}
        ></div>
        <div
          className={`w-full h-2 bg-${getProgressBarColor(4)} transition-all duration-300`}
        ></div>
        <div
          className={`w-full h-2 bg-${getProgressBarColor(5)} transition-all duration-300`}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
