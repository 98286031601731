import { check } from "assets/images";
import { LinkButton } from "components/widgets";
import { HOME } from "routes/CONSTANTS";

interface SuccessProps {
  response: string;
}

const Success = ({ response }: SuccessProps) => {
  return (
    <div className="flex items-center justify-center h-screen p-2 md:p-5">
      <div className="md:h-[481px] md:w-[481px] w-full h-[350px] px-2 md:px-4 py-4 rounded-lg border-4 border-orange flex flex-col items-center justify-center gap-4">
        <div className="rounded-full flex items-center justify-center bg-gray md:h-[250px] h-[180px] md:w-[250px] w-[180px] ">
          <img src={check} className="md:h-[200px] h-[120px] md:w-[200px] w-[120px]" />
        </div>
        <p className="text-white text-sm md:text-base text-center ">{response}</p>
        <LinkButton to={HOME}>Home</LinkButton>
      </div>
    </div>
  );
};

export default Success;
