import useDate from "hooks/useDate";

const PrivacyPage = () => {
  const { dateTime } = useDate();
  return (
    <div className="  px-2 py-10 md:px-36 xl:px-80  leading-8 md:leading-10">
      <div className=" mb-6 md:mb-8 text-center  px-4 xl:px-0 ">
        <p className="text-base text-green my-8 md:my-16">
          Current as of <span>{`${dateTime.toDateString()} `}</span>
        </p>
        <div className="text-2xl md:text-5xl font-semibold mb-4 md:mb-12 ">Privacy Policy</div>
        <p className="text-sm sm:text-xl text-gray-200  mb-2  md:mb-16">
          Your privacy is important to us at BMHM. We respect your privacy regarding any information
          we may collect from you across our website.
        </p>

        <p className=" text-center text-green sm:text-lg sm:text-left sm:text-gray-200 sm:my-8 my-8">
          At Black Mental Health Matters," we are committed to protecting the privacy of our donors,
          clients, and website visitors. This Privacy Policy applies to all personal information
          collected by "Black Mental Health Matters," both online and offline.
        </p>
        <p className=" md:text-green text-orange  text-center sm:text-lg sm:text-left sm:text-gray-200">
          Personal Information Collection and Use
        </p>
      </div>

      <div className="bg-green rounded-lg sm:bg-white text-white sm:text-gray-200 sm:lg px-4 ">
        {/* What information do we collect? */}
        <div className="pb-6 pt-12 md:pb-10 md:pt-20 ">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            What information do we collect?
          </h1>
          <p className="leading-8 md:leading-10 md:text-lg text-base">
            We collect personal information from donors, clients, and website visitors through
            various means, including but not limited to:
            <p>
              Donations made through our website or by mail Signing up for our newsletter or email
              updates Contacting us through our website or by email Participating in surveys or
              other research studies The personal information we collect may include: Name
            </p>
            <p>
              Contact information (email, phone number, mailing address) Demographic information
              (age, gender, race, ethnicity) Other information relevant to our mission and services.
            </p>
          </p>
        </div>
        {/* How do we use your information? */}

        <div className="py-6 md:py-10">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            How do we use your information?
          </h1>
          <p className="mb-6 md:text-lg text-base leading-8 md:leading-10">
            At Black Mental Health Matters Inc, we take the privacy and security of your personal
            information seriously. We collect personal information from our users in order to
            operate our web application and provide mental health services. Some of the ways in
            which we use your personal information include:
          </p>
          <p className="mb-6 md:text-lg text-base leading-8 md:leading-10">
            Processing donations and issuing receipts: We collect personal information such as name,
            email address, and payment information to process donations made to our organization and
            to issue receipts for tax purposes.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Sending newsletters, updates, and other communications: We use your email address to
            send you newsletters, updates, and other communications about our organization and
            mental health services. You can opt-out of these communications at any time.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Responding to inquiries and requests for information: We use your personal information
            to respond to your inquiries and requests for information about our organization and
            mental health services.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Conducting research and analysis to improve our services: We may use your personal
            information to conduct research and analysis to improve our services and better
            understand our users' needs.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Connecting users with mental health providers: Our web application connects users who
            need mental health services with mental health providers. We use your personal
            information to match you with a mental health provider that meets your needs.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Complying with legal and regulatory requirements: We may be required to disclose your
            personal information in response to a subpoena, court order, or other legal or
            regulatory request.
          </p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Please note that we only collect and use personal information that is necessary for the
            purposes outlined above, and we take steps to protect the security and confidentiality
            of your personal information. If you have any questions or concerns about how we use
            your personal information, please contact us.
          </p>
        </div>
        {/* Do we use cookies and other tracking technologies? */}

        <div className="py-6 md:py-10">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            Do we use cookies and other tracking technologies?
          </h1>

          <p className="mb-6 md:text-lg text-base">Disclosure of Personal Information .</p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            We will not sell, share, or otherwise disclose personal information to any third party,
            except as required by law or to protect the rights, property, or safety of "Black Mental
            Health Matters," our donors, clients, or others
          </p>
        </div>
        {/* How long do we keep your information? */}

        <div className="py-6 md:py-10">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            How long do we keep your information?
          </h1>
          <p className="mb-6 md:text-lg text-base">Changes to This Policy.</p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            We reserve the right to modify this Privacy Policy at any time. If we make changes to
            this policy, we will update this page and indicate the date of the latest revision.
          </p>
        </div>
        {/*  How do we keep your information safe? */}

        <div className="py-6 md:py-10">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            How do we keep your information safe?
          </h1>
          <p className="mb-6 md:text-lg text-base">Security Measures.</p>
          <p className="md:text-lg text-base  leading-8 md:leading-10">
            We take appropriate security measures to protect personal information from unauthorized
            access, alteration, disclosure, or destruction. However, please be aware that no method
            of transmission over the internet or method of electronic storage is completely secure.
          </p>
        </div>
        {/* What are your privacy rights? */}

        <div className=" py-6 md:py-10">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            What are your privacy rights?
          </h1>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam
            ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu.
            Maecenas est morbi mattis id in ac pellentesque ac.
          </p>
        </div>
        {/*  How can you contact us about this policy? */}

        <div className=" pt-10 pb-20 ">
          <h1 className=" text-orange sm:text-3xl sm:text-black font-semibold mb-4">
            How can you contact us about this policy?
          </h1>
          <p className="mb-6 md:text-lg text-base">Contact Us</p>
          <p className="md:text-lg text-base leading-8 md:leading-10">
            If you have any questions or concerns about this Privacy Policy or our handling of
            personal information, please contact us at{" "}
            <a href="mailto:info@bmhm.org">info@bmhm.org </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
