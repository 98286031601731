import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Story } from "types";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import { StoryPage } from "./StoryPage";

const SharedThoughts = () => {
  const { id = "" } = useParams<{ id?: string }>();
  const [story, setStory] = useState<Story | null>(null);

  const fetchStory = () => {
    axios
      .get(`/api/stories/${id}`)
      .then((response) => {
        setStory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchStory();
  }, [id]);

  return (
    <div>
      {story && <StoryPage match={{ params: { storyId: story.id } }} />}
      {story && <CommentList storyId={story.id} />}
      {story && <CommentForm storyId={story.id} />}
    </div>
  );
};

export default SharedThoughts;
