import { Facebook, Instagram, Linkedin, Twitter, Youtube, LogoFooter } from "assets/images";

const ContactInfo = () => {
  const socialMediaIcons = [
    {
      id: 1,
      icon: Facebook,
      url: "https://web.facebook.com/bmhminc"
    },
    {
      id: 2,
      icon: Twitter,
      url: "https://twitter.com/BMHMINC?t=j14EyEHkS9U3H7dB4D7yMQ&s=09"
    },
    {
      id: 3,
      icon: Linkedin,
      url: "https://www.linkedin.com/company/black-mental-health-matters-bmhm"
    },
    {
      id: 4,
      icon: Instagram,
      url: "https://instagram.com/bmhminc"
    },
    {
      id: 5,
      icon: Youtube,
      url: "https://www.youtube.com/channel/UCm7g0xsxaqnoR8lLdLWgfaw"
    }
  ];
  return (
    <div className=" text-gray flex flex-col gap-4 ">
      <a href="/">
        <img src={LogoFooter} alt="logo-footer" height={100} width={100} />
      </a>
      <span className="text-sm md:text-base block hover:text-orange">
        <a href="https://wa.me/5125227790">(512) 522-7790</a>
      </span>
      <span className="text-sm md:text-base block hover:text-orange">
        <a href="mailto:info@bmhm.org">info@bmhm.org </a>
      </span>
      <div className="lg:flex gap-4 items-center justify-center hidden">
        {socialMediaIcons.map((icon) => (
          <a key={icon.id} href={icon.url}>
            <img src={icon.icon} className="h-{20} w-{20}" />
          </a>
        ))}
      </div>
      <span className="lg:hidden block  ">
        <p className="text-sm md:text-base">
          {" "}
          © {new Date().getFullYear()} Black Mental Health Matters. All rights reserved.
        </p>
      </span>
    </div>
  );
};

export default ContactInfo;
