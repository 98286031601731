import { PageWrapper } from "components";
import FaqView from "./FaqView";

export const FaqContainer = () => {
  return (
    <PageWrapper>
      <FaqView />
    </PageWrapper>
  );
};
