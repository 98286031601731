import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  placeholder: string;
  type?: "text" | "email";
}

function SRInput({ nameAndId, placeholder, type }: FormInputProps) {
  return (
    <div className="flex flex-col">
      <Field
        name={nameAndId}
        id={nameAndId}
        placeholder={placeholder}
        type={type ?? "text"}
        className="bg-white text-base w-full font-normal py-1 md:py-2 rounded-[8px] border-[#667085] text-[#667085] placeholder-[#667085] pl-6 border-2 h-14 focus:ring-0 outline-none"
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default SRInput;
