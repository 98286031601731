const Specialization = () => {
  return (
    <div className="flex lg:justify-center">
      <div className=" bg-[#005028] text-white px-12 pb-16 pt-8 lg:w-[848px]">
        <div className="flex flex-col lg:flex-row gap-20">
          <ul className="list-disc list-inside">
            <h2 className="text-[24px] font-bold py-6">Therapeutic Approach</h2>
            <li className="text-[16px]">Cognitive Behavioural Therapy (CBT)</li>
            <li className="text-[16px]">Acceptance and Commitment Therapy (ACT)</li>
            <li className="text-[16px]">Psychodynamic Therapy</li>
            <li className="text-[16px]">Family Therapy</li>
            <li className="text-[16px]">Narrative Therapy</li>
          </ul>
          <ul className="list-disc list-inside">
            <h2 className="text-[24px] font-bold py-6">Issues I Treat </h2>
            <li className="text-[16px]">Mental Health Issues</li>
            <li className="text-[16px]">Anger Management</li>
            <li className="text-[16px]">Trauma</li>
            <li className="text-[16px]">Self-Esteem Issues</li>
            <li className="text-[16px]">Relationship Issues</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Specialization;
