import { Video } from "assets/images";

const DashboardAppointments = () => {
  return (
    <div>
      <div className="flex items-center gap-12 lg:gap-4 pl-4 lg:pl-0 py-8">
        <p className="text-[#3C4E40] text-[14px] lg:text-[24px]">Upcoming Appointments</p>
        <p className="text-[#FF9C00] text-[12px] font-semiBold">see all</p>
      </div>
      <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between">
        <div className="lg:basis-1/2">
          <div className="flex flex-col pl-4 lg:pl-0 gap-2">
            <div className="flex items-center gap-3 lg:gap-6 p-1 bg-[#EEF3F7] rounded-[10px]">
              <p>
                Tue
                <br />
                19
              </p>
              <div className="border border-r-[3px] border-[#005028] h-[42px] rounded-[20px]"></div>
              <div>
                <p className="text-[#005028] text-[14px] font-semiBold">Mental Health Class</p>
                <p className="font-[300] text-[12px] text-[#005028]">09:00-10:00 - Zoom Meeting</p>
              </div>
              <div className="rounded-full bg-white p-2">
                <img src={Video} />
              </div>
            </div>
            <div className="flex items-center gap-3 lg:gap-6 p-1 bg-[#EEF3F7] rounded-[10px]">
              <p>
                Tue
                <br />
                26
              </p>
              <div className="border border-r-[3px] border-[#005028] h-[42px] rounded-[20px]"></div>
              <div>
                <p className="text-[#005028] text-[14px] font-semiBold">Mental Health Class</p>
                <p className="font-[300] text-[12px] text-[#005028]">09:00-10:00 - Zoom Meeting</p>
              </div>
              <div className="rounded-full bg-white p-2">
                <img src={Video} />
              </div>
            </div>
          </div>
        </div>
        <div className="lg:basis-[47%] px-2">
          <div className="flex items-center justify-between pl-4 py-1">
            <p className="text-[#3C4E40] text-[16px]">My Community</p>
            <p className="text-[#FF9C00] text-[12px] font-semiBold">All</p>
          </div>
          <table className="w-full ">
            <tbody>
              <tr>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="rounded-[5px] bg-[#3C4E40] p-1 text-white">BM</p>
                  </div>
                </td>
                <td>
                  <p className="text-[#667085] font-medium">Mental Health Class</p>
                </td>
                <td>
                  <p className="rounded-full bg-[#3C4E40] text-white text-[9px] text-center  p-1">
                    10
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="rounded-[5px] bg-[#3C4E40] p-1 text-white">HM</p>
                  </div>
                </td>
                <td>
                  <p className="text-[#667085] font-medium">Health Matters</p>
                </td>
                <td>
                  <p className="rounded-full bg-[#3C4E40] text-white text-[9px] text-center  p-1">
                    10
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardAppointments;
