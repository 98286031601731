import {
  vector1,
  vector2,
  vector3,
  vector4,
  vector5,
  vector6,
  vector7,
  vector8,
  vector9,
  vector10,
  vector11,
  vector12
} from "assets/images";

export const serviceData = [
  {
    id: 0,
    name: "Corpcare",
    img: vector1,
    description:
      "Designed for Corporates  that  prioritize Mental Health in the workplace  and need Therapy for their Employees."
  },
  {
    id: 1,
    name: "Therapy for Non Profits",
    img: vector2,
    description:
      "Designed to help non profits prioritize the mental health of their employees and volunteers."
  },
  {
    id: 2,
    name: "Educational Institution",
    img: vector3,
    description:
      "Provides  Institutional Therapy for Educators and employees at the Universities, Colleges and High Schools."
  },
  {
    id: 3,
    name: "Studcare",
    img: vector4,
    description:
      "Designed for students to help them take care of their mental health by providing discounted Therapy."
  },
  {
    id: 4,
    name: "Corporate Training",
    img: vector5,
    description:
      "Black Mental Health Matters offers corporate training services to businesses ,Universities, churches, organizations and government agencies on mental health , wellness and self care."
  },
  {
    id: 5,
    name: "Individual Therapy",
    img: vector6,
    description: "Providing individual therapy to underrepresented communities"
  },
  {
    id: 6,
    name: "Family Therapy",
    img: vector7,
    description:
      "Black Mental Health Matters  family Therapy package encourages  families  to prioritize their mental well being."
  },
  {
    id: 7,
    name: "Vetcare",
    img: vector8,
    description:
      "Tailored to help  military  veterans recover from PTSD and better manage their mental health."
  },
  {
    id: 8,
    name: "Active Duty Therapy",
    img: vector9,
    description:
      "Therapy services tailored for servicemen and women in active duty in the military."
  },
  {
    id: 9,
    name: "Religious Organization",
    img: vector10,
    description:
      "Therapy services for religious organizations and partnerships with religious organizations in hosting training programs to raise awareness,encourage holistic approach to mental health."
  },
  {
    id: 10,
    name: "C-Level Therapy",
    img: vector11,
    description:
      "Therapy tailored to help corporate leaders manage their mental health and increase productivity."
  },
  {
    id: 11,
    name: "Community Engagements",
    img: vector12,
    description:
      "Providing free training ,seminars on mental health  to raise awareness and providing resources to communities."
  }
];
