import { Formik, Form, FieldArray } from "formik";
import StepButton from "./StepButton";
import { FormInput } from "components/modules";
import { eduExpSchema } from "validations";
import { plus, x } from "assets/icons";

interface EduAndExperienceProps {
  onSubmit: (values: any) => void;
}

const EducationAndExperience = ({ onSubmit }: EduAndExperienceProps) => {
  const step2InitialValues = {
    education: [{ institution: "", degree: "", year: "" }],
    license_type: "",
    occupation_title: "",
    years_of_experience: "",
    areas_of_experience: ""
  };

  return (
    <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
      <Formik
        initialValues={step2InitialValues}
        validationSchema={eduExpSchema}
        onSubmit={onSubmit}
        enableReinitialize={false}
      >
        {({ values }) => (
          <Form className="flex flex-col gap-4 md:gap-6">
            <FieldArray name="education">
              {({ push, remove }) => (
                <div className="flex flex-col gap-4">
                  <p>6. Education History</p>
                  <div className="flex flex-col gap-8">
                    {values.education.map((_, index) => (
                      <div
                        key={index}
                        className="flex md:flex-row flex-col items-center gap-2 justify-between"
                      >
                        <FormInput
                          nameAndId={`education.${index}.institution`}
                          label={`Education ${index + 1}: Institution`}
                          placeholder="Institution"
                        />
                        <FormInput
                          nameAndId={`education.${index}.degree`}
                          label={`Education ${index + 1}: Degree`}
                          placeholder="Degree"
                        />
                        <FormInput
                          nameAndId={`education.${index}.year`}
                          label={`Education ${index + 1}: Year`}
                          placeholder="Year"
                        />
                        {index > 0 && (
                          <div>
                            <button
                              type="button"
                              className="text-sm text-[#FF0000] flex items-center gap-2"
                              onClick={() => remove(index)}
                            >
                              <img src={x} /> Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="flex items-center text-orange gap-2 text-sm"
                      onClick={() => push({ institution: "", degree: "", year: "" })}
                    >
                      <img src={plus} /> Add Education
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>

            <FormInput nameAndId="license_type" label="7. License Type" placeholder="LCSW" />
            <FormInput
              nameAndId="occupation_title"
              label="8. Occupation Title"
              placeholder="Clinical Social Worker"
            />
            <FormInput
              nameAndId="years_of_experience"
              label="9. Years of Experience"
              placeholder="5+ Years"
            />
            <FormInput
              nameAndId="areas_of_experience"
              label="10. Areas of Experience"
              placeholder="Anxiety, Post-partum Depression, ADHD"
            />

            <div className="w-full md:px-16 px-5 flex items-center justify-center mt-4">
              <StepButton>Next 2/5</StepButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EducationAndExperience;
