import { ResetPasswordForm } from "components";

const ResetPasswordView = () => {
  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordView;
