import { LinkButton } from "components/widgets";
import { Link } from "react-router-dom";
import { INDIVIDUALFORM, ORGANIZATIONFORM } from "routes/CONSTANTS";

const Program = () => {
  return (
    <div className="py-12 md:py-28 flex flex-col items-center justify-center gap-8 md:gap-12 md:px-28 px-5">
      <h2 className="font-bold md:text-6xl text-2xl text-[#667085] text-center md:w-[908px] w-full border-b-4 md:border-b-8 border-orange py-4 md:py-8">
        Join our Partnership Program
      </h2>
      <p className="md:text-3xl text-base font-bold text-center">
        A partnership focused on promoting mental health research, champion for mental health
      </p>
      <div className="flex md:flex-row flex-col items-center justify-center md:gap-8 gap-4 w-full">
        <LinkButton to={ORGANIZATIONFORM}>Organization</LinkButton>
        <Link
          to={INDIVIDUALFORM}
          className=" text-orange text-base md:text-2xl hover:bg-[#FFAC2A] border-2 border-orange bg-white hover:text-white py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
        >
          Individual
        </Link>
      </div>
    </div>
  );
};

export default Program;
