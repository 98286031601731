import { PageWrapper } from "components";
import ResourcesView from "./ResourcesView";

export const ResourcesContainer = () => {
  return (
    <PageWrapper>
      <ResourcesView />
    </PageWrapper>
  );
};
