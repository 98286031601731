import { Link } from "react-router-dom";
import { SIGNUP } from "routes/CONSTANTS";
import {
  lg1,
  lg2,
  lg3,
  lg4,
  lg5,
  lg6,
  lg7,
  sn1,
  sn2,
  sn3,
  sn31,
  sn4,
  sn5,
  sn6
} from "assets/images";

const LoginText = () => {
  return (
    <div className="w-full bg-green flex md:flex-col flex-row justify-between md:gap-0 gap-2 h-[250px] md:h-full  md:overflow-hidden">
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6 ">
        <img src={sn1} alt="" />
        <img src={sn2} alt="" />
        <img src={sn3} alt="" />
        <img src={sn31} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg1} alt="" />
        <img src={lg2} alt="" />
        <img src={lg3} alt="" />
      </div>
      <div className="flex flex-col gap-8 items-center justify-center">
        <p className="font-bold text-xl md:text-5xl text-white w-full md:w-[482px] md:text-left text-center">
          Don’t have an account?
        </p>
        <p className="text-base font-normal text-white w-full md:w-[450px] md:text-left text-center">
          Register today to access our mental health services, as well as interact with industry
          professionals.
        </p>
        <Link
          to={SIGNUP}
          className="text-orange text-base font-normal w-full md:w-[450px] md:text-left text-center"
        >
          Sign Up
        </Link>
      </div>
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6">
        <img src={sn4} alt="" />
        <img src={sn5} alt="" />
        <img src={sn6} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg4} alt="" className="pl-4" />
        <img src={lg5} alt="" />
        <img src={lg6} alt="" />
        <img src={lg7} alt="" />
      </div>
    </div>
  );
};

export default LoginText;
