interface SingleReviewProps {
  name: string;
  img: string;
  review: string;
  title: string;
  university: string;
}

const SingleReview = ({ img, name, review, title, university }: SingleReviewProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-3 ">
      <img className="md:h-36" src={img} alt={name} />
      <div className="flex flex-col text-sm font-semibold gap-3">
        <div className="text-blue">&#9733; &#9733; &#9733; &#9733; &#9733;</div>
        <p className="text-black text-base font-normal text-justify">{review}</p>
        <h1>
          {name}
          <span className="ml-2 opacity-70">{title}</span>
        </h1>
        <p className="opacity-70">{university}</p>
      </div>
    </div>
  );
};

export default SingleReview;
