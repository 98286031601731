import { volunteer, intern } from "assets/images";
import { SingleProgram } from "./SingleProgram";

const Programs = () => {
  return (
    <div className=" py-10 md:px-12 xl:px-28 px-5">
      <h1 className="text-green text-3xl font-bold">Internship and Volunteer Programs</h1>
      <p className="pb-3">
        We have opportunities for those with experience, little, or no experience. We offer you a
        platform to grow your career for professional or personal development. Check out the
        opportunities below.
      </p>
      <div className="flex flex-col md:flex-row justify-between gap-10 md:gap-20">
        <SingleProgram
          name="Internship"
          description="Recent graduates and students are offered opportunities to get real life experience to boost or start their professional careers."
          img={intern}
        />
        <SingleProgram
          name="Volunteer"
          description="Our volunteer programme provides interested talents with opportunities to make noticeable impacts in Africa and beyond."
          img={volunteer}
        />
      </div>
    </div>
  );
};

export default Programs;
