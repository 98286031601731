import React from "react";

interface ProgressBarProps {
  id: number;
  numSignatures: number;
  totalSignatures: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ numSignatures, totalSignatures }) => {
  const progress = Math.min(numSignatures / totalSignatures, 1) * 100;

  return (
    <div className=" mt-10 flex flex-col   bg-white rounded-lg px-2 md:px-16 pt-3 pb-6 md:py-5  md:h-[7rem] md:w-[600px] w-full ">
      <p className="text-base md:text-lg text-center">Petition Progress</p>
      <div className="flex  items-center justify-center bg-green mt-3">
        <div className="h-2 bg-orange md:w-[500px] w-full rounded-sm">
          <div className="" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      {/* <div className=" text-base text-orange">
        {numSignatures} out of {totalSignatures} signatures <span className="hidden">for {id}</span>
      </div> */}
    </div>
  );
};

export default ProgressBar;
