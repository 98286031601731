import { search, SearchArticlesBg } from "assets/images";

const SearchArticles = () => {
  return (
    <div
      className=" md:h-[60vh] h-[40vh] w-screen  bg-no-repeat   bg-center  p-10 "
      style={{
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        backgroundImage: `url(${SearchArticlesBg})`
      }}
    >
      <h1 className="text-center text-white text-4xl font-bold mb-24 mt-8">Journal Articles</h1>
      <div className="grid grid-cols-[1.5fr,0.2fr]  xl:mx-60 rounded   ">
        <input
          className=" py-4 text-center font-normal text-base placeholder-whiteLight  bg-transparent outline-0 border-2 border-whiteLight"
          type="text"
          placeholder="Explore more than 1000 articles from our  30+ open acces Jornals - All free to read and downlaod"
        />
        <button
          className="bg-green bg-no-repeat bg-center "
          style={{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            backgroundImage: `url(${search})`
          }}
        ></button>
      </div>
    </div>
  );
};

export default SearchArticles;
