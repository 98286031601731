import { PageWrapper } from "components";
import ProductView from "./ProductView";

export const ProductContainer = () => {
  return (
    <PageWrapper>
      <ProductView />
    </PageWrapper>
  );
};
