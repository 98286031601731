import { contactHero } from "assets/images";

const ContactHero = () => {
  return (
    <div
      className=" h-[40vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center "
      style={{ backgroundImage: `url(${contactHero})` }}
    >
      <div className="bg-black bg-opacity-70 w-screen h-[40vh] flex items-center md:justify-start justify-center">
        <h1 className=" text-white md:text-6xl text-4xl font-semibold md:pl-12 xl:pl-28">
          Contact Us
        </h1>
      </div>
    </div>
  );
};

export default ContactHero;
