import { AboutUs, abtusleft, abtusright } from "assets/images";

const WhoAreWe = () => {
  return (
    <div>
      <div className="absolute top-0 left-0 -z-10">
        <img src={abtusleft} />
      </div>
      <div className="md:grid items-center justify-center md:grid-cols-2 flex flex-col-reverse md:flex-none gap-12 md:py-16 py-12 md:px-28 px-5 ">
        <div className="grid items-center justify-items-start md:gap-4 gap-2">
          <div className="border-l-4 md:border-l-8 border-l-green  pl-5">
            <h1 className="text-lg md:text-4xl font-light ">Who are we?</h1>
            <h1 className="text-2xl md:text-6xl font-semibold text-orange pt-4">
              About <span className="text-black">Us</span>
            </h1>
          </div>

          <p className="text-base md:text-2xl md:pl-5 md:pt-8 pt-4 md:leading-[60px] leading-10">
            Black Mental Health Matters is an international mental health advocacy movement to
            dispel myths, network Mental Health Professionals, encourage open dialogue around Mental
            health among Black Communities, eradicate stigma and provide an enabling environment for
            people suffering from mental health to heal. We are a registered non profit corporation
            based in Texas, United States
          </p>
        </div>
        <div className=" grid items-center justify-items-center">
          <img src={AboutUs} alt="about-us" />
        </div>
      </div>
      <div className="absolute right-0 md:top-20 top-[550px] -z-10">
        <img src={abtusright} />
      </div>
    </div>
  );
};

export default WhoAreWe;
