import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import { logo, menu, close } from "assets/images";
import { NavLink, Link } from "react-router-dom";
import { HOME, SIGNUP } from "routes/CONSTANTS";

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const aboutUs = [
    { label: "Careers", href: "/careers" },
    { label: "Research", href: "/research" },
    { label: "FAQs", href: "/faqs" },
    { label: "Contact Us", href: "/contact" }
  ];

  const resources = [{ label: "Our Stories", href: "/stories" }];

  const getInvolved = [
    { label: "Volunteer", href: "/volunteer" },
    { label: "Internship", href: "/internshipform" },
    { label: "Donate", href: "/donate" },
    { label: "Campaign", href: "/campaign" },
    { label: "Be a Voice", href: "/be-a-voice" }
  ];

  const services = [
    { label: "Product", href: "/product" },
    { label: "Partner With Us", href: "/partner" }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`md:px-12 xl:px-28 px-5 md:py-4 py-2 flex items-center justify-between md:gap-0 sm:gap-0 z-40 bg-white w-full fixed top-0 left-0 ${
        scrolled ? "shadow-lg" : ""
      }`}
    >
      <Link to={HOME} className=" w-full md:w-44">
        <img src={logo} className="md:h-20 md:w-24 h-16 w-16" />
      </Link>
      <div
        className={`md:flex md:flex-row z-50 justify-center items-center md:w-auto md:relative absolute   ${
          menuOpen
            ? "left-[0px] top-[4.8rem] bg-white  z-40 md:p-10 w-[300px] h-[100vh]  transition-all duration-500 ease-in overflow-y-auto scrollbar-thumb-white scrollbar-track-white scrollbar-thin pb-28"
            : " left-[-800px] top-[4.8rem] transition-all duration-500 ease-in md:top-0 md:left-0"
        }`}
      >
        <div className="md:pl-0 pl-4 md:pt-0 pt-12 md:pb-0 pb-4 ">
          <NavLink
            to={HOME}
            className={({ isActive }) => (isActive ? "text-green-100" : "")}
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <span className="px-4 py-6 md:py-2 text-[#3C4E40] md:font-normal font-semibold hover:text-green-100 text-base md:block md:mx-2">
              Home
            </span>
          </NavLink>
        </div>

        <Dropdown title={{ label: "About Us", href: "/about-us" }} items={aboutUs} />
        <Dropdown title={{ label: "Resources", href: "/resources" }} items={resources} />
        <Dropdown title={{ label: "Get Involved", href: "/get-involved" }} items={getInvolved} />
        <Dropdown title={{ label: "Services", href: "/services" }} items={services} />

        <div className="left-0 lg:pl-12 pl-8 mt-7 md:mt-0 h-auto w-[200px] text-center">
          <Link
            to={SIGNUP}
            className="px-6 py-3 text-white text-base bg-green hover:bg-green-100 rounded-md block md:mx-2"
          >
            Get Started
          </Link>
        </div>
      </div>

      <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
        <img src={menuOpen ? close : menu} className="text-white" />
      </button>
    </nav>
  );
};
export default Navbar;
