import { useState } from "react";
import { PartnerFormInput, Success, TextareaInput } from "components/modules";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CenterLoader } from "components/widgets";
import { toast } from "react-toastify";
import { ContactFormProps } from "types";
import axios from "axios";
import env from "configs";
import { CONTACT } from "services/CONSTANTS";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formikInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    issue_description: ""
  };

  const individualFormValidation = Yup.object({
    first_name: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    last_name: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
    issue_description: Yup.string()
      .required("Please enter your message")
      .min(10, "Please enter a message")
  });

  const handleSubmit = async (formData: ContactFormProps) => {
    setIsLoading(true);

    try {
      await axios.post(`${env.API_BASE_URL}/${CONTACT}`, formData);
      setIsSuccess(true);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container md:px-12 xl:px-28 px-5">
      <div className="bg-green rounded-md md:p-8 p-3 mt-12 md:py-16 py-12 ">
        {isSuccess && (
          <Success response="Hello! We have received your message and, We will contact you shortly. Thank you!" />
        )}
        {!isSuccess && (
          <>
            <p className="text-white md:text-4xl text-2xl font-bold text-center pb-1">Contact Us</p>
            <Formik
              initialValues={formikInitialValues}
              validationSchema={individualFormValidation}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form>
                  {isLoading ? (
                    <div className="text-center mt-8">
                      <CenterLoader />
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-1 md:gap-8 gap-4 md:mt-12 mt-4">
                        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
                          <PartnerFormInput nameAndId="first_name" label="First Name" />
                          <PartnerFormInput nameAndId="last_name" label="Last Name" />
                        </div>
                        <PartnerFormInput nameAndId="email" label="Email" />
                        <PartnerFormInput nameAndId="phone" label="phone" />
                        <TextareaInput
                          nameAndId="issue_description"
                          label="How can we help?"
                          className="bg-white text-black md:text-2xl text-base font-normal py-1 px-2 md:h-[180px] h-[120px] focus:ring-0 border-2 border-white rounded-md outline-none"
                        />
                      </div>

                      <div className="mt-16 mb-8 flex items-center justify-center">
                        <button
                          type="submit"
                          className="text-white text-2xl bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
                          disabled={isSubmitting}
                        >
                          Send
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
