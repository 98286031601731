import { joinImg } from "assets/images";
import { LinkButton } from "components/widgets";
import { VOLUNTEER } from "routes/CONSTANTS";

const JoinUs = () => {
  return (
    <section>
      {/* Join section start */}
      <div className=" flex items-center md:justify-between py-10 md:py-24 px-5 md:px-28  md:flex-row flex-col-reverse">
        <div className="mt-20 md:w-3/6 md:mr-3 max-sm:w-auto">
          <img src={joinImg} width="450px" />
        </div>
        <div className="md:w-3/6 md:ml-3">
          <h2 className=" text-center md:text-left md:text-[#667085] font-semibold text-2xl md:text-5xl mb-4 border-b-4 md:border-b-8 border-green md:px-0 py-2 md:py-4 md:w-[80%] mx-auto md:mx-0 md:max-w-md">
            Why Volunteer?
          </h2>

          <div className="md:pt-6 text-base md:text-2xl leading-8 md:leading-10 lg:pr-32 max-sm:pr-0 text-justify">
            <p className=" mt-6">
              Volunteering your expertise and talents to help is an incredibly impactful and
              fulfilling way to make a difference in the lives of many individuals struggling with
              mental health issues. Help us achieve our goals and contribute to the more significant
              cause of promoting mental health equity. Whatever your skills are, this organization
              has a place for you.
            </p>
            <p className=" mt-6">
              You will also gain valuable experience that can benefit you personally and
              professionally that can be added to your portfolio/resume making you a more attractive
              candidate for potential employers.
            </p>
            <p className=" mt-6">
              Join us in our mission to create a world where mental health is a priority for all
              individuals. Your involvement will make a real difference in the lives of many.
            </p>
          </div>
          <div className="pt-6 md:pt-8">
            <LinkButton to={VOLUNTEER}>Volunteer</LinkButton>
          </div>
        </div>
      </div>
      {/* Join section end */}
    </section>
  );
};

export default JoinUs;
