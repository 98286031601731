import { upload } from "assets/images";
import axios from "axios";
import { GroupFormInput, TextareaInput, GroupSelectInput, Success } from "components/modules";
import { CenterLoader } from "components/widgets";
import env from "configs";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { VOLUNTEER } from "services/CONSTANTS";
import { VolunteerFormProps } from "types";
import * as Yup from "yup";

const VolunteerForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formikInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    postalCode: "",
    streetAddress: "",
    position: "",
    experience: "",
    committedHours: "",
    heardAbout: ""
  };

  const volunteerValidation = Yup.object({
    firstName: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    lastName: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
    phone: Yup.number().required("Please enter your Phone Number"),
    country: Yup.string().required("Please enter your Country"),
    state: Yup.string().required("Please enter your State/Province/Region"),
    postalCode: Yup.string().required("Please enter your PostalCode"),
    streetAddress: Yup.string().required("Please enter your Street Address")
  });

  const handleSubmit = async (values: VolunteerFormProps) => {
    setIsLoading(true);
    setIsSuccess(false);

    try {
      await axios.post(`${env.API_BASE_URL}/${VOLUNTEER}`, values);
      setIsSuccess(true);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <h1 className="text-center font-bold text-[40px] md:text-[46.82px] md:leading-[66.33px] pb-8 text-[#005028]">
        Volunteer <span className="text-[#ff9c00]">Registration</span> Form
      </h1>
      {isSuccess && (
        <Success response="You have successfully submitted your request as a volunteer, We will contact you shortly" />
      )}

      <Formik
        initialValues={formikInitialValues}
        validationSchema={volunteerValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          {isLoading ? (
            <div className="text-center mt-8">
              <CenterLoader />
            </div>
          ) : (
            <>
              <div className="bg-green rounded-md md:p-8 p-3 flex flex-col md:gap-8 gap-4">
                <div>
                  <h2 className="font-bold text-[35px] md:text-[40px] md:leading-[66.33px] text-[#ffffff] text-center ">
                    Thank you for choosing to volunteer with us
                  </h2>
                  <p className="font-bold text-[24px] md:text-[28px] md:leading-[46.82px] text-[#ffffff] text-center">
                    Please read through this description and choose your preferred interests
                  </p>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
                  <GroupFormInput
                    nameAndId="firstName"
                    label="First Name*"
                    placeholder=""
                    type="text"
                  />
                  <GroupFormInput
                    nameAndId="lastName"
                    label="Last Name*"
                    placeholder=""
                    type="text"
                  />
                  <GroupFormInput
                    nameAndId="email"
                    label="Email Address*"
                    placeholder=""
                    type="email"
                  />
                  <GroupFormInput nameAndId="phone" label="Phone*" placeholder="" type="tel" />
                  <GroupFormInput nameAndId="country" label="Country*" placeholder="" type="text" />
                  <GroupFormInput
                    nameAndId="state"
                    label="State/Province/Region*"
                    placeholder=""
                    type="text"
                  />
                  <GroupFormInput
                    nameAndId="postalCode"
                    label="Postal Code*"
                    placeholder=""
                    type="number"
                  />
                  <GroupFormInput
                    nameAndId="streetAddress"
                    label="Street Address*"
                    placeholder=""
                    type="text"
                  />
                </div>
                <GroupSelectInput
                  nameAndId="position"
                  label="Which of the following volunteer position do you want to apply for?"
                >
                  <option value="">Select a position</option>
                  <option value="">Host</option>
                  <option value="">Frontend Developer</option>
                  <option value="">UI/UX Designer</option>
                </GroupSelectInput>

                <TextareaInput
                  nameAndId="experience"
                  label="Tell us about your experience and how it’s relevant to and of the above programs"
                  placeholder="Enter your story..."
                />

                <GroupSelectInput
                  nameAndId="committedHours"
                  label="How much time are you willing to commit towards volunteering per week?"
                >
                  <option value="">Select an Option</option>
                  <option value="">10 Hours</option>
                  <option value="">20 Hours</option>
                  <option value="">40 Hours</option>
                </GroupSelectInput>
                <GroupSelectInput
                  nameAndId="heardAbout"
                  label="Where did you hear about our volunteer opportunities?"
                >
                  <option value="">Select an Option</option>
                  <option value="">Linkedin</option>
                  <option value="">friend</option>
                  <option value="">Other</option>
                </GroupSelectInput>
                <div>
                  <h3 className="font-bold text-[28px] md:text-[33.16px] text-[#ffffff] md:leading-[46.82px]">
                    Upload your cv
                  </h3>
                  <div className="h-[192.15px] border-solid border-2 border-[#ffffff] rounded-md flex justify-end items-center">
                    <input type="file" className=" hidden" />
                    <button>
                      <img src={upload} className="p-4" />
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-center py-8 ">
                  <button
                    type="submit"
                    className="text-white text-base md:text-2xl bg-[#FF9C00] hover:bg-[#FFAC2A]  md:h-16 rounded-md w-[240.92px] h-[48.21px]"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default VolunteerForm;
