import React from "react";
import { Field, ErrorMessage } from "formik";
// import { chevrondown } from "assets/icons";

interface GroupSelectProps {
  nameAndId: string;

  children: React.ReactNode;
}

function SMSelect({ nameAndId, children }: GroupSelectProps) {
  return (
    <div className="flex flex-col w-[150px] bg-white md:h-[73px] h-[44px] rounded-l-md border-white border-2 items-center justify-center">
      <div>
        <Field
          name={nameAndId}
          id={nameAndId}
          as={"select"}
          className=" py-1 focus:ring-0  outline-none md:text-2xl text-base"
        >
          {children}
        </Field>

        {/* <div className="flex items-center gap-2 md:mt-4 mt-2">
          <img src={chevrondown} />
        </div> */}
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default SMSelect;
