import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface MultiCarouselProps {
  children: React.ReactNode;
}
function ReactMultiCarousel({ children }: MultiCarouselProps) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const singleResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="relative">
      <Carousel
        responsive={responsive || singleResponsive}
        containerClass="py-3 pl-2 static"
        itemClass="p-2"
        showDots={true}
        arrows={true}
      >
        {children}
      </Carousel>
    </div>
  );
}

export default ReactMultiCarousel;
