import { HeaderBackground, bcampaign, money } from "assets/images";
import { LinkButton } from "components/widgets";
import { SIGNUP } from "routes/CONSTANTS";

const GetInvolvedHero = () => {
  return (
    <>
      <header
        className="h-[68vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full "
        style={{ backgroundImage: `url(${HeaderBackground})` }}
      >
        <div className="flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 ">
          <div className="text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight">
            Break stigma, promote wellness together
          </div>
          <div className="pt-2 md:pt-8">
            <LinkButton to={SIGNUP}>Get Involved</LinkButton>
          </div>
        </div>
      </header>
      <div className="bg-green text-white flex justify-between items-center px-7 md:px-16 flex-row h-[72px] md:h-[128px] ">
        <div>
          <h1 className="font-bold font-sans text-sm md:text-6xl text-center md:text-left">985+</h1>
          <h6 className="text-[#FFFBE8] font-normal text-[8px] md:text-xl">Donation Received</h6>
        </div>

        <div className="grid grid-cols-2 content-center gap-0">
          <div className="">
            <img src={money} className="h-7 w-6 md:h-auto md:w-[83px]" />
          </div>
          <div className="-ml-3 md:-ml-6">
            <h1 className="font-bold font-sans text-sm md:text-6xl text-center md:text-left">
              10M
            </h1>
            <h6 className="text-[#FFFBE8] text-[8px] md:text-xl">Money Donated</h6>
          </div>
        </div>

        <div className="grid grid-cols-2 content-center gap-0">
          <div>
            <img src={bcampaign} className=" h-7 w-6 md:h-auto md:w-[83px]" />
          </div>
          <div className="-ml-5 md:-ml-9">
            {" "}
            <h1 className="font-bold font-sans text-sm md:text-6xl text-center md:text-left">
              12+
            </h1>
            <h6 className="text-[#FFFBE8] text-[8px] md:text-xl">Active Campaigns</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInvolvedHero;
