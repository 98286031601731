const BMHMServices = () => {
  return (
    <div className="text-center md:px-28 px-6 md:py-16 py-12">
      <h1 className="md:text-4xl text-3xl font-bold mb-4">Black Mental Health Matters Services</h1>
      <p className="md:text-2xl text-lg opacity-70 md:px-44">
        Black Mental Health Matters offers a range of therapy and training services to individuals,
        corporations, non-profits, educational institutions, veterans, and religious organizations,
        as well as community engagement opportunities, all designed to prioritize and improve mental
        health and wellness.
      </p>
    </div>
  );
};

export default BMHMServices;
