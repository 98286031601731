import { Formik, Form } from "formik";
import StepButton from "./StepButton";
import { ThRadio, ThTextarea } from "components/modules";
import { questionnaireSchema } from "validations";

interface QuestionnaireProps {
  onSubmit: (values: any) => void;
}

const Questionnaire = ({ onSubmit }: QuestionnaireProps) => {
  const step4InitialValues = {
    mode_of_service: "",
    sliding_scale: "",
    approach_to_therapy: "",
    statement: ""
  };

  return (
    <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
      <Formik
        initialValues={step4InitialValues}
        validationSchema={questionnaireSchema}
        onSubmit={onSubmit}
      >
        <Form className=" flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <label>13. How would you like to offer your services?</label>
            <div className="flex items-center justify-between">
              <ThRadio name="mode_of_service" value="virtual" label="Virtual" />
              <ThRadio name="mode_of_service" value="inPerson" label="In-Person" />
              <ThRadio name="mode_of_service" value="both" label="Both" />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label>14. Are you willing to provide sliding-scale?</label>
            <div className="flex items-center justify-between">
              <ThRadio name="sliding_scale" value="yes" label="Yes" />
              <ThRadio name="sliding_scale" value="no" label="No" />
              <ThRadio name="sliding_scale" value="notSure" label="Not Sure" />
            </div>
          </div>

          <ThTextarea
            nameAndId="approach_to_therapy"
            label="15. What is your approach to therapy?"
            placeholder="Creating a supportive and safe environment for clients to explore their emotions and develop coping strategies. "
          />
          <ThTextarea
            nameAndId="statement"
            label="16. In a brief statement, why do you want to work with Black Mental Health Matters Inc.?"
            placeholder="I believe in the organization’s mission to address and dismantle systematic barriers to promote mental wellness."
          />

          <div className="w-full md:px-16 px-5 flex items-center justify-center mt-4">
            <StepButton>Next 4/5</StepButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Questionnaire;
