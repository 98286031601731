import { AttachFile } from "assets/images";
import { GroupFormInput, TextareaInput } from "components/modules";
import { Form, Formik } from "formik";
import { InternshipFormProps } from "types";
import * as Yup from "yup";

const InternshipForm = () => {
  const formikInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    univeristy: "",
    course: "",
    year: "",
    gpa: "",
    resume: "",
    coverLetter: "",
    skills: "",
    interests: "",
    gains: "",
    reference1: "",
    phone1: "",
    reference2: "",
    phone2: "",
    verify: false
  };

  const volunteerValidation = Yup.object({
    firstname: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    lastname: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    phone: Yup.number().required("Please enter your Phone Number")
  });

  const handleSubmit = (values: InternshipFormProps) => {
    console.log(values);
  };
  return (
    <div className="">
      <Formik
        initialValues={formikInitialValues}
        validationSchema={volunteerValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          <div className="bg-green  md:py-8 md:px-12 xl:px-28 p-5 flex flex-col md:gap-8 gap-4">
            <div className="text-center font-bold text-[24px] md:text-[34px]">
              <h1 className="text-[#ffffff]">
                Internship Application <span className="text-[#ff9c00]">Form</span>
              </h1>
            </div>
            <div className="text-center font-bold text-[12px] md:text-[24px]">
              <h3 className="text-[#ff9c00]">
                Black Mental Health <span className="text-[#ffff]">Matters</span>
              </h3>
            </div>

            <p className="font-normal text-[12px] md:text-[24px]  text-[#ffffff] text-center md:h-[136px] px-5 md:px-12 md:px-24">
              Thank you for your interest in the internship program at Black Mental Health Matters!
              We appreciate your dedication to supporting Black Mental Health. Please fill out the
              application form to the best of your ability. Our team will review your application
              and reach out if we find you to be a great fit for our internship program. Thank you
              again for your interest, we look forward to hearing from you soon!
            </p>
            <p className="text-[#ff9c00] font-bold text-[24px] mt-4">Personal Information:</p>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
              <GroupFormInput
                nameAndId="firstname"
                label="First Name*"
                placeholder="First Name"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="lastname"
                label="Last Name*"
                placeholder="Last Name"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="phone"
                label="Phone Number*"
                placeholder="Enter Phone Number"
                type="tel"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="date"
                label="Date of Birth*"
                placeholder="dd/mm/yyyy"
                type="number"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
            </div>
            <GroupFormInput
              nameAndId="address"
              label="Address*"
              placeholder="Address"
              type="text"
              className="bg-white h-[54px] rounded-[8px] px-4"
            />
            <p className="text-[#ff9c00] font-bold text-[24px] mt-4">Academic Information:</p>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
              <GroupFormInput
                nameAndId="University"
                label="Name of University*"
                placeholder="Name of University"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="course"
                label="Course of Study*"
                placeholder="Course of Study"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="year"
                label="Expected Year of Graduation*"
                placeholder="Expected Year of Graduation*"
                type="number"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="gpa"
                label="GPA*"
                placeholder="Enter your GPA Score"
                type="number"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
            </div>
            <p className="text-[#ff9c00] font-bold text-[24px] mt-4">Resume/CV:</p>
            <label className="bg-[#ECECEC] cursor-pointer h-14 text-[#3C4E40] text-base relative md:w-[230px] w-full rounded-[8px] flex items-center justify-center">
              <img src={AttachFile} alt="attach-file" />
              Attach Resume/CV
              <input
                type="file"
                name="resume"
                className="opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer"
              />
            </label>
            <p className="text-[#C4C4C4]">(File types: pdf, doc, docx, txt, rtf)</p>
            <label className="bg-[#ECECEC] cursor-pointer h-14 text-[#3C4E40] text-base relative md:w-[230px] w-full rounded-[8px] flex items-center justify-center">
              <img src={AttachFile} alt="attach-file" />
              Attach Cover Letter
              <input
                type="file"
                name="coverLetter"
                className="opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer"
              />
            </label>
            <p className="text-[#C4C4C4]">(File types: pdf, doc, docx, txt, rtf)</p>

            <p className="text-[#ff9c00] font-bold text-[24px] mt-4">Skills And Qualifications:</p>
            <TextareaInput
              nameAndId="skills"
              label="Please describe any relevant Skills, Experiences, or Coursework that would makes you a best candidate for this Internship*"
              placeholder=""
              className="bg-white h-[184px] rounded-[8px] p-4 outline-none"
            />
            <TextareaInput
              nameAndId="interests"
              label="Why are you interested in Interning with Black Mental Health Matters*?"
              placeholder=""
              className="bg-white h-[184px] rounded-[8px] p-4 outline-none"
            />
            <TextareaInput
              nameAndId="gains"
              label="What do you hope to gain*?"
              placeholder=""
              className="bg-white h-[184px] rounded-[8px] p-4 outline-none"
            />
            <p className="text-[#ff9c00] font-bold text-[24px] mt-4">References:</p>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
              <GroupFormInput
                nameAndId="reference1"
                label="Reference 1*"
                placeholder="Enter name of referee"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="phone1"
                label="Phone Number*"
                placeholder="Enter referee's phone number"
                type="tel"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="reference2"
                label="Reference 2*"
                placeholder="Enter name of referee"
                type="text"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
              <GroupFormInput
                nameAndId="phone2"
                label="Phone Number*"
                placeholder="Enter referee's phone number"
                type="tel"
                className="bg-white h-[54px] rounded-[8px] px-4"
              />
            </div>
            <div className=" text-white flex gap-2 ">
              <input name="verify" type="check" className="h-4 w-4" />
              <label className="font-normal md:text-[20px] text-sm">
                I certify that the information provided in this application is true and complete to
                the best of my knowledge. I understand that any false statements or omissions may
                disqualify me from further consideration for an internship or result in termination
                if discovered after my acceptance. I authorize Black Mental Health Matters to verify
                any information provided in this application.
              </label>
            </div>

            <div className="flex items-center md:justify-start  justify-center py-4 ">
              <button
                type="submit"
                className="text-white text-base md:text-2xl bg-[#FF9C00] hover:bg-[#FFAC2A]  md:h-16 rounded-md w-[240.92px] h-[48.21px]"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default InternshipForm;
