import { InstagramIcon, YoutubeIcon, linkedlnIcon, messageIcon, star } from "assets/icons";
import { Accept, Certified, TherapistProfile, map } from "assets/images";
import { Link } from "react-router-dom";
import { APPOINTMENTS } from "routes/CONSTANTS";
const ProfileInfo = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-center lg:items-center lg:gap-8 gap-16 ">
      <div className="flex flex-col gap-16">
        <div>
          <img
            src={TherapistProfile}
            className="border-8 border-[#005028] rounded-tr-[120px] rounded-bl-[120px]"
          />
        </div>

        <div>
          <Link
            to={APPOINTMENTS}
            className="p-[40px] h-[84px] text-white text-[17px] bg-[#00A552] hover:bg-green-100 rounded-[10px] font-[500]"
          >
            Book An Appointment
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col lg:flex-row gap-4  lg:gap-32 lg:items-center">
          <div className="flex items-center">
            <h1 className="text-[#667085] font-bold text-[24px] md:text-[28px] p-0">
              Alex Starnes
            </h1>
            <img src={Certified} />
          </div>

          <div className="flex gap-4 items-center">
            <img src={star} />
            <p className="text-[#3C4E40] text-[24px]">4.3</p>
          </div>
        </div>
        <div className="bg-[#B2ECC4] rounded-full w-[250px]">
          <div className="flex gap-6 px-7 py-3 items-center">
            <img src={Accept} className="text-[#3C4E40]" />
            <p className="text-[#3C4E40] font-[300] text-[20px]">Accepting Clients</p>
          </div>
        </div>
        <p className="md:text-[18px] text-[16px] text-[#3C4E40]">
          Licensed Associate Professional Counselor (LAPC)
        </p>
        <div className="flex gap-2 items-center">
          <img src={map} className="text-[[#667085]" />
          <p className="text-[#667085] text-[16px]">Buford, GA</p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={messageIcon} className="text-[[#3C4E40]" />
          <p className="text-[#3C4E40] text-[14px] md:text-[16px]">
            alexstarnes@mentalhealthcounselor.com
          </p>
        </div>
        <div className="flex items-center gap-6">
          <img src={linkedlnIcon} />
          <img src={InstagramIcon} />
          <img src={YoutubeIcon} />
        </div>
        <hr className="border border-1 border-[#C4C4C4]"></hr>
      </div>
    </div>
  );
};

export default ProfileInfo;
