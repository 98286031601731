import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import RouterConfig from "./routes/RouterConfig";
import "./index.css";
import { GoToTop } from "hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="font-Archivo">
      <Provider store={store}>
        <BrowserRouter>
          <GoToTop />
          <RouterConfig />
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </div>
  );
}

export default App;
