import { donate } from "assets/images";
import { LinkButton } from "components/widgets";
import { DONATE } from "routes/CONSTANTS";

const Donate = () => {
  return (
    <section>
      {/* Donate section start */}
      <div className=" flex items-center md:justify-between py-10 md:py-24 px-8 md:px-14 lg:px-20 max-sm:px-5  bg-green md:flex-row flex-col">
        <div className="md:w-3/6 md:mr-4 md:pr-10 text-white max-sm:w-auto">
          <h2 className="font-semibold text-3xl md:text-5xl lg:text-6xl md:leading-tight mb-4 border-l-8 border-orange pl-6 py-4 md:py-0">
            Why we need <br /> <span className="text-orange">your</span> support
          </h2>
          <p className="md:pt-8 text-base text-justify md:text-2xl leading-8 md:leading-10 lg:pl-6 ">
            Mental health is a vital aspect of our well-being. By donating, you contribute to a
            cause that directly supports those in need who are often marginalized and
            underrepresented. Your donation will help fund therapy and resources promoting mental
            health equity in the Black community.
          </p>
          <div className="pt-8 md:pt-6">
            <LinkButton to={DONATE}>Donate</LinkButton>
          </div>
        </div>
        <div className=" mt-20 md:mt-0 lg:w-3/6 ml-4 lg:pl-20 max-sm:w-auto max-sm:pl-0 max-sm:ml-0 max-sm:mt-5">
          <img src={donate} className="md:w-[420px]" />
        </div>
      </div>
    </section>
  );
};

export default Donate;
