import { LoginTherapistForm } from "components";

const LoginTherapistView = () => {
  return (
    <div>
      <LoginTherapistForm />
    </div>
  );
};

export default LoginTherapistView;
