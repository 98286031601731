import PrivacyPage from "components/layout/Privacy/PrivacyPage";
import { Helmet } from "react-helmet";

const PrivacyView = () => {
  return (
    <>
      <Helmet>
        <title>BMHM | Privacy Policy</title>
        <meta name="description" content="Description of Home Page" />
        <meta name="keywords" content="home, page, react" />
      </Helmet>
      <PrivacyPage />
    </>
  );
};

export default PrivacyView;
