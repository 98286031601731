import { PageWrapper } from "components";
import StoriesView from "./StoriesView";

export const StoriesContainer = () => {
  return (
    <PageWrapper>
      <StoriesView />
    </PageWrapper>
  );
};
