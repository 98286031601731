import { apply } from "assets/images";
import { LinkButton } from "components/widgets";

const Apply = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 md:py-16 pb-12 md:px-12 xl:px-28 px-5">
      <div className="">
        <div className="md:hidden block mb-12">
          <LinkButton to="/volunteerform">Become a Volunteer</LinkButton>
        </div>
        <div className="w-full md:w-[446px] md:h-[457px] h-full bg-gray-400 rounded-md md:block hidden">
          <img src={apply} className="-ml-4 -mt-4 rounded-md" />
        </div>
      </div>
      <div className="flex gap-8">
        <div className="flex flex-col items-center pt-1 md:mt-0 mt-12">
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
          <div className="md:h-12 h-14 w-1 bg-green-100"></div>
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
          <div className="md:h-12 h-14 w-1 bg-green-100"></div>
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
          <div className="md:h-12 h-9 w-1 bg-green-100"></div>
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
          <div className="md:h-12 h-14 w-1 bg-green-100"></div>
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
          <div className="md:h-20 h-14 w-1 bg-green-100"></div>
          <div className="h-4 w-4 bg-green-100 rounded-full"></div>
        </div>
        <div className="flex flex-col md:gap-9 gap-6 md:text-xl text-base md:font-semibold font-normal text-gray-700 md:mt-0 mt-12">
          <p>Choose the areas or fields you are passionate about.</p>
          <p>Determine what expertise and knowledge you can contribute.</p>
          <p>Make a resume for your service.</p>
          <p>Determine the frequency of your volunteerism.</p>
          <p>Obtain all the information you need about the volunteer position.</p>
          <p>
            After considering the above then{" "}
            <span className="text-green-100">
              <a href="/volunteerform">click here</a>
            </span>{" "}
            to fill out the volunteer application form or contact us via email at{" "}
            <span className="text-orange">
              <a href="mailto:volunteer@bmhm.org">volunteer@bmhm.org</a>
            </span>{" "}
            if you have any questions or concerns.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Apply;
