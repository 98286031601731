import { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { eye, eyeslash } from "assets/icons";

interface FormInputProps {
  nameAndId: string;
  placeholder: string;
  label: string;
}

function PwdInput({ nameAndId, placeholder, label }: FormInputProps) {
  const [password, setPassword] = useState<boolean>(true);
  return (
    <div className="flex flex-col border-b-2 border-b-black w-full ">
      <label className="text-black text-base font-light" htmlFor={nameAndId}>
        <span className="">{label}</span>
      </label>

      <div className="flex items-center justify-between">
        <Field
          name={nameAndId}
          id={nameAndId}
          placeholder={placeholder}
          type={password ? "password" : "text"}
          className="bg-white placeholder-gray placeholder:text-sm text-black text-base font-normal pt-2 pb-1 focus:ring-0 border-none outline-none"
        />
        <div className="cursor-pointer" onClick={() => setPassword(!password)}>
          {password ? <img src={eye} /> : <img src={eyeslash} />}
        </div>
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default PwdInput;
