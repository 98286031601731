import SingleReview from "./SingleReview";
import { daven, jenny } from "assets/images";

const Testmonial = () => {
  return (
    <div className="md:px-12 xl:px-28 px-5">
      <p className="text-sm text-blue pb-5 text-center font-semibold">3940+ Happy student intern</p>
      <h1 className="text-5xl font-bold pb-5 text-center">Don’t just take our words</h1>
      <div className="flex flex-col md:flex-row justify-evenly py-10 md:gap-8 gap-8">
        <SingleReview
          img={jenny}
          name="Jenny Wilson"
          title="Psychology"
          review={`"Interning with Black Mental Health Matters Inc was an eye-opening experience that allowed me to gain a deeper understanding of the unique challenges faced by Black individuals seeking mental health care. As a Psychology Major, I was already interested in mental health disparities, but this internship gave me a chance to work directly with a community-based organization dedicated to addressing these disparities. One of the most rewarding parts of my internship was being involved in writing research journals. I had never been involved in this type of work before, but I quickly realized the importance of using research to inform policies and programs that support mental health in Black communities. This experience also helped me develop my writing and analytical skills, which will undoubtedly be useful in my future career."`}
          university="University of briggs"
        />
        <SingleReview
          img={daven}
          name="Devon Lane"
          title="Psychology"
          review={`"Interning with Black Mental Health Matters Inc was a life-changing experience for me. As a Psychology Major, I had always been interested in the intersection of mental health and marginalized communities. This internship gave me the opportunity to learn more about the challenges faced by Black communities in accessing mental health care and contribute to the development of solutions. Being involved in writing research journals was a highlight of my internship. Through this process, I gained a deeper understanding of the research process and how research can be used to inform policies and programs that support mental health in Black communities. I also developed strong writing and analytical skills that I know will be valuable in my future career."`}
          university="University of Dustse"
        />
      </div>
    </div>
  );
};

export default Testmonial;
