import { why } from "assets/images";

const Why = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 items-center justify-center sm:px-16 md:px-28 px-5 md:py-16 py-12">
      <div>
        <img src={why} />
      </div>
      <div className="text-black flex flex-col gap-4">
        <p className="text-2xl md:text-5xl font-semibold md:pb-8 pb-4 md:text-left text-center md:border-b-8 border-b-4 border-b-green">
          Why Partner With Us?
        </p>
        <p className="md:text-xl text-base font-normal md:text-left text-center leading-8 md:leading-10">
          Black Mental Health Matters is a nonprofit organization that aims to promote mental health
          wellness and destigmatize mental health in the Black community. We welcome partners from
          all backgrounds and industries and are open to partnering with like-minded organizations
          who share our values and are committed to addressing disparities in mental healthcare.
          Partnering with BMHM can be beneficial for your organization because it is impactful,
          diverse, collaborative, innovative, and community-focused. By partnering with BMHM, you
          will be supporting a cause that is making a real difference in the lives of BIPOC
          communities. BMHM believes that collaboration is the key to creating meaningful change and
          is always exploring new ways to encourage trauma-informed care and improve access to
          culturally responsive care. Lastly, BMHM is rooted in the Black community and is dedicated
          to serving the needs of our community members. By partnering with BMHM, you will be
          supporting an organization that is making a real difference in the lives of underserved
          communities
        </p>
      </div>
    </div>
  );
};

export default Why;
