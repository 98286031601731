/* eslint-disable react/prop-types */
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Banner from "./Banner";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";
import React, { useState } from "react";
import { toPng, toBlob } from "html-to-image";
import { logo } from "assets/images";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CustomizedResources = ({ isOpen, onClose }: ModalProps) => {
  const banners = [
    {
      id: 1,
      item: <Banner />
    },
    {
      id: 2,
      item: <Banner2 />
    },
    {
      id: 3,
      item: <Banner3 />
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [name, setName] = useState("");
  const [image, setImage] = useState(logo);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage((reader.result as string) || image);
    };
  };

  const handleDownloadClick = () => {
    toPng(document.getElementById("banner-form") as HTMLElement)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${name}_banner.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to download banner");
      });
  };

  const handleShareClick = () => {
    toBlob(document.getElementById("banner-form") as HTMLElement)
      .then(async (blob) => {
        if (!blob) {
          alert("Failed to create image");
          return;
        }
        const file = new File([blob], `${name}_banner.png`, { type: "image/png" });
        const fileArray = [file];
        const shareData = {
          title: "Banner",
          files: fileArray
        };
        return await navigator.share(shareData);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to share banner");
      });
  };

  return isOpen ? (
    <div className="fixed inset-0 bg-[#000] bg-opacity-70 z-[1000] flex items-center justify-center p-5">
      <div className="p-2 flex flex-col gap-4 items-center justify-center h-full w-[700px] bg-[#FFFBE8] rounded-lg">
        <Carousel
          showDots={false}
          arrows={true}
          swipeable
          draggable
          slidesToSlide={1}
          responsive={responsive}
          dotListClass=""
        >
          {banners.map((banner) => {
            return (
              <div className=" items-center justify-items-center " key={banner.id}>
                <div className="">{banner.item}</div>
              </div>
            );
          })}
        </Carousel>

        <label
          className="ml-2 bg-[#ADB3BC] cursor-pointer h-14 text-white text-base relative md:w-[250px] w-full rounded-sm flex items-center justify-center"
          htmlFor="photo"
        >
          Upload Photo [Optional]
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer"
          />
        </label>

        <input
          type="text"
          name="name"
          placeholder="Enter your name"
          value={name}
          onChange={handleNameChange}
          className="rounded-md border-[#667085] text-[#667085] placeholder-[#667085] pl-6 border-2 h-10 md:h-14  w-full focus:ring-0 outline-none"
        />

        <div className="flex flex-col gap-4 w-full">
          <button
            type="button"
            onClick={handleShareClick}
            className="text-white text-sm md:text-xl bg-[#FF9C00] hover:bg-[#FF9C03] py-2 px-4 md:h-14 flex items-center justify-center rounded-md w-full"
          >
            SHARE
          </button>
          <button
            type="button"
            onClick={handleDownloadClick}
            className="text-white text-sm md:text-xl bg-[#00A552] hover:bg-[#00A550] py-2 px-4 md:h-14 flex items-center justify-center rounded-md w-full"
          >
            DOWNLOAD
          </button>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-black sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CustomizedResources;
