import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  type?: "text" | "number" | "email" | "password";
  className?: string;
  labelClassName?: string;
}

function PartnerFormInput({ nameAndId, label, type, className, labelClassName }: FormInputProps) {
  return (
    <div className="flex flex-col">
      <label
        className={`text-white md:text-2xl text-base font-bold md:pb-3 pb-2 ${
          labelClassName ?? ""
        }`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        type={type ?? "text"}
        className={
          className ??
          "bg-white placeholder-gray text-black md:text-2xl text-base font-normal py-1 md:px-4 px-2 md:h-[73px] h-[44px] focus:ring-0 border-2 border-white rounded-md outline-none"
        }
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default PartnerFormInput;
