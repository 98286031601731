interface StepButtonProps {
  children: React.ReactNode;
}

const StepButton = ({ children }: StepButtonProps) => {
  return (
    <button type="submit" className="bg-green hover:bg-green-100 text-white px-2 py-3 w-full">
      {children}
    </button>
  );
};

export default StepButton;
