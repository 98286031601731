import { DashboardWrapper } from "components";
import ChatsView from "./ChatsView";

export const ChatsContainer = () => {
  return (
    <DashboardWrapper>
      <ChatsView />
    </DashboardWrapper>
  );
};
