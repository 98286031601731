// import Modal from "../Campaign/Modal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { campaigns } from "utils";

import RecentComment from "./RecentComment";

const FullPetition = () => {
  const [commentOpen, setCommentOpen] = useState(false);

  const params = useParams();

  const petitionStory = campaigns.find((s) => s.id === Number(params.id));

  if (!petitionStory) {
    return <div>no petition</div>;
  }
  return (
    <>
      <div className="mt-[5rem] px-28 py-5 ">
        <div>
          <p className="text-xl font-semibold">Preamble:</p>
          <p className="text-[18px] text-justify">
            Mental health is a fundamental aspect of our overall well-being and is essential for a
            healthy, fulfilling life. Despite its importance, access to quality mental health
            services remains a challenge for many people worldwide, particularly for those in low-
            and middle-income countries. It's time to take action and ensure that everyone has
            access to the mental health services they need, regardless of where they live or their
            financial situation.
          </p>
        </div>

        <div className="mt-5">
          <p className="text-xl font-semibold">Petition:</p>
          <p className="text-[18px] text-justify">
            We, the undersigned, call on governments and decision-makers worldwide to take immediate
            action to provide free mental health services for all people. We demand the following:
          </p>
        </div>

        <div className="mt-5 flex flex-col gap-5">
          <p className="text-[18px] text-justify"> {petitionStory.Paragraphone}</p>
          <p className="text-[18px] text-justify"> {petitionStory.paragraphtwo}</p>
          <p className="text-[18px] text-justify"> {petitionStory.paragraphthree}</p>
          <p className="text-[18px] text-justify"> {petitionStory.paragraphfour}</p>
          <p className="text-[18px] text-justify"> {petitionStory.paragraphfive}</p>
          <p className="text-[18px] text-justify"> {petitionStory.paragraphsix}</p>
        </div>

        <div className="flex  justify-between items-center mt-10">
          <div className="flex gap-5">
            <button className="bg-green hover:bg-green-100 text-white font-bold md:py-4 p-3 md:px-4 rounded-md md:w-48 w-40">
              Sign the Petition
            </button>
            <button className="bg-orange  text-white font-bold md:py-4 p-3 md:px-4 rounded-md md:w-48 w-40">
              Share
            </button>
          </div>
          <div className="flex gap-5">
            <p className="text-[#EB354B] text-xl">100k Share</p>
            <p className="text-[#EB354B] text-xl">200.03k Comments</p>
          </div>
        </div>

        <p
          className="text-green text-end cursor-pointer underline mt-5 text-xl"
          onClick={() => setCommentOpen(!commentOpen)}
        >
          Write comment
        </p>
        {commentOpen && <RecentComment />}
      </div>
    </>
  );
};

export default FullPetition;
