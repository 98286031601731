import { ForgotPasswordForm } from "components";

const ForgotPasswordView = () => {
  return (
    <div>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordView;
