import { PageWrapper } from "components";
import GetInvolvedView from "./GetInvolvedView";

export const GetInvolvedContainer = () => {
  return (
    <PageWrapper>
      <GetInvolvedView />
    </PageWrapper>
  );
};
