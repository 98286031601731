import { BsArrowLeft } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { campaigns } from "utils";

// import { mentalStability } from "assets/images"

const PetitionHero = () => {
  const params = useParams();
  console.log(params);
  console.log(campaigns);
  const petitionStory = campaigns.find((s) => s.id === Number(params.id));

  if (!petitionStory) {
    return <div>no petition</div>;
  }

  return (
    <div>
      <header
        className="h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center "
        style={{ backgroundImage: `url(${petitionStory.image})` }}
      >
        <div className="flex flex-col gap-8 justify-center items-center px-6  lg:px-96 h-[80vh] bg-[#000] bg-opacity-60 w-full">
          <h1 className="text-white font-bold md:font-semibold text-4xl md:text-6xl text-center md:leading-tight">
            {petitionStory.title}
          </h1>
          <BsArrowLeft />
        </div>
      </header>
    </div>
  );
};

export default PetitionHero;
