import { Journal } from "assets/images";

const DashboardJournal = () => {
  return (
    <div className="bg-[#FFFAF2] rounded-[10px] lg:w-[236px] h-[154px]">
      <div>
        <div className="flex justify-center py-2 ">
          <img src={Journal} />
        </div>
        <p className="text-[24px] text-[#3C4E40] text-center ">Journal</p>
        <p className="text-[15px] text-[#3C4E40] text-center py-2">
          What’s Happening? You can write about it.
        </p>
      </div>
    </div>
  );
};

export default DashboardJournal;
