import * as Yup from "yup";

const hostSchema = Yup.object({
  first_name: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
  last_name: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
  email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
  phone: Yup.string().required("Please enter your Phone Number").min(3, "Invalid phone number"),
  country: Yup.string().required("Please enter your Country").min(2, "Invalid country"),
  city: Yup.string().required("Please enter your City").min(2, "Invalid city"),
  postal_code: Yup.string().required("Please enter your Postal Code").min(3, "Invalid postal code"),
  home_address: Yup.string()
    .required("Please enter your Home Address")
    .min(5, "Invalid home address"),
  professional_title: Yup.string()
    .required("Please enter your Professional Title")
    .min(2, "Invalid Title")
});

export default hostSchema;
