import { CTextarea } from "components/modules";
import { Form, Formik } from "formik";

interface StepTwoProps {
  onNext: (values: any) => void;
}

const StepTwo = ({ onNext }: StepTwoProps) => {
  const formikInitialValues = {
    description: ""
  };

  const handleSubmit = (values: any) => {
    onNext(values);
  };
  return (
    <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      <Form className="flex flex-col gap-12">
        <CTextarea
          nameAndId="description"
          label="What needs to be done?"
          sublabel="Explain the problem, what needs to change, and why."
          subtext="Think of it as a letter you're writing directly to the person who has the power to solve the issue."
        />
      </Form>
    </Formik>
  );
};

export default StepTwo;
