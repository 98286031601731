import {
  lg1,
  lg2,
  lg3,
  lg4,
  lg5,
  lg6,
  lg7,
  sn1,
  sn2,
  sn3,
  sn31,
  sn4,
  sn5,
  sn6
} from "assets/images";
import { Link } from "react-router-dom";
import { SIGNUP } from "routes/CONSTANTS";

const ForgotPasswordText = () => {
  return (
    <div className="w-full bg-green flex md:flex-col flex-row justify-between md:gap-0 gap-2 h-[250px] md:h-full  md:overflow-hidden">
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6 ">
        <img src={sn1} alt="" />
        <img src={sn2} alt="" />
        <img src={sn3} alt="" />
        <img src={sn31} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg1} alt="" />
        <img src={lg2} alt="" />
        <img src={lg3} alt="" />
      </div>
      <div className="flex flex-col gap-8 items-center justify-center">
        <p className="text-base font-normal text-white w-full md:w-[450px] md:text-left text-center">
          Forgot your passsword? no worries.
        </p>
        <p className="font-bold text-xl md:text-5xl text-white w-full md:w-[482px] md:text-left text-center">
          Get reset intructions sent to your email.
        </p>

        <p className="text-green-100 text-base w-full md:w-[450px] font-normal md:text-left text-center">
          New here?
          <span className="hover:text-orange">
            <Link to={SIGNUP}> signup today for free </Link>
          </span>
        </p>
      </div>
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6">
        <img src={sn4} alt="" />
        <img src={sn5} alt="" />
        <img src={sn6} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg4} alt="" className="pl-4" />
        <img src={lg5} alt="" />
        <img src={lg6} alt="" />
        <img src={lg7} alt="" />
      </div>
    </div>
  );
};

export default ForgotPasswordText;
