const GuestFormHero = () => {
  return (
    <div className="flex items-center justify-center md:h-[20vh]">
      <p className="text-green font-bold md:text-6xl text-2xl  md:w-[602px] w-[216px] text-center md:leading-[75px]">
        <span className="font-normal">Show Host</span>{" "}
        <span className="text-orange">Application</span> Form
      </p>
    </div>
  );
};

export default GuestFormHero;
