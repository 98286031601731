const DonateFormHero = () => {
  return (
    <div className="flex items-center justify-center md:h-[20vh]">
      <p className="text-green font-bold md:text-6xl text-2xl  md:w-[602px] w-[216px] text-center md:leading-[75px]">
        <span className="text-orange">Donate</span> Now
      </p>
    </div>
  );
};

export default DonateFormHero;
