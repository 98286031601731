import { longArrowRight } from "assets/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
  currentStep: number;
  question: string;
  answers: string[];
}

const Step = ({ handleNext, handlePrevious, question, answers, currentStep }: Props) => {
  const [selectedOption, setSelectedOption] = useState<number>();
  const [textAnswer, setTextAnswer] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedAnswer = localStorage.getItem(`Q${currentStep}`);
    if (storedAnswer) {
      answers.forEach((answer, index) => {
        if (answer === storedAnswer) {
          setSelectedOption(index);
        }
      });
    }
  }, []);

  const handleRadioChange = (index: number) => {
    localStorage.setItem(`Q${currentStep}`, answers[index]);
    setSelectedOption(index);
  };

  const submitQuiz = () => {
    navigate("/dashboard");
    localStorage.clear();
  };

  return (
    <>
      <p className="text-[#4A4A4A] text-2xl">{currentStep}/9:</p>
      <div className="mt-3">
        <p className="text-green text-3xl">{question}</p>
      </div>
      <form onSubmit={submitQuiz}>
        {answers?.map((answer, index) => {
          return (
            <div key={index}>
              <input
                type="radio"
                id={`radio-input-${answer}`}
                name="radio-group"
                value={answer}
                checked={selectedOption === index}
                onChange={() => handleRadioChange(index)}
                className="h-5 w-5 hidden"
              />
              <label htmlFor={`radio-input-${answer}`} className={`ml-2 cursor-pointer`}>
                <div
                  className={`py-[10px] px-[20px] border border-[#005028] text-2xl 
                rounded-md w-full transition-colors duration-200 ${
                  selectedOption === index ? "bg-green text-white" : ""
                }`}
                >
                  {answer}
                </div>
              </label>
            </div>
          );
        })}

        {currentStep === 9 && (
          <textarea
            className="w-full h-40 p-4 border border-green mt-12 mb-12 rounded-md"
            onChange={(e) => setTextAnswer(e.target.value)}
          >
            {textAnswer}
          </textarea>
        )}

        <div className={`${currentStep > 1 ? `justify-between` : `justify-end`} flex mt-10`}>
          {currentStep > 1 && (
            <button
              className="px-6 py-3 flex gap-2 items-center text-white text-base bg-green hover:bg-green-100 rounded-[4px] block md:mx-2"
              onClick={handlePrevious}
            >
              <img className="rotate-180 text-green" src={longArrowRight} alt="" />
              {`Back`}
            </button>
          )}
          {currentStep === 9 || selectedOption !== undefined ? (
            <button
              className="px-6 py-3 flex gap-2 items-center text-white text-base bg-green hover:bg-green-100 rounded-[4px] block md:mx-2"
              type={currentStep === 9 ? `submit` : `button`}
              onClick={() => (currentStep === 9 ? submitQuiz : handleNext())}
            >
              {`Next`}
              <img src={longArrowRight} alt="" />
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default Step;
