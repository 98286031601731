import { OrganizationForm, OrganizationFormHero } from "components";

const OrganizationFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <OrganizationFormHero />
      <OrganizationForm />
    </div>
  );
};

export default OrganizationFormView;
