import type { ReactNode } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

interface Props {
  children: ReactNode;
}

const DashboardWrapper = ({ children }: Props) => {
  return (
    <div className="bg-[#F6F9FB] flex">
      <DashboardSidebar />
      <div className="w-full">
        <DashboardHeader />
        <div className="p-8">{children}</div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
