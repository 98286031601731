import { Button } from "components/widgets";

interface VacancyProps {
  vacancy: string;
  type: string;
  experience: string;
}

const Vacancy = ({ vacancy, type, experience }: VacancyProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 justify-between items-between gap-3 md:items-center bg-whiteBright p-5 rounded-xl shadow-lg m-2">
      <div className="flex flex-row md:flex-col gap-3">
        <p>DESIGNATION</p>
        <p className="font-bold">{vacancy}</p>
      </div>
      <div className="flex flex-row md:flex-col gap-3">
        <p>LOCATION</p>
        <p className="font-bold">{type}</p>
      </div>
      <div className="flex flex-row md:flex-col gap-3">
        <p>YRS OF EXPERIENCE</p>
        <p className="font-bold">{experience}</p>
      </div>
      <Button label="Apply" style="mt-1" />
    </div>
  );
};

export default Vacancy;
