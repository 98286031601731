import { memo } from "react";
import {
  JasmynJames,
  MekayaWilliams,
  QuotationEnding,
  QuotationStarting,
  RhyanaEbanksBabb
} from "assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      Image: RhyanaEbanksBabb,
      paragraph:
        "I have loved hosting shows for BMHM, it has allowed me to be more educated on the state of mental health within Black communities across the globe, raise awareness of issues the directly impact Black people of all kinds and created a safe space to explore what mental health can look like from research, lived experience and practitioner perspectives. It has been an eye opening journey so far and I truly believe in the BMHM mission",
      name: "Rhyana Ebanks-Babb"
    },
    {
      id: 2,
      Image: MekayaWilliams,
      paragraph:
        "Working with BMHM has not only been my first  LinkedIn opportunity but an opportunity that I will cherish forever. Hearing perspectives beyond a western point of view is always refreshing and being involved with BMHM is a breath of fresh air.",
      name: "Mekaya Williams-Franklin"
    },
    {
      id: 3,
      Image: JasmynJames,
      paragraph:
        "I truly enjoy this space that has been created for our community to explore and engage in conversation about Mental Health. It has been a great experience to be able to give information while also learning from different cultural perspectives around the world. This is a phenomenal organization",
      name: "Jasmyn R. James"
    }
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="md:py-16 py-12 md:px-28 px-5">
      <div className="px-4 md:border-l-8 border-l-4 border-green">
        <p className="mb-2 font-medium md:text-4xl text-xl">What they are saying about us?</p>
        <p className="md:text-6xl text-2xl text-orange">Testimonials</p>
      </div>

      <Carousel
        showDots={true}
        arrows={true}
        swipeable
        draggable
        slidesToSlide={1}
        responsive={responsive}
        dotListClass=""
      >
        {testimonials.map((testimonial) => {
          return (
            <div className="py-10 md:py-28 items-center justify-items-center " key={testimonial.id}>
              <div className="bg-[#FFFBE8] gap-2 items-center grid md:grid-cols-2 grid-cols-1 lg:py-12 lg:px-20 py-8 px-2 md:min-h-[550px] min-h-[720px] shadow-lg rounded-2xl">
                <div>
                  <img
                    src={testimonial.Image}
                    alt={testimonial.name}
                    className="md:h-[350px] h-[250px] w-[250px] md:w-[350px] rounded-full"
                  />
                </div>
                <div className="">
                  <div className="">
                    <img
                      src={QuotationStarting}
                      alt="quotation-starting"
                      className="md:h-8 h-4 md:w-8 w-4"
                    />
                  </div>

                  <p className="md:md:text-xl text-base md:mx-8 mx-4 text-justify md:leading-10 leading-8">
                    {testimonial.paragraph}
                  </p>

                  <div className="flex items-center justify-end">
                    <img
                      src={QuotationEnding}
                      alt="quotation-ending"
                      className="md:h-8 h-4 md:w-8 w-4 "
                    />
                  </div>

                  <div className="md:text-xl text-lg font-bold py-2 md:px-8 px-4">
                    {testimonial.name}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default memo(Testimonials);
