import { Arrow, Comment, Like, NoteBoard, Star, StartTherapy } from "assets/images";
import { LinkButton } from "components/widgets";
import { SIGNUP } from "routes/CONSTANTS";

const WeDeliver = () => {
  return (
    <div className="grid  grid-rows-[0.fr,0.5fr,0fr] md:grid-rows-[1fr,2fr,1fr] items-center justify-items-center md:px-8 px-5 md:py-0 py-12">
      <div className=" text-3xl md:text-6xl font-semibold md:border-none border-b-4 border-b-orange md:p-0 pb-4">
        <span className="md:border-b-8 md:border-orange  md:pb-5">How do we </span>
        deliver?
      </div>

      <div className="grid grid-rows md:grid-cols-[1fr,0.1fr,1fr,0.1fr,1fr] md:gap-0 gap-16 md:mt-0 mt-16">
        <div className="md:grid md:grid-rows-3 md:gap-3 flex flex-col gap-6 text-center  items-center justify-items-center ">
          <p className="text-2xl px-12">Getting to know you</p>
          <div className=" grid items-center justify-items-center">
            <img src={NoteBoard} alt="note-board" />
          </div>
          <div className="text-lg md:text-2xl md:px-4">
            Answer background questions about yourself
          </div>
        </div>

        <div className=" grid items-center justify-items-center">
          <img src={Arrow} alt="arrow" />
        </div>

        <div className="md:grid md:grid-rows-[1fr,1fr,1fr] md:gap-3 flex flex-col gap-6 text-center items-center justify-items-center ">
          <p className="text-lg md:text-2xl px-12">Culturally Sensitive Mental Health Care</p>
          <div className=" grid  gap-3 items-center justify-items-center">
            <img src={Like} alt="like" />
            <div className="grid grid-cols-4 gap-1 pt-4">
              <img src={Star} alt="star" />
              <img src={Star} alt="star" /> <img src={Star} alt="star" />{" "}
              <img src={Star} alt="star" />
            </div>
          </div>
          <div className="text-lg md:text-2xl">
            We'll find the best match thats fits you the best
          </div>
        </div>

        <div className=" grid items-center justify-items-center">
          <img src={Arrow} alt="arrow" />
        </div>

        <div className="md:grid md:grid-rows-[1fr,1fr,1fr] md:gap-3 flex flex-col gap-6 text-center items-center justify-items-center">
          <p className="text-lg md:text-2xl px-12">Start therapy</p>
          <div className=" grid items-center justify-items-center relative">
            <img src={Comment} alt="comment" className="absolute left-3/4 bottom-3/4" />
            <img src={StartTherapy} alt="start-therapy" />
          </div>
          <div className="text-lg md:text-2xl">Start treatment to a happier and better you.</div>
        </div>
      </div>

      <div className="md:mt-0 mt-16 w-full flex items-center justify-center">
        <LinkButton to={SIGNUP}>Get Started</LinkButton>
      </div>
    </div>
  );
};

export default WeDeliver;
