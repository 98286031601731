import { submitted } from "assets/images";
import { Link } from "react-router-dom";
import { BiCopy } from "react-icons/bi";
import { FiFacebook, FiTwitter, FiLinkedin, FiInstagram, FiYoutube } from "react-icons/fi";

const SubmittedFormModal = () => {
  return (
    <div className="flex justify-center flex-col items-center text-center ">
      <img src={submitted} alt="submitted icon" className="w-[50%]" />
      <p className="text-[28px] font-semibold mt-6">Petition Signed Successfully</p>

      <div className="mt-8">
        <p className="text-[18px] text-[#667085] mb-8">Share to socials</p>
        <div className="flex gap-10 text-2xl text-[#667085] mb-10">
          <Link to="#">
            <BiCopy />
          </Link>
          <Link to="#">
            <FiFacebook />
          </Link>
          <Link to="#">
            <FiTwitter />
          </Link>
          <Link to="#">
            <FiLinkedin />
          </Link>
          <Link to="#">
            <FiInstagram />
          </Link>
          <Link to="#">
            <FiYoutube />
          </Link>
        </div>
        <p className="underline text-xl mb-16 cursor-pointer text-green font-normal">close</p>
      </div>
    </div>
  );
};

export default SubmittedFormModal;
