// import { contactflag} from "assets/images";
// import { HelpLines } from "types";

export const helpLines = [
  {
    country: "Nigeria",
    Emergency: "112",
    Suicide_Crisis: "08058820777, 09030000741",
    AIDS_HIV: "0905 083 7511, 6222",
    Domestic_Violence: "112 , 08056268573 , 07080601080 , 08085754226 , 07032165181, 08137960048",
    Sexual_Abuse: "09090006463",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "081 3157 7877",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Ghana",
    Emergency: "999",
    Suicide_Crisis: "2332 444 71279",
    AIDS_HIV: "",
    Domestic_Violence: "18555",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " HTTP://WWW.LIFELINE-INTERNATIONAL.ORG/LOOKING_FOR_HELP/GHANA"
  },
  {
    country: "Côte d’Ivoire",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Niger",
    Emergency: "112",
    Suicide_Crisis: "112",
    AIDS_HIV: "",
    Domestic_Violence: "22720321111, 22797388090",
    Sexual_Abuse: "22720321111, 22797388090",
    Mental_Health_Condition: "22780063800",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "227733201, 227723741",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Burkina Faso",
    Emergency: "17",
    Suicide_Crisis: "22625331283172",
    AIDS_HIV: "",
    Domestic_Violence: "22650335307",
    Sexual_Abuse: "22650335307",
    Mental_Health_Condition: "22670218383",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Mali",
    Emergency: "8000-1115",
    Suicide_Crisis: "22380001115",
    AIDS_HIV: "",
    Domestic_Violence: "2232210146, 2236738989",
    Sexual_Abuse: "2232210146, 2236738989",
    Mental_Health_Condition: "226202802",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "2232228332",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Senegal",
    Emergency: "17",
    Suicide_Crisis: "116",
    AIDS_HIV: "",
    Domestic_Violence: "221339917821, 221339902710",
    Sexual_Abuse: "221339917821, 221339902710",
    Mental_Health_Condition: "2218251930",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "221338599696",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Guinea",
    Emergency: "117",
    Suicide_Crisis: "224628707894",
    AIDS_HIV: "",
    Domestic_Violence: "22460281189",
    Sexual_Abuse: "224622146214",
    Mental_Health_Condition: "2218251930",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "224461286",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Benin",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Sierra Leone",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Togo",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Liberia",
    Emergency: "911",
    Suicide_Crisis: "6534308",
    AIDS_HIV: "",
    Domestic_Violence: "231 443 739 1413",
    Sexual_Abuse: "231 443 739 1413",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "231 443 739 1413",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " HTTP://WWW.LIFELINE-INTERNATIONAL.ORG/LOOKING_FOR_HELP/LIBERIA"
  },
  {
    country: "Mauritania",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Gambia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Guinea-Bissau",
    Emergency: "117",
    Suicide_Crisis: "2453215616, 2453215305",
    AIDS_HIV: "",
    Domestic_Violence: "245905015",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Cape Verde",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Ethiopia",
    Emergency: "911",
    Suicide_Crisis: "+251 91 972 1145",
    AIDS_HIV: "+251 91 972 1145",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "+251 91 199 8619",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Tanzania",
    Emergency: "112",
    Suicide_Crisis: "112, (255) 22 2117362",
    AIDS_HIV: "",
    Domestic_Violence: "(255) 222 862865",
    Sexual_Abuse: "(255) 222 862865",
    Mental_Health_Condition: "(255) 757 528 295",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(255) 22 266 6096",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Kenya",
    Emergency: "911",
    Suicide_Crisis: "722178177",
    AIDS_HIV: "",
    Domestic_Violence: "1195",
    Sexual_Abuse: "",
    Mental_Health_Condition: "+254 20 3000378, +254 20 2051323",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "116",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "1192",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Uganda",
    Emergency: "112",
    Suicide_Crisis: "(0-800) 111044, 999 112",
    AIDS_HIV: "",
    Domestic_Violence: "(0757) 709096",
    Sexual_Abuse: "(0757) 709096",
    Mental_Health_Condition: "0800 21 21 21",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "0 (800) 199195",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(256) 414 664 730, (256) 7835 21306",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Mozambique",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Madagascar",
    Emergency: "117",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "813, 2610340535013",
    Sexual_Abuse: "2610340418897",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "147",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "261202220215",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Malawi",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Zambia",
    Emergency: "999",
    Suicide_Crisis: "231 443 739 1413",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Somalia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Zimbabwe",
    Emergency: "999",
    Suicide_Crisis: "263 09 65000",
    AIDS_HIV: "",
    Domestic_Violence: "263-4 794983",
    Sexual_Abuse: "",
    Mental_Health_Condition: "263 733724567, 077 297 3238",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "263 73 286 7960",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "South Sudan",
    Emergency: "999",
    Suicide_Crisis: "999, 998",
    AIDS_HIV: "",
    Domestic_Violence: "0930008057, 0930008061",
    Sexual_Abuse: "0930008057, 0930008062",
    Mental_Health_Condition: "912649191",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "116",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Rwanda",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Burundi",
    Emergency: "117",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "25722279025, 25775955250",
    Sexual_Abuse: "25722279025, 25775955250",
    Mental_Health_Condition: "25775668879, 25722235378",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Eritrea",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Mauritius",
    Emergency: "112",
    Suicide_Crisis: "+230 800 93 93",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Djibouti",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Comoros",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Seychelles",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Egypt",
    Emergency: "122",
    Suicide_Crisis: "131114, 762 1602",
    AIDS_HIV: "34844169",
    Domestic_Violence: "02-25776792, 02-25787089",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "16000",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Algeria",
    Emergency: "34342, 43",
    Suicide_Crisis: "0021 3983 2000 58",
    AIDS_HIV: "",
    Domestic_Violence: "021 36 99 99",
    Sexual_Abuse: "021 36 99 99",
    Mental_Health_Condition: "26767139",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "26767139",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Sudan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Morocco",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Tunisia",
    Emergency: "197",
    Suicide_Crisis: "197, 190",
    AIDS_HIV: "",
    Domestic_Violence: "1899, 80 101 400",
    Sexual_Abuse: "1899, 80 101 401",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "South Sudan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Libya",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Democratic Republic of the Congo",
    Emergency: "117",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Angola",
    Emergency: "",
    Suicide_Crisis: "244222335870",
    AIDS_HIV: "",
    Domestic_Violence: "244932099730, '244924170902",
    Sexual_Abuse: "244932099730, '244924170902",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Cameroon",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Chad",
    Emergency: "2251-1237",
    Suicide_Crisis: "23522511237",
    AIDS_HIV: "",
    Domestic_Violence: "235519110",
    Sexual_Abuse: "235519110",
    Mental_Health_Condition: "23522522501",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "235519110",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Central African Republic",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Republic of the Congo",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Gabon",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Equatorial Guinea",
    Emergency: "114",
    Suicide_Crisis: "114",
    AIDS_HIV: "114",
    Domestic_Violence: "240555570333, 240556666156, 240551136818",
    Sexual_Abuse: "240555570333, 240556666156, 240551136818",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Sao Tome and Principe",
    Emergency: "",
    Suicide_Crisis: "‘ (239) 222-12-22 ext. 123’ ",
    AIDS_HIV: "",
    Domestic_Violence: "23922252112",
    Sexual_Abuse: "23922252112",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "South Africa",
    Emergency: "10111",
    Suicide_Crisis: "(0861) 322 322, 0027 51 444 5000, 0861 435 787",
    AIDS_HIV: "(011) 234 4837",
    Domestic_Violence: "0 (800) 150 150, ",
    Sexual_Abuse: "(083) 765 1235",
    Mental_Health_Condition: "0861 322 322, 0800 567 567, +27 (0) 11 781 1852",
    Human_Trafficking: "",
    Eating_Disorder: "27 (0)82 863 3159",
    Child_Abuse: "116,  087 822 1516",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(0800) 12 13 14, 0861 435 722, 0861 00 6962",
    Alzheimer_and_dementia: "0860 102 681, 0800 333 231",
    Bullying: "+27 (0) 11 781 1852",
    Website: "www.sadag.org"
  },
  {
    country: "Namibia",
    Emergency: "",
    Suicide_Crisis: " (09264) 61-232-221",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.LIFELINE.ORG.ZA"
  },
  {
    country: "Botswana",
    Emergency: "911",
    Suicide_Crisis: "2673911270",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "+267 311 7851, +267 73 487 900, +267 74 007 055",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " WWW.LIFELINEBOTSWANA.ORG/INDEX.HTML"
  },
  {
    country: "Lesotho",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Swaziland",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  // North America
  {
    country: "United States",
    Emergency: "911",
    Suicide_Crisis: "988, 1-800-273-TALK (8255)",
    AIDS_HIV: "(800) 448-0440,  1-800-221-7044",
    Domestic_Violence: "(800) 799-7233",
    Sexual_Abuse: "(888) 773-8368, 800-656- 4673",
    Mental_Health_Condition: "(800) 950-6264, 1-800-950-NAMI (6264)",
    Human_Trafficking: "(888) 373-7888",
    Eating_Disorder: "(630) 577-1330, 800-931-2237, 888-375-7767",
    Child_Abuse: "(800) 656-4673, 800-422-4453, 866-367-5444, 888-PREVENT",
    Postpartum_Support: "(800) 944-4773",
    Alcohol_and_Substance_Abuse: " 1-800-662-HELP (4357), 855-378-4373",
    Alzheimer_and_dementia: "800-272-3900, 866-232-8484, 800-539-9767",
    Bullying: "800-273-8255, 800-852-8336",
    Website: ""
  },
  {
    country: "Mexico",
    Emergency: "911",
    Suicide_Crisis: " 5255102550",
    AIDS_HIV: "",
    Domestic_Violence: "649 115 4927",
    Sexual_Abuse: "55 534 55737",
    Mental_Health_Condition: "555 533 5533, 1-866-531-2600",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "800 216 9231",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Canada",
    Emergency: "911",
    Suicide_Crisis: "1 (833) 456 4566, ",
    AIDS_HIV: "",
    Domestic_Violence: "(604) 875-0885, 1 (800) 363-9010, 1-800-799-7233,",
    Sexual_Abuse: "(604) 583-1295, 204-786-8631",
    Mental_Health_Condition: "1-866-531-2600,  1-866-585-0445, 613-921-5565",
    Human_Trafficking: "",
    Eating_Disorder: "(800) 931 2237, 1 (800) 888 4680, 416-340-4156, 1-866-633-4220",
    Child_Abuse: "(604) 583-1295, 800-422-4453",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(888) 425 2666,  1-866-585-0445, 1-866-531-2600",
    Alzheimer_and_dementia: "1-800-616-8816, 1-800-936-6033",
    Bullying: "1-877-352-4497",
    Website: " WWW.CRISISCENTRE.BC.CA"
  },
  {
    country: "Guatemala",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Haiti",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Dominican Republic",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Cuba",
    Emergency: "106",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Honduras",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Nicaragua",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "El Salvador",
    Emergency: "911",
    Suicide_Crisis: "126",
    AIDS_HIV: "",
    Domestic_Violence: "198",
    Sexual_Abuse: "198",
    Mental_Health_Condition: "50322849861",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Costa Rica",
    Emergency: "911",
    Suicide_Crisis: "506-253-5439",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Panama",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Jamaica",
    Emergency: "",
    Suicide_Crisis: " 1-888-429-KARE (5273)",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Trinidad And Tobago",
    Emergency: "",
    Suicide_Crisis: " (868) 645 2800",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Bahamas",
    Emergency: "911",
    Suicide_Crisis: "(2) 322-2763",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Belize",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Barbados",
    Emergency: "911",
    Suicide_Crisis: "(246) 4299999",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Saint Lucia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Grenada",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Saint Vincent And The Grenadines",
    Emergency: "",
    Suicide_Crisis: " 9784 456 1044",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Antigua And Barbuda",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Dominica",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Saint Kitts And Nevis",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Greenland",
    Emergency: "134",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  // South America
  {
    country: "Argentina",
    Emergency: "911, 112",
    Suicide_Crisis: "(2) 538194, +5402234930430",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "www.familiardesuicida.com.ar"
  },
  {
    country: "Bolivia",
    Emergency: "911",
    Suicide_Crisis: "3911270",
    AIDS_HIV: "",
    Domestic_Violence: "156",
    Sexual_Abuse: "156",
    Mental_Health_Condition: "800113040",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Brazil",
    Emergency: "188",
    Suicide_Crisis: "90035191",
    AIDS_HIV: "(81) 3421-7670",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(11) 3814-3487",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Chile",
    Emergency: "",
    Suicide_Crisis: "4141",
    AIDS_HIV: "800-37-88-00",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "800 730 800",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "http://www(.)nepsis(.)cl/"
  },
  {
    country: "Colombia",
    Emergency: "123, (5) 664 5612, (5) 664 4675",
    Suicide_Crisis: "106, (5) 339 9999",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "106, (605) 330 9000 ext 5131",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "106, (5) 330 9000 Extensión: 5131, (5) 330 8100",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Ecuador",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "911",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Guyana",
    Emergency: "999",
    Suicide_Crisis: "223-0001",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "999",
    Mental_Health_Condition: " (+592) 223–0001, 223–0009, (+592) 600-7896, 623-4444",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Paraguay",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Peru",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Suriname",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Uruguay",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "08000767 y *0767",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Venezuela",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  // AUSTRALIA
  {
    country: "Australia",
    Emergency: "000",
    Suicide_Crisis: "131114, 1300 659 467",
    AIDS_HIV: "",
    Domestic_Violence: "1800 737 732, 1300 78 99 78",
    Sexual_Abuse: "1800 737 732",
    Mental_Health_Condition: "1300 554 660, 1300 22 4636, 1800 187 263, 1800 022 222",
    Human_Trafficking: "",
    Eating_Disorder: "1 800 (33 4673) ED HOPE, 1800 33 4673",
    Child_Abuse: "1800 55 1800",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "1800 250 015, 1300 368 186,",
    Alzheimer_and_dementia: "1800 100 500, 1800 699 799",
    Bullying: "1800 55 1800, 1800 650 890",
    Website: "www.lifeline.org.au"
  },
  {
    country: "Papua New Guinea",
    Emergency: "",
    Suicide_Crisis: "675 326 0011",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.LIFELINE.WEB.ZA/PAPUA.HTM"
  },
  {
    country: "New Zealand",
    Emergency: "111",
    Suicide_Crisis: "1737, 0800 543 354, 0800 726 666",
    AIDS_HIV: "0 (800) 802 437",
    Domestic_Violence: "(06) 356 5868, 0800 456 450",
    Sexual_Abuse: "(06) 356 5868, (03) 377 5402",
    Mental_Health_Condition: "09 623 4812, 0800 611 116, 0800 269 4389 (0800 ANXIETY)",
    Human_Trafficking: "",
    Eating_Disorder: "0800 745 477,  09 522 2679",
    Child_Abuse: "(06) 356 5868",
    Postpartum_Support: "0800 376 633 or text 234",
    Alcohol_and_Substance_Abuse: "0 (800) 682 468, 0800 787 797, 0800 778 778",
    Alzheimer_and_dementia: "0800 004 001",
    Bullying: " 0800 376 633 or text 234, 0800 543 754 (0800 KIDSLINE)",
    Website: "WWW.LIFELINE.ORG.NZ/"
  },
  {
    country: "Solomon Islands",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Fiji",
    Emergency: "917",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Vanuatu",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Samoa",
    Emergency: "",
    Suicide_Crisis: "32000",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "HTTP://SAMOALIFELINE.ORG/"
  },
  {
    country: "Kiribati",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Tonga",
    Emergency: "",
    Suicide_Crisis: "23000",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Micronesia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Palau",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Marshall Islands",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Tuvalu",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Nauru",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  // ASIA
  {
    country: "China",
    Emergency: "110",
    Suicide_Crisis: "800-810-1117 or 010-82951332",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "021-64387250",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " http://www.lifeline-shanghai.com"
  },
  {
    country: "India",
    Emergency: "112",
    Suicide_Crisis:
      "8888817666, 91 44 24640050, 91-9820466726, +91 84229 84528, +91 84229 84529,7893078930",
    AIDS_HIV: "23543333",
    Domestic_Violence: "91 8800528880, 1800 212 9131, 98330 52684, +91-8882 498 498, ",
    Sexual_Abuse: "91 8800528880",
    Mental_Health_Condition:
      "+91-8686139139, 91-9820466726, +91 84229 84528, +91 84229 84529, 18005-477-200",
    Human_Trafficking: "1800 419 8588",
    Eating_Disorder: "0800 745 477",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "91 9818122848, 1800-11-0031, 1800 112 356",
    Alzheimer_and_dementia: "14567, 1800-180-1253, 98461 98471, 98461 98473, or 98461 98786",
    Bullying: "1098, 0124-4007444, 8448-8448-45",
    Website: "WWW.MPA.ORG.IN"
  },
  {
    country: "Indonesia",
    Emergency: "112",
    Suicide_Crisis: "1-800-273-8255",
    AIDS_HIV: "(62-411) 871051",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Pakistan",
    Emergency: "115",
    Suicide_Crisis: "0337-9216207",
    AIDS_HIV: "",
    Domestic_Violence: "1098",
    Sexual_Abuse: "",
    Mental_Health_Condition: "0311-7786264",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "042-1121",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Bangladesh",
    Emergency: "999",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Japan",
    Emergency: "110",
    Suicide_Crisis: "810352869090",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Philippines",
    Emergency: "911",
    Suicide_Crisis: "28969191, 0927-6541629",
    AIDS_HIV: "(02) 8376-2541, (02) 8410-0204",
    Domestic_Violence: "(63)2 8926 7744, +63 915 259 3029",
    Sexual_Abuse: "(63)2 8926 7744",
    Mental_Health_Condition:
      "+63 2 921 4958, 0917-899-8727, 0966-351-4518,  +63 2 8893 7603, +63 917 800 1123",
    Human_Trafficking: "",
    Eating_Disorder: "(63)2 8921 4958",
    Child_Abuse: "1-6-3",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "1550, (0917) 322-7807",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Vietnam",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Turkey",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Iran",
    Emergency: "110",
    Suicide_Crisis: " 1480",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Thailand",
    Emergency: "191",
    Suicide_Crisis: "(02) 713-6793",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "1323",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.SAMARITANSTHAILAND.BLOGSPOT.COM"
  },
  {
    country: "Myanmar",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "South Korea",
    Emergency: "112",
    Suicide_Crisis: "(2) 715 8600",
    AIDS_HIV: "02-792-0080",
    Domestic_Violence: "1577-1366",
    Sexual_Abuse: "02-880-5073",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Iraq",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Afghanistan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Saudi Arabia",
    Emergency: "112",
    Suicide_Crisis: "112, 911",
    AIDS_HIV: "971 50 6624994",
    Domestic_Violence: "1919",
    Sexual_Abuse: "",
    Mental_Health_Condition: "055 6701200",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "116111",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "1955, 8001278888, 995",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Uzbekistan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Malaysia",
    Emergency: "999",
    Suicide_Crisis: "(06) 2842500",
    AIDS_HIV: "603 4047 4222",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "603-2727 7434",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "603-2727 7434",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Yemen",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Nepal",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "North Korea",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Sri Lanka",
    Emergency: "",
    Suicide_Crisis: "011 057 2222662",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Kazakhstan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Syria",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Cambodia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Jordan",
    Emergency: "911",
    Suicide_Crisis: "110",
    AIDS_HIV: "",
    Domestic_Violence: "799530746",
    Sexual_Abuse: "791420249",
    Mental_Health_Condition: "777149409",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "797892444",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Azerbaijan",
    Emergency: "112",
    Suicide_Crisis: "510–66–36",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "United Arab Emirates",
    Emergency: "112 or 911, Police: 999,Ambulance: 998",
    Suicide_Crisis: "800 46342",
    AIDS_HIV: "",
    Domestic_Violence: "800 446 292",
    Sexual_Abuse: "800 2626",
    Mental_Health_Condition: "800623, 920033360",
    Human_Trafficking: "800 7283",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "050 110 6898, 0501310055",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Tajikistan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Israel",
    Emergency: "100",
    Suicide_Crisis: "1201",
    AIDS_HIV: "",
    Domestic_Violence: "1-800-22-0000",
    Sexual_Abuse: "1203",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "02-6333387, 9779",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Laos",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Lebanon",
    Emergency: "",
    Suicide_Crisis: "1564",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Kyrgyzstan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Turkmenistan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Singapore",
    Emergency: "999",
    Suicide_Crisis: "1800- 221 4444",
    AIDS_HIV: "(65) 6254 0212",
    Domestic_Violence: "903434-9",
    Sexual_Abuse: "903434-9",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "6-RECOVER (6-7326837)",
    Alzheimer_and_dementia: "WWW.SAMARITANS.ORG.SG",
    Bullying: "",
    Website: ""
  },
  {
    country: "Oman",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "State of Palestine",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Kuwait",
    Emergency: "112",
    Suicide_Crisis: "94069304",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "22440904, 22440905",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "147",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Georgia",
    Emergency: "112",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Mongolia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Armenia",
    Emergency: "911, 112",
    Suicide_Crisis: "(2) 538194",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Qatar",
    Emergency: "999, 112",
    Suicide_Crisis: "55530240",
    AIDS_HIV: "",
    Domestic_Violence: "919",
    Sexual_Abuse: "919",
    Mental_Health_Condition: "16000",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "50709809, 055597512",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Bahrain",
    Emergency: "999",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "80008001",
    Sexual_Abuse: "80008001",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "998",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "17300978",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Timor-Leste",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Cyprus",
    Emergency: "112",
    Suicide_Crisis: "8000 7773",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.CYPRUSSAMARITANS.ORG"
  },
  {
    country: "Bhutan",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Maldives",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Brunei",
    Emergency: "993",
    Suicide_Crisis: "145",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  // Europe
  {
    country: "Russia",
    Emergency: "112",
    Suicide_Crisis: "78202577577",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Ukraine",
    Emergency: "7333",
    Suicide_Crisis: "7333",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " WWW.DOVIRA058.NETFIRMS.COM"
  },
  {
    country: "Poland",
    Emergency: "112",
    Suicide_Crisis: "5270000",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "pomoctel.free.ngo.pl"
  },
  {
    country: "Romania",
    Emergency: "112",
    Suicide_Crisis: "0800 801 200",
    AIDS_HIV: "+40 21 201 78 83, +40 21 319 93 29",
    Domestic_Violence: "0262 25 07 70, 021 311 46 36",
    Sexual_Abuse: "263 25 07 70, 021 311 46 36",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "http://www.antisuicid.com/"
  },
  {
    country: "Czech Republic",
    Emergency: "112",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "116 111",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Hungary",
    Emergency: "112",
    Suicide_Crisis: "116123",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Belarus",
    Emergency: "112, 102",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "8 801 100 8 801",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "801–100–1611",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Bulgaria",
    Emergency: "112",
    Suicide_Crisis: "0035 9249 17 223",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Slovakia",
    Emergency: "112",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Moldova",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Germany",
    Emergency: "112",
    Suicide_Crisis: "8001810771",
    AIDS_HIV: "49 (0)69 762933, (069) 40 58 68-0",
    Domestic_Violence: "49 (0) 30 – 611 03 00",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "(0421) 408190 02",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "samaritans.org"
  },
  {
    country: "France",
    Emergency: "112",
    Suicide_Crisis: "145394000",
    AIDS_HIV: "0800 840 800",
    Domestic_Violence: "",
    Sexual_Abuse: "0800 059595",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "119",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.SOS-AMITIE.ORG"
  },
  {
    country: "Netherlands",
    Emergency: "112",
    Suicide_Crisis: "900 0113",
    AIDS_HIV: "31 20 62 62 669, 31 20 528 7828",
    Domestic_Violence: "0900 1 262626",
    Sexual_Abuse: "020 613 0245",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "0800 2000",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.SENSOOR.NL"
  },
  {
    country: "Belgium",
    Emergency: "112",
    Suicide_Crisis: "1813",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.TELE-ONTHAAL.BE"
  },
  {
    country: "Austria",
    Emergency: "112, 142",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.TELEFONSEELSORGE.AT"
  },
  {
    country: "Switzerland",
    Emergency: "112",
    Suicide_Crisis: "143",
    AIDS_HIV: "044 255 37 76",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.143.CH"
  },
  {
    country: "Luxembourg",
    Emergency: "112",
    Suicide_Crisis: "352 45 45 45",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Liechtenstein",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Monaco",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "United Kingdom",
    Emergency: "112, 999",
    Suicide_Crisis: "919-231-4525,  116 123",
    AIDS_HIV: "0 (800) 012 322",
    Domestic_Violence: "0808 2000 247, 1800 341 341",
    Sexual_Abuse: "01 (474) 537 392, 0808 802 9999, 08088 01 03 02, 0808 8000 123",
    Mental_Health_Condition:
      "(020) 7250 8062, 0300 123 3393, 0808 801 0525, 0300 304 7000, 03444 775 774",
    Human_Trafficking: "",
    Eating_Disorder: "0 (345) 634 1414, 0808 801 0677, 0845 838 2040",
    Child_Abuse: "0800 1111",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse:
      "0 (845) 06 121 12, 0300 1236600, 0300 123 1110,  08080 10 10 11, 0300 999 1212",
    Alzheimer_and_dementia: "0333 150 3456, 0800 888 6678",
    Bullying: "0300 323 0169, 0808 800 2222, 0800 169 6928",
    Website: ""
  },
  {
    country: "Sweden",
    Emergency: "112",
    Suicide_Crisis: "020 22 00 60",
    AIDS_HIV: "+46 8 714 54 10",
    Domestic_Violence: "903434-9",
    Sexual_Abuse: "903434-9",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.NATIONELLAHJALPLINJEN.SE"
  },
  {
    country: "Denmark",
    Emergency: "112",
    Suicide_Crisis: "4570201201",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.LIVSLINIEN.DK"
  },
  {
    country: "Finland",
    Emergency: "112",
    Suicide_Crisis: "010 195 202",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "www.mll.fi/nuortennetti"
  },
  {
    country: "Norway",
    Emergency: "112",
    Suicide_Crisis: "4781533300",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.KIRKENS-SOS.NO PAPUA"
  },
  {
    country: "Ireland",
    Emergency: "116123",
    Suicide_Crisis: "122, 999, 116 123",
    AIDS_HIV: "(021) 4276 676, (091) 566 266",
    Domestic_Violence: "1800 341 900,  01 554 3811, ",
    Sexual_Abuse: "1800 341 900, 1800 778888",
    Mental_Health_Condition: "01 2841166, 1890 474 474, 01 541 3715",
    Human_Trafficking: "",
    Eating_Disorder: "01 2107906",
    Child_Abuse: "1800 66 66 66",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "1800 459 459, 0800 008 6811",
    Alzheimer_and_dementia: "1800 341 341",
    Bullying: "1800 66 66 66",
    Website: "WWW.SAMARITANS.ORG"
  },
  {
    country: "Lithuania",
    Emergency: "",
    Suicide_Crisis: "8-800 2 8888",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " WWW.JPPC.LT"
  },
  {
    country: "Latvia",
    Emergency: "113",
    Suicide_Crisis: " 371 67222922",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Estonia",
    Emergency: "112",
    Suicide_Crisis: "3726558088",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " WWW.USALDUS.EE"
  },
  {
    country: "Iceland",
    Emergency: "112",
    Suicide_Crisis: "1717",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Turkey",
    Emergency: "112",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Italy",
    Emergency: "112",
    Suicide_Crisis: "800860022",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.TELEFONOAMICO.IT"
  },
  {
    country: "Spain",
    Emergency: "112",
    Suicide_Crisis: "914590050",
    AIDS_HIV: "34 933 182 056",
    Domestic_Violence: "16",
    Sexual_Abuse: "16",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Greece",
    Emergency: "112",
    Suicide_Crisis: "1018",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "1056",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.KLIMAKA.ORG.GR"
  },
  {
    country: "Portugal",
    Emergency: "112",
    Suicide_Crisis: "21 854 07 40 and 8 96 898 21 50",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "WWW.AAC.UC.PT",
    Bullying: "",
    Website: ""
  },
  {
    country: "Serbia",
    Emergency: "",
    Suicide_Crisis: " (+381) 21-6623-393",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "centarsrce.org.yu"
  },
  {
    country: "Croatia",
    Emergency: "112",
    Suicide_Crisis: "(01) 4833-888",
    AIDS_HIV: "0800 448 767",
    Domestic_Violence: "0800-655-222",
    Sexual_Abuse: "0800-655-222",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: "WWW.PLAVI-TELEFON.HR"
  },
  {
    country: "Bosnia and Herzegovina",
    Emergency: "124",
    Suicide_Crisis: "080 05 03 05, 0800-300303",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Albania",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Slovenia",
    Emergency: "112",
    Suicide_Crisis: " (01) 520–99–00",
    AIDS_HIV: "",
    Domestic_Violence: "+386 31 233 211",
    Sexual_Abuse: "",
    Mental_Health_Condition: "116 123",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "North Macedonia",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Montenegro",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Malta",
    Emergency: "112, 179",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: " WWW.APPOGG.GOV.MT/SUPPORTLINE179.ASP"
  },
  {
    country: "Andorra",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "San Marino",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  },
  {
    country: "Holy See",
    Emergency: "",
    Suicide_Crisis: "",
    AIDS_HIV: "",
    Domestic_Violence: "",
    Sexual_Abuse: "",
    Mental_Health_Condition: "",
    Human_Trafficking: "",
    Eating_Disorder: "",
    Child_Abuse: "",
    Postpartum_Support: "",
    Alcohol_and_Substance_Abuse: "",
    Alzheimer_and_dementia: "",
    Bullying: "",
    Website: ""
  }
];
