/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { BMHM_USER_DATA } from "./CONSTANTS";

interface IUser {
  authToken?: string;
}

export default function authHeader() {
  const user: IUser = JSON.parse(localStorage.getItem(BMHM_USER_DATA) as string);
  if (user && user?.authToken) {
    return { Authorization: "Bearer " + user.authToken };
  } else {
    return {};
  }
}
