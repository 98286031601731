import { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const customIcon = (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M12 24l-9-9c0-6 4-10 10-10s10 4 10 10l-9 9z" fill="#f00" />
      <path d="M12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" fill="#fff" />
    </svg>
  </div>
) as unknown as HTMLElement;

const customMarker = L.icon({
  iconUrl: "data:image/svg+xml;utf8," + encodeURIComponent(customIcon.outerHTML),
  iconSize: [24, 24], // size of the icon
  iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -24] // point from which the popup should open relative to the iconAnchor
});

const Map = () => {
  useEffect(() => {
    const map = L.map("map").setView([30.341931, -97.762427], 15);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
    }).addTo(map);

    const marker = L.marker([30.341931, -97.762427], { icon: customMarker }).addTo(map);
    marker.bindPopup("<b>5900 Balcones Dr, Austin, Texas 78731, US</b>").openPopup();
  }, []);

  return <div id="map" style={{ width: "100%", height: "400px" }} />;
};

export default Map;
