import { PageWrapper } from "components";
import ResearchView from "./ResearchView";

export const ResearchContainer = () => {
  return (
    <PageWrapper>
      <ResearchView />
    </PageWrapper>
  );
};
