import { linkedinBlack } from "assets/icons";
import {
  Bmhmlink,
  linkedinVector,
  ResearchTeamImg10,
  ResearchTeamImg11,
  ResearchTeamImg12,
  ResearchTeamImg13,
  ResearchTeamImg14,
  ResearchTeamImg15,
  ResearchTeamImg16,
  ResearchTeamImg17,
  ResearchTeamImg18,
  ResearchTeamImg19,
  ResearchTeamImg20,
  ResearchTeamImg2,
  ResearchTeamImg4,
  ResearchTeamImg6,
  ResearchTeamImg7,
  ResearchTeamImg8,
  ResearchTeamImg9,
  TeamImg5
} from "assets/images";
import { useMemo } from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const ResearchTeam = () => {
  const stories = [
    {
      id: 1,
      Image: ResearchTeamImg2,
      paragraph: "Director",
      name: "Dr. Dia Pfleger",
      personalLink: "https://www.linkedin.com/in/dr-dia-p-equityfirst/",
      personalLinkedin: linkedinVector,
      BmhmLink: Bmhmlink
    },
    {
      id: 2,
      Image: ResearchTeamImg4,
      paragraph: "Member",
      name: "Dr. D'Quayvion Cloud",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/dr-dquayvion-cloud-lcswa-915258b2/",
      BmhmLink: Bmhmlink
    },
    {
      id: 3,
      Image: TeamImg5,
      paragraph: "Member",
      name: "Dr Judith Bruce-Golding",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/jbgeducation/",
      BmhmLink: Bmhmlink
    },
    {
      id: 4,
      Image: ResearchTeamImg6,
      paragraph: "Member",
      name: "Dr Dawn G. Crosson",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/dawn-g-crosson-psy-d-612890109/",
      BmhmLink: Bmhmlink
    },
    {
      id: 5,
      Image: ResearchTeamImg7,
      paragraph: "Member",
      name: "Itiyopiya Ewart",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/iewart/",
      BmhmLink: Bmhmlink
    },
    {
      id: 6,
      Image: ResearchTeamImg8,
      paragraph: "Principal Investigator",
      name: "Geoffrey Whittle-Walls",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/geoffrey-whittle-walls/",
      BmhmLink: Bmhmlink
    },
    {
      id: 7,
      Image: ResearchTeamImg9,
      paragraph: "Member",
      name: "Isimbi Sebageni",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/isimbi-sebageni/",
      BmhmLink: Bmhmlink
    },
    {
      id: 8,
      Image: ResearchTeamImg10,
      paragraph: "Member",
      name: "Esther Bikoba",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/estherbikoba/",
      BmhmLink: Bmhmlink
    },
    {
      id: 9,
      Image: ResearchTeamImg11,
      paragraph: "Member",
      name: "Sonia Harte",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/diane-harte-5b105a12/",
      BmhmLink: Bmhmlink
    },
    {
      id: 10,
      Image: ResearchTeamImg12,
      paragraph: "Member",
      name: "Sista Oloruntoyin",
      personalLinkedin: linkedinVector,
      personalLink: "",
      BmhmLink: Bmhmlink
    },
    {
      id: 11,
      Image: ResearchTeamImg13,
      paragraph: "Member",
      name: "Birgit Umaigba",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/birgit-umaigba-5b7958175/",
      BmhmLink: Bmhmlink
    },
    {
      id: 12,
      Image: ResearchTeamImg14,
      paragraph: "Member",
      name: "Dr. Bayo Curry-Winchell",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/drbcw/",
      BmhmLink: Bmhmlink
    },
    {
      id: 13,
      Image: ResearchTeamImg15,
      paragraph: "Member",
      name: "Pennie Anassi",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/kerubo/ ",
      BmhmLink: Bmhmlink
    },
    {
      id: 14,
      Image: ResearchTeamImg16,
      paragraph: "Member",
      name: "Rachel Harris",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/rachel-harris-mha-mpa-2b0b705/",
      BmhmLink: Bmhmlink
    },
    {
      id: 15,
      Image: ResearchTeamImg17,
      paragraph: "Member",
      name: "Dr. Candance Willet",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/candance-willett/",
      BmhmLink: Bmhmlink
    },
    {
      id: 16,
      Image: ResearchTeamImg18,
      paragraph: "Member",
      name: "Ramabulana Lufuno",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/lufuno-lucky-ramabulana-578180213/",
      BmhmLink: Bmhmlink
    },
    {
      id: 17,
      Image: ResearchTeamImg19,
      paragraph: "Member",
      name: "Angel Rangel",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/%C3%A1ngel-emmanuel-rangel-bocardo-68481b",
      BmhmLink: Bmhmlink
    },
    {
      id: 18,
      Image: ResearchTeamImg20,
      paragraph: "Member",
      name: "Rita Nwanze",
      personalLinkedin: linkedinVector,
      personalLink: "https://www.linkedin.com/in/%C3%A1ngel-emmanuel-rangel-bocardo-68481b",
      BmhmLink: Bmhmlink
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const researchTeamCarousel = useMemo(() => {
    return (
      <Carousel responsive={responsive} itemClass="py-12" showDots={true}>
        {stories.map(({ Image, name, id, paragraph, personalLink }) => {
          return (
            <div
              className="px-8 py-5 "
              key={id}
              style={{ boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="p-2 flex flex-col items-center justify-center">
                <img src={Image} alt={name} className="rounded-full w-[210px] h-[210px]" />
                <div className="text-2xl mt-8 font-semibold text-green">{name}</div>
                <div className="">
                  <p className="text-base mb-3 mt-2 font-semiblod">{paragraph}</p>
                </div>
                <div className="flex gap-5 mt-2">
                  <Link to={personalLink}>
                    <img src={linkedinBlack} alt="" />
                  </Link>
                  <Link to="">
                    <img src={Bmhmlink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }, []);
  return (
    <div className="px-20 max-md:px-5 py-20">
      <div>
        <hr className=" mb-5 h-2 bg-green mx-56 max-md:mx-6" />
        <h2 className="text-center font-semibold text-2xl md:text-5xl leading-loose max-md:leading-tight ">
          Meet Our <span className="text-green">Research</span> Team
        </h2>
      </div>
      <div className="md:grid hidden grid-cols-3 max-md:grid-cols-1 place-items-center md:mt-16 mt-12">
        {stories.map(({ Image, name, id, paragraph, personalLink }) => {
          return (
            <div className="pl-8 py-5" key={id}>
              <div className="p-2 flex flex-col items-center justify-center">
                <img src={Image} alt={name} className="rounded-full w-[250px] h-[250px]" />
                <div className="text-2xl mt-5 font-semibold text-green">{name}</div>
                <div className="">
                  <p className="text-base mb-3 mt-2 font-semiblod">{paragraph}</p>
                </div>
                <div className="flex gap-5">
                  <Link to={personalLink}>
                    <img src={linkedinBlack} alt="" />
                  </Link>
                  <Link to="">
                    <img src={Bmhmlink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="md:hidden w-full">{researchTeamCarousel}</div>
    </div>
  );
};

export default ResearchTeam;
