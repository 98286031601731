import { VolunteerForm } from "components/layout/VolunteerForm";

const VolunteerFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <VolunteerForm />
    </div>
  );
};

export default VolunteerFormView;
