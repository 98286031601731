import { entail } from "assets/images";

const Entail = () => {
  return (
    <div className="flex gap-4 md:gap-12 py-12 md:py-16 md:px-12 xl:px-28 px-5 items-center justify-between ">
      <div className="flex flex-col md:gap-8 gap-4 md:w-[620px] md:h-[457px] h-full">
        <p className="border-l-4 md:border-l-8 border-l-orange md:text-4xl text-2xl font-bold md:w-[233px] w-full pl-4 md:pl-8 md:text-left text-center">
          What does <span className="text-orange md:text-4xl text-2xl font-bold">Volunteering</span>{" "}
          Entail?
        </p>
        <p className="md:text-xl text-base text-gray-700 leading-8 md:leading-10 md:text-justify text-center">
          Volunteering with BMHM is the unpaid provision of one's time, talent, and energy to the
          need, cause, and mission of Black Mental Health. The whole point of volunteering is to
          allow you to give some of your own time to benefit those in the Black Community.
        </p>
      </div>
      <div className="w-full md:w-[446px] h-full md:h-[457px] bg-gray-600 rounded-md hidden md:block">
        <img src={entail} className="-ml-4 -mt-4 rounded-md" />
      </div>
    </div>
  );
};

export default Entail;
