import { Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  type: "checkbox";
  className?: string;
  labelClassName?: string;
}

function FormCheckbox({ nameAndId, label, type, className, labelClassName }: FormInputProps) {
  return (
    <div className="flex items-center cursor-pointer gap-4">
      <Field
        name={nameAndId}
        id={nameAndId}
        type={type}
        className={className ?? "bg-white accent-green-100 md:h-6 h-4 md:w-6 w-4"}
      />
      <label
        className={`font-normal text-sm text-white md:text-xl ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>
    </div>
  );
}

export default FormCheckbox;
