import { PageWrapper } from "components";
import OrganizationFormView from "./OrganizationFormView";

export const OrganizationFormContainer = () => {
  return (
    <PageWrapper>
      <OrganizationFormView />
    </PageWrapper>
  );
};
