import { GuestForm, GuestFormHero } from "components";

const GuestFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <GuestFormHero />
      <GuestForm />
    </div>
  );
};

export default GuestFormView;
