// import env from 'configs';
import env from "configs";

// Auth routes
export const LOG_OUT = "auth/logout";
export const LOGIN = "auth/login";
export const REGISTER = "auth/register";
export const LOGIN_SUCCESS_URL = "auth/login_success";
export const VERIFY_TOKEN = "/auth/verify";
export const GOOGLE_END_POINT = `${env.API_BASE_URL}/auth/google`;
export const LINKEDIN_END_POINT = `${env.API_BASE_URL}/auth/linkedin`;
export const MICROSOFT_END_POINT = `${env.API_BASE_URL}/auth/microsoft`;
export const THERAPIST_REGISTRATION = `${env.API_BASE_URL}/auth/therapist/register`;

export const GET_USER = "/user";
export const FORGOT_PASSWORD = "auth/reset_password";
export const RESET_PASSWORD = "auth/change_password";
// export const CHANGE_PASSWORD = "user/change-password";

export const UPDATE_USER = "/update_user";
export const DELETE_USER = "/delete_user";

export const GOOGLE_LOGIN = "/google/signup";
export const GOOGLE_CALLBACK = "/google/callback";
export const LINKED_LOGIN = "/linkedin/signup";
export const MICROSOFT_LOGIN = "/microsoft/signup";
export const MICROSOFT_CALLBACK = "/microsoft/callback";
export const FACEBOOK_CALLBACK = "/auth/facebook/callback ";

export const PUBLIC_REQUEST_KEY = "public-request";

/**
 * Settings
 */

export const UPDATE_USER_PROFILE = "/user";
export const UPLOAD_FILE = "/upload/profile-photo/";

export const BMHM_USER_DATA = "BMHM_USER_DATA";

export const CONTACT = "/contact_us";
export const VOLUNTEER = "/add/volunteer";
export const STORIES = "/user-stories";
export const INDIVIDUAL_FORM = "/individualform";
export const ORGANIZATION_FORM = "/organizationform";
export const HOST_FORM = "/show/request_to_host_show";
export const GUEST_FORM = "/show/add_guest_request";
export const REASEARCHER = "/add/researcher";

// Request Status

export const RESPONSE_SUCCESS = "SUCCESS";
export const RESPONSE_ERROR = "FAILURE";
