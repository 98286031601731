import AbstractContain from "./AbstractContain";
import AbstractList from "./AbstractList";
import ActiveButton from "./ActiveButton";

const Abstract = () => {
  return (
    <div className="px-10 grid sm:grid-cols-[0.4fr,1.3fr,0.4fr] items-start justify-items-center">
      <AbstractList />
      <AbstractContain />
      <ActiveButton />
    </div>
  );
};

export default Abstract;
