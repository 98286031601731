import { SearchEclipsImg, SearchResultImg } from "assets/images";
import { Journal } from "types";

export const journals: Journal[] = [
  {
    id: "1",
    Image: SearchResultImg,
    ProfileIMG: SearchEclipsImg,
    paragraph:
      "Effect of Torsemide vs Furosemide After Discharge on All-Cause Mortality in Patients Hospitalized With Heart FailureThe TRANSFORM-HF Randomized Clinical Trial",
    name: "Dr, Abel Mike",
    journal: "Journal of medical research",
    abstract:
      "Question Does torsemide reduce all-cause mortality compared with furosemide in patients with heart failure following hospitalization? Findings In this randomized clinical trial of 2859 patients, 26.1% of patients randomized to torsemide and 26.2% randomized to furosemide died over a median follow-up of 17.4 months without a significant difference between groups. Meaning Among patients discharged after hospitalization for heart failure, torsemide compared with furosemide did not result in a significant difference in all-cause mortality over 12 months; however, interpretation of these findings is limited by loss to follow-up and participant crossover and nonadherence."
  },
  {
    id: "2",
    Image: SearchResultImg,
    ProfileIMG: SearchEclipsImg,
    paragraph:
      "Effect of Torsemide vs Furosemide After Discharge on All-Cause Mortality in Patients Hospitalized With Heart FailureThe TRANSFORM-HF Randomized Clinical Trial",
    name: "Dr, Abel Mike",
    journal: "Journal of medical research",
    abstract:
      "Question Does torsemide reduce all-cause mortality compared with furosemide in patients with heart failure following hospitalization? Findings In this randomized clinical trial of 2859 patients, 26.1% of patients randomized to torsemide and 26.2% randomized to furosemide died over a median follow-up of 17.4 months without a significant difference between groups. Meaning Among patients discharged after hospitalization for heart failure, torsemide compared with furosemide did not result in a significant difference in all-cause mortality over 12 months; however, interpretation of these findings is limited by loss to follow-up and participant crossover and nonadherence."
  },
  {
    id: "3",
    Image: SearchResultImg,
    ProfileIMG: SearchEclipsImg,
    paragraph:
      "Effect of Torsemide vs Furosemide After Discharge on All-Cause Mortality in Patients Hospitalized With Heart FailureThe TRANSFORM-HF Randomized Clinical Trial",
    name: "Dr, Abel Mike",
    journal: "Journal of medical research",
    abstract:
      "Question Does torsemide reduce all-cause mortality compared with furosemide in patients with heart failure following hospitalization? Findings In this randomized clinical trial of 2859 patients, 26.1% of patients randomized to torsemide and 26.2% randomized to furosemide died over a median follow-up of 17.4 months without a significant difference between groups. Meaning Among patients discharged after hospitalization for heart failure, torsemide compared with furosemide did not result in a significant difference in all-cause mortality over 12 months; however, interpretation of these findings is limited by loss to follow-up and participant crossover and nonadherence."
  },
  {
    id: "4",
    Image: SearchResultImg,
    ProfileIMG: SearchEclipsImg,
    paragraph:
      "Effect of Torsemide vs Furosemide After Discharge on All-Cause Mortality in Patients Hospitalized With Heart FailureThe TRANSFORM-HF Randomized Clinical Trial",
    name: "Dr, Abel Mike",
    journal: "Journal of medical research",
    abstract:
      "Question Does torsemide reduce all-cause mortality compared with furosemide in patients with heart failure following hospitalization? Findings In this randomized clinical trial of 2859 patients, 26.1% of patients randomized to torsemide and 26.2% randomized to furosemide died over a median follow-up of 17.4 months without a significant difference between groups. Meaning Among patients discharged after hospitalization for heart failure, torsemide compared with furosemide did not result in a significant difference in all-cause mortality over 12 months; however, interpretation of these findings is limited by loss to follow-up and participant crossover and nonadherence."
  }
];
