import ServiceCard from "./ServiceCard";
import { serviceData } from "./ServiceData";

const CardsView = () => {
  return (
    <div className="md:px-28 px-5 grid md:grid-cols-3 grid-cols-1 justify-center items-center gap-5 mx-auto">
      {serviceData.map((service) => {
        return (
          <ServiceCard
            key={service.id}
            img={service.img}
            header={service.name}
            text={service.description}
          />
        );
      })}
    </div>
  );
};

export default CardsView;
