import { CampaignForm, CampaignFormHero } from "components";

const CampaignFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <CampaignFormHero />
      <CampaignForm />
    </div>
  );
};

export default CampaignFormView;
