import { Link } from "react-router-dom";
import { LOGIN, SIGNUP_THERAPIST, SIGNUP_USER } from "routes/CONSTANTS";
import SignupText from "./SignupText";
import { rightArrow } from "assets/icons";
import { logo } from "assets/images";

const Signup = () => {
  return (
    <div className=" grid md:grid-cols-2 grid-cols-1 md:h-screen h-auto">
      <SignupText
        headline="Let’s Get Started"
        text="Create an account today to access mental health services and speak to a licensed professional."
      />
      <div className="flex flex-col justify-between gap-4 md:h-screen h-auto pt-4 pb-16 px-5 md:px-16">
        <div className="flex justify-center md:justify-end">
          <img src={logo} />
        </div>
        <div className="grid grid-cols-2 gap-8 md:gap-16 items-center justify-center">
          <Link
            to={SIGNUP_USER}
            className="bg-orange rounded-2xl md:py-8 md:px-12 p-4 md:w-64 w-36 h-36 md:h-64  font-bold text-white flex flex-col justify-between gap-4"
          >
            <div className="text-xl md:text-4xl flex flex-col gap-4">
              <span>User </span>
              Sign Up
            </div>
            <div className="flex justify-end">
              <img src={rightArrow} />
            </div>
          </Link>
          <Link
            to={SIGNUP_THERAPIST}
            className="bg-orange rounded-2xl p-4 md:py-8 md:px-12 w-36 md:w-64 h-36 md:h-64 text-4xl font-bold text-white flex flex-col justify-between gap-4"
          >
            <div className="text-xl md:text-4xl flex flex-col gap-4">
              <span>Therapist </span>
              Sign Up
            </div>
            <div className="flex justify-end">
              <img src={rightArrow} />
            </div>
          </Link>
        </div>
        <div className="text-base md:text-lg text-black text-centermd:mt-0 mt-12 flex gap-1 jutify-center items-center">
          Already have an account? Login{" "}
          <span className="hover:text-orange text-green-100">
            <Link to={LOGIN}> Here</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Signup;
