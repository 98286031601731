import {
  vlinkedin,
  vbmhm,
  JasmynJames,
  RhyanaEbanksBabb,
  MekayaWilliams,
  AshleyBAye,
  ClaireNasasira,
  TrinityThomson,
  TerriFloyd,
  AbigailOni,
  JosephineEbhoomhan,
  DrJoanSamuelsDennis,
  EricaDolland,
  ClaudiaWei,
  AnneDiouf,
  TriumphUrias,
  IsraelAdekanye,
  FlorenceANneoma,
  DalmasChituyi,
  JoanNobei,
  EakyTang,
  TegaUwadia,
  SophiaEdeki
} from "assets/images";
import { LinkButton } from "components/widgets";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMemo } from "react";
import { longArrowRight } from "assets/icons";

const Testimonial = () => {
  const cardData = [
    {
      id: 1,
      image: AshleyBAye,
      name: "Ashley Baye",
      role: "Human Resources Manager",
      linkedinUrl: "https://www.linkedin.com/in/ashley-baye-938749194",
      bmhmUrl: ""
    },
    {
      id: 2,
      image: RhyanaEbanksBabb,
      name: "Rhyana Ebanks-Babb",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/rhyana-ebanks-babb-b0b324179/",
      bmhmUrl: ""
    },
    {
      id: 3,
      image: MekayaWilliams,
      name: "Mekaya Williams-Franklin",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/mekaya-williams-franklin-b8766421b/",
      bmhmUrl: ""
    },
    {
      id: 4,
      image: TrinityThomson,
      name: "Trinity Thompson",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/trinitythompson3/",
      bmhmUrl: ""
    },
    {
      id: 5,
      image: JasmynJames,
      name: "Jasmyn Ruja",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/jasmynrjames/",
      bmhmUrl: ""
    },
    {
      id: 6,
      image: ClaireNasasira,
      name: "Claire Nasasira",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/claire-nasasira-485685b4/",
      bmhmUrl: ""
    },
    {
      id: 7,
      image: AbigailOni,
      name: "Abigail Oni",
      role: "Show Guest",
      linkedinUrl: "https://www.linkedin.com/in/abigailarchibong/",
      bmhmUrl: ""
    },
    {
      id: 8,
      image: TerriFloyd,
      name: "Terri Floyd",
      role: "Show Guest",
      linkedinUrl: "https://www.linkedin.com/in/terri-floyd-ms-bb787451",
      bmhmUrl: ""
    },
    {
      id: 9,
      image: JosephineEbhoomhan,
      name: "Josephine Ebhoomhan",
      role: "Show Guest",
      linkedinUrl: "https://www.linkedin.com/in/josephine-ebhoomhan-3b2b51b4",
      bmhmUrl: ""
    },
    {
      id: 10,
      image: EricaDolland,
      name: "Erica Dolland",
      role: "Show Host",
      linkedinUrl: "https://www.linkedin.com/in/erica-dolland-ms-ed-cht-4a485441/",
      bmhmUrl: ""
    },
    {
      id: 11,
      image: DrJoanSamuelsDennis,
      name: "Dr. Joan Samuels-Dennis ",
      role: "Guest Speaker",
      linkedinUrl: "https://www.linkedin.com/in/joan-samuels-dennis-rn-phd-6b990146/",
      bmhmUrl: ""
    },
    {
      id: 12,
      image: ClaudiaWei,
      name: "Claudia Wei",
      role: "Product Designer",
      linkedinUrl: "https://www.linkedin.com/in/claudiawei/",
      bmhmUrl: ""
    },
    {
      id: 13,
      image: AnneDiouf,
      name: "Anne Diouf",
      role: "Research Assistant",
      linkedinUrl: "https://www.linkedin.com/in/anne-c-diouf-a82a341b7",
      bmhmUrl: ""
    },
    {
      id: 14,
      image: TriumphUrias,
      name: "Triumph Urias",
      role: "Machine Learning Engineer",
      linkedinUrl: "https://www.linkedin.com/in/triumph-urias/",
      bmhmUrl: ""
    },
    {
      id: 15,
      image: IsraelAdekanye,
      name: "Israel Adekanye",
      role: "Backend Engineer",
      linkedinUrl: "https://www.linkedin.com/in/israel-adekanye",
      bmhmUrl: ""
    },
    {
      id: 16,
      image: FlorenceANneoma,
      name: "Florence .A. Nneoma",
      role: "Product Designer",
      linkedinUrl: "https://www.linkedin.com/in/florence-asoegwu/",
      bmhmUrl: ""
    },
    {
      id: 17,
      image: DalmasChituyi,
      name: "Dalmas Chituyi",
      role: "Senior Machine Learning Engineer",
      linkedinUrl: "https://www.linkedin.com/in/dalmas-chituyi-36a456237",
      bmhmUrl: ""
    },
    {
      id: 18,
      image: SophiaEdeki,
      name: "Sophia Edeki",
      role: "Frontend Engineer",
      linkedinUrl: "https://www.linkedin.com/in/sophia-edeki",
      bmhmUrl: ""
    },
    {
      id: 19,
      image: TegaUwadia,
      name: "Tega Uwadia",
      role: "Product Designer",
      linkedinUrl: "http://www.linkedin.com/in/tega-uwadia-780654236",
      bmhmUrl: ""
    },
    {
      id: 20,
      image: EakyTang,
      name: "Eaky Tang",
      role: "Product Designer",
      linkedinUrl: "https://www.linkedin.com/in/eakyt/",
      bmhmUrl: ""
    },
    {
      id: 21,
      image: JoanNobei,
      name: "Joan Nobei",
      role: "Product Designer",
      linkedinUrl: "https://www.linkedin.com/in/joan-nobei-2704b6227/",
      bmhmUrl: ""
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      slidesToSlide: 4,
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      slidesToSlide: 4,
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      slidesToSlide: 3,
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      slidesToSlide: 1,
      items: 1
    }
  };

  const testimonialsCarousel = useMemo(() => {
    return (
      <Carousel
        responsive={responsive}
        showDots={true}
        itemClass=""
        swipeable={true}
        draggable={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        containerClass="pt-4 pb-8 "
      >
        {cardData.map((card) => (
          <div
            className="rounded py-8 px-4 mx-2"
            key={card.id}
            style={{ boxShadow: "0px 7px 31px 5px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="flex items-center justify-center w-[220px] h-[220px] ">
              <img src={card.image} className="rounded-full text-center h-full w-full" />
            </div>
            <p className="text-center font-bold text-green text-xl py-5">{card.name}</p>
            <p className="text-center text-base">{card.role}</p>
            <hr className="text-whiteLight my-5" />
            <div className="flex gap-12 items-center justify-center pb-2">
              <a href={card.linkedinUrl} className="">
                <img src={vlinkedin} />
              </a>
              <a href={card.bmhmUrl} className="">
                <img src={vbmhm} />
              </a>
            </div>
          </div>
        ))}
      </Carousel>
    );
  }, []);

  return (
    <section className="py-10 px-8 md:px-14 lg:px-20 max-sm:px-5">
      <div className="flex justify-center">
        <div className="my-5">
          <hr className="w-50 h-1 md:h-2 bg-green text-green w-2/5 float-right " />
          <h2 className="font-bold md:font-semibold text-[#667085] text-3xl md:text-6xl leading-tight py-2">
            What Volunteers Say
          </h2>
          <hr className="w-50 h-1 md:h-2 bg-orange text-orange w-3/5 md:w-1/4 mr-28" />
        </div>
      </div>
      <p className="text-center lg:px-72 text-[14px] md:text-xl leading-6 md:leading-loose">
        WE PLACE HUGE VALUE ON STRONG RELATIONSHIP AND HAVE SEEN THE BENEFITS THEY BRING. VOLUNTEERS
        FEEDBACK IS VITAL IN HELPING US TO GET IT RIGHT.
      </p>

      <div
        className="my-10 h-auto w-full "
        // style={{ boxShadow: "0px 7px 31px 5px rgba(0, 0, 0, 0.1)" }}
      >
        {testimonialsCarousel}
      </div>
      <div className="pt-8 ">
        <LinkButton to="">
          <span className="flex gap-6">
            View More <img src={longArrowRight} alt="" />
          </span>
        </LinkButton>
      </div>
    </section>
  );
};

export default Testimonial;
