import { Healing, Nature } from "assets/images";

const AppointmentHeader = () => {
  return (
    <div className="flex lg:justify-center my-8">
      <div className=" bg-white p-6 lg:w-[848px]">
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex lg:basis-[60%]">
            <img src={Healing} />
          </div>
          <div className="flex lg:basis-[35%]">
            <img src={Nature} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentHeader;
