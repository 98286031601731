import { PageWrapper } from "components";
import VolunteerView from "./VolunteerView";

export const VolunteerContainer = () => {
  return (
    <PageWrapper>
      <VolunteerView />
    </PageWrapper>
  );
};
