import { memo } from "react";

import { storiesImg } from "assets/images";

const OurStory = () => {
  return (
    <div className="md:pt-20 md:px-12 xl:px-28 px-5">
      <div className=" ">
        <h2 className="font-semibold text-3xl md:text-6xl text-[#005028] md:text-center text-left md:block ">
          Stories
        </h2>

        <div className=" mt-6 md:mt-8">
          <div className="flex md:flex-row flex-col justify-between items-center md:gap-2 gap-12">
            <div className="md:w-1/2 w-full md:block hidden">
              <img src={storiesImg} alt="stories-img" />
            </div>
            <div className="md:w-1/2 w-full">
              <p className="text-black md:text-2xl text-base leading-8 md:leading-10 text-justify ">
                We recognize that mental health struggles are all too common, yet often shrouded in
                stigma. It is our hope that by hearing the stories of others, we can gain further
                understanding and empathy for those experiencing mental health challenges. We hope
                that these stories can provide us with insight and strength to better support
                ourselves, our loved ones, and our broader community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OurStory);
