const Insurance = () => {
  return (
    <div className="flex lg:justify-center">
      <div className="lg:w-[848px]">
        <div className="flex flex-col lg:flex-row gap-8">
          <ul className="list-disc list-inside bg-[#FF9C00] lg:w-[435px] rounded-[10px] p-6">
            <h2 className="text-[24px] font-bold py-6">Insurance Plans Accepted</h2>
            <li className="text-[16px]">Avon HMO</li>
            <li className="text-[16px]">Hygeia HMO</li>
            <li className="text-[16px]">AXA Global healthcare</li>
          </ul>
          <ul className="list-inside bg-[#FF9C00] lg:w-[435px] rounded-[10px] p-6">
            <h2 className="text-[24px] font-bold py-6">Swift Check</h2>
            <li className="text-[16px]">Language: English</li>
            <li className="text-[16px]">Session type: Online</li>
            <li className="text-[16px]">Rate: $70 / session</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
