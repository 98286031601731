import { logo } from "assets/images";
import { FormInput } from "components/modules";
import { Form, Formik } from "formik";
import { useAppDispatch } from "hooks";
import { Link } from "react-router-dom";
import { forgotPassword } from "redux/slices/auth.slice";
import { LOGIN } from "routes/CONSTANTS";
import ForgotPasswordText from "./ForgotPasswordText";
import { forgotPwdSchema } from "validations";

const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();

  const formikInitialValues = {
    email: ""
  };

  const handleSubmit = (details: { email: string }) => {
    void dispatch(forgotPassword(details));
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto">
      <ForgotPasswordText />

      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <div className="flex items-center justify-center">
          <img src={logo} />
        </div>
        <p className="font-bold text-2xl md:text-4xl text-black py-4 md:py-8">Forgot Password</p>
        <div>
          <Formik
            initialValues={formikInitialValues}
            enableReinitialize={true}
            validationSchema={forgotPwdSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-4">
              <div className="border-b-2 border-b-black w-full pt-4">
                <FormInput
                  nameAndId="email"
                  placeholder="johndoe@gmail.com"
                  label="Email"
                  type="email"
                />
              </div>

              <div className="bg-green flex items-center justify-center w-full rounded-md mx-auto mt-8">
                <button type="submit" className="bg-green py-3 px-8 text-white w-full rounded-md">
                  Reset Password
                </button>
              </div>
              {/* {loading ? <Loader /> : <Success />} */}
            </Form>
          </Formik>
          <div className="hover:text-green-100 text-lg text-green pt-8 mt-4">
            <Link to={LOGIN} className="font-normal text-lg text-green-100 hover:text-green">
              Go back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
