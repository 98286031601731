import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ContactFormProps } from "types";

// Async Thunk
export const contactform = createAsyncThunk(
  "/contact_us",
  async (formData: ContactFormProps, { dispatch }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const res = await axios.post("https://bmhm-backend.onrender.com/docs/#/contact_us", formData);
      if (res.status >= 200 && res.status < 300) {
        dispatch(resetForm);
        return res.data;
      } else {
        throw new Error(`Request failed with status code: ${res.status}`);
      }
    } catch (err) {
      throw err;
    }
  }
);

// Initial state of the form
const initialState = {
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  formData: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    issue_description: ""
  }
};

// Slice
const formSlice = createSlice({
  name: "contactform",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    resetForm: (state) => {
      state.formData = initialState.formData;
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(contactform.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorMessage = "";
    });
    builder.addCase(contactform.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = "";
    });
    builder.addCase(contactform.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action.error.message ?? "Something went wrong";
    });
  }
});

export const { resetForm, setFormData } = formSlice.actions;
export default formSlice.reducer;
