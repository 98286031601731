import { copylink } from "assets/images";
import { memo, useState } from "react";
// import { helpLines } from "utils";

const countryFlags: Record<string, string> = {
  US: "🇺🇸",
  CA: "🇨🇦",
  GB: "🇬🇧",
  FR: "🇫🇷",
  DE: "🇩🇪",
  NG: "NG"
};

const ContactUs = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [selectedNumber, setSelectedNumber] = useState<string | number>("");

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
    setSelectedNumber(event.target.value);
  };

  const copyToClipboard = (number: number) => {
    void navigator.clipboard.writeText(number.toString());
    alert(`Copied ${number} to clipboard`);
  };

  return (
    <>
      <div
        className="md:mx-56 max-md:mx-5 my-24 py-10 md:px-28 px-5 items-center justify-center text-center text-white  bg-orange md:h-[34rem] h-auto"
        style={{ boxShadow: "0px 7px 31px 5px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }}
      >
        <h2 className="font-semibold text-5xl leading-tight text-white max-md:text-center">
          Need help?
        </h2>
        <div className="mt-5 ">
          <p className="md:text-2xl text-lg leading-[34px] font-bold  text-white max-md:text-center">
            If you are in a crisis or your loved one is in danger the following resources can
            provide you with immediate help in your respective country
          </p>
        </div>
        <hr className=" h-px my-8 bg-white border-0 dark:bg-white    " />
        <p className="text-base  text-white max-md:text-center">
          Select your region so we can link you up with a correspondent close to you
        </p>
        <div className="flex flex-col gap-2 items-center justify-center pt-4">
          {/* <label htmlFor="country-dropdown">Select a country:</label> */}

          <div className="md:px-40 px-5 w-full">
            <select
              onChange={handleDropdownChange}
              className="p-2 rounded border-gray-300 border-2 outline-none pr-8 text-black w-full"
            >
              {/* {helpLines.map((line) => (
                <option
                  key={line.id}
                  value={line.countryCode}
                  className="flex items-center justify-between gap-4 divide-x"
                >
                  <div>{line.flag}</div>
                  <div>{line.country}</div>
                </option>
              ))} */}
            </select>
            <div className="flex text-center items-center justify-center gap-4 md:gap-8 pt-4 md:pt-8">
              <div className="flex items-center gap-2">
                <p className="text-white md:text-2xl text-lg font-bold"> Call </p>
                <p className="text-white underline md:text-2xl text-lg font-bold">
                  {countryFlags[selectedCountry]}
                  {selectedNumber}
                </p>
              </div>
              <span onClick={() => copyToClipboard(selectedNumber as number)}>
                <img
                  src={copylink}
                  alt="copylink"
                  className="cursor-pointer md:h-5 h-4 w-4 md:w-5"
                />
              </span>
            </div>
            <div>
              <button className="bg-[#00a552] w-[300px] h-[55px] rounded-[8px] my-6">
                View all hotlines
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ContactUs);
