import { OurTrustedPartners, PartnerHero, Program, Relationships, Why } from "components";
import { Helmet } from "react-helmet";

const PartnerView = () => {
  return (
    <>
      <Helmet>
        <title>Partner with Black Mental Health matters to make a difference</title>
        <meta
          name="description"
          content="Looking to make a difference in Black mental health? Let's collaborate and partner for positive change"
        />
        <meta name="keywords" content="partner, black mental health" />
      </Helmet>
      <PartnerHero />
      <Why />
      <Relationships />
      <OurTrustedPartners />
      <Program />
    </>
  );
};

export default PartnerView;
