const Therapists = () => {
  return (
    <div>
      <h1 className=" text-lg md:text-3xl font-bold	text-orange  mb-4">Healing</h1>

      <ul className=" list-disc font-normal md:text-xl text-base leading-8 md:leading-10 text-justify flex flex-col gap-8 mt-8 ml-6 mb-14">
        <li>
          Be aware of the potential for power imbalances and work to ensure that Patients are active
          participants in their therapy.
        </li>
        <li>
          Work to break down the stigma surrounding black mental health and support Patients in
          seeking help and healing.
        </li>
        <li>
          Create a safe and non-judgmental space for Patients to share their thoughts and feelings,
          and encourage open and honest communication.
        </li>

        <li>
          Maintain professional boundaries and confidentiality at all times in accordance with the
          ethical guidelines set forth by your professional licensing board.
        </li>
        <li>
          Foster a safe and inclusive space for all patients, free from discrimination, bias, and
          microaggressions
        </li>
      </ul>

      <h1 className="text-lg md:text-3xl font-bold	text-orange  mb-4">Cultural sensitivity</h1>
      <ul className=" list-disc font-normal md:text-xl text-base leading-8 md:leading-10 text-justify ml-6 mb-14 flex flex-col gap-8 mt-8">
        <li>Be respectful and considerate of others' experiences and perspectives.</li>
        <li>
          Recognize and acknowledge the unique cultural and societal factors that can impact black
          mental health.
        </li>
        <li> Show empathy and support towards others seeking help and healing.</li>
        <li>
          Cultivate a deep understanding of the unique experiences and challenges faced by Black
          individuals, and strive to provide culturally sensitive and responsive care.
        </li>
      </ul>
    </div>
  );
};

export default Therapists;
