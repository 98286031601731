import { ReactElement } from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  to: string;
  children: ReactElement | string;
  className?: string;
}

const LinkButton = ({ to, children, className }: ButtonProps) => {
  return (
    <Link
      to={to}
      className={`text-white md:text-2xl text-lg bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md ${
        className ?? ""
      }`}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
