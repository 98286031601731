import { PageWrapper } from "components";
import OpportunityView from "./OpportunityView";

export const OpportunityContainer = () => {
  return (
    <PageWrapper>
      <OpportunityView />
    </PageWrapper>
  );
};
