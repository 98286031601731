import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { commentsArrowRight } from "assets/images";

interface Props {
  storyId: number;
}

const CommentForm: React.FC<Props> = ({ storyId }) => {
  const initialValues = {
    name: "",
    email: "",
    commentText: ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    commentText: Yup.string().required("Comment is required")
  });

  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    try {
      await axios.post("/api/comments", {
        name: values.name,
        email: values.email,
        body: values.commentText,
        storyId
      });
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched }) => (
        <Form>
          <div>
            <label htmlFor="name">Name:</label>
            <Field type="text" name="name" id="name" />
            {errors.name && touched.name ? <div>{errors.name}</div> : null}
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <Field type="email" name="email" id="email" />
            {errors.email && touched.email ? <div>{errors.email}</div> : null}
          </div>
          <div>
            <label htmlFor="commentText">Comment:</label>
            <Field
              placeholder="What are your thoughts on this story?"
              as="textarea"
              name="commentText"
              id="commentText"
              className="border border-[#C4C4C4] py-[24px] px-[16px] md:w-[250px] lg:w-[380px] h-[150px]  gap-[10px]"
            />
            {errors.commentText && touched.commentText ? <div>{errors.commentText}</div> : null}
          </div>

          <button
            type="submit"
            className="flex items-center gap-1 w-[200px] md:w-[189.82px] h-[54px] p-[16px] rounded-[4px]  border-2 border-[#FF9C00] text-[#FF9C00]"
          >
            <span className="font-bold text-base">Post a comment</span>
            <img src={commentsArrowRight} alt="arrow-right" />
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;
