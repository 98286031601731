import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  placeholder: string;
  label: string;
  type?: "textarea";
  className?: string;
  labelClassName?: string;
}

function Thtextarea({
  nameAndId,
  placeholder,
  label,
  type,
  className,
  labelClassName
}: FormInputProps) {
  return (
    <div className="flex flex-col border-b-2 border-b-black w-full">
      <label
        className={`text-black text-base font-light ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        placeholder={placeholder}
        type={type}
        className={
          className ??
          "bg-white placeholder-gray placeholder:text-sm text-black text-base font-normal h-28 pt-2 pb-1 focus:ring-0 border-none outline-none"
        }
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default Thtextarea;
