import { useMemo } from "react";
import { LinkButton } from "components/widgets";
import { STORIESFORM } from "routes/CONSTANTS";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { stories } from "utils";

const SharedStories = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const ourStoriesCarousel = useMemo(() => {
    return (
      <Carousel
        responsive={responsive}
        showDots={true}
        swipeable
        draggable
        slidesToSlide={1}
        dotListClass=""
        itemClass="py-12 md:px-8 px-2"
      >
        {stories.map((story) => {
          return (
            <div
              className="w-full md:p-12 p-5 bg-[#FFFBE8] flex md:gap-6 gap-1 flex-col items-center justify-center h-[600px] md:pb-6 cursor-pointer md:hover:scale-105 ease-in-out duration-300  rounded-2xl shadow-md"
              key={story.title}
            >
              <div>
                <img src={story.image} className="inline" />
              </div>
              <div className=" mt-5 text-center md:mb-2">
                <p className=" font-normal text-[#3C4E40] text-justify">
                  {story.paragraph.slice(0, 295)}...
                  <span className="text-orange">
                    <Link to={`stories/${story.id}`}> view full story </Link>
                  </span>
                </p>
              </div>
              <p className="text-[#3C4E40] text-sm md:text-2xl font-bold mt-4">{story.title}</p>
              <div>
                <p className="text-[#C4C4C4] md:text-xl text-xs">{story.location}</p>
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }, []);

  return (
    <div className="md:px-12 xl:px-28 px-5 md:py-16 py-12">
      <div className="border-l-8 border-solid border-orange p-5">
        <h1 className="text-[#005028] font-bold text-4xl">Some shared stories</h1>
      </div>
      <div className="md:mt-16 mt-8 md:flex md:justify-between">
        <div className="w-full">{ourStoriesCarousel}</div>
      </div>

      <div className="flex justify-center flex-col items-center mt-12 max-md:justify-center px-5">
        <p className="font-medium text-xl">Want to share your story too?</p>
        <LinkButton to={STORIESFORM} className="mt-10">
          Let’s hear yours
        </LinkButton>
      </div>
    </div>
  );
};

export default SharedStories;
