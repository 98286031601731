import { researchStoriesImg } from "assets/images";

const ResearchStoriesHero = () => {
  return (
    <>
      <header
        className="h-[60vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center "
        style={{ backgroundImage: `url(${researchStoriesImg})` }}
      >
        <div className="flex flex-col gap-8 justify-center items-center px-6  lg:px-96 h-[60vh] bg-[#000] bg-opacity-60 w-full">
          <h1 className="text-white font-bold md:font-semibold text-4xl md:text-6xl text-center md:leading-tight">
            Our Stories
          </h1>
        </div>
      </header>
    </>
  );
};

export default ResearchStoriesHero;
