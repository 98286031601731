import { OpportunityCover, Programs, RecentOpening, Testmonial } from "components";
import { Helmet } from "react-helmet";

const OpportunityView = () => {
  return (
    <>
      <Helmet>
        <title>Opportunities with Black Mental Health Matters</title>
        <meta
          name="description"
          content="Discover your passion for serving communities with Black Mental Health Matters: Check out our available career opportunities now."
        />
        <meta name="keywords" content="opportunities, careers, black mental health matters" />
      </Helmet>
      <OpportunityCover />
      <Programs />
      <Testmonial />
      <RecentOpening />
    </>
  );
};

export default OpportunityView;
