import * as Yup from "yup";

const resetPwdSchema = Yup.object({
  email: Yup.string().required("Please enter your Email Address").email("Invalid email address"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Weak Password. Password must have:, At least one upper case, At least one lower case, At least one digit, At least one special character, Minimum eight in length"
    )
    .max(25, "Password length exceeded"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Weak Password. Password must have:, At least one upper case, At least one lower case, At least one digit, At least one special character, Minimum eight in length"
    )
    .max(25, "Password length exceeded"),
  terms: Yup.boolean().isTrue("Please accept terms and conditions")
});

export default resetPwdSchema;
