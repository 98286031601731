import React, { useState, useEffect } from "react";
import axios from "axios";

interface Comment {
  id: number;
  name: string;
  email: string;
  body: string;
}

interface Props {
  storyId: number;
}

const CommentList: React.FC<Props> = ({ storyId }) => {
  const [comments, setComments] = useState<Comment[]>([]);

  const fetchComments = () => {
    axios
      .get(`/api/comments?storyId=${storyId}`)
      .then((response) => {
        setComments(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchComments();
  }, [storyId]);

  return (
    <div>
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id}>
            <h4>{comment.name}</h4>
            <p>{comment.body}</p>
          </div>
        ))
      ) : (
        <p>No comments yet.</p>
      )}
    </div>
  );
};

export default CommentList;
