import { Helmet } from "react-helmet";
import {
  CarouselComponent,
  DigitalResources,
  Faqs,
  Help,
  HomeHero,
  Join,
  OurServices,
  OurPartners,
  Podcasts,
  Testimonials
} from "components";

const HomeView = () => {
  return (
    <>
      <Helmet>
        <title>Your first stop for a better mental health - Black Mental Health Matters</title>
        <meta
          name="description"
          content="BMHM is a web based application to connect users who need mental health services with mental health providers and provide an enabling environment to heal from depression ,anxiety and other forms of mental illness."
        />
        <meta name="keywords" content="online therapy, mental health services" />
      </Helmet>
      <HomeHero />
      <OurServices />
      <Help />
      <DigitalResources />
      <CarouselComponent />
      <Podcasts />
      <Testimonials />
      <Faqs />
      <OurPartners />
      <Join />
    </>
  );
};

export default HomeView;
