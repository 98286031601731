import axios from "axios";
import { useState } from "react";
import * as Yup from "yup";
import { GroupFormInput, GroupSelectInput, Success } from "components/modules";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { AmountButton, CenterLoader } from "components/widgets";
import { DonateFormProps } from "types";
import Select from "react-flags-select";

const DonateForm = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [tabOpen, setTabOpen] = useState(1);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const formikInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    amount: "",
    phone: "",
    currency: "",
    creditCardNo: "",
    cardHolder: "",
    expiryDate: "",
    cvv: ""
  };

  const guestValidation = Yup.object({
    firstname: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    lastname: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address")
  });

  const handleSubmit = async (values: DonateFormProps) => {
    setIsLoading(true);
    setIsSuccess(false);
    console.log(values);

    try {
      const res = await axios.post("/api/guestform", values);

      if (res.status >= 200 && res.status < 300) {
        setIsSuccess(true);
      } else {
        setErrorMessage(`Request failed with status code: ${res.status}`);
        setIsSuccess(false);
        toast.error(errorMessage);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsSuccess(false);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (countryCode: string) => {
    setSelectedCountry(countryCode);
  };

  const handleButtonClick = (amount: number) => {
    setSelectedAmount(amount);
  };

  return (
    <div className="bg-green rounded-md md:p-8 p-3 mt-12">
      {isSuccess && <Success response="" />}
      {!isSuccess && (
        <>
          <p className="md:text-2xl text-sm font-bold text-white text-center">
            Choose amount to donate to BMHM.
          </p>
          <Formik
            initialValues={formikInitialValues}
            onSubmit={handleSubmit}
            validationSchema={guestValidation}
          >
            {({ isSubmitting }) => (
              <Form>
                {isLoading ? (
                  <div className="text-center mt-8">
                    <CenterLoader />
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between">
                      <div className="flex md:gap-28 gap-4">
                        <div className=" text-white md:text-2xl md:mt-8 mt-4 flex items-center ">
                          <input
                            type="checkbox"
                            className="md:h-6 md:w-6 h-4 w-4 md:mr-4 mr-2 accent-green-100"
                          />
                          <label className="font-normal md:text-2xl text-sm">One Time</label>
                        </div>
                        <div className=" text-white md:text-2xl md:mt-8 mt-4 flex items-center ">
                          <input
                            type="checkbox"
                            className="md:h-6 md:w-6 h-4 w-4 md:mr-4 mr-2 accent-green-100"
                          />
                          <label className="font-normal md:text-2xl text-sm">Monthly</label>
                        </div>
                      </div>
                      <GroupSelectInput label="" nameAndId="currency">
                        <option value="usd">Usd $</option>
                        <option value="gbp">Gbp</option>
                        <option value="cad">Cad</option>
                      </GroupSelectInput>
                    </div>

                    <div className="mt-8 grid md:grid-cols-4 grid-cols-2 gap-4 md:gap-8">
                      <AmountButton
                        amount={10}
                        selected={selectedAmount === 10}
                        onClick={handleButtonClick}
                      />
                      <AmountButton
                        amount={20}
                        selected={selectedAmount === 20}
                        onClick={handleButtonClick}
                      />
                      <AmountButton
                        amount={30}
                        selected={selectedAmount === 30}
                        onClick={handleButtonClick}
                      />
                      <AmountButton
                        amount={50}
                        selected={selectedAmount === 50}
                        onClick={handleButtonClick}
                      />
                      <AmountButton
                        amount={100}
                        selected={selectedAmount === 100}
                        onClick={handleButtonClick}
                      />
                      <input
                        className="bg-green placeholder-gray text-white md:text-2xl text-base font-normal py-1 px-4 md:h-[73px] h-[44px] focus:ring-0 border-2 border-gray rounded-md outline-none"
                        type="number"
                        id="amount"
                        placeholder="$ Custom Amount"
                        name="amount"
                        value={selectedAmount}
                        onChange={(e) => setSelectedAmount(parseInt(e.target.value))}
                      />
                    </div>

                    <div className="flex items-center justify-center pb-4 pt-8 md:mt-16 md:mb-8 w-full">
                      <p className="text-xl md:text-3xl font-bold text-white">Enter Your Details</p>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 md:pt-4 pt-2">
                      <GroupFormInput
                        nameAndId="first_name"
                        type="text"
                        placeholder=""
                        label="First Name*"
                      />
                      <GroupFormInput
                        nameAndId="last_name"
                        type="text"
                        placeholder=""
                        label="Last Name*"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:gap-8 gap-4 pt-8">
                      <GroupFormInput
                        nameAndId="email"
                        type="email"
                        placeholder=""
                        label="Email*"
                      />
                      <div className="flex flex-col ">
                        <label
                          htmlFor="country-dropdown"
                          className="text-white md:text-2xl text-base font-bold pb-3"
                        >
                          Country:
                        </label>
                        <Select
                          id="country-dropdown"
                          selected={selectedCountry}
                          onSelect={handleSelectChange}
                          className="w-full border-2 md:h-[73px] h-[44px] border-gray rounded-md"
                        />
                      </div>
                      <GroupFormInput
                        nameAndId="phone"
                        type="text"
                        placeholder=""
                        label="Phone Number"
                      />
                    </div>
                    <div className="flex items-center justify-center pb-4 pt-8 md:mt-16 md:mb-8 w-full">
                      <p className="text-xl md:text-3xl font-bold text-white">Payment Details</p>
                    </div>

                    <div className="rounded-md w-full md:h-[105px] h-[90px] border-2 border-gray py-4 px-2">
                      <p className="text-white  text-base md:text-xl font-bold">Total</p>
                      <div className="flex justify-between items-center mt-2">
                        <p className="text-gray text-lg md:text-3xl font-bold">
                          $ {selectedAmount}
                        </p>
                        <p className="text-orange">Change</p>
                      </div>
                    </div>

                    <div className="bg-white p-2 grid grid-cols-2 items-center justify-center md:my-8 my-4 rounded-md w-full md:w-[300px] mx-auto md:mt-16 mt-12">
                      <span
                        onClick={() => setTabOpen(1)}
                        className={
                          tabOpen === 1
                            ? "bg-orange rounded-md text-white px-2 py-4 w-full text-center"
                            : "bg-white-rounded-md text-black px-2 py-4 w-full text-center"
                        }
                      >
                        Credit Card
                      </span>
                      <span
                        onClick={() => setTabOpen(2)}
                        className={
                          tabOpen === 2
                            ? "bg-orange rounded-md text-white px-2 py-4 w-full text-center"
                            : "bg-white-rounded-md text-black px-2 py-4 w-full text-center"
                        }
                      >
                        Paypal
                      </span>
                    </div>

                    {tabOpen === 1 && (
                      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 md:pt-16 pt-8">
                        <GroupFormInput
                          nameAndId="creditCardNo"
                          type="text"
                          placeholder=""
                          label="Credit Card Number"
                        />
                        <GroupFormInput
                          nameAndId="cardHolder"
                          type="text"
                          placeholder=""
                          label="Name of card Holder"
                        />
                        <GroupFormInput
                          nameAndId="expiryDate"
                          type="text"
                          placeholder=""
                          label="Expiry Date"
                        />
                        <GroupFormInput nameAndId="cvv" type="text" placeholder="" label="cvv" />
                      </div>
                    )}

                    {tabOpen === 2 && (
                      <div>
                        <button className="w-full py-4 px-2 bg-orange text-white text-base md:text-2xl rounded-md md:mt-16 mt-8">
                          Donate With Paypal
                        </button>
                      </div>
                    )}

                    <div className="mt-16 mb-8 flex items-center justify-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="text-white md:text-2xl text-base bg-orange py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
                      >
                        Donate ${selectedAmount}
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default DonateForm;
