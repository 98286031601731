import { logo } from "assets/images";
import { PwdInput } from "components/modules";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAppDispatch } from "hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LOGIN, LOGIN_USER, TERMS } from "routes/CONSTANTS";
import { resetPwdSchema } from "validations";
import ResetPasswordText from "./ResetPasswordText";
import { resetPassword } from "redux/slices/auth.slice";
import { CenterLoader } from "components/widgets";
import { useState } from "react";

interface ResetPwdData {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userId, token } = useParams<{ userId: string; token: string }>();

  const formikInitialValues = {
    terms: false,
    password: "",
    confirmPassword: ""
  };

  const handleSubmit = async (details: ResetPwdData) => {
    setIsLoading(true);

    try {
      await dispatch(resetPassword({ userId, token, ...details }))
        .unwrap()
        .then(() => {
          navigate(LOGIN_USER);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto">
      {isLoading && <CenterLoader />}
      <ResetPasswordText />

      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <div className="flex items-center justify-center">
          <img src={logo} />
        </div>
        <p className="font-bold text-base md:text-2xl text-gray-100 py-4 md:py-8">
          Your new password must be different from previous used passwords
        </p>
        <div>
          <Formik
            initialValues={formikInitialValues}
            enableReinitialize={true}
            validationSchema={resetPwdSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-4">
              <div className="border-b-2 border-b-black w-full pt-4">
                <PwdInput
                  nameAndId="password"
                  placeholder="*****************"
                  label="New Password"
                />
              </div>
              <div className="border-b-2 border-b-black w-full pt-4">
                <PwdInput
                  nameAndId="password"
                  placeholder="*****************"
                  label=" Confirm Password"
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 md:gap-4 items-center justify-start">
                  <Field type="checkbox" name="terms" className="accent-green" />
                  <label htmlFor="terms" className="font-normal text-xs md:text-base text-ash">
                    I agree to all the statements in the
                    <span className="text-green hover:text-green-100">
                      <Link to={TERMS}> Terms of Service</Link>
                    </span>
                  </label>
                </div>
                <ErrorMessage
                  component="label"
                  name="terms"
                  className="text-sm w-full text-[#FF0000]"
                />
              </div>

              <div className="bg-green flex items-center justify-center w-full rounded-md mx-auto mt-8">
                <button type="submit" className="bg-green py-3 px-8 text-white w-full rounded-md">
                  Reset Password
                </button>
              </div>
            </Form>
          </Formik>
          <div className="hover:text-green-100 text-lg text-green pt-8 mt-4">
            <Link to={LOGIN} className="font-normal text-lg text-green-100 hover:text-green">
              Go back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
