import { ErrorMessage, Field } from "formik";

interface TextareaInputProps {
  nameAndId: string;
  label: string;
  placeholder?: string;
  labelClassName?: string;
  className?: string;
}

function TextareaInput({
  nameAndId,
  label,
  placeholder,
  labelClassName,
  className
}: TextareaInputProps) {
  return (
    <div className="flex flex-col">
      <label
        className={`text-white md:text-2xl text-base font-bold pb-3 ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        placeholder={placeholder}
        as={"textarea"}
        className={
          className ??
          "bg-green placeholder-gray text-white md:text-2xl text-base font-normal py-1 px-2 md:h-[180px] h-[120px] focus:ring-0 border-2 border-gray rounded-md outline-none"
        }
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default TextareaInput;
