import { DashboardWrapper } from "components";
import ReferAFriendView from "./ReferAFriendView";

export const ReferAFriendContainer = () => {
  return (
    <DashboardWrapper>
      <ReferAFriendView />
    </DashboardWrapper>
  );
};
