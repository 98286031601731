import { currentuser } from "assets/images";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

// import { GroupFormInput } from "components/modules"

const RecentComment = () => {
  const liked = false;
  const comments = [
    {
      id: 1,
      desc: "Lorem ipsum dolor sit amet consectetur. Ipsum mi viverra odio nunc tristique ipsum cursus in nunc. Pretium tortor amet gravida diam amet. Rhoncus vivamus vel vel sed sagittis hendrerit. Et sodales vel tristique nulla ac. Vivamus integer nunc nullam convallis at nec dignissim. Amet fringilla id eget urna. Sapien quis pellentesque fames amet nulla. Volutpat id in ut facilisi. Etiam volutpat euismod aliquam sed suspendisse mattis ipsum curabitur ultricies. Nunc dui venenatis augue sapien elementum pharetra ultrices a non. Sed duis quam ipsum faucibus.In augue ultricies faucibus eget lectus donec consequat. Fames faucibus consequat scelerisque neque turpis platea. Tincidunt nec hac mauris tincidunt euismod egestas lectus. Mauris at pellentesque tristique pellentesque libero ornare sagittis. Tortor elementum ridiculus diam aliquam tempor pretium amet molestie.",
      name: "John Doe",
      userId: 1,
      profilePicture: currentuser
    },
    {
      id: 2,
      desc: "Lorem ipsum dolor sit amet consectetur. Ipsum mi viverra odio nunc tristique ipsum cursus in nunc. Pretium tortor amet gravida diam amet. Rhoncus vivamus vel vel sed sagittis hendrerit. Et sodales vel tristique nulla ac. Vivamus integer nunc nullam convallis at nec dignissim. Amet fringilla id eget urna. Sapien quis pellentesque fames amet nulla. Volutpat id in ut facilisi. Etiam volutpat euismod aliquam sed suspendisse mattis ipsum curabitur ultricies. Nunc dui venenatis augue sapien elementum pharetra ultrices a non. Sed duis quam ipsum faucibus.In augue ultricies faucibus eget lectus donec consequat. Fames faucibus consequat scelerisque neque turpis platea. Tincidunt nec hac mauris tincidunt euismod egestas lectus. Mauris at pellentesque tristique pellentesque libero ornare sagittis. Tortor elementum ridiculus diam aliquam tempor pretium amet molestie.",
      name: "Jane Doe",
      userId: 2,
      profilePicture: currentuser
    }
  ];

  return (
    <div className=" w-full">
      <p className="text-[28px] font-semibold">Recent Comments</p>
      <div className="write flex  items-center mt-10">
        <div className=" ">
          <img src={currentuser} alt="" className="w-[70%]" />
        </div>
        <div className=" w-full">
          <input type="text" placeholder="write a comment" className="border px-4 py-2 w-4/5" />
          <button className=" text-white px-6 ml-3 py-2 bg-green">Send</button>
        </div>
      </div>

      {comments.map((comment) => (
        <div className="comment  mt-10" key={comment.id}>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className=" ">
                <img src={currentuser} alt="" className="w-[70%]" />
              </div>
              <div className="">
                <p className="text-xl font-medium">{comment.name}</p>
                <p className="font-medium text-[18px]">1 hour ago</p>
              </div>
            </div>

            <div className="item text-xl font-medium">
              {liked ? (
                <AiFillHeart className="inline text-2xl" />
              ) : (
                <AiOutlineHeart className="inline text-2xl" />
              )}
              12 Likes
            </div>
          </div>
          <div className="info mt-2">
            <p className="text-xl text-justify">{comment.desc}</p>
          </div>
          {/* <span className="date">1 hour ago</span> */}
        </div>
      ))}

      <div className="mt-10">
        <span className="text-blue underline text-xl font-bold cursor-pointer ">View more...</span>
      </div>
    </div>
  );
};

export default RecentComment;
