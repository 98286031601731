/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AuthService } from "services";
import { formatErrorResponse } from "utils";
import { setMessage } from "./message.slice";
import { BMHM_USER_DATA } from "services/CONSTANTS";

interface RegisterData {
  full_name: string;
  email: string;
  password: string;
}

interface LoginData {
  username: string;
  password: string;
}

interface User {
  email: string;
  full_name: string;
  createdAt: string;
  updatedAt: string;
  // ... other properties
}

interface ApiResponse {
  STATUS?: string;
  MESSAGE?: string;
  STATUS_CODE?: number;
  BODY?: {
    authToken?: string;
    user?: User;
  };
}

// interface AuthState {
//   isLoggedIn: boolean;
//   user: User | null;
//   isLoading: boolean;
// }

const user = JSON.parse(localStorage.getItem(BMHM_USER_DATA) as string);
const initialState = user
  ? { isLoggedIn: true, user, isLoading: false }
  : { isLoggedIn: false, user: null, isLoading: false };

export const register = createAsyncThunk(
  "auth/register",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  async ({ full_name, email, password }: RegisterData, thunkAPI) => {
    try {
      const response = await AuthService.register({ full_name, email, password });
      const successMessage = response.data.MESSAGE;
      toast.success(successMessage);
      thunkAPI.dispatch(setMessage(successMessage));
      return response.data?.BODY;
    } catch (error: any) {
      const errorMessage = error.response?.data?.MESSAGE || error.message || error.toString();
      toast.error(errorMessage);
      thunkAPI.dispatch(setMessage(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const googleRegister = createAsyncThunk("auth/googleRegister", async (_, thunkAPI) => {
  try {
    const response = await AuthService.googleSignup();
    const successMessage = response.data.MESSAGE;
    toast.success(successMessage);
    thunkAPI.dispatch(setMessage(successMessage));
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.MESSAGE || error.message || error.toString();
    toast.error(errorMessage);
    thunkAPI.dispatch(setMessage(errorMessage));
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: LoginData, thunkAPI) => {
    try {
      const response = await AuthService.login({ username, password });
      const responseData: ApiResponse = response;

      if (responseData.BODY) {
        toast.success(responseData.MESSAGE);
      }
      return responseData.BODY;
    } catch (error) {
      const errorMessage = formatErrorResponse(error);
      toast.error(errorMessage);
      thunkAPI.dispatch(setMessage(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const loginSuccess = createAsyncThunk("auth/loginSuccess", async (_, thunkAPI) => {
  try {
    const response = await AuthService.loginSuccess();
    const responseData: ApiResponse = response;

    if (responseData.BODY) {
      toast.success(responseData.MESSAGE);
    }
    return responseData.BODY;
  } catch (error) {
    const errorMessage = formatErrorResponse(error);
    toast.error(errorMessage);
    thunkAPI.dispatch(setMessage(errorMessage));
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const confirmAccount = createAsyncThunk(
  "auth/confirmAccount",
  async (code: string, thunkAPI) => {
    try {
      const { MESSAGE, DATA } = await AuthService.confirmAccount(code);
      toast.success(MESSAGE);
      return { userId: DATA.id, email: DATA.email };
    } catch (error) {
      const message = formatErrorResponse(error);
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (details: { email: string }, thunkAPI) => {
    try {
      const { DATA, MESSAGE } = await AuthService.forgotPassword(details);
      toast.success(MESSAGE);
      return { userId: DATA.id, email: DATA.email };
    } catch (error) {
      const message = formatErrorResponse(error);
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

interface ResetPasswordParams {
  userId?: string;
  token?: string;
  password: string;
  confirmPassword: string;
}

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ userId, token, password, confirmPassword }: ResetPasswordParams, thunkAPI) => {
    try {
      const { DATA, MESSAGE } = await AuthService.resetPassword({
        userId,
        token,
        password,
        confirmPassword
      });
      toast.success(MESSAGE);
      return { userId: DATA.userId, email: DATA.email };
    } catch (error) {
      const message = formatErrorResponse(error);
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
  window.location.reload();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // register actions
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      // state.isLoggedIn = false;
      // state.isLoading = false;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(register.rejected, (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
    });

    // login actions
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.user = null;
    });

    // login success actions
    builder.addCase(loginSuccess.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginSuccess.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(loginSuccess.rejected, (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.user = null;
    });

    // forgot password actions
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.isLoading = false;
    });

    // forgot password actions
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

const { reducer } = authSlice;
export default reducer;
