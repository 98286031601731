import { useState, useEffect } from "react";
import { ReasrchSearchImg, searchCiteLogoImg } from "assets/images";
import { journals } from "utils";
import { CenterLoader } from "components/widgets";
import { Link } from "react-router-dom";

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJournals, setFilteredJournals] = useState(journals);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [searchTerm]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setFilteredJournals(
      journals.filter(
        (journal) =>
          journal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          journal.paragraph.toLowerCase().includes(searchTerm.toLowerCase()) ||
          journal.journal.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center py-20  mx-72 max-md:mx-5">
        <h5 className="mt-2">
          Explore more than 1000 articles from our 30+ open acces Jornals - All free to read and
          downlaod
        </h5>
        <form className="w-full ">
          <div className="border rounded-lg flex items-center bg-white h-12">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              className="rounded-md text-black w-full h-full px-2"
              placeholder="eg: covid article"
            />
            <button className="bg-green text-white rounded-r-md px-4 py-2 h-full">
              <img src={ReasrchSearchImg} alt="search icon" />
            </button>
          </div>
        </form>
      </div>
      <div className="px-20 mb-20 max-md:px-0">
        {isLoading ? <CenterLoader /> : null}
        <p className="ml-10 max-md:ml-5 ">Total results: {filteredJournals.length} articles</p>
        {filteredJournals.length === 0 ? (
          <div>Your Search Yielded 0 results</div>
        ) : (
          <ul>
            {filteredJournals.map((journal: any) => (
              <li className="flex mx-10 max-md:mx-6 my-10 max-md:flex-col" key={journal.id}>
                <div className="mr-4 max-md:mr-0">
                  <img src={journal.Image} alt="Result Image" />
                </div>
                <div className="flex flex-col pr-40 max-md:pr-8  max-md:mt-3">
                  <div className="text-xl leading-tight text-bold font-bold max-md:text-base max-md:font-medium max-md:pr-10">
                    {journal.paragraph}
                  </div>
                  <div className="flex justify-between my-5 max-md:flex-col ">
                    <div className="text-xl italic text-[#3C4E40] tracking-wider max-md:text-lg">
                      {journal.journal}
                    </div>
                    <div className="text-lg text-[#3C4E40] tracking-wider flex items-center max-md:mt-2">
                      <img src={journal.ProfileIMG} alt="Profile picture" />
                      <div className="ml-2">{journal.name}</div>
                    </div>
                  </div>
                  <div className="flex">
                    <button className="font-bold text-[#00A552] tracking-widest"> PDF </button>{" "}
                    <div className="mx-4">| </div>{" "}
                    <button className="font-bold text-[#00A552] tracking-widest"> XML</button>
                  </div>
                  <div className="flex my-5">
                    <Link
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      to={`journal/${journal.id}`}
                      className="font-sm text-white bg-orange p-5 rounded mr-5"
                    >
                      View asbtract
                    </Link>
                    <button className="font-sm text-orange border border-orange py-5 px-9 rounded">
                      <div className="flex items-center">
                        <div>
                          {" "}
                          <img src={searchCiteLogoImg} alt="logo" className="mr-2" />
                        </div>
                        <div>Cite</div>
                      </div>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchPage;
