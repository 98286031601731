const AbstractContain = () => {
  return (
    <>
      <div className="font-light sm:text-lg lg:text-2xl text-[#3C4E40]">
        <h1 className="font-bold mb-4"> Abstract</h1>
        <p>
          Question Does torsemide reduce all-cause mortality compared with furosemide in patients
          with heart failure following hospitalization? Findings In this randomized clinical trial
          of 2859 patients, 26.1% of patients randomized to torsemide and 26.2% randomized to
          furosemide died over a median follow-up of 17.4 months without a significant difference
          between groups. Meaning Among patients discharged after hospitalization for heart failure,
          torsemide compared with furosemide did not result in a significant difference in all-cause
          mortality over 12 months; however, interpretation of these findings is limited by loss to
          follow-up and participant crossover and nonadherence.
        </p>
        <h1 className="font-bold my-4"> Background</h1>
        <p>
          Between 30% to 76% of COVID-19 patients have persistent physical and mental symptoms,
          sometimes up to 9 months after acute COVID-19. Current rehabilitation is mostly focused on
          the physical symptoms, whereas experts have agreed on the need for a biopsychosocial
          approach. A novel approach such as virtual reality (VR) rehabilitation at home might
          benefit patients and therapists, especially considering the expected rush of patients with
          post–COVID-19 condition needing rehabilitation.
        </p>
        <h1 className="font-bold my-4"> Objective</h1>
        <p>
          The aim of this study was to investigate the feasibility of self-administered VR exercises
          at home for post–COVID-19 condition.
        </p>
        <h1 className="font-bold my-4"> Methods</h1>
        <p>
          This was a single-arm feasibility study in an outpatient care setting. Patients who needed
          physiotherapy because of post–COVID-19 condition were included as determined by the
          treating physiotherapist. Participants performed VR physical exercises at home for a
          period of 6 weeks and were allowed to perform VR mental exercise through applications
          available on the VR platform to reduce stress and anxiety and promote cognitive
          functioning. The main outcomes were related to feasibility (ie, duration and frequency of
          VR use), safety (ie, adverse events), patient satisfaction, and reasons to withdraw.
          Physical performance, daily activities, cognitive functioning, anxiety and depression, and
          the quality of life were measured before and after.
        </p>
        <h1 className="font-bold my-4">Results</h1>
        <p>
          In total, 48 patients were included; 1 (2%) patient did not start VR, and 7 (15%) patients
          withdrew, mostly due to dizziness. Almost 70% (33/47) of participants reported
          experiencing any adverse event during VR exercising. However, only 25% (9/36) recalled
          these events at the end of the intervention period. The majority (27/36, 75%) of the
          patients described VR as having a positive influence on their recovery, and the global
          satisfaction score was 67%. The average VR use was 30 minutes per session, 3-4 times a
          week for 3-6 weeks. The overall use of VR applications was almost equally distributed over
          the 3 sets of VR exercises (physical, relaxing, and cognitive). However, the use frequency
          of physical exercises seemed to decrease over time, whereas the use of cognitive and
          relaxation exercises remained stable. Physical performance and quality of life outcomes
          were significantly improved after 6 weeks.
        </p>
        <h1 className="font-bold my-4"> Conclusions</h1>
        <p>
          VR physical exercises at home is feasible and safe with good acceptance in a significant
          percentage of patient with post–COVID-19 condition.
        </p>
        <h1 className="font-bold my-4">Introduction</h1>
        <p>
          The ongoing COVID-19 pandemic is leading to serious morbidity and mortality worldwide
          [1,2]. Studies show that 30% to 76% of COVID-19 patients have persistent symptoms,
          sometimes up to 9 months after acute COVID-19 [3-5]. These patients have a variety of
          symptoms in the physical and mental domains [6,7]. A substantial amount of post-COVID-19
          patients experience limitations in daily activities and social participation in the long
          term [8,9]. This condition was described as “long COVID,” “Post-(acute-)COVID syndrome,”
          or “postacute sequelae of SARS-CoV-2 infection” and is now termed “post–COVID-19
          condition” [10-12]. Patient-tailored post–COVID-19 rehabilitation is needed to recover
          these physical and mental functions and ultimately improve the patients’ quality of life
          [13,14]. Several reviews, consensus statements, and position papers concerning
          post–COVID-19 rehabilitation have already been put forth by professional rehabilitation
          organizations [13,15-18]. These experts agree on the need for an individualized program
          with a multimodal approach, not only aiming at restoring physical functioning, but also at
          reducing anxiety and depression and offering cognitive rehabilitation when needed. Virtual
          reality (VR) applications may be important tools in such rehabilitation, since they have
          the potential to address all aspects of this multimodal approach in a single solution
          [19]. Furthermore, they can provide health care practitioners with an easy-to-administer,
          tailor-made home rehabilitation solution against an impending surge of demand for
          post–COVID-19 rehabilitation. VR has the ability to immerse someone into another world,
          which can be used to distract patients from experiencing pain, fatigue, and anxiety and
          may increase therapy adherence. VR is increasingly used in rehabilitation such as
          poststroke rehabilitation, limb rehabilitation, and the treatment of posttraumatic stress
          disorder [20-22]. The use of VR for the improvement of general physical condition and
          health is relatively new and often involves 2D “exergaming” [23,24]. Recently, VR
          relaxation games were used for inpatient post–COVID-19 rehabilitation, showing high
          patient satisfaction and benefits regarding stress reduction and cognitive functioning
          [25]. VR exercises for patients with post–COVID-19 condition outside of the hospital may
          have similar benefits. These exercises would enlarge access to rehabilitation resources in
          general and, more specifically, for the large group of patients with acute COVID-19 at
          home. This study aimed to assess feasibility—usability, acceptability, tolerability, and
          safety—of 6 weeks of VR exercises at home indicated by community-based physiotherapists.
          Secondarily, we analyzed the changes in physical and mental functions and the quality of
          life.
        </p>
        <h1 className="font-bold my-4">Method</h1>
        <p>
          Several reviews, consensus statements, and position papers concerning post–COVID-19
          rehabilitation have already been put forth by professional rehabilitation organizations
          [13,15-18]. These experts agree on the need for an individualized program with a
          multimodal approach, not only aiming at restoring physical functioning, but also at
          reducing anxiety and depression and offering cognitive rehabilitation when needed. Virtual
          reality (VR) applications may be important tools in such rehabilitation, since they have
          the potential to address all aspects of this multimodal approach in a single solution
          [19]. Furthermore, they can provide health care practitioners with an easy-to-administer,
          tailor-made home rehabilitation solution against an impending surge of demand for
          post–COVID-19 rehabilitation. VR has the ability to immerse someone into another world,
          which can be used to distract patients from experiencing pain, fatigue, and anxiety and
          may increase therapy adherence. VR is increasingly used in rehabilitation such as
          poststroke rehabilitation, limb rehabilitation, and the treatment of posttraumatic stress
          disorder [20-22]. The use of VR for the improvement of general physical condition and
          health is relatively new and often involves 2D “exergaming” [23,24]. Recently, VR
          relaxation games were used for inpatient post–COVID-19 rehabilitation, showing high
          patient satisfaction and benefits regarding stress reduction and cognitive functioning
          [25]. VR exercises for patients with post–COVID-19 condition outside of the hospital may
          have similar benefits. These exercises would enlarge access to rehabilitation resources in
          general and, more specifically, for the large group of patients with acute COVID-19 at
          home. This study aimed to assess feasibility—usability, acceptability, tolerability, and
          safety—of 6 weeks of VR exercises at home indicated by community-based physiotherapists.
          Secondarily, we analyzed the changes in physical and mental functions and the quality of
          life
        </p>
        <h1 className="font-bold my-4">Conclusion</h1>
        <p>
          These exercises would enlarge access to rehabilitation resources in general and, more
          specifically, for the large group of patients with acute COVID-19 at home. This study
          aimed to assess feasibility—usability, acceptability, tolerability, and safety—of 6 weeks
          of VR exercises at home indicated by community-based physiotherapists. Secondarily, we
          analyzed the changes in physical and mental functions and the quality of life.
        </p>
      </div>
    </>
  );
};

export default AbstractContain;
