import { Link } from "react-router-dom";
import { CONTACT } from "routes/CONSTANTS";

const GetInTouch = () => {
  return (
    <div className="xl:px-40 md:px-28 px-5">
      <div className="flex flex-col justify-center items-center rounded-md bg-[#FFAC2A33] mt-6 mb-16 md:h-[350px] h-[420px] px-2 md:px-5 py-2">
        <h2 className="text-2xl md:text-4xl font-semibold leading-tight text-center">
          Have Additional Questions?
        </h2>
        <h5 className="my-3 text-center px-2 md:leading-0 leading-8">
          If you have a question that is not answered here, please reach out to a mental health
          professional, a trusted source or contact us below for more information. Remember, taking
          care of your mental health is important and seeking support is a sign of strength.
        </h5>
        <Link to={CONTACT} className="text-white bg-green px-10 py-2 rounded mt-4">
          {" "}
          Get in touch{" "}
        </Link>
      </div>
    </div>
  );
};

export default GetInTouch;
