import { useParams } from "react-router-dom";
import { journals } from "utils";

const JournalAbstract = () => {
  const params = useParams();
  const journalAbstract = journals.find((j) => j.id === params.id);

  if (!journalAbstract) {
    return <div>Journal not found</div>;
  }

  return <div>{journalAbstract.abstract}</div>;
};

export default JournalAbstract;
