import { resourceHero } from "assets/images";

const ResourceHero = () => {
  return (
    <div
      className=" h-[80vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center"
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      style={{ backgroundImage: `url(${resourceHero})` }}
    >
      <div className=" bg-[rgba(0,0,0,0.6)] w-screen h-[80vh]">
        <div className="absolute top-40 left-5 md:left-20 text-white">
          <h1 className="text-4xl md:text-6xl font-bold leaading-[40px] md:leading-[80px]">
            Digital Resources{" "}
          </h1>
          <p className="text-base font-bold md:w-[550px] mt-2.5">
            Empowering you to spread awareness and support mental health through our digital
            resources, featuring customizable banners and a curated list of social media and website
            tools.
          </p>
          <button className="text-white bg-[#005028] h-9 w-28 mt-2.5 rounded-lg text-base font-normal">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourceHero;
