import { InternshipFormHero, InternshipForm } from "components";

const IndividualFormView = () => {
  return (
    <>
      <InternshipFormHero />
      <InternshipForm />
    </>
  );
};

export default IndividualFormView;
