import {
  lg1,
  lg2,
  lg3,
  lg4,
  lg5,
  lg6,
  lg7,
  sn1,
  sn2,
  sn3,
  sn31,
  sn4,
  sn5,
  sn6
} from "assets/images";

interface StepProps {
  currentStep: number;
}

const Step = ({ currentStep }: StepProps) => {
  const stepColor = (step: number) => {
    if (step === currentStep) {
      return "text-orange";
    }
    if (step < currentStep) {
      return "text-white";
    }
    return "text-white";
  };

  const numColor = (step: number) => {
    if (step === currentStep) {
      return "text-orange rounded-full border-2 border-orange bg-green";
    }
    if (step < currentStep) {
      return "text-white";
    }
    return "text-white";
  };

  return (
    <div className="w-full bg-green flex md:flex-col flex-row justify-between md:gap-0 gap-2 h-[250px] md:h-full  md:overflow-hidden">
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6 ">
        <img src={sn1} alt="" />
        <img src={sn2} alt="" />
        <img src={sn3} alt="" />
        <img src={sn31} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg1} alt="" />
        <img src={lg2} alt="" />
        <img src={lg3} alt="" />
      </div>
      <div className="flex items-center justify-center">
        <div className="md:h-[432px] md:w-[358px] bg-[#000000] bg-opacity-50 rounded-2xl md:py-16 py-4 md:px-4 px-2 flex items-center gap-2 md:gap-4">
          <div className="flex flex-col gap-2 md:gap-8">
            <p
              className={`${numColor(
                1
              )} text-[10px] md:text-lg w-6 md:w-8 h-6 md:h-8 flex items-center justify-center`}
            >
              1
            </p>
            <p
              className={`${numColor(
                2
              )} text-[10px] md:text-lg w-6 md:w-8 h-6 md:h-8 flex items-center justify-center`}
            >
              2
            </p>
            <p
              className={`${numColor(
                3
              )} text-[10px] md:text-lg w-6 md:w-8 h-6 md:h-8 flex items-center justify-center`}
            >
              3
            </p>
            <p
              className={`${numColor(
                4
              )} text-[10px] md:text-lg w-6 md:w-8 h-6 md:h-8 flex items-center justify-center`}
            >
              4
            </p>
            <p
              className={`${numColor(
                5
              )} text-[10px] md:text-lg w-6 md:w-8 h-6 md:h-8 flex items-center justify-center`}
            >
              5
            </p>
          </div>
          <div className="flex flex-col gap-4 md:gap-9">
            <p className={` ${stepColor(1)} text-[10px] md:text-lg`}>Background Information</p>
            <p className={` ${stepColor(2)} text-[10px] md:text-lg`}>Education & Experience</p>
            <p className={` ${stepColor(3)} text-[10px] md:text-lg`}>Documents & References</p>
            <p className={` ${stepColor(4)} text-[10px] md:text-lg`}>Questionannaire</p>
            <p className={` ${stepColor(5)} text-[10px] md:text-lg`}>Review & Submit</p>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:flex-row flex-col items-start justify-start md:gap-20 gap-4 h-1/6">
        <img src={sn4} alt="" />
        <img src={sn5} alt="" />
        <img src={sn6} alt="" />
      </div>
      <div className="md:hidden flex md:flex-row flex-col items-start justify-between py-8">
        <img src={lg4} alt="" className="pl-4" />
        <img src={lg5} alt="" />
        <img src={lg6} alt="" />
        <img src={lg7} alt="" />
      </div>
    </div>
  );
};

export default Step;
