import Button from "components/widgets/buttons/Button";
import { Link } from "react-router-dom";
import Vacancy from "./Vacancy";
import { jobList } from "utils";

const RecentOpening = () => {
  return (
    <div className="px-5 py-10 md:px-12 xl:px-28 ">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl md:text-3xl font-bold opacity-70">Recent Opening</h1>
        <Link to="/" className="text-orange">
          See all
        </Link>
      </div>

      <div className="flex flex-col gap-5 my-3">
        <ul className="flex flex-col gap-5">
          {jobList.map((job) => (
            <li key={job.id}>
              <Link to={`jobs/${job.id}`}>
                <Vacancy vacancy={job.vacancy} type={job.location} experience={job.experience} />
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-row justify-between">
        <p className="text-gray">Page 1 of 50</p>
        <Button style="" label="Next" />
      </div>
    </div>
  );
};

export default RecentOpening;
