import { researchGroup } from "assets/images";
import { LinkButton } from "components/widgets";

const VolunteerHero = () => {
  return (
    <div
      className=" h-[80vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center"
      style={{ backgroundImage: `url(${researchGroup})` }}
    >
      <div className=" bg-[rgba(0,0,0,0.6)] w-screen h-[80vh] flex items-center justify-start">
        <div className="absolute md:left-12 xl:left-28 text-white flex flex-col gap-8 px-5">
          <h1 className="md:text-6xl text-3xl font-bold md:leading-[70px] md:w-[600px] w-full md:text-left text-center">
            Are you ready to become a <span className="text-orange">Volunteer</span> with BMHM?{" "}
          </h1>
          <p className="md:text-2xl text-lg md:font-bold font-medium md:w-[700px] w-full md:text-left text-center">
            We deeply appreciate our team of volunteers at BMHM and are humbled by their generosity
            in dedicating their time and efforts towards our shared goal.
          </p>
          <LinkButton to="/volunteerform">Become A Volunteer</LinkButton>
        </div>
      </div>
    </div>
  );
};

export default VolunteerHero;
