import { SignupUserForm } from "components";

const SignupUserView = () => {
  return (
    <div>
      <SignupUserForm />
    </div>
  );
};

export default SignupUserView;
