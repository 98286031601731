import { PageWrapper } from "components";
import ServicesView from "./ServicesView";

export const ServicesContainer = () => {
  return (
    <PageWrapper>
      <ServicesView />
    </PageWrapper>
  );
};
