export const HOME = "/";
export const ABOUT_US = "/about-us";
export const GET_INVOLVED = "/get-involved";
export const SERVICES = "/services";
export const CAMPAIGN = "/campaign";
export const STORIES = "/stories";
export const BE_A_VOICE = "/be-a-voice";

export const LOGIN = "/auth/login";
export const LOGIN_USER = "/auth/login/user";
export const LOGIN_THERAPIST = "/auth/login/therapist";
export const SIGNUP = "/auth/signup";
export const SIGNUP_USER = "/auth/signup/user";
export const SIGNUP_THERAPIST = "/auth/signup/therapist";
export const FORGOT_PASSWORD = "/auth/reset_password";
// export const RESET_PASSWORD = `/auth/${userId}/${Token}`;
export const RESET_PASSWORD = `/auth/change_password`;
export const CHANGE_PASSWORD = "/auth/change_password";
export const THER_LOGIN = "auth/ther-login";
export const THER_SIGNUP = "auth/ther-signup";

export const TERMS = "/terms";
export const PRIVACY = "/privacy";

export const DONATE = "/donate";
export const DONATE_ONCE = "/donate-once";
export const DONATE_MONTHLY = "/donate-monthly";
export const HOSTFORM = "/hostform";
export const GUESTFORM = "/guestform";
export const ORGANIZATIONFORM = "/organizationform";
export const INDIVIDUALFORM = "/individualform";
export const STORIESFORM = "/storiesform";
export const JOURNALFORM = "/journalform";
export const CAMPAIGNFORM = "/campaignform";
export const RESEARCH = "/research";
export const RESEARCHSTORIES = "/researchStories";
export const OPPORTUNITY = "/careers";
export const COMMUNITY = "/community";
export const PUBLICATION = "/publication";
export const JOBS = "/careers/jobs/:id";
export const ABSTRACT = "/search-article/journal/:id";
export const STORY = "research/stories/:id";
export const SEARCHARTICLE = "/search-article";
export const VOLUNTEER = "/volunteer";
export const PARTNER = "/partner";
export const VOLUNTEERFORM = "/volunteerform";
export const FAQ = "/faqs";
export const CONTACT = "/contact";
export const RESOURCES = "/resources";
export const DONATEFORM = "/donateform";
export const INTERNSHIP = "/internshipform";
export const PETITION = "/campaign/petitions/:id";
export const PRODUCT = "/product";

// Dashboard

export const DASHBOARD = "/dashboard";
export const APPOINTMENTS = "/dashboard/appointments";
export const AUDIOCASTS = "/dashboard/audiocasts";
export const CHATS = "/dashboard/chats.tsx";
export const TRENDS = "/dashboard/trends";
export const GROUPS = "/dashboard/groups";
export const EVENTS = "/dashboard/events";
export const REFER_A_FRIEND = "/dashboard/refer";
export const SETTINGS = "/dashboard/settings";
export const LOGOUT = "/logout";
export const QUIZ = "/dashboard/quiz";

export const THER_DASHBOARD = "/therapist";
export const ADMIN_DASHBOARD = "/admin";
