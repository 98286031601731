import { useState } from "react";
import BackgroundInformation from "./BackgroundInformation";
import EducationAndExperience from "./EducationAndExperience";
import DocumentsAndReferences from "./DocumentsAndReference";
import Questionnaire from "./Questionnaire";
import ReviewAndSubmit from "./ReviewAndSubmit";
import Step from "./Step";
import ProgressBar from "./ProgressBar";
import { arrowLeft } from "assets/icons";
import { HOME } from "routes/CONSTANTS";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "assets/images";
import { toast } from "react-toastify";
import env from "configs";
import axios from "axios";
import { CenterLoader } from "components/widgets";
import { THERAPIST_REGISTRATION } from "services/CONSTANTS";

const SignupTherapistForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData: any) => {
    setIsLoading(true);

    try {
      await axios.post(`${env.API_BASE_URL}/${THERAPIST_REGISTRATION}`, formData);
      toast.success("You have successfully registered. Your registration will be approved shortly");
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStepSubmit = (values: any) => {
    setFormValues((prevValues) => ({ ...prevValues, ...values }));

    if (step < 5) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleSubmitFinalStep = async () => {
    try {
      await handleSubmit(formValues);
    } catch (error) {
      console.error("Error during final form submission:", error);
    }
  };

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return <BackgroundInformation onSubmit={handleStepSubmit} />;
      case 2:
        return <EducationAndExperience onSubmit={handleStepSubmit} />;

      case 3:
        return <DocumentsAndReferences onSubmit={handleStepSubmit} />;
      case 4:
        return <Questionnaire onSubmit={handleStepSubmit} />;
      case 5:
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        return <ReviewAndSubmit onSubmit={handleSubmitFinalStep} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="text-center mt-8">
          <CenterLoader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto ">
            <Step currentStep={step} />
            <div className="py-2">
              <div className="flex items-center md:justify-between justify-center pl-12 px-5">
                <img
                  src={arrowLeft}
                  onClick={() => navigate(-1)}
                  className="cursor-pointer md:block hidden"
                />
                <Link to={HOME}>
                  <img src={logo} />
                </Link>
              </div>
              <ProgressBar currentStep={step} />
              <h1 className="text-green font-bold text-2xl md:text-4xl md:pl-12 py-2 md:pr-28 px-5 my-4">
                Therapist Registration
              </h1>
              {renderFormStep()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupTherapistForm;
