import { useState } from "react";
import axios from "axios";
import { FormCheckbox, PartnerFormInput, Success } from "components/modules";
import { Form, Formik } from "formik";
import { partnerSchema } from "validations";
import { CenterLoader } from "components/widgets";
import { toast } from "react-toastify";
import { OrganizationFormProps } from "types";

const OrganizationForm = () => {
  const [openModal, setOpenModal] = useState<true | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formikInitialValues = {
    logo: null,
    first_name: "",
    last_name: "",
    email: "",
    website_url: "",
    finance: false,
    awareness: false,
    legislative: false,
    socialMedia: false,
    professional: false,
    others: false
  };

  const handleSubmit = async (values: OrganizationFormProps) => {
    setIsLoading(true);
    setIsSuccess(false);

    try {
      const res = await axios.post("/api/individualform", values);

      if (res.status >= 200 && res.status < 300) {
        setIsSuccess(true);
      } else {
        setErrorMessage(`Request failed with status code: ${res.status}`);
        setIsSuccess(false);
        toast.error(errorMessage);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
      setIsSuccess(false);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="bg-green rounded-md md:p-8 p-3 mt-12">
      {isSuccess && <Success response="" />}
      {!isSuccess && (
        <>
          <p className="text-white md:text-4xl text-2xl font-bold text-center pb-12">
            Partner Registration
          </p>
          <Formik
            initialValues={formikInitialValues}
            validate={partnerSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form encType="multipart/form-data">
                {isLoading ? (
                  <div className="text-center mt-8">
                    <CenterLoader />
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-1 gap-8">
                      <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
                        <PartnerFormInput nameAndId="first_name" label="First Name" />
                        <PartnerFormInput nameAndId="last_name" label="Last Name" />
                      </div>
                      <PartnerFormInput nameAndId="email" label="Email" />
                      <PartnerFormInput nameAndId="website_url" label="Website URL" />
                      <div>
                        <p className="text-white font-bold text-base md:text-2xl mb-2 md:mb-4">
                          Logo
                        </p>
                        <label className="flex items-center cursor-pointer" htmlFor="file-input">
                          <span className="bg-white rounded-l-md py-2 px-2 md:px-4 md:h-[73px] h-[44px] w-[100px] md:w-[200px] text-sm md:text-2xl text-black flex items-center">
                            Select file
                          </span>
                          <input
                            type="file"
                            name="logo"
                            accept=".jpg,.png"
                            multiple={false}
                            className="bg-green border-2 border-gray h-[73px] rounded-r-md hidden"
                            onChange={handleFileInputChange}
                          />
                          <input
                            type="text"
                            name="file"
                            className="bg-green border-2 border-gray md:h-[73px] h-[44px] w-full rounded-r-md"
                          />
                        </label>
                        {selectedFile && <span className="ml-2">{selectedFile.name}</span>}
                      </div>
                    </div>

                    <p className="font-bold text-base md:text-2xl text-white md:mt-8 mt-8">
                      Why do you want to Partner with Us?
                    </p>

                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 mt-4">
                      <FormCheckbox type="checkbox" nameAndId="finance" label="Finance and Aid" />
                      <FormCheckbox
                        type="checkbox"
                        nameAndId="awareness"
                        label="Awareness Campaign"
                      />
                      <FormCheckbox
                        type="checkbox"
                        nameAndId="legislative"
                        label="Legislative Laws and Policy"
                      />
                      <FormCheckbox
                        type="checkbox"
                        nameAndId="socialMedia"
                        label="Social Media Influence"
                      />
                      <FormCheckbox
                        type="checkbox"
                        nameAndId="professional"
                        label="Professional Partnership"
                      />
                      <FormCheckbox type="checkbox" nameAndId="others" label="Others" />
                    </div>

                    <div className="mt-16 mb-8 flex items-center justify-center">
                      <button
                        // type="submit"
                        disabled={isSubmitting}
                        className="text-white text-2xl bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
                        onClick={() => setOpenModal(true)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center p-5">
          <div className="p-2 flex flex-col gap-4 items-center justify-center h-[362px] w-[334px] bg-white rounded-lg">
            <p className="text-black text-base font-bold">Are you sure you want to submit?</p>
            <div className="flex md:flex-row flex-col gap-2 w-full p-2">
              <button
                className="p-2 border-2 border-orange bg-white text-orange rounded-md w-full"
                onClick={() => {
                  setOpenModal(null);
                }}
              >
                Cancel
              </button>

              <button
                className="p-2 bg-orange text-white rounded-md border-2 border-orange w-full"
                type="submit"
                onClick={() => {
                  setOpenModal(null);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationForm;
