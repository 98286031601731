import { DashboardWrapper } from "components";
import TrendsView from "./TrendsView";

export const TrendsContainer = () => {
  return (
    <DashboardWrapper>
      <TrendsView />
    </DashboardWrapper>
  );
};
