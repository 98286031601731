import { google, linkedin, microsoft } from "assets/icons";
import { logo } from "assets/images";
import { FormInput, PwdInput } from "components/modules";
import { Link, useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD, SIGNUP, THER_DASHBOARD } from "routes/CONSTANTS";
import { Field, Form, Formik } from "formik";
import { login } from "redux/slices/auth.slice";
import { useAppDispatch, useQuery } from "hooks";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoginText from "components/layout/Login/LoginText";
import { loginSchema } from "validations";
import { CenterLoader } from "components/widgets";

const LoginTherapistForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = query.get("redirect");
    if (queryString) {
      const errorMessage = query.get("error");
      if (errorMessage) {
        if (errorMessage?.length > 0 && errorMessage !== "undefined") {
          toast.error(errorMessage);
        }
      }
    }
  }, []);

  const formikInitialValues = {
    username: "",
    password: "",
    remember: false
  };

  const handleSubmit = (details: { username: string; password: string }) => {
    setIsLoading(true);
    dispatch(login({ ...details }))
      .unwrap()
      .then((res: any) => {
        setIsLoading(false);
        if (res.STATUS === "SUCCESS") {
          toast.success(res.MESSAGE);
          navigate(THER_DASHBOARD);
        } else if (res.STATUS === "FAILURE") {
          toast.error(res.MESSAGE);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("An unexpected error occurred. Please try again.");
      });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto">
      {isLoading && <CenterLoader />}
      <LoginText />

      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <div className="flex items-center justify-center">
          <img src={logo} />
        </div>
        <p className="font-bold md:text-4xl text-2xl text-black py-4 md:py-8">User Login</p>
        <div>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={loginSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-4">
              <div className="border-b-2 border-b-black w-full pt-4">
                <FormInput
                  nameAndId="email"
                  placeholder="johndoe@gmail.com"
                  label="Email"
                  type="email"
                />
              </div>

              <div className="border-b-2 border-b-black w-full pt-4">
                <PwdInput nameAndId="password" placeholder="*****************" label="Password" />
              </div>

              <div className="flex justify-between">
                <div className="flex gap-4 items-center justify-start">
                  <Field type="checkbox" name="remember" className="accent-green" />
                  <label htmlFor="remember" className="font-normal text-base text-ash">
                    Remember Me
                  </label>
                </div>
                <Link to={FORGOT_PASSWORD} className="text-orange text-base">
                  Forgot Password?
                </Link>
              </div>

              <div className="bg-green flex items-center justify-center w-56 rounded-md mx-auto mt-2">
                <button type="submit" className="bg-orange py-3 px-8 text-white w-full rounded-md">
                  Login
                </button>
              </div>
            </Form>
          </Formik>

          <div className="flex flex-col gap-4 pt-4">
            <div className="flex gap-2 items-center justify-center pt-2">
              <div className="w-44 h-[0.5px] bg-black"></div>
              <div>or</div>
              <div className="w-44 h-[0.5px] bg-black"></div>
            </div>

            <div className="flex items-center justify-center gap-8 pt-2">
              <img src={microsoft} alt="" className="h-8 w-8" />
              <img src={linkedin} alt="" className="h-8 w-8" />
              <img src={google} alt="" className="h-8 w-8" />
            </div>

            <div className="text-green-100 text-lg text-center hover:text-green cursor-pointer pt-2">
              <Link to={SIGNUP}>Don't have an account?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginTherapistForm;
