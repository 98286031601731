import { PageWrapper } from "components";
import IndividualFormView from "./IndividualFormView";

export const IndividualFormContainer = () => {
  return (
    <PageWrapper>
      <IndividualFormView />
    </PageWrapper>
  );
};
