import * as Yup from "yup";

const docRefSchema = Yup.object({
  references: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required("Please enter name")
          .min(2, "Must be a minimum of 2 characters"),
        phone: Yup.string()
          .required("Please enter phone no")
          .min(2, "Must be a minimum of 2 characters"),
        relationship: Yup.string()
          .required("Please enter relationship")
          .min(2, "Must be a minimum of 2 characters"),
        email: Yup.string()
          .required("Please enter your Email Address")
          .email("Invalid email address")
      })
    )
    .required("Please enter your references information")
});

export default docRefSchema;
