import OnboardingLayout from "components/layout/Quiz/OnboardingLayout";
import OnboardingQuiz from "components/layout/Quiz/OnboardingQuiz";
import { Helmet } from "react-helmet";

const QuizView = () => {
  return (
    <div>
      <Helmet>
        <title>Campaigns for Black Mental Health Matters</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>

      <OnboardingLayout>
        <OnboardingQuiz />
      </OnboardingLayout>
    </div>
  );
};

export default QuizView;
