import { Formik, Form } from "formik";
import StepButton from "./StepButton";
import { FormInput } from "components/modules";
import { backgroundInfoSchema } from "validations";

interface BgInformationProps {
  onSubmit: (values: any) => void;
}

const BackgroundInformation = ({ onSubmit }: BgInformationProps) => {
  const step1InitialValues = {
    full_name: "",
    email: "",
    phone: "",
    country: "",
    city: ""
  };

  return (
    <div>
      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <Formik
          initialValues={step1InitialValues}
          validationSchema={backgroundInfoSchema}
          onSubmit={onSubmit}
          enableReinitialize={false}
        >
          <Form className="flex flex-col gap-4 md:gap-6">
            <FormInput nameAndId="full_name" label="1. Full Name" placeholder="John Doe" />
            <FormInput nameAndId="email" label="2. Email Address" placeholder="johndoe@gmail.com" />
            <FormInput nameAndId="phone" label="3. Phone Number" placeholder="+1(432) 266-8785" />
            <FormInput nameAndId="country" label="4. Country" placeholder="USA" />
            <FormInput nameAndId="city" label="5. City/State" placeholder="Denver, CO" />

            <div className="w-full md:px-16 px-5 flex items-center justify-center">
              <StepButton>Next 1/5</StepButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default BackgroundInformation;
