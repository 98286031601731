const Patients = () => {
  return (
    <div>
      <h1 className=" text-lg md:text-3xl font-bold	text-orange  mb-4">Healing</h1>
      <ul className=" list-disc font-normal md:text-xl text-base leading-8 md:leading-10 text-justify ml-6 mb-14 flex flex-col gap-8 mt-8">
        <li>
          Be honest and open with your Therapist. We are here to help you, and the more we know
          about your concerns and experiences, the better equipped we will support you. Remember
          that healing is a journey, and it takes time. Be kind to yourself and practice self-care.
        </li>
        <li>
          Be willing to take responsibility for your well-being. The therapist is here to guide and
          support you, but ultimately it is up to you to take the steps necessary to improve your
          mental health.
        </li>
        <li>
          Remember that healing is a journey, and it takes time. Be kind to yourself and practice
        </li>
      </ul>

      <h1 className="text-lg md:text-3xl font-bold	text-orange  mb-4">Cultural sensitivity</h1>
      <p className=" md:text-xl text-base leading-8 md:leading-10 text-justify ">
        We understand that mental health experiences and expressions of mental illness may vary
        within the Black community. We ask that members be mindful of this and approach discussions
        with cultural sensitivity.
      </p>
      <ul className=" list-disc font-normal md:text-xl text-base leading-8 md:leading-10 text-justify ml-6 mb-14 flex flex-col gap-8 mt-8">
        <li>
          Approach conversations with an open mind and heart and understanding to one another.
        </li>
        <li> Be respectful and considerate of others' experiences and perspectives.</li>
        <li>
          Recognize and acknowledge the unique cultural and societal factors that can impact black
          mental health.
        </li>
        <li> Work together to break down the stigma surrounding black mental health.</li>
        <li>Show empathy and support towards others seeking help and healing.</li>
      </ul>
    </div>
  );
};

export default Patients;
