import { JournalForm } from "components";

const JournalFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <JournalForm />
    </div>
  );
};

export default JournalFormView;
