import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth.slice";
import counter from "./counter.slice";
import message from "./message.slice";
import guestform from "./guestform.slice";
import hostform from "./hostform.slice";
import contactform from "./contactform.slice";

const rootReducer = combineReducers({
  auth,
  counter,
  message,
  guestform,
  hostform,
  contactform
});

export default rootReducer;
