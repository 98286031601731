import {
  gradientBackground,
  handshake,
  home2,
  home3,
  home4,
  home5,
  homeHeroImg,
  phoneCall
} from "assets/images";

const HomeHero = () => {
  return (
    <div
      className="skeleton bg-no-repeat relative bg-cover bg-center "
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      style={{ backgroundImage: `url(${gradientBackground})` }}
    >
      <div className=" grid md:grid-cols-2 grid-cols-1  justify-between md:px-12 xl:px-28 px-5 ">
        <div className="flex flex-col  w-full  justify-center md:p-0 py-10 px-5 ">
          <h1 className="md:text-6xl  text-4xl font-bold lg:leading-[80px] text-center md:text-left">
            <span className="text-orange">Your</span>{" "}
            <span className="text-[#667085]">First Stop For A Better Mental Health</span>
          </h1>
          <p className="text-2xl font-normal mt-5">Let us go through it with you</p>
        </div>
        <div className=" w-full md:mt-0 mt-4 flex items-center justify-center">
          <div>
            <div className="flex justify-between">
              <img
                src={home2}
                className="lg:-translate-x-24 -translate-x-8 -translate-y-2 lg:-translate-y-0"
              />
              <img
                src={home3}
                className="lg:translate-x-16 translate-x-6 -translate-y-2 lg:-translate-y-0"
              />
            </div>
            <div className="border-[#005028] border-[6px] rounded-2xl">
              <div className="flex items-center gap-2 bg-white px-2 py-1  z-20 w-[140px] rounded-lg">
                <img src={phoneCall} className="bg-[#005028] rounded-full p-1" />
                <div>
                  <p className="text-[#005028] text-[11px] font-500">NEED HELP?</p>
                  <p className="text-[#887085] text-[11px]">(512)522-7790</p>
                </div>
              </div>
              <img src={homeHeroImg} className=" scale-[105%]  -translate-y-2" />
              <div className="flex items-center gap-2 bg-white px-2 py-1 translate-x-20 z-20 w-[170px] rounded-lg">
                <img src={handshake} className="bg-[#005028] rounded-full p-1" />
                <div>
                  <p className="text-[#005028] text-[11px] font-700 text-center">+2000</p>
                  <p className="text-[#887085] text-[11px]">Experts & Professional</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <img src={home4} className="lg:-translate-x-16 -translate-x-8" />
              <img src={home5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
