import { PageWrapper } from "components/modules";
import { useParams } from "react-router-dom";
import { jobList } from "utils";

const JobDetail = () => {
  const params = useParams();
  const job = jobList.find((j) => j.id === params.id);

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <PageWrapper>
      <div className="w-full md:px-12 xl:px-28 px-5 md:py-16 py-12 flex flex-col gap-4">
        <div className="font-bold text-base md:text-2xl">{job.vacancy}</div>
        <div>{job.type}</div>
        <div>Location: {job.location}</div>
        <div>Experience: {job.experience}</div>
        <div className="font-semibold text-base md:text-xl">Summary:</div>
        <div>{job.summary}</div>
        <div className="font-semibold text-base md:text-xl">Responsibilities:</div>
        <div>
          {job.responsibilities.map((responsibility) => (
            <li key={responsibility} className="pl-2 md:pl-4">
              {responsibility}
            </li>
          ))}
        </div>
        <div className="font-semibold text-base md:text-xl">Qualifications:</div>
        <div>
          {job.qualifications.map((qualification) => (
            <li key={qualification} className="pl-2 md:pl-4">
              {qualification}
            </li>
          ))}
        </div>
        <div className="font-semibold text-base md:text-xl">Benefits:</div>
        <div>
          {job.benefits.map((benefit) => (
            <li key={benefit} className="pl-2 md:pl-4">
              {benefit}
            </li>
          ))}
        </div>
        <button className="py-4 rounded-md text-white px-2 bg-orange mt-8 md:mt-16">Apply</button>
      </div>
    </PageWrapper>
  );
};

export default JobDetail;
