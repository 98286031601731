import { producthero } from "assets/images";

const ProductHero = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-5 md:px-32 py-24">
      <div>
        <div className="flex gap-6 items-center">
          <div className="bg-orange w-3 md:w-6 h-16"></div>
          <p className="text-green text-bold text-4xl md:text-5xl font-bold">Our Services</p>
        </div>
        <div className="md:hidden block md:mt-0 mt-8">
          <img src={producthero} alt="product hero" />
          <div className="grid grid-cols-4 gap-2 md:gap-4 absolute md:ml-[365px] md:mt-[-140px] -z-10 ml-64 mt-[-80px]">
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
            <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          </div>
        </div>
        <div>
          <p className="text-black text-xl md:text-2xl mt-8">
            At Black Mental Health Matters, we are dedicated to providing a comprehensive range of
            mental health services to support your emotional well-being. Our platform offers a
            diverse array of services designed to meet your unique needs, including therapy,
            counseling, and coaching. We also provide access to self-help resources, mindfulness
            exercises, and peer support groups, fostering a holistic approach to mental health care.
            Whether you're seeking individual therapy, relationship counseling, or simply looking
            for a supportive community, our platform is here to help you on your journey towards
            mental wellness and personal growth. Explore our services today, and take the first step
            towards a happier, healthier you.
          </p>
        </div>
      </div>
      <div className="hidden md:block">
        <img src={producthero} alt="product hero" />
        <div className="grid grid-cols-4 gap-2 md:gap-4 absolute md:ml-[365px] md:mt-[-140px] -z-10 ml-64 mt-[-80px]">
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
          <div className="bg-orange rounded-full md:h-10 md:w-10 h-6 w-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductHero;
