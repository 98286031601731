import { Calender, GoBack, GoForward } from "assets/images";
const Appointment = () => {
  return (
    <div className="row-span-2 bg-[#FEF4F2] rounded-[10px] lg:w-[329px] h-[323px]">
      <div>
        <div className="flex justify-center py-2 ">
          <img src={Calender} />
        </div>
        <p className="text-[24px] text-[#3C4E40] text-center ">Appointment</p>
        <div className="flex justify-center">
          <p className="text-[15px] text-[#3C4E40] text-center py-2 w-[219px] ">
            Book an appointment with our Professional Therapists
          </p>
        </div>
      </div>
      <div className="bg-[#F3DEDE] rounded-[15px] lg:w-[299px] h-[150px] mx-auto ">
        <div>
          <div className="flex items-center justify-between text-[#005028] text-[18px] font-bold p-2 mt-2">
            <p> December 2021</p>
            <div className="flex gap-2 items-center">
              <button>
                <img src={GoBack} />
              </button>
              <button>
                <img src={GoForward} />
              </button>
            </div>
          </div>
          <table className="w-full ">
            <thead>
              <tr>
                <th>
                  <div className="w-full flex justify-center">
                    <p
                      className="text-[12px] font-semiBold text-center text-[#005028]
                                          py-5"
                    >
                      Mo
                    </p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">Tu</p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">We</p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">Th</p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">Fr</p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">Sa</p>
                  </div>
                </th>
                <th>
                  <div className="w-full flex justify-center">
                    <p className="text-[12px] font-semiBold text-center text-[#005028]">Su</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">15</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">16</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">17</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">18</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">19</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">20</p>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-2 cursor-pointer flex w-full justify-center">
                    <p className="text-[12px] font-normal text-center text-[#005028]">21</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
