import { careerbg, careerIcon } from "assets/images";
import { Button } from "components/widgets";

const OpportunityCover = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div
      className="bg-cover bg-fixed md:h-[80vh] h-[65vh] w-full"
      style={{ backgroundImage: `url(${careerbg})` }}
    >
      <div className="bg-[rgba(0,0,0,0.6)] bg-cover w-full md:h-[80vh] h-[65vh] ">
        <div className="flex  justify-center items-center w-full  flex-col relative ">
          <div className=" md:px-16 px-2 md:w-[70%] xl-[w-50%] w-full  md:h-[13rem] h-[9rem] text-center md:mt-40 mt-28 ">
            <h1 className="text-white font-bold text-[34px] md:text-[64px] md:leading-[80px] leading-[50px]">
              Find the right career and internships.
            </h1>
          </div>
          <img src={careerIcon} className="absolute md:left-[300px] md:bottom-[15px] " />
          <div className=" w-full flex md:flex-row  flex-col justify-center md:gap-10 gap-5 md:mt-0 mt-5 md:px-0 px-5">
            <input
              type="text"
              placeholder="Keywords, jobs title or location"
              className="text-[#C4C4C4] md:w-[775px] w-[300px] md:px-20 px-10 md:py-0 py-2"
            />
            <Button label="Apply" style="mt-1 w-[200px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityCover;
