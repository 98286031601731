const About = () => {
  return (
    <div className="flex lg:justify-center my-8">
      <div className=" bg-white p-4 lg:w-[848px]">
        <div>
          <h2 className="text-[#3C4E40] font-bold text-[24px] py-4">About My Client </h2>
          <p className="md:w-[500px] lg:w-[810px]">
            My name is Alex, and I am a licensed therapist with years of experience helping clients
            overcome emotional distress, anxiety, depression, and other mental health concerns. I
            specialize in working with individuals, couples, and families who are struggling with a
            variety of issues, including addiction, relationship problems, family conflict, trauma,
            and more. I also offer workshops and evidence-based skill training to help clients
            develop coping strategies, improve their communication skills, and build resilience.
          </p>
        </div>
        <div>
          <h1 className="text-[#3C4E40] font-bold text-[24px] pt-12 pb-4">
            My Background and Approach{" "}
          </h1>
          <p className=" md:w-[500px] lg:w-[810px]">
            I offer a free consultation to new clients, where we can discuss your concerns, goals,
            and how I can help you achieve them. I believe that therapy is a collaborative process,
            and I will work with you to create a personalized treatment plan that is tailored to
            your unique needs and circumstances.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
