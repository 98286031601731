import { Anchor, Apple, Google, IHeart, Spotify } from "assets/images";

const Podcasts = () => {
  const podcasts = [
    {
      id: 1,
      image: Anchor,
      name: "Anchor FM",
      podcastUrl: "https://anchor.fm/blackmentalhealthmatters"
    },
    {
      id: 2,
      image: IHeart,
      name: "iHeart Radio",
      podcastUrl: "https://www.iheart.com/podcast/269-black-mental-health-matter-106401772/"
    },

    {
      id: 3,
      image: Apple,
      name: "Apple Podcast",
      podcastUrl: "https://podcasts.apple.com/us/podcast/black-mental-health-matters/id1661428617"
    },

    {
      id: 4,
      image: Google,
      name: "Google Podcast",
      podcastUrl:
        "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kNmZhZjhiYy9wb2RjYXN0L3Jzcw"
    },
    {
      id: 5,
      image: Spotify,
      name: "Spotify",
      podcastUrl: "https://open.spotify.com/show/3MjBPzYvJiG4J7HyDmRguU"
    }
  ];

  return (
    <div className=" grid bg-white m-5 rounded-lg md:rounded-none grid-rows= gap-4 items-center justify-items-center md:px-12 xl:px-28 px-5 md:mx-16 py-12 md:shadow-none shadow-lg lg:grid-cols-[0.8fr,1.5fr]  xl:grid-cols-[0.3fr,0.8fr]  ">
      <div className="text-3xl self-center  md:text-5xl md:self-start md:mb-12 mb-4 lg:border-r-8 border-green ">
        Watch our <span className="text-orange">podcast</span> on:
      </div>

      <div className="grid grid-cols-5 pb-8 gap-4 border-b-8 border-orange">
        {podcasts.map((podcast) => {
          return (
            <a
              href={podcast.podcastUrl}
              className=" grid grid-rows gap-1 justify-items-center"
              key={podcast.id}
            >
              <img className="" src={podcast.image} alt={podcast.name} />
              <div className="text-center md:text-2xl text-xs font-normal ">{podcast.name}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Podcasts;
