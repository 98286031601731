import { OurMission, OurTeam, WeDeliver, WhoAreWe, WhyUs } from "components";
import { Helmet } from "react-helmet";

const AboutUsView = () => {
  return (
    <>
      <Helmet>
        <title>About Black mental health matters</title>
        <meta
          name="description"
          content="Discover our story and mission to prioritize mental health and wellness, empowering the Black community"
        />
        <meta name="keywords" content="about, black mental health matters" />
      </Helmet>
      <WhoAreWe />
      <OurTeam />
      <OurMission />
      <WhyUs />
      <WeDeliver />
    </>
  );
};

export default AboutUsView;
