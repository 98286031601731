import { DonateForm, DonateFormHero } from "components";

const DonateFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <DonateFormHero />
      <DonateForm />
      {/* <DonatenowFormHeader /> */}
      {/* <DonatenowForm /> */}
      {/* <PaymentForm /> */}
    </div>
  );
};

export default DonateFormView;
