import useDate from "hooks/useDate";

const TermsAndCondition = () => {
  const { dateTime } = useDate();

  return (
    <div className=" px-2 xl:px-80 py-20 text-lg ">
      <p className="text-base text-green my-8 md:my-16 text-center">
        Current as of <span>{`${dateTime.toDateString()} `}</span>
      </p>
      <h1 className="text-green text-2xl font-semibold sm:text-5xl  mb-4 md:mb-12 text-center">
        Terms and Condition
      </h1>
      <p className="md:px-0 px-3 text-center md:text-lg text-base text-gray-200 mb-2  md:mb-8">
        Your privacy is important to us at BMHM. We respect your privacy regarding any information
        we may collect from you across our website.
      </p>
      <p className=" md:px-0 px-3 font-semibold text-orange  mb-10 md:text-lg text-lg text-center mt-8">
        Terms and Conditions for Black Mental Health Matters
      </p>
      <p className="md:px-0 px-3 mb-10  leading-8 md:leading-10 md:text-lg text-base">
        Welcome to Black Mental Health Matters (BMHM), a non-profit organization dedicated to
        raising awareness and providing support for mental health issues within the Black community.
        The following terms and conditions outline the rules and regulations for the use of our
        website and the services we offer.
      </p>
      <div className="bg-green md:bg-white px-4 py-8 rounded-lg mt-4 md:mt-0 ">
        <div className="text-xs md:text-base  text-white md:text-black">
          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg pt-6 md:pt-10 mb-4">
            Use of Our Services
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
            By using our website and the services we provide, you agree to be bound by these terms
            and conditions. If you do not agree to these terms, you are prohibited from using or
            accessing our website and services.
          </p>

          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg pt-6 md:pt-10 mb-4">
            Content
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
            The content on our website and in our services is for general information purposes only.
            BMHM makes no representations or warranties of any kind, express or implied, about the
            completeness, accuracy, reliability, suitability or availability with respect to the
            website or the information, products, services, or related graphics contained on the
            website for any purpose. Any reliance you place on such information is therefore
            strictly at your own risk.
          </p>
          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg pt-6 md:pt- mb-4">
            Limitations of Liability
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
            BMHM will not be liable for any losses or damages arising from the use of our website or
            services. This includes, but is not limited to, indirect or consequential loss or
            damage, or any loss or damage whatsoever arising from loss of data or profits arising
            out of or in connection with the use of our website or services.
          </p>

          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg pt-6 md:pt-10 mb-4">
            External Links
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
            Our website may contain links to external websites. We have no control over the nature,
            content and availability of those sites. The inclusion of any links does not necessarily
            imply a recommendation or endorse the views expressed within them.
          </p>

          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg pt-6 md:pt-10 mb-4">
            Changes to Terms and Conditions
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
            BMHM reserves the right to make changes to these terms and conditions at any time. Your
            continued use of our website and services following any changes indicates your
            acceptance of the new terms.
          </p>

          <h1 className="font-semibold text-orange md:text-black sm:text-3xl text-lg mb-4">
            Contact Us
          </h1>
          <p className=" mb-6  leading-8 md:leading-10 md:text-lg text-base">
            If you have any questions or concerns about these terms and conditions, please contact
            us at
            <a href="mailto:info@bmhm.org">info@bmhm.org </a>
            <p className="sm:text-3xl text-base pt-6 md:pt-10 mb-4 text-orange">
              {" "}
              Acceptance of Terms and Conditions{" "}
            </p>
            <p className=" leading-8 md:leading-10 md:text-lg text-base pb-6 md:pb-10">
              By using our website and services, you signify your acceptance of these terms and
              conditions. If you do not agree to these terms, please do not use our website or
              services
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
