import { Link } from "react-router-dom";
import { DONATEFORM } from "routes/CONSTANTS";
import { longArrowRight } from "assets/icons";
import { empowerimg } from "assets/images";

const Empower = () => {
  return (
    <div className="mt-10 md:px-12 xl:px-28 px-5 text-black md:py-16 py-12">
      <div className="flex md:flex-row flex-col justify-between">
        <div className="w-full md:w-1/2 py-10">
          <img src={empowerimg} alt="empowerimg" />
        </div>
        <div className="w-full md:w-1/2 md:px-10">
          <h1 className="font-bold text-3xl md:text-5xl md:leading-[68px] leading-10">
            Empower black voices From all over the Globe
          </h1>
          <div className="w-full md:w-4/5">
            <p className="text-lg md:text-2xl md:leading-[34px] leading-8 mt-6 md:mt-8 text-justify">
              {" "}
              Your contribution helps us amplify the voices of those who are often unheard. Join us
              in our mission to create lasting change in black mental health
            </p>
          </div>
          <Link
            to={DONATEFORM}
            className="py-2 px-2 w-full md:w-52  md:h-14 h-12 md:mt-20 mt-12  bg-orange flex items-center justify-center rounded-md "
          >
            <span className="font-medium leading-[18.5px] w- text-white mr-2">Donate Now</span>
            <img src={longArrowRight} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Empower;
