interface ButtonProps {
  label: string;
  style: string;
  onClick?: () => any;
}

const Button = ({ label, style, onClick }: ButtonProps) => {
  return (
    <button
      className={`px-6 py-3 text-white text-base bg-green hover:bg-green-100 rounded-md ${style}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
