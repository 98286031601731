import { google, linkedin, microsoft } from "assets/icons";
import { logo } from "assets/images";
import { FormInput, PwdInput } from "components/modules";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD, FORGOT_PASSWORD, SIGNUP } from "routes/CONSTANTS";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { login, loginSuccess } from "redux/slices/auth.slice";
import { useEffect, useState } from "react";
import LoginText from "components/layout/Login/LoginText";
import { loginSchema } from "validations";
import { CenterLoader } from "components/widgets";
import { useAppDispatch, useQuery } from "hooks";
import { GOOGLE_END_POINT, LINKEDIN_END_POINT, MICROSOFT_END_POINT } from "services/CONSTANTS";
import { toast } from "react-toastify";

interface LoginData {
  username: string;
  password: string;
}

const LoginUserForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const formikInitialValues = {
    username: "",
    password: ""
    // remember: false
  };

  useEffect(() => {
    const queryString = query.get("redirect");
    if (queryString) {
      if (queryString === "success") {
        void dispatch(loginSuccess())
          .unwrap()
          .then(() => navigate(DASHBOARD));
      } else {
        const errorMessage = query.get("error");
        if (errorMessage) {
          if (errorMessage?.length > 0 && errorMessage !== "undefined") {
            toast.error(errorMessage);
          }
        }
      }
    }
  }, []);

  const handleSubmit = async (details: LoginData) => {
    setIsLoading(true);
    try {
      await dispatch(login(details))
        .unwrap()
        .then(() => {
          navigate(DASHBOARD);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const googleLogin = () => {
    window.open(GOOGLE_END_POINT, "_self");
  };
  const microsoftLogin = () => {
    window.open(MICROSOFT_END_POINT, "_self");
  };
  const linkedinLogin = () => {
    window.open(LINKEDIN_END_POINT, "_self");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-screen h-auto">
      {isLoading && <CenterLoader />}

      <LoginText />

      <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
        <div className="flex items-center justify-center">
          <img src={logo} />
        </div>
        <p className="font-bold md:text-4xl text-2xl text-black py-4 md:py-8">User Login</p>
        <div>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={loginSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-4">
              <div className="border-b-2 border-b-black w-full pt-4">
                <FormInput
                  nameAndId="username"
                  placeholder="johndoe@gmail.com"
                  label="Email"
                  type="email"
                />
              </div>

              <div className="border-b-2 border-b-black w-full pt-4">
                <PwdInput nameAndId="password" placeholder="*****************" label="Password" />
              </div>

              <div className="flex justify-between">
                <div className="flex gap-4 items-center justify-start">
                  <Field type="checkbox" name="remember" className="accent-green" />
                  <label htmlFor="remember" className="font-normal text-base text-ash">
                    Remember Me
                  </label>
                  <ErrorMessage
                    component="label"
                    name="remember"
                    className="text-sm w-full text-[#FF0000]"
                  />
                </div>
                <Link to={FORGOT_PASSWORD} className="text-orange text-base">
                  Forgot Password?
                </Link>
              </div>

              <div className="bg-green flex items-center justify-center w-56 rounded-md mx-auto mt-2">
                <button type="submit" className="bg-orange py-3 px-8 text-white w-full rounded-md">
                  Login
                </button>
              </div>
            </Form>
          </Formik>

          <div className="flex flex-col gap-4 pt-4">
            <div className="flex gap-2 items-center justify-center pt-2">
              <div className="w-44 h-[0.5px] bg-black"></div>
              <div>or</div>
              <div className="w-44 h-[0.5px] bg-black"></div>
            </div>

            <div className="flex items-center justify-center gap-8 pt-2">
              <button onClick={microsoftLogin} className="cursor-pointer">
                <img src={microsoft} alt="" className="h-8 w-8" />
              </button>
              <button onClick={linkedinLogin} className="cursor-pointer">
                <img src={linkedin} alt="" className="h-8 w-8" />
              </button>
              <button onClick={googleLogin} className="cursor-pointer">
                <img src={google} alt="" className="h-8 w-8" />
              </button>
            </div>

            <div className="text-green-100 text-lg text-center hover:text-green cursor-pointer pt-2">
              <Link to={SIGNUP}>Don't have an account?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginUserForm;
