import { useState } from "react";
import SubmitResources from "./SubmitResources";
import CustomizedResources from "./CustomizedResource";

const ResourceButtons = () => {
  const [modal, setModal] = useState(false);
  const setModalOpen = () => {
    setModal(true);
  };

  const setCloseModal = () => {
    setModal(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="md:px-20 px-5 py-12">
      <h3 className="text-base text-left md:text-2xl md:text-center pb-12">
        Download pre-made or customize your own templates and assets for your personal campaigns
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <button className="bg-[#ff9c00] text-white text-base py-4  h-[125px] rounded-md flex items-center justify-center">
          Download Resources
        </button>

        <button
          onClick={setModalOpen}
          className=" h-[125px] bg-[#b2ecc4] border-[5px] border-[#005028] rounded-md text-[#005028] flex items-center justify-center"
        >
          Submit Resources
        </button>
        <button
          onClick={openModal}
          className=" h-[125px] bg-[#fffbe8] border-[5px] border-[#ff9c00] rounded-md text-[#ff9c00] flex items-center justify-center"
        >
          Customize Resources
        </button>
      </div>
      <SubmitResources modalOpen={modal} modalClose={setCloseModal} />
      <CustomizedResources isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ResourceButtons;
