import { DashboardWrapper } from "components";
import GroupsView from "./GroupsView";

export const GroupsContainer = () => {
  return (
    <DashboardWrapper>
      <GroupsView />
    </DashboardWrapper>
  );
};
