import { ErrorMessage, Field, Form, Formik } from "formik";

interface StepFourProps {
  onNext: (values: any) => void;
}

const StepFour: React.FC<StepFourProps> = ({ onNext }) => {
  const formikInitialValues = {
    location: ""
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      // validate={(values) => {
      //   const errors: Record<string, string> = {};
      //   if (!values.location.trim()) {
      //     errors.location = "Please enter a location";
      //   }
      //   return errors;
      // }}
      onSubmit={(values, actions) => {
        onNext(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>

          <div className="mt-4 flex flex-col md:flex-row gap-6">
            <label className="inline-flex items-center">
              <Field
                type="radio"
                name="location"
                value="global"
                className="accent-green-100 h-4 w-4"
                onChange={() => {
                  setFieldValue("location", "global");
                }}
              />
              <span className="ml-2">
                This is a national or global campaign without any location
              </span>
            </label>

            <label className="flex items-center">
              <Field
                type="radio"
                name="location"
                value=""
                className="accent-green-100 h-4 w-4"
                onChange={() => {
                  setFieldValue("location", "");
                }}
              />
              <span className="ml-2"></span>
              <div className="mb-4">
                <Field name="location">
                  {({ field }: any) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        id="location"
                        className="bg-white placeholder-gray text-black md:text-2xl text-sm font-normal p-2 h-[44px] md:h-[72px] focus:ring-0 border-2 border-[#A1A1A1] outline-none rounded-md"
                        placeholder="Enter the location"
                        disabled={values.location === "global"}
                      />
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
            </label>
          </div>

          <div>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepFour;
