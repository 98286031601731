import { PageWrapper } from "components";
import VolunteerFormView from "./VolunteerFormView";

export const VolunteerFormContainer = () => {
  return (
    <PageWrapper>
      <VolunteerFormView />
    </PageWrapper>
  );
};
