import { donateBg } from "assets/images";
import { Link } from "react-router-dom";
import { DONATEFORM } from "routes/CONSTANTS";
import { longArrowRight } from "assets/icons";

const DonateHero = () => {
  return (
    <div>
      <div
        className="bg-cover bg-fixed bg-center flex h-[80vh] md:px-12 xl:px-28 px-5 w-full"
        style={{ backgroundImage: `url(${donateBg})` }}
      >
        <div className="flex flex-col gap-4 md:items-start items-center justify-center w-full md:w-2/3">
          <h1 className="text-4xl md:text-8xl text-white md:leading-[120px] leading-10 font-bold text-center md:text-start">
            Support black mental health
          </h1>
          <div className="mt-4  md:w-4/5 w-full">
            <p className="text-white text-lg md:text-2xl leading-[34px] md:text-left text-justify">
              Your donation helps us provide essential services and support for those in need. Every
              dollar counts. Thank you for your contribution.
            </p>
            <Link
              to={DONATEFORM}
              className="py-2 px-2 w-full md:w-52 h-12 md:h-14 mt-8  bg-orange flex items-center justify-center rounded "
            >
              <span className="font-medium leading-[18.5px] w- text-white mr-2">Donate Now</span>
              <img src={longArrowRight} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateHero;
