import { cite, share } from "assets/images";

const ActiveButton = () => {
  return (
    <div className=" sm:gap-4 text-sm grid grid-cols-6  sm:grid sm:grid-cols-2 text-green font-normal  ">
      <div
        className=" bg-no-repeat bg-center w-[100%]"
        style={{
          backgroundImage: `url(${share})`
        }}
      ></div>

      <div>Download</div>

      <div
        className="bg-no-repeat  bg-center w-[100%]"
        style={{
          backgroundImage: `url(${cite})`
        }}
      ></div>
      <div>Cite</div>
      <div
        className=" bg-no-repeat bg-center w-[100%] "
        style={{
          backgroundImage: `url(${share})`
        }}
      ></div>
      <div>Share</div>
    </div>
  );
};

export default ActiveButton;
