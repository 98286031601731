import { PageWrapper } from "components";
import TermsView from "./TermsView";

export const TermsContainer = () => {
  return (
    <PageWrapper>
      <TermsView />
    </PageWrapper>
  );
};
