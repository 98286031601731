import { PageWrapper } from "components";
import JournalFormView from "./JournalFormView";

export const JournalFormContainer = () => {
  return (
    <PageWrapper>
      <JournalFormView />
    </PageWrapper>
  );
};
