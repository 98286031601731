import * as Yup from "yup";

const questionnaireSchema = Yup.object({
  mode_of_service: Yup.string().required("Please select a service Option"),
  sliding_scale: Yup.string().required("Please select a sliding scale preference"),
  approach_to_therapy: Yup.string()
    .required("Please enter your Approach to Therapy")
    .min(10, "Must be a minimum of 10 words"),
  statement: Yup.string().required("Please enter your City").min(10, "Must be a minimum of 10 words")
});

export default questionnaireSchema;
