import { bookmark, people } from "assets/icons";
import { field, volunteers, peoples } from "assets/images";
import { LinkButton } from "components/widgets";

const Skills = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-20 pb-12 md:py-16 md:px-12 xl:px-28 px-5">
      <div className="flex flex-col md:gap-8 gap-4">
        <p className="font-bold md:text-4xl text-2xl md:text-start text-center">
          What skills are required to Become a <span className="text-orange">Volunteer</span>?
        </p>
        <p className="md:text-xl text-base font-medium  md:leading-10 leading-8 text-black text-center md:text-justify">
          We have a wide range of volunteers from across the globe. This means that we are properly
          equipped and skilled to Volunteers should be able to relate to the people they are helping
          and show understanding and empathy.
        </p>

        <div className="flex justify-between items-center md:gap-4">
          <div className="flex flex-col items-center justify-center gap-4">
            <img src={field} alt="" className="md:h-16 md:w-16 h-8 w-8" />
            <p className="text-green font-bold text-base">200+</p>
            <p className="text-xs text-green text-center">volunteers working with BMHM</p>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <img src={volunteers} alt="" className="md:h-16 md:w-16 h-8 w-8" />
            <p className="text-green font-bold text-base">69%</p>
            <p className="text-xs text-green text-center">Hail from various parts of Africa</p>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <img src={peoples} alt="" className="md:h-16 md:w-16 h-8 w-8" />
            <p className="text-green font-bold text-base">59%</p>
            <p className="text-xs text-green text-center">Are professionals in their field</p>
          </div>
        </div>
        <div className="hidden md:block">
          <LinkButton to="/volunteerform">Become A Volunteer</LinkButton>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex md:gap-4 gap-2">
          <div className="shadow-md h-8 w-8 rounded-md flex items-center justify-center">
            <img src={bookmark} />
          </div>
          <div className="w-full">
            <p className="text-2xl font-bold mb-2">Reliable</p>
            <p className="md:text-xl text-base md:leading-10 leading-8 text-black text-justify">
              Being dependable is a highly prized quality because BMHM expects its volunteers to
              show up and because the people in our communities depend on the programs they deliver.
            </p>
          </div>
        </div>
        <div className="flex md:gap-4 gap-2">
          <div className="shadow-md h-8 w-8 rounded-md flex items-center justify-center">
            <img src={people} />
          </div>
          <div className="w-full">
            <p className="text-2xl font-bold mb-2">Interpersonal Traits</p>
            <p className="md:text-xl text-base md:leading-10 leading-8 text-black text-justify">
              You can be sure that you'll do a great job if you have excellent interpersonal skills
              that you can apply to various situations.
            </p>
          </div>
        </div>
        <div className="flex md:gap-4 gap-2">
          <div className="shadow-md h-8 w-8 rounded-md flex items-center justify-center">
            <img src={people} />
          </div>
          <div className="w-full">
            <p className="text-2xl font-bold mb-2">Fast Learner</p>
            <p className="md:text-xl text-base md:leading-10 leading-8 text-black text-justify">
              The capacity to learn new things quickly will make you successful at BMHM. You must
              quickly adapt and pick things up. Volunteering at BMHM is a fantastic way to use and
              develop these skills.
            </p>
          </div>
        </div>
        <div className="flex md:gap-4 gap-2">
          <div className="shadow-md h-8 w-8 rounded-md flex items-center justify-center">
            <img src={bookmark} />
          </div>
          <div className="w-full">
            <p className="text-2xl font-bold mb-2">Problem Solving</p>
            <p className="md:text-xl text-base md:leading-10 leading-8 text-black text-justify">
              Your capacity to not only overcome obstacles through problem-solving but also to
              suggest fresh strategies for that organisation will be vital at BMHM.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
