import { Formik, Form, FieldArray } from "formik";
import StepButton from "./StepButton";
import { docRefSchema } from "validations";
import { ThInput } from "components/modules";
import { plus, x } from "assets/icons";

interface DocAndRefProps {
  onSubmit: (values: any) => void;
}

const DocumentsAndReferences = ({ onSubmit }: DocAndRefProps) => {
  const step3InitialValues = {
    references: [{ name: "", phone: "", relationship: "", email: "" }]
  };

  return (
    <div className="w-full md:pl-12 py-2 md:pr-28 px-5">
      <Formik
        initialValues={step3InitialValues}
        validationSchema={docRefSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="flex flex-col gap-4 md:gap-8">
            <FieldArray name="references">
              {({ push, remove }) => (
                <div className="flex flex-col gap-4">
                  <p>Reference</p>
                  <div className="flex flex-col gap-8">
                    {values.references.map((_, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-2 items-center gap-2 justify-between"
                      >
                        <ThInput
                          nameAndId={`references.${index}.name`}
                          label="Name:"
                          placeholder="John Doe"
                        />
                        <ThInput
                          nameAndId={`references.${index}.phone`}
                          label="Phone #:"
                          placeholder="(221) 113-6432"
                        />
                        <ThInput
                          nameAndId={`references.${index}.relationship`}
                          label="Relationship:"
                          placeholder="Colleague"
                        />
                        <ThInput
                          nameAndId={`references.${index}.email`}
                          label="Email:"
                          placeholder="johndoe@gmail.com"
                        />
                        {index > 0 && (
                          <div>
                            <button
                              type="button"
                              className="text-sm text-[#FF0000] flex items-center gap-2"
                              onClick={() => remove(index)}
                            >
                              <img src={x} /> Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="flex items-center text-orange gap-2 text-sm"
                      onClick={() => push({ name: "", phone: "", relationship: "", email: "" })}
                    >
                      <img src={plus} /> Add Reference
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>

            <div className="w-full md:px-16 px-5 flex items-center justify-center mt-4">
              <StepButton>Next 3/5</StepButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DocumentsAndReferences;
