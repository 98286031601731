import { useParams } from "react-router-dom";
import { campaigns } from "utils";

const PetitionRange = () => {
  const params = useParams();
  console.log(params);
  console.log(campaigns);
  const petitionStory = campaigns.find((s) => s.id === Number(params.id));

  if (!petitionStory) {
    return <div>no petition</div>;
  }
  return (
    <div className="text-center flex flex-col justify-center items-center  py-10 mt-[3rem]">
      <div className="w-[70%]">
        <p className="text-6xl font-bold text-green">{petitionStory.title}</p>
      </div>
      <div>progress bar</div>
    </div>
  );
};

export default PetitionRange;
