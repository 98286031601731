// import axios from "axios";
import React, { useState } from "react";
import PetitionForm, { PetitionFormValues } from "./PetitionForm";
import ProgressBar from "./ProgressBar";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import ShareModal from "./ShareModal";
import SharePetition from "./SharePetition";
// import { stories } from "utils";

interface PetitionProps {
  id: number;
  title: string;
  image: string;
  description: string;
  numSignatures: number;
  totalSignatures: number;
  petitionnote: string;

  onFormSubmit: (id: number, values: PetitionFormValues) => void;
}

const Petition: React.FC<PetitionProps> = ({
  id,
  title,
  description,
  totalSignatures,
  image,
  petitionnote,

  onFormSubmit
}) => {
  const [numSignatures, setNumSignatures] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalopen] = useState(false);

  // using the backend api

  //   const handleFormSubmit = async (values: PetitionFormValues) => {
  //     // submit the form and update the number of signatures
  //     try {
  //       const response = await axios.post("/api/sign-petition", values);
  //       setNumSignatures(response.data.numSignatures);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const handleFormSubmit = (id: number, values: PetitionFormValues) => {
    console.log(values);
    // simulate a form submission by setting a delay of 2 seconds
    setTimeout(() => {
      // update the number of signatures
      setNumSignatures(numSignatures + 1);
      // call the onFormSubmit function with the id and values
      onFormSubmit(id, values);
    }, 2000);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openShareModal = () => {
    setIsShareModalopen(true);
  };
  const closeShareModal = () => {
    setIsShareModalopen(false);
  };
  return (
    <div className="bg-white rounded-lg shadow-md">
      <div
        className="h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover  flex align-center justify-center"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="bg-[rgba(0,0,0,0.8)] bg-cover w-full h-[80vh]  ">
          <div className="flex flex-col items-center justify-center mt-[8rem] px-5">
            <div className="w-[80%] mb-5">
              <h2 className="md:text-[60px] text-base md:text-2xl font-bold text-white text-center md:leading-[80px]">
                {title}
              </h2>
            </div>
            <button
              className="bg-orange  text-white font-bold md:py-4 p-3 md:px-4 rounded-md md:w-48 w-40"
              onClick={openModal}
            >
              Sign this petition
            </button>
            <ProgressBar id={id} numSignatures={numSignatures} totalSignatures={totalSignatures} />
          </div>
        </div>
      </div>
      <div className="pt-4 pb-16 md:px-44 px-5 mt-[6rem]">
        <p className=" mb-4 text-justify font-normal md:text-xl text-base md:leading-8 leading-6">
          {description}
        </p>

        <p className="text-[20px] font-normal">{petitionnote}</p>

        {/* {isShowmore && <div>This div is displayed when the button is clicked</div>} */}
        <div className="flex items-end  justify-end">
          <div className="">
            <Link to={`petitions/${id}`}>
              <span className="font-medium leading-[18.5px] text-orange mr-2">read more....</span>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center md:gap-12 gap-4 mt-4">
          <button
            className="bg-green hover:bg-green-100 text-white font-bold md:py-4 p-3 md:px-4 rounded-md md:w-48 w-40"
            onClick={openModal}
          >
            Sign the Petition
          </button>
          <button
            className="bg-orange  text-white font-bold md:py-4 p-3 md:px-4 rounded-md md:w-48 w-40"
            onClick={openShareModal}
          >
            Share
          </button>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <PetitionForm id={id} totalSignatures={totalSignatures} onFormSubmit={handleFormSubmit} />
      </Modal>

      <ShareModal isOpen={isShareModalOpen} onClose={closeShareModal}>
        <SharePetition />
      </ShareModal>
    </div>
  );
};

export default Petition;
