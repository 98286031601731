import {
  reseacrh1,
  reseacrh2,
  reseacrh3,
  reseacrh4,
  reseacrh5,
  research6,
  research7,
  research8
} from "assets/images";
import Carousel from "react-multi-carousel";

const Relationships = () => {
  const organization = [
    {
      id: 1,
      image: reseacrh1,
      title: "Research Institutes",
      description: "Therapy for Researchers in need of wellness"
    },
    {
      id: 2,
      image: reseacrh2,
      title: "Media",
      description: "Prioritize the mental health of the media industry"
    },
    {
      id: 3,
      image: reseacrh3,
      title: "Celebrities",
      description: "Prioritize the mental health of celebrities or others in need."
    },
    {
      id: 4,
      image: reseacrh4,
      title: "Educational Institutions",
      description: "Prioritize the mental health of  Educators and Students at Schools"
    },
    {
      id: 5,
      image: reseacrh5,
      title: "Corporations",
      description: "Mental Health in the workplace and need Therapy for their Employees."
    },
    {
      id: 6,
      image: research6,
      title: "Non Profits",
      description: "prioritize the mental health of their employees and volunteers."
    },
    {
      id: 7,
      image: research7,
      title: "Government Agencies",
      description: "Offers services to organizations and government agencies on wellness"
    },
    {
      id: 8,
      image: research8,
      title: "Religious Organizations",
      description:
        "Religious organizations in hosting  to encourage holistic approach to mental health."
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className="md:py-16 py-12">
      <p className="md:text-4xl text-xl font-bold mx-auto px-5 text-center pb-8 md:pb-12 md:w-[835px] w-full">
        Black Mental Health Matters has forged strong relationships with partners over the years
      </p>
      <div className="bg-green grid md:grid-cols-4 md:gap-12 gap-4 grid-cols-1 items-center justify-center md:px-8 px-5 py-12 md:py-16">
        {organization.map((org) => (
          <div key={org.id} className="md:flex flex-col gap-4 items-center justify-center hidden">
            <img src={org.image} />
            <p className="text-2xl text-white font-bold text-center">{org.title}</p>
            <p className="text-lg text-white text-center">{org.description}</p>
          </div>
        ))}
        <Carousel responsive={responsive} showDots={true}>
          {organization.map((org) => (
            <div
              key={org.id}
              className="flex flex-col gap-4 items-center justify-center md:hidden py-8"
            >
              <img src={org.image} />
              <p className="text-2xl text-white font-bold text-center">{org.title}</p>
              <p className="text-lg text-white text-center">{org.description}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Relationships;
