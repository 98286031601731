import * as Yup from "yup";

const forgotPwdSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required("Please enter your Email Address")
    .email("Invalid email address")
});

export default forgotPwdSchema;
