import { campaignHelp, fifteenDollars, fiveDollars, tenDollars } from "assets/images";

const AlsoDonate = () => {
  return (
    <div
      className=" h-[80vh]  bg-fixed bg-no-repeat relative bg-cover bg-center"
      style={{ backgroundImage: `url(${campaignHelp})` }}
    >
      <div className=" bg-[rgba(0,80,40,0.76)] h-[80vh]  flex items-start md:items-center justify-center pt-8">
        <div className="absolute flex flex-col md:flex-row justify-between items-center gap-20">
          <div>
            <h2 className=" text-3xl text-center md:text-left md:text-[55px] font-semibold md:font-normal md:leading-[54px] text-white md:w-[332px]">
              You can also Donate to <span className=" font-bold text-orange">Help Others</span>
            </h2>
          </div>
          <div className="flex gap-5 md:gap-10">
            <img src={fiveDollars} alt="" className="w-[100px] h-[174px] md:h-auto md:w-auto" />
            <img src={tenDollars} alt="" className="w-[100px] h-[174px] md:h-auto md:w-auto" />
            <img src={fifteenDollars} alt="" className="w-[100px] h-[174px] md:h-auto md:w-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlsoDonate;
