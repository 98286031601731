import { Accordion } from "components/modules";

const Faqs = () => {
  const questions = [
    {
      question: "What is Black Mental Health?",
      answer:
        "Black mental health is the mental health of individuals who identify as Black or of African descent. It includes people of African, Afro-Caribbean, Afro-Latinx, and other Black backgrounds."
    },
    {
      question: "What are common mental health challenges Black people face?",
      answer:
        "They often face systemic racism, discrimination, poverty, healthcare disparities, trauma, cultural stigma, and other adversity that disproportionately affect the Black community."
    },
    {
      question: "How does racism affect black mental health?",
      answer:
        "Racism can cause chronic stress for Black individuals, It can lead to feelings of isolation, low self-esteem, and a sense of hopelessness and worthlessness. It affects access to essential resources for maintaining good mental health. It can also impact Black individuals' ability to achieve socioeconomic success, impacting their mental health"
    }
  ];

  return (
    <div className="bg-green text-base text-white py-16 md:12  grid gap-8 lg:items-center justify-items-center md:px-12 xl:px-28 px-5">
      <p className="text-2xl md:text-5xl">Frequently Asked Questions</p>
      <p className="text-base md:text-2xl text-whiteLight self-center text-center">
        With a few basic questions, you can search and compare personalized quotes from top carriers
      </p>
      <div className="w-full h-auto">
        {questions.map(({ question, answer }, index) => (
          <Accordion
            key={index}
            title={question}
            questionTextColor="text-white"
            bgColor="bg-green"
            rounded="rounded-md"
            iconBg="bg-orange"
            border="border-gray"
            childColor="text-gray"
          >
            {answer}
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
