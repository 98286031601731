import { AlsoDonate, CampaignHero, OtherCampaigns } from "components/layout/Campaign";
import StartConversation from "components/layout/Campaign/StartConversation";
import { Helmet } from "react-helmet";

const CampaignView = () => {
  return (
    <>
      <Helmet>
        <title>Campaigns for Black Mental Health Matters</title>
        <meta
          name="description"
          content="Start the conversation on Black mental health in your community and join the fight for equitable access to resources and services. Together, we can make a difference"
        />
        <meta name="keywords" content="campaign, fight, black mental health" />
      </Helmet>
      <CampaignHero />
      <OtherCampaigns />
      <StartConversation />
      <AlsoDonate />
    </>
  );
};

export default CampaignView;
