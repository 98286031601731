import React from "react";

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, children }: ShareModalProps) => {
  return isOpen ? (
    <div className="fixed z-10 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div className="inline-block p-5  bg-white align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="flex justify-between py-2 ">
            <p className="text-[#3C4E40] text-xl font-bold">Share Petition</p>
            <p onClick={onClose} className="cursor-pointer text-orange">
              X
            </p>
          </div>
          <hr className="bg-green font-bold mb-5" />
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default ShareModal;
