import {
  copy,
  facebookicon,
  gmail,
  linkedinicon,
  pininterest,
  sendchamp,
  telegram,
  tinder,
  twittericon,
  whatsapp
} from "assets/images";
import { Link } from "react-router-dom";

const SharePetition = () => {
  return (
    <div className="">
      <p className="text-[#4A4A4A] text-[18px]">Copy link to clipboard</p>
      <div className="flex items-center">
        <input
          type="text"
          placeholder="aioue-9038r68rasfjh-983u2138-hdaerxf73"
          className="w-[100%] border p-2"
        />
        <button className="bg-green p-2">
          <img src={copy} />
        </button>
      </div>
      <div className="mt-5">
        <p className="text-[#4A4A4A] text-[18px]">Share to social</p>
        <div className="grid grid-cols-6 gap-4 mt-5">
          <Link to="#">
            <img src={telegram} />
          </Link>
          <Link to="#">
            <img src={twittericon} />
          </Link>
          <Link to="#">
            <img src={whatsapp} />
          </Link>
          <Link to="#">
            <img src={facebookicon} />
          </Link>
          <Link to="#">
            <img src={sendchamp} />
          </Link>
          <Link to="#">
            <img src={linkedinicon} />
          </Link>
          <Link to="#">
            <img src={tinder} />
          </Link>
          <Link to="#">
            {" "}
            <img src={pininterest} />
          </Link>
          <Link to="#">
            {" "}
            <img src={gmail} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SharePetition;
