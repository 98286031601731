const CampaignFormHero = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center md:h-[30vh]">
      <p className="text-green font-bold md:text-6xl text-2xl  md:w-[800px] w-[216px] text-center md:leading-[75px]">
        <span className="font-bold">Working Towards</span>{" "}
        <span className="text-orange">Mental</span> Stability
      </p>
      <div>
        <p className="text-center font-bold text-gray-200">Start A Petition</p>
        <ul className="text-xs">
          <li>Reach our audience of millions fighting for change</li>
          <li>Easy to get started on your own. Help from experts if you need it</li>
          <li>Go beyond a petition when you're ready with powerful organizing tools</li>
        </ul>
      </div>
    </div>
  );
};

export default CampaignFormHero;
