import React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";

interface RadioProps {
  name: string;
  value: string;
  label: string;
}

const ThRadio: React.FC<RadioProps> = ({ name, value, label }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <input
            type="radio"
            className="accent-green-100"
            id={name + value}
            {...field}
            value={value}
            checked={field.value === value}
            onChange={() => form.setFieldValue(name, value)}
          />
          <label htmlFor={name + value} className="text-base text-black">
            {label}
          </label>
        </div>
        <ErrorMessage
          component="label"
          name={name + value}
          className="text-sm w-full text-[#FF0000]"
        />
      </div>
    )}
  </Field>
);

export default ThRadio;
