import { DashboardWrapper } from "components";
import AppointmentsView from "./AppointmentsView";

export const AppointmentsContainer = () => {
  return (
    <DashboardWrapper>
      <AppointmentsView />
    </DashboardWrapper>
  );
};
