/* eslint-disable react/jsx-key */
import { memo } from "react";
import {
  bmmhInstagram,
  bmmhNet,
  TherapyForBlackGirls,
  TherapyForBlackMen,
  lovelandFoundation,
  MelaninAndMentalHealth,
  InclusiveTherapists,
  Frontline,
  BMMUK,
  BMHA,
  BlackGirlsBreathing,
  BlackBritishParent,
  BlackMentalWellness,
  BEAM,
  Bayo,
  AlkemeHealth,
  Aakoma,
  BlackMaleMentalHealth,
  DepressedWhileBlack,
  BlackGirlFest,
  RacismAndAntiRacism,
  AABH,
  BlackGirlThriving,
  TheBraveProject,
  BlackGirlsThriveWorldwide
} from "assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ResourceBmmh = () => {
  const BmmhResources = [
    {
      id: 1,
      Image: BlackMaleMentalHealth,
      Heading: "Black Male Mental Health",
      paragraph: "“Our perspective, our stories, our brilliance, our healing.”",
      instagram: "https://www.instagram.com/blackmalementalhealth/?hl=en",
      net: ""
    },
    {
      id: 2,
      Image: BMHA,
      Heading: "Black Mental Health Alliance",
      paragraph:
        "“Trusted forum for culturally-competent mental health programs and services for marginalized communities.””",
      instagram: "https://www.instagram.com/bmhaofficial/?hl=en",
      net: "https://blackmentalhealth.com/"
    },
    {
      id: 3,
      Image: AlkemeHealth,
      Heading: "Alkeme Health",
      paragraph:
        "“Alkeme is a digital health platform designed to empower, heal, and inspire – creating a legacy of generational health.”",
      instagram: "https://www.instagram.com/alkemehealth/?hl=en",
      net: "https://alkemehealth.com/"
    },
    {
      id: 4,
      Image: lovelandFoundation,
      Heading: "The Loveland Foundation ",
      paragraph:
        "“Loveland Foundation is committed to showing up for communities of colour in unique and powerful ways, with a particular focus on Black women and girls.”",
      instagram: "https://www.instagram.com/thelovelandfoundation/?hl=en",
      net: "https://thelovelandfoundation.org/"
    },
    {
      id: 5,
      Image: InclusiveTherapists,
      Heading: "Inclusive Therapists ",
      paragraph: "“Therapy directory that celebrates ALL: Identities, Abilities & Bodies.”",
      instagram: "https://www.instagram.com/inclusivetherapists/?hl=en",
      net: "https://www.inclusivetherapists.com/"
    },
    {
      id: 6,
      Image: MelaninAndMentalHealth,
      Heading: "Melanin & Mental Health ",
      paragraph: "“Promoting mental wellness of Black/Latinx people.”",
      instagram: "https://www.instagram.com/melaninandmentalhealth/?hl=en",
      net: "https://www.melaninandmentalhealth.com/"
    },
    {
      id: 7,
      Image: DepressedWhileBlack,
      Heading: "Depressed While Black",
      paragraph: "“Black-affirming mental health nonprofit founded by Imadé.”",
      instagram: "https://www.instagram.com/depressedwhileblack/?hl=en",
      net: "https://www.depressedwhileblack.com/"
    },
    {
      id: 8,
      Image: BlackGirlsBreathing,
      Heading: "Black Girls Breathing",
      paragraph:
        "“Black girls breathing is a platform that provides a safe space for Black women and girls to actively manage their mental health and heal their trauma through breathwork and community. They look to provide free and accessible mental health resources to 1 Million Black women and girls by 2025.”",
      instagram: "https://instagram.com/blackgirlsbreathing?igshid=YmMyMTA2M2Y=",
      net: "https://blackgirlsbreathing.com/"
    },
    {
      id: 9,
      Image: BlackBritishParent,
      Heading: "Black British Parent ",
      paragraph:
        "“Black British Parent is a platform that fosters relationships with and for African and Caribbean families as well as the neighborhood. The goal is to improve parents for the sake of their children.Their main priorities are cultural sensitivity, mutual empowerment, and joint development as parents and adult children of African and Caribbean descent.”",
      instagram: "https://www.instagram.com/blackbritishparent/?igshid=YmMyMTA2M2Y%3D",
      net: "https://blackbritishparent.co.uk/"
    },
    {
      id: 10,
      Image: BlackGirlFest,
      Heading: "Black Girl Fest",
      paragraph:
        "“BGF is a creative platform that develops projects intending to improve the future of our young girls in the neighborhood. Projects that include: The Club, BGF Academy, BGF Creative Fund, Girlhood, and The Black Girl Festival.”",
      instagram: "https://www.instagram.com/blackgirlfest/?igshid=YmMyMTA2M2Y%3D",
      net: "https://www.blackgirlfest.com/"
    },
    {
      id: 11,
      Image: TheBraveProject,
      Heading: "The Brave Project",
      paragraph:
        "“The BRAVE project is a UK-based suicide prevention and well-being service; for Black, Asian, and Minority Ethnic boys and young men. The platform also offers knowledge and skills to help young people deal with worries and anxiety.”",
      instagram: "https://www.instagram.com/the_braveproject/?igshid=YmMyMTA2M2Y%3D",
      net: ""
    },
    {
      id: 12,
      Image: Frontline,
      Heading: "Front Line Therapist",
      paragraph:
        "“Frontline Therapist is your one-stop resource for all of your mental health needs, offering guidance and support along the way to mental wellness.”",
      instagram: "https://www.instagram.com/frontlinetherapist/?igshid=YmMyMTA2M2Y%3D",
      net: ""
    },
    {
      id: 13,
      Image: BMMUK,
      Heading: "Black Minds Matter UK  ",
      paragraph:
        "“Black Minds Matter UK is a fully registered charity operating in the UK; connecting Black individuals and families with free mental health services- by professional Black therapists to support their mental health. Our vision is to make mental health topics more relevant and accessible for all Black people in the U.K., removing the stigma and remodeling the services to be relevant for the Black community.”",
      instagram: "https://www.instagram.com/blackmindsmatter.uk/?hl=en",
      net: "https://www.blackmindsmatteruk.com/"
    },
    {
      id: 14,
      Image: BlackMentalWellness,
      Heading: "Black Mental Wellness",
      paragraph:
        "“ Provides access to evidence-based information and resources about mental and behavioral health topics from a Black perspective. Check out the site’s Coping & Wellness tab for actionable strategies like how to discuss race, discrimination and racial trauma with youth.”",
      instagram: "https://www.instagram.com/blackmentalwellness/",
      net: "https://www.blackmentalwellness.com/"
    },
    {
      id: 15,
      Image: TherapyForBlackGirls,
      Heading: "Therapy for Black Girls",
      paragraph:
        "“Therapy for Black Girls is an online space dedicated to encouraging the mental wellness of Black women and girls.”",
      instagram: "https://www.instagram.com/therapyforblackgirls/",
      net: "https://therapyforblackgirls.com/"
    },
    {
      id: 16,
      Image: TherapyForBlackMen,
      Heading: "Therapy for Black Men",
      paragraph:
        "“Therapy for Black Men is working to dismantle the stigma that asking for help is a sign of weakness. The organization provides free therapy for men in a judgment-free, multiculturally competent setting.”",
      instagram: "https://www.instagram.com/therapyforblkmen/",
      net: "https://therapyforblackmen.org/"
    },
    {
      id: 17,
      Image: RacismAndAntiRacism,
      Heading: "Racism and Anti-Racism in America ",
      paragraph:
        "“Racism and Anti-Racism in America – A free online training series from the University of Michigan on dismantling systemic racism.”",
      instagram: "https://www.instagram.com/michigan.online/",
      net: "https://online.umich.edu/collections/racism-antiracism/"
    },
    {
      id: 18,
      Image: BEAM,
      Heading: "Black Emotional and Mental Health Collective (BEAM)",
      paragraph:
        "“Black Emotional and Mental Health Collective (BEAM)  is removing barriers that Black people experience getting access to or staying connected with emotional health care and healing and offers a nationwide directory of Black therapists who are available virtually.”",
      instagram: "https://www.instagram.com/_beamorg/",
      net: "https://beam.community/"
    },
    {
      id: 19,
      Image: AABH,
      Heading: "African American Behavioral Health Center of Excellence (AABH CoE)",
      paragraph:
        "“African American Behavioral Health Center of Excellence (AABH CoE), from the Substance Abuse and Mental Health Services Administration, is determined to help transform mental health and substance use care for African Americans, making it safer, more effective, more accessible, more inclusive, more welcoming, more engaging and more culturally appropriate and responsive.”",
      instagram: "https://twitter.com/AabhCoe",
      net: "https://africanamericanbehavioralhealth.org/#:~:text=AABH%20CoE%20%2D%20Home&text=The%20African%20American%20Behavioral%20Health,Care%2C%20Morehouse%20School%20of%20Medicine."
    },
    {
      id: 20,
      Image: Bayo,
      Heading: "Bayo",
      paragraph:
        "“BAYO, which means 'joy has found us' in Yoruba, is a space where you can find collectives, organizations, and services from across the UK aimed at the black community to support your mental health and wellbeing. It is run by The Ubele Initiative.”",
      instagram: "https://www.instagram.com/ubeleinitiative/?hl=en",
      net: "https://bayo.ubele.org/"
    },
    {
      id: 21,
      Image: Aakoma,
      Heading: "AAKOMA",
      paragraph:
        "“In order to meet the mental health needs of youth of color, AAKOMA is a platform that believes that we must work at three different levels:raising awareness among individuals, providing readily available tools for ongoing management, and changing systems to better accommodate youth and provide care.”",
      instagram: "https://www.instagram.com/aakomaproject/",
      net: "http://aakomaproject.org/"
    },
    {
      id: 22,
      Image: BlackGirlThriving,
      Heading: "Black Girl Thriving",
      paragraph:
        "“Black Girl Thriving (BGT) is dedicated to empowering and unlocking the full potential of black girls & women worldwide.  We want black girls and women to thrive academically, physically, mentally, spiritually, professionally, socially, and financially. BGT is more than a brand; it’s a lifestyle.”",
      instagram: "https://www.instagram.com/blackgirlthriving/",
      net: "https://blackgirlthriving.com/"
    },
    {
      id: 23,
      Image: BlackGirlsThriveWorldwide,
      Heading: "Black Girls Thrive Worldwide",
      paragraph:
        "“Black Girls Thrive  was founded in 2020 in Newark, NJ in order to provide racial trauma healing, mental health therapy and mental health support to black girls and women during the COVID-19 pandemic. This Group is ideal for any Black Woman who is interested in healing from the multiple forms of oppression we face in this society”",
      instagram: "",
      net: "https://www.blackgirlsthriveworldwide.com/"
    }
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className=" pb-16  mt-8">
      <Carousel
        showDots={true}
        arrows={true}
        swipeable
        draggable
        slidesToSlide={1}
        responsive={responsive}
        dotListClass=""
      >
        {BmmhResources.map((bmmh) => {
          return (
            <div className="bg-[#005028]">
              <div key={bmmh.id} className="md:flex gap-4 md:px-20 px-5 py-6">
                <img
                  src={bmmh.Image}
                  width="300px"
                  style={{ aspectRatio: "3/2", objectFit: "contain" }}
                />
                <div>
                  <div className="flex flex-col py-12 md:py-28 gap-4">
                    <h1 className="font-bold  text-2xl sm:text-3xl lg:text-4xl text-[#ffac2a]">
                      {bmmh.Heading}
                    </h1>
                    <p className="font-normal text-base sm:text-[20px] lg:text-[24px] text-[#ffffff]">
                      {bmmh.paragraph}
                    </p>
                    <div className="flex items-center gap-2 py-2">
                      {bmmh.instagram && (
                        <a target="blank" href={bmmh.instagram}>
                          <img
                            src={bmmhInstagram}
                            className="bg-[#ffffff] rounded-full p-1 w-[42px] h-[42px]"
                          />
                        </a>
                      )}
                      {bmmh.net && (
                        <a target="blank" href={bmmh.net}>
                          <img
                            src={bmmhNet}
                            className="bg-[#ffffff] rounded-full p-1 w-[42px] h-[42px]"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default memo(ResourceBmmh);
