import { FormButton } from "components/widgets";
import { useState } from "react";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const CampaignForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({});

  const handleNext = (values: any) => {
    // Update form values as the user progresses through steps
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setCurrentStep(currentStep + 1);
  };

  const handleFinalSubmit = (values: any) => {
    // Handle the final form submission logic here
    console.log("Final Form Values:", values);
    // You can send the form data to your server or perform any other necessary actions
  };
  return (
    <div className="mx-auto">
      <div className="flex md:flex-row flex-col gap-16 md:mt-16 mt-12">
        <div className="md:w-12 w-full flex md:flex-col flex-row items-center md:justify-center">
          {currentStep >= 1 && (
            <div className="flex flex-row md:flex-col items-center">
              <div className="h-4 w-4 bg-green-100 rounded-full"></div>
              <div className="md:h-[350px] h-1 md:w-1 w-16 bg-green-100"></div>
              <div className="h-4 w-4 bg-green-100 rounded-full"></div>
            </div>
          )}
          {currentStep >= 2 && (
            <div className="flex flex-row md:flex-col items-center">
              <div className="md:h-[320px] h-1 md:w-1 w-16 bg-green-100"></div>
              <div className="h-4 w-4 bg-green-100 rounded-full"></div>
            </div>
          )}
          {currentStep >= 3 && (
            <div className="flex flex-row md:flex-col items-center">
              <div className="md:h-[320px] h-1 md:w-1 w-16 bg-green-100"></div>
              <div className="h-4 w-4 bg-green-100 rounded-full"></div>
            </div>
          )}
          {currentStep >= 4 && (
            <div className="flex flex-row md:flex-col items-center">
              <div className="md:h-[320px] h-1 md:w-1 w-16 bg-green-100"></div>
              <div className="h-4 w-4 bg-green-100 rounded-full"></div>
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-12 ">
            {currentStep >= 1 && <StepOne onNext={handleNext} />}
            {currentStep >= 2 && <StepTwo onNext={handleNext} />}
            {currentStep >= 3 && <StepThree onNext={handleNext} />}
            {currentStep >= 4 && <StepFour onNext={handleNext} />}
          </div>
          <div className="flex items-center justify-center">
            <div className="md:mt-16 mt-12">
              {currentStep === 1 && (
                <FormButton onClick={() => handleNext(formValues)}>
                  What Needs To Be Done?
                </FormButton>
              )}
              {currentStep === 2 && (
                <FormButton onClick={() => handleNext(formValues)}>Why Is It Important?</FormButton>
              )}
              {currentStep === 3 && (
                <FormButton onClick={() => handleNext(formValues)}>Where is It?</FormButton>
              )}
              {currentStep === 4 && (
                <button
                  onClick={() => handleFinalSubmit(formValues)}
                  className="text-white md:text-xl text-lg bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
