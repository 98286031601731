const IMAGES_PATH = "/assets/images";
export const logo = `${IMAGES_PATH}/logo.png`;
export const partner1 = `${IMAGES_PATH}/partner1.png`;
export const partner2 = `${IMAGES_PATH}/partner2.png`;
export const partner3 = `${IMAGES_PATH}/partner3.png`;
export const partner4 = `${IMAGES_PATH}/partner4.png`;
export const vector1 = `${IMAGES_PATH}/vector1.png`;
export const vector2 = `${IMAGES_PATH}/vector2.png`;
export const vector3 = `${IMAGES_PATH}/vector3.png`;
export const vector4 = `${IMAGES_PATH}/vector4.png`;
export const vector5 = `${IMAGES_PATH}/vector5.png`;
export const vector6 = `${IMAGES_PATH}/vector6.png`;
export const vector7 = `${IMAGES_PATH}/vector7.png`;
export const vector8 = `${IMAGES_PATH}/vector8.png`;
export const vector9 = `${IMAGES_PATH}/vector9.png`;
export const vector10 = `${IMAGES_PATH}/vector10.png`;
export const vector11 = `${IMAGES_PATH}/vector11.png`;
export const vector12 = `${IMAGES_PATH}/vector12.png`;
export const MemberTwo = `${IMAGES_PATH}/member-two.png`;
export const ImageOne = `${IMAGES_PATH}/image-one.png`;
export const ImageTwo = `${IMAGES_PATH}/image-two.png`;
export const ladiesAndArrows = `${IMAGES_PATH}/ladiesAndArrows.png`;
export const gradientBackground = `${IMAGES_PATH}/gradientBackground.webp`;
export const contactflag = `${IMAGES_PATH}/flag.png`;

// Dashboard dashboardlogo
export const dashboardimg = `${IMAGES_PATH}/dashboardimg.png`;
export const dashboardlogo = `${IMAGES_PATH}/dashboardlogo.png`;
export const NotificationIcon = `${IMAGES_PATH}/NotificationIcon.png`;
export const profilePic = `${IMAGES_PATH}/profilePic.png`;
export const dashsearchIcon = `${IMAGES_PATH}/dashsearchIcon.png`;
export const logout = `${IMAGES_PATH}/logout.png`;
export const activity = `${IMAGES_PATH}/activity.png`;
export const quiz = `${IMAGES_PATH}/quiz.png`;

// Services
export const serviceCover = `${IMAGES_PATH}/service-cover.webp`;

// Resources images
export const researchGroup = `${IMAGES_PATH}/researchGroup.webp`;
export const resourcesVolunteer = `${IMAGES_PATH}/resourcesVolunteer.png`;
export const africanFamily = `${IMAGES_PATH}/african-family.webp`;

// Signup images
export const signup = `${IMAGES_PATH}/signup-bg.png`;
export const sn1 = `${IMAGES_PATH}/sn1.png`;
export const sn2 = `${IMAGES_PATH}/sn2.png`;
export const sn3 = `${IMAGES_PATH}/sn3.png`;
export const sn31 = `${IMAGES_PATH}/sn31.png`;
export const sn4 = `${IMAGES_PATH}/sn4.png`;
export const sn5 = `${IMAGES_PATH}/sn5.png`;
export const sn6 = `${IMAGES_PATH}/sn6.png`;
export const lg1 = `${IMAGES_PATH}/lg1.png`;
export const lg2 = `${IMAGES_PATH}/lg2.png`;
export const lg3 = `${IMAGES_PATH}/lg3.png`;
export const lg4 = `${IMAGES_PATH}/lg4.png`;
export const lg5 = `${IMAGES_PATH}/lg5.png`;
export const lg6 = `${IMAGES_PATH}/lg6.png`;
export const lg7 = `${IMAGES_PATH}/lg7.png`;

// GetInvolved Images
export const HeaderBackground = `${IMAGES_PATH}/HeaderBackground.webp`;
export const bcampaign = `${IMAGES_PATH}/Groupcampaign.png`;
export const money = `${IMAGES_PATH}/GroupmoneyDonated.png`;
export const joinImg = `${IMAGES_PATH}/joinImg.png`;
export const donate = `${IMAGES_PATH}/donate.png`;
export const campaign = `${IMAGES_PATH}/campaign.png`;
export const jasmine = `${IMAGES_PATH}/Jasmine.png`;
export const daniel = `${IMAGES_PATH}/Daniel.png`;
export const jeff = `${IMAGES_PATH}/Jeff.png`;
export const hillday = `${IMAGES_PATH}/Hillday.png`;
export const Gfacebook = `${IMAGES_PATH}/Gfacebook.svg`;
export const Gtwitter = `${IMAGES_PATH}/Gtwitter.svg`;
export const messenger = `${IMAGES_PATH}/messenger.svg`;
export const slack = `${IMAGES_PATH}/slack.svg`;
export const Gvolunteer = `${IMAGES_PATH}/volunteer.webp`;
export const vlinkedin = `${IMAGES_PATH}/vlinkedin.png`;
export const vbmhm = `${IMAGES_PATH}/vbmhm.png`;

// about-us page
// WhoAreWe
export const AboutUs = `${IMAGES_PATH}/about-us.png`;
export const abtusleft = `${IMAGES_PATH}/abtus-left.png`;
export const abtusright = `${IMAGES_PATH}/abtus-right.png`;
// Our Team
export const MemberOne = `${IMAGES_PATH}/member-one.png`;
// Our Mission
export const Mission = `${IMAGES_PATH}/about-image.png`;
// We Deliver
export const Arrow = `${IMAGES_PATH}/arrow.png`;
export const Comment = `${IMAGES_PATH}/comment.png`;
export const Like = `${IMAGES_PATH}/like.png`;
export const Star = `${IMAGES_PATH}/star.png`;
export const NoteBoard = `${IMAGES_PATH}/note-board.png`;
export const StartTherapy = `${IMAGES_PATH}/start-therapy.png`;

// Podcasts
export const Anchor = `${IMAGES_PATH}/anchor.png`;
export const IHeart = `${IMAGES_PATH}/i-heart.png`;
export const Apple = `${IMAGES_PATH}/apple.png`;
export const Google = `${IMAGES_PATH}/google.png`;
export const Spotify = `${IMAGES_PATH}/spotify.png`;
export const DoeJane = `${IMAGES_PATH}/doe-jane.png`;

// Testimonials
export const DiaPfleger = `${IMAGES_PATH}/dia-pfleger.png`;
export const AbigailOni = `${IMAGES_PATH}/abigail-oni.webp`;
export const AshleyBAye = `${IMAGES_PATH}/ashley-baye.jpeg`;
export const ClaireNasasira = `${IMAGES_PATH}/claire-nasasira.png`;
export const DeonteCarter = `${IMAGES_PATH}/deonte-carter.png`;
export const JasmynJames = `${IMAGES_PATH}/jasmyn-james.webp`;
export const DrJoanSamuelsDennis = `${IMAGES_PATH}/joan-samuels-dennis.jpg`;
export const MekayaWilliams = `${IMAGES_PATH}/mekaya-williams.webp`;
export const RhyanaEbanksBabb = `${IMAGES_PATH}/rhyana-ebanks-babb.png`;
export const TerriFloyd = `${IMAGES_PATH}/terri-floyd.png`;
export const JosephineEbhoomhan = `${IMAGES_PATH}/Josephine-ebhoomhan.jpg`;
export const TrinityThomson = `${IMAGES_PATH}/trinity-thomson.jpeg`;
export const EricaDolland = `${IMAGES_PATH}/erica-dolland.jpeg`;
export const ToniciaFreeman = `${IMAGES_PATH}/tonicia-freeman.png`;
export const CerissaBrown = `${IMAGES_PATH}/cerissa-brown.png`;
export const QuotationEnding = `${IMAGES_PATH}/quotation-ending.png`;
export const QuotationStarting = `${IMAGES_PATH}/quotation-starting.png`;
export const ClaudiaWei = `${IMAGES_PATH}/ClaudiaWei.webp`;
export const AnneDiouf = `${IMAGES_PATH}/AnneDiouf.webp`;
export const TriumphUrias = `${IMAGES_PATH}/TriumphUrias.webp`;
export const IsraelAdekanye = `${IMAGES_PATH}/IsraelAdekanye.webp`;
export const FlorenceANneoma = `${IMAGES_PATH}/FlorenceANneoma.webp`;
export const DalmasChituyi = `${IMAGES_PATH}/DalmasChituyi.webp`;
export const SophiaEdeki = `${IMAGES_PATH}/SophiaEdeki.webp`;
export const TegaUwadia = `${IMAGES_PATH}/TegaUwadia.webp`;
export const EakyTang = `${IMAGES_PATH}/EakyTang.webp`;
export const JoanNobei = `${IMAGES_PATH}/JoanNobei.webp`;

// Landing page
export const pinkStroke = `${IMAGES_PATH}/pinkStroke.png`;
export const mental = `${IMAGES_PATH}/mental.png`;
export const therapist = `${IMAGES_PATH}/therapist.png`;
export const therapistForSomeone = `${IMAGES_PATH}/therapistForSomeone.png`;
export const skillAssessment = `${IMAGES_PATH}/skillAssessment.png`;
export const ArrowLeft = `${IMAGES_PATH}/arrow-left.png`;
export const ArrowRight = `${IMAGES_PATH}/arrow-right.png`;
export const therapistAndCard = `${IMAGES_PATH}/therapistAndCard.png`;
export const DrMarsha = `${IMAGES_PATH}/DrMarsha.png`;
export const DrPia = `${IMAGES_PATH}/DrPia.png`;
export const DrDawn = `${IMAGES_PATH}/DrDawn.jpg`;
export const certified = `${IMAGES_PATH}/certified.png`;
export const video1 = `${IMAGES_PATH}/video1.png`;
export const homeHeroImg = `${IMAGES_PATH}/homeHeroImg.png`;
export const home2 = `${IMAGES_PATH}/home2.png`;
export const home3 = `${IMAGES_PATH}/home3.png`;
export const home4 = `${IMAGES_PATH}/home4.png`;
export const home5 = `${IMAGES_PATH}/home5.png`;
export const phoneCall = `${IMAGES_PATH}/phone-call.png`;
export const handshake = `${IMAGES_PATH}/handshake.png`;

// OurPartners
export const PartnerOne = `${IMAGES_PATH}/partner-one.png`;
export const PartnerTwo = `${IMAGES_PATH}/partner-two.png`;
export const SakaiVms = `${IMAGES_PATH}/sa-kai-vms.png`;
export const Usaf = `${IMAGES_PATH}/usaf.webp`;
export const WestPaua = `${IMAGES_PATH}/west-paua.png`;
export const MHlogo = `${IMAGES_PATH}/MHlogo.png`;

// Join
export const JoinUs = `${IMAGES_PATH}/join-us.png`;

// Footer
export const Youtube = `${IMAGES_PATH}/youtube.png`;
export const Instagram = `${IMAGES_PATH}/instagram.png`;
export const Linkedin = `${IMAGES_PATH}/linkedin.png`;
export const Twitter = `${IMAGES_PATH}/twitter.png`;
export const Facebook = `${IMAGES_PATH}/facebook.png`;
export const LogoFooter = `${IMAGES_PATH}/logo-footer.png`;
export const Point = `${IMAGES_PATH}/point.png`;

// Donate
export const donateBg = `${IMAGES_PATH}/donateBg.webp`;

export const favorite = `${IMAGES_PATH}/favorite.png`;
export const whydonate1 = `${IMAGES_PATH}/whydonate1.png`;
export const whydonate2 = `${IMAGES_PATH}/whydonate2.png`;
export const whydonate3 = `${IMAGES_PATH}/whydonate3.png`;
export const empowerimg = `${IMAGES_PATH}/empowerimg.png`;
export const howtoDonateimg = `${IMAGES_PATH}/howtoDonateimg.png`;

// Nav
export const menu = `${IMAGES_PATH}/menu.png`;
export const close = `${IMAGES_PATH}/close.png`;

// Research

export const researchHero = `${IMAGES_PATH}/researchHero.webp`;
export const storiesImg = `${IMAGES_PATH}/storiesImg.png`;
export const MentalEverydayImg = `${IMAGES_PATH}/MentalEveryday.png`;
export const ResearchTeamImg = `${IMAGES_PATH}/ResearchTeamImg.png`;
export const ResearchTeamImg2 = `${IMAGES_PATH}/ResearchTeamImg2.png`;
export const ResearchTeamImg1 = `${IMAGES_PATH}/ResearchTeamImg1.png`;
export const ResearchTeamImg3 = `${IMAGES_PATH}/ResearchTeamImg3.png`;
export const ResearchTeamImg4 = `${IMAGES_PATH}/ResearchTeamImg4.png`;
export const ResearchTeamImg5 = `${IMAGES_PATH}/ResearchTeamImg5.png`;
export const TeamImg5 = `${IMAGES_PATH}/TeamImg5.png`;
export const ResearchTeamImg6 = `${IMAGES_PATH}/DrDawn.webp`;
export const ResearchTeamImg7 = `${IMAGES_PATH}/TeamImg7.jpg`;
export const ResearchTeamImg8 = `${IMAGES_PATH}/geoffrey-whittle-walls.webp`;
export const ResearchTeamImg9 = `${IMAGES_PATH}/isimbi-sebageni.jpeg`;
export const ResearchTeamImg10 = `${IMAGES_PATH}/esther-bikoba.jpeg`;
export const ResearchTeamImg11 = `${IMAGES_PATH}/sonia-harte.jpeg`;
export const ResearchTeamImg12 = `${IMAGES_PATH}/sista-oloruntoyin.jpg`;
export const ResearchTeamImg13 = `${IMAGES_PATH}/birgit-umaigba.jpg`;
export const ResearchTeamImg14 = `${IMAGES_PATH}/Dr-bayo-curry-winchell.jpeg`;
export const ResearchTeamImg15 = `${IMAGES_PATH}/Pennie-Anassi.jpg`;
export const ResearchTeamImg16 = `${IMAGES_PATH}/rachel-harris.jpg`;
export const ResearchTeamImg17 = `${IMAGES_PATH}/Dr-candance-willet.jpg`;
export const ResearchTeamImg18 = `${IMAGES_PATH}/ramabulana-lufuno.webp`;
export const ResearchTeamImg19 = `${IMAGES_PATH}/angel-rangel.jpeg`;
export const ResearchTeamImg20 = `${IMAGES_PATH}/rita-nwanze.webp`;
export const whatWeDoImgR = `${IMAGES_PATH}/whatWeDoImgR.png`;
export const ResearchAttachImg = `${IMAGES_PATH}/ResearchAttachImg.svg`;
export const ReasrchSearchImg = `${IMAGES_PATH}/ReasrchSearchImg.svg`;
export const sharedStoryImg = `${IMAGES_PATH}/sharedStoryImg.png`;
export const ReasrchCardImg2 = `${IMAGES_PATH}/researchCardimg2.png`;
export const ReasrchCardImg3 = `${IMAGES_PATH}/researchCardimg3.png`;
export const ReasrchCardImg4 = `${IMAGES_PATH}/researchCardimg4.png`;
export const ReasrchIcon = `${IMAGES_PATH}/ReasrchIcon.png`;
export const linkedinVector = `${IMAGES_PATH}/linkedinvector.png`;
export const Bmhmlink = `${IMAGES_PATH}/Bmhmlink.png`;
export const copylink = `${IMAGES_PATH}/copylink.png`;
export const outline = `${IMAGES_PATH}/outline.png`;
export const Researchfile = `${IMAGES_PATH}/file.png`;

// product page
export const producthero = `${IMAGES_PATH}/producthero.png`;
export const additionalservices = `${IMAGES_PATH}/additionalservices.png`;

// error page
export const BackArrow = `${IMAGES_PATH}/back-arrow.png`;
export const CatPhoto = `${IMAGES_PATH}/cat-photo.png`;

// career page
export const intern = `${IMAGES_PATH}/intern-program.png`;
export const volunteer = `${IMAGES_PATH}/volunteer-program.png`;
export const daven = `${IMAGES_PATH}/daven.png`;
export const jenny = `${IMAGES_PATH}/jenny.png`;
export const LoveIcon = `${IMAGES_PATH}/love-icon.png`;
export const OpportunityCovered = `${IMAGES_PATH}/opportunity-cover.webp`;
export const careerIcon = `${IMAGES_PATH}/campaignIcon.png`;
export const careerbg = `${IMAGES_PATH}/campaign-bg.png`;

// campaign

export const mentalStability = `${IMAGES_PATH}/mentalStability.webp`;
export const petitionProgress = `${IMAGES_PATH}/petitionProgress.png`;
export const helpingHand = `${IMAGES_PATH}/helpingHand.png`;
export const fiveDollars = `${IMAGES_PATH}/fiveDollars.png`;
export const campaignHelp = `${IMAGES_PATH}/campaignHelp.webp`;
export const campaigningPeople = `${IMAGES_PATH}/campaigningPeople.png`;
export const tenDollars = `${IMAGES_PATH}/tenDollars.png`;
export const fifteenDollars = `${IMAGES_PATH}/fifteenDollars.png`;
export const campaignHand = `${IMAGES_PATH}/campaignHand.png`;
export const currentuser = `${IMAGES_PATH}/currentuser.png`;
export const copy = `${IMAGES_PATH}/copy.png`;

// sharepetiotion
export const whatsapp = `${IMAGES_PATH}/whatsapp.png`;
export const telegram = `${IMAGES_PATH}/telegram.png`;
export const tinder = `${IMAGES_PATH}/tinder.png`;
export const facebookicon = `${IMAGES_PATH}/facebookicon.png`;
export const pininterest = `${IMAGES_PATH}/pininterest.png`;
export const twittericon = `${IMAGES_PATH}/twittericon.png`;
export const linkedinicon = `${IMAGES_PATH}/linkedinicon.png`;
export const gmail = `${IMAGES_PATH}/gmail.png`;
export const sendchamp = `${IMAGES_PATH}/sendchamp.png`;
export const submitted = `${IMAGES_PATH}/submitted.png`;

// be a voice
export const thumbPrint = `${IMAGES_PATH}/thumbPrint.png`;
export const voiceAddressing = `${IMAGES_PATH}/voiceAddressing.png`;
export const playButton = `${IMAGES_PATH}/playButton.png`;
export const dottedPattern = `${IMAGES_PATH}/dottedPattern.png`;
export const grayWave = `${IMAGES_PATH}/grayWave.png`;
export const testifier1 = `${IMAGES_PATH}/testifier1.png`;
export const testifier2 = `${IMAGES_PATH}/testifier2.png`;
export const testifier3 = `${IMAGES_PATH}/testifier3.png`;
export const testifier4 = `${IMAGES_PATH}/testifier4.png`;
export const testifier5 = `${IMAGES_PATH}/testifier5.png`;
export const testifier6 = `${IMAGES_PATH}/testifier6.png`;
export const testifier7 = `${IMAGES_PATH}/testifier7.png`;
export const testifier8 = `${IMAGES_PATH}/testifier8.png`;
export const testifier9 = `${IMAGES_PATH}/testifier9.png`;
export const testifier10 = `${IMAGES_PATH}/testifier10.png`;
export const testifier11 = `${IMAGES_PATH}/testifier11.png`;
export const testifier12 = `${IMAGES_PATH}/testifier12.jpg`;
export const testifier13 = `${IMAGES_PATH}/testifier13.png`;
export const testifier14 = `${IMAGES_PATH}/testifier14.png`;
export const testifier15 = `${IMAGES_PATH}/testifier15.jpg`;
export const testifier16 = `${IMAGES_PATH}/testifier16.png`;
export const testifier17 = `${IMAGES_PATH}/testifier17.jpg`;

// success
export const check = `${IMAGES_PATH}/check-circle.png`;

// Search Article Page
export const search = `${IMAGES_PATH}/search.png`;
export const SearchArticlesBg = `${IMAGES_PATH}/search-articles.png`;
export const searchArticleIMG = `${IMAGES_PATH}/searchArticleIMG.png`;
export const SearchResultImg = `${IMAGES_PATH}/SearchResultImg.png`;
export const SearchEclipsImg = `${IMAGES_PATH}/SearchEclipsImg.png`;
export const searchCiteLogoImg = `${IMAGES_PATH}/searchCiteLogoImg.png`;
export const AuthorsLinksBg = `${IMAGES_PATH}/authors-links.png`;
export const share = `${IMAGES_PATH}/share.png`;
export const cite = `${IMAGES_PATH}/cite.png`;

// volunteer
export const apply = `${IMAGES_PATH}/apply.png`;
export const entail = `${IMAGES_PATH}/entailBg.png`;
export const field = `${IMAGES_PATH}/field.png`;
export const volunteers = `${IMAGES_PATH}/volunteers.png`;
export const peoples = `${IMAGES_PATH}/people.png`;
// volunteerForm
export const upload = `${IMAGES_PATH}/cloud-upload.png`;

// Partner
export const why = `${IMAGES_PATH}/whyImg.png`;
export const reseacrh1 = `${IMAGES_PATH}/research1.png`;
export const reseacrh2 = `${IMAGES_PATH}/research2.png`;
export const reseacrh3 = `${IMAGES_PATH}/research3.png`;
export const reseacrh4 = `${IMAGES_PATH}/research4.png`;
export const reseacrh5 = `${IMAGES_PATH}/research5.png`;
export const research6 = `${IMAGES_PATH}/research6.png`;
export const research7 = `${IMAGES_PATH}/research7.png`;
export const research8 = `${IMAGES_PATH}/research8.png`;

// research stories
export const researchStoriesImg = `${IMAGES_PATH}/researchStoriesImg.png`;
export const researchStoriesProfile = `${IMAGES_PATH}/researchStoriesProfile.png`;
export const storiesComment = `${IMAGES_PATH}/uil_Comment.png`;
export const storiesLike = `${IMAGES_PATH}/ei_like.png`;
export const map = `${IMAGES_PATH}/map-pin.png`;
export const commentsArrowRight = `${IMAGES_PATH}/arrow-right.png`;
export const arrowDown = `${IMAGES_PATH}/arrow-down.png`;

// FAQ
export const faqHeroImg = `${IMAGES_PATH}/faqHeroImg.webp`;

// contact
export const contactHero = `${IMAGES_PATH}/contacthero.jpg`;

// new resource page
export const resourceHero = `${IMAGES_PATH}/resourceHero.webp`;
export const resourceInstagram = `${IMAGES_PATH}/resourceInstagram.png`;
export const resourceFB = `${IMAGES_PATH}/resourceFB.png`;
export const resourceNet = `${IMAGES_PATH}/resourceNet.png`;
export const resourceTwitter = `${IMAGES_PATH}/resourceTwitter.png`;
export const line1 = `${IMAGES_PATH}/Line1.png`;
export const line2 = `${IMAGES_PATH}/Line2.png`;
export const line3 = `${IMAGES_PATH}/Line3.png`;
export const line4 = `${IMAGES_PATH}/Line4.png`;
export const line5 = `${IMAGES_PATH}/Line5.png`;
export const line6 = `${IMAGES_PATH}/Line6.png`;
export const bmmh = `${IMAGES_PATH}/bmmh.png`;
export const bmmh2 = `${IMAGES_PATH}/bmmh2.png`;
export const bmmh3 = `${IMAGES_PATH}/bmmh3.png`;
export const bmmh4 = `${IMAGES_PATH}/bmmh4.png`;
export const bmmh5 = `${IMAGES_PATH}/bmmh5.png`;
export const bmmh8 = `${IMAGES_PATH}/bmmh8.png`;
export const bmmh9 = `${IMAGES_PATH}/bmmh9.png`;
export const bmmh10 = `${IMAGES_PATH}/bmmh10.png`;
export const bmmhInstagram = `${IMAGES_PATH}/bmmhInstagram.png`;
export const bmmhNet = `${IMAGES_PATH}/bmmhNet.png`;
export const bmmhCarousel1 = `${IMAGES_PATH}/bmmhCarousel1.png`;
export const bmmhCarousel2 = `${IMAGES_PATH}/bmmhCarousel2.png`;
export const bmmhCarousel3 = `${IMAGES_PATH}/bmmhCarousel3.png`;
export const bmmhCarousel4 = `${IMAGES_PATH}/bmmhCarousel4.png`;
export const bmmhForward = `${IMAGES_PATH}/bmmhForward.png`;

// customized Resource page
export const uploadResource = `${IMAGES_PATH}/upload.png`;
export const bannerBg = `${IMAGES_PATH}/bannerBg.webp`;

// About us team
export const DanielMwambonu = `${IMAGES_PATH}/daniel-mwambonu.png`;
export const DrMeganPickens = `${IMAGES_PATH}/Dr-megan-pickens.jpg`;
export const DrKevinWashington = `${IMAGES_PATH}/Dr-kevin-washington.jpg`;

// Internship form
export const AttachFile = `${IMAGES_PATH}/attach-file.png`;

// Dashboard
export const GoForward = `${IMAGES_PATH}/goforward.png`;
export const GoBack = `${IMAGES_PATH}/goback.png`;
export const Calender = `${IMAGES_PATH}/calender-icon.png`;
export const Journal = `${IMAGES_PATH}/journal-icon.png`;
export const Moodboard = `${IMAGES_PATH}/moodboard.png`;
export const Moodboards = `${IMAGES_PATH}/moodboards.png`;
export const Video = `${IMAGES_PATH}/video.png`;
export const TrendingPost = `${IMAGES_PATH}/TrendingPost.png`;
export const dashCertified = `${IMAGES_PATH}/dashCertified.png`;

// Resources
export const DepressedWhileBlack = `${IMAGES_PATH}/DepressedWhileBlack.webp`;
export const BlackMaleMentalHealth = `${IMAGES_PATH}/BlackMaleMentalHealth.webp`;
export const Aakoma = `${IMAGES_PATH}/Aakoma.webp`;
export const AlkemeHealth = `${IMAGES_PATH}/AlkemeHealth.webp`;
export const Bayo = `${IMAGES_PATH}/Bayo.webp`;
export const BEAM = `${IMAGES_PATH}/BEAM.webp`;
export const BlackMentalWellness = `${IMAGES_PATH}/BlackMentalWellness.webp`;
export const BlackBritishParent = `${IMAGES_PATH}/BlackBritishParent.webp`;
export const BlackGirlsBreathing = `${IMAGES_PATH}/BlackGirlsBreathing.webp`;
export const BMHA = `${IMAGES_PATH}/BMHA.webp`;
export const BMMUK = `${IMAGES_PATH}/BMMUK.webp`;
export const Frontline = `${IMAGES_PATH}/Frontline.webp`;
export const InclusiveTherapists = `${IMAGES_PATH}/InclusiveTherapists.webp`;
export const MelaninAndMentalHealth = `${IMAGES_PATH}/MelaninAndMentalHealth.webp`;
export const lovelandFoundation = `${IMAGES_PATH}/lovelandFoundation.webp`;
export const TherapyForBlackMen = `${IMAGES_PATH}/TherapyForBlackMen.webp`;
export const TherapyForBlackGirls = `${IMAGES_PATH}/TherapyForBlackGirls.webp`;
export const BlackGirlFest = `${IMAGES_PATH}/BlackGirlFest.webp`;
export const RacismAndAntiRacism = `${IMAGES_PATH}/RacismAndAntiRacism.webp`;
export const AABH = `${IMAGES_PATH}/AABH.webp`;
export const BlackGirlThriving = `${IMAGES_PATH}/BlackGirlThriving.webp`;
export const TheBraveProject = `${IMAGES_PATH}/TheBraveProject.webp`;
export const BlackGirlsThriveWorldwide = `${IMAGES_PATH}/BlackGirlsThriveWorldwide.webp`;

// Therapist profile
export const TherapistProfile = `${IMAGES_PATH}/TherapistProfile.png`;
export const Certified = `${IMAGES_PATH}/certified.png`;
export const Accept = `${IMAGES_PATH}/accept.png`;
export const Healing = `${IMAGES_PATH}/Healing.png`;
export const Nature = `${IMAGES_PATH}/fblf.png`;

// Payment methods
export const paypal = `${IMAGES_PATH}/paypal.png`;
export const zelle = `${IMAGES_PATH}/zelle.png`;
export const creditCard = `${IMAGES_PATH}/creditCard.png`;
