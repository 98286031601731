const MonthlyDonors = () => {
  const monthlydonor = [
    {
      id: 1,
      number: "985+",
      text: "Donation Received"
    },
    {
      id: 2,
      number: "10M",
      text: " Money Donated"
    },
    {
      id: 3,
      number: "12+",
      text: "Active Campaigns"
    }
  ];
  return (
    <div className=" bg-green mt-14 py-5 ">
      <div className="flex justify-between md:px-16 px-5">
        {monthlydonor.map((item) => (
          <div className=" md:w-[300px] text-center text-white " key={item.id}>
            <h1 className="md:text-6xl text-sm font-bold">{item.number}</h1>
            <p className="mt-2 md:text-xl text-[8px] "> {item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthlyDonors;
