import { PageWrapper } from "components";
import ContactView from "./ContactView";

export const ContactContainer = () => {
  return (
    <PageWrapper>
      <ContactView />
    </PageWrapper>
  );
};
