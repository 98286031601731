// import { whatWeDoImgR } from "assets/images";
import { ReasrchIcon } from "assets/images";

const WhatWeDo = () => {
  const whatwedo = [
    {
      id: 1,
      text: "Racial and ethnic health disparities"
    },
    {
      id: 2,
      text: "Family Health"
    },
    {
      id: 3,
      text: "Integration of mental health care"
    },
    {
      id: 4,
      text: "Health information technology"
    },
    {
      id: 5,
      text: "Artificial Intelligence"
    },
    {
      id: 6,
      text: "Women’s Health"
    },
    {
      id: 7,
      text: "Patient-centered care"
    },
    {
      id: 8,
      text: "Men’s Health"
    }
  ];

  return (
    <div className="md:py-24 py-12 md:px-12 xl:px-28 px-5  ">
      <div className="grid md:grid-cols-2 grid-cols-1 justify-between md:gap-10 gap-12 ">
        <div className=" w-full">
          <div className="md:border-l-8 border-l-4 border-l-orange border-solid md:pl-5 pl-3 pt-5">
            <h4 className="md:text-3xl text-xl ">What we do?</h4>
            <h2 className="font-semibold text-[#005028] md:text-6xl text-2xl ">Research</h2>
          </div>
          <div className="md:text-2xl text-base md:leading-10 leading-8 text-black text-justify md:px-0 px-2 md:mt-12 mt-8">
            To further its primary goal of BMHM, the Institute conducts clinical and health services
            research. Our research helps us advance black mental health throughout the diaspora and
            allows us to continuously improve the care we provide. Although the Institute works on a
            wide range of research topics, we have concentrated our efforts in a few crucial areas,
            such as;
          </div>
        </div>

        <div className=" w-full hidden md:grid md:grid-cols-3 md:justify-start grid-cols-1 xl:gap-10  gap-5">
          {whatwedo.map((item, id) => {
            return (
              <div
                key={id}
                style={{ boxShadow: "0px 6px 10px 2px rgba(0, 0, 0, 0.12)" }}
                className="bg-[#B2ECC4] xl:w-[12rem] h-[6rem] text-green flex items-center px-5 text-center font-bold relative justify-center rounded-[10px] cursor-pointer shadow-md"
              >
                {item.text}
                <img
                  src={ReasrchIcon}
                  alt="researchicon"
                  className=" absolute bottom-16 left-4  "
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
