import { campaignHand } from "assets/images";
import { Link } from "react-router-dom";
import { CAMPAIGNFORM } from "routes/CONSTANTS";

const StartConversation = () => {
  return (
    <div className="flex md:flex-row  flex-col  md:mt-20 mt-10">
      <div className="md:w-[60%]  w-full relative">
        <img src={campaignHand} alt="campaignHand" className="object-cover" />
        <div className="absolute inset-0 bg-gradient-to-r from-[#000000CC] to-[#000000B3]"></div>
      </div>
      <div className="bg-[#000000] opacity-[.8] md:pt-28 md:pb-0 pb-10 pt-10 md:px-36 px-8">
        <div className=" md:w-[70%] w-[100%] ">
          <h1 className="md:text-[64px] text-3xl text-white font-normal md:leading-[62px] leading-[45px]">
            Start the conversation on mental health.
          </h1>
        </div>
        <div className="flex md:flex-row flex-col md:gap-0 gap-5 mt-16 justify-between">
          <div className="border-l border-white leading-[19px] text-white text-base px-3 md:w-[23%] w-[100%]">
            Create a campaign for yourself or your community.
          </div>
          <div className="border-l border-white leading-[19px] text-white text-base px-3 md:w-[23%] w-[100%]">
            share your petition to the world to start the conversation .
          </div>
        </div>
        <div className="md:mt-20 mt-10">
          <Link
            to={CAMPAIGNFORM}
            className="py-2 px-2 w-full md:w-60 h-12 md:h-14 mt-8 text-white font-bold bg-orange flex items-center justify-center rounded "
          >
            Create a campaign
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StartConversation;
