import { DiaPfleger, DanielMwambonu, DrMeganPickens, DrKevinWashington } from "assets/images";
import Carousel from "react-multi-carousel";

const OurTeam = () => {
  const ourTeam = [
    {
      id: 1,
      image: DrKevinWashington,
      name: "Dr Kevin Washington",
      position: "President"
    },
    {
      id: 2,
      image: DiaPfleger,
      name: "Dr Dia Pfleger",
      position: "Vice President"
    },
    {
      id: 3,
      image: DanielMwambonu,
      name: "Daniel Mwambonu",
      position: "Chief Executive officer"
    },
    {
      id: 4,
      image: DrMeganPickens,
      name: "Dr Megan Pickens",
      position: "Director"
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className="bg-green md:px-16 px-5 md:py-16 py-12">
      <div className="bg-green text-white text-center">
        <h1 className=" text-3xl md:text-6xl font-semibold">Our Team</h1>
        <p className=" text-lg md:text-xl italic md:pt-8 pt-4">People who made us successful</p>
      </div>

      <div className="">
        <Carousel
          showDots={true}
          arrows={false}
          containerClass=""
          slidesToSlide={2}
          responsive={responsive}
        >
          {ourTeam.map((member) => {
            return (
              <div
                className="text-white flex items-center justify-center flex-col py-16  px-2"
                key={member.id}
              >
                <div>
                  <img src={member.image} alt={member.name} className="max-w-[100%] h-[30vh]" />
                </div>
                <div className="text-2xl font-bold pt-8">{member.name}</div>
                <div className="text-lg xl:text-2xl">{member.position}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default OurTeam;
