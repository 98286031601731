import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  placeholder: string;
  label: string;
  type?: "text" | "number" | "email" | "password";
  className?: string;
  labelClassName?: string;
}

function ThInput({
  nameAndId,
  placeholder,
  label,
  type,
  className,
  labelClassName
}: FormInputProps) {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center gap-2">
        <label
          className={`text-black text-base font-light ${labelClassName ?? ""}`}
          htmlFor={nameAndId}
        >
          <span className="">{label}</span>
        </label>

        <Field
          name={nameAndId}
          id={nameAndId}
          placeholder={placeholder}
          type={type ?? "text"}
          className={
            className ??
            "bg-white placeholder-gray placeholder:text-sm text-black text-base font-normal focus:ring-0 border-none outline-none"
          }
        />
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default ThInput;
