import { ErrorMessage, Field } from "formik";

interface TextareaInputProps {
  nameAndId: string;
  label: string;
}

function GroupFormTextarea({ nameAndId, label }: TextareaInputProps) {
  return (
    <div className="flex flex-col">
      <label className="text-white md:text-2xl text-base font-bold pb-3" htmlFor={nameAndId}>
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        as={"textarea"}
        className="bg-green placeholder-gray text-white md:text-2xl text-base font-normal p-1 md:h-[150px] h-[44px] focus:ring-0 border-2 border-gray rounded-md outline-none"
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default GroupFormTextarea;
