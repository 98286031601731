import { PageWrapper } from "components";
import PartnerView from "./PartnerView";

export const PartnerContainer = () => {
  return (
    <PageWrapper>
      <PartnerView />
    </PageWrapper>
  );
};
