import { map, storiesComment, storiesLike } from "assets/images";
import { useParams } from "react-router-dom";
import { stories } from "utils";

const ResearchProfile = () => {
  const params = useParams();
  const researchStory = stories.find((s) => s.id === params.id);

  if (!researchStory) {
    return <div>Story not found</div>;
  }
  return (
    <div className="flex  flex-col justify-center md:px-52 md:py-14">
      <div className="md:flex items-center  gap-8 py-5 md:w-[479px] md:h-[146px]">
        <div className="md:w-[139px] md:h-[139px] flex items-center justify-center">
          <img src={researchStory.image} />
        </div>
        <div>
          <div className="flex flex-col items-center justify-center  md:items-start gap-4 md:w-[301px]">
            <p className="font-bold text-2xl text-center text-[#3C4E40]">{researchStory.title}</p>
            <div className="flex items-center gap-2">
              <img src={map} alt="" className="w-[24px]  h-[24px]" />
              <span className="font-normal text-[21.12px] font-Archivo text-[#667085]">
                {researchStory.location}
              </span>
            </div>
          </div>
          <div className="flex justify-center md:justify-start  items-center gap-6 md:w-[301px] h-[50px]">
            <div className="flex items-center gap-1 w-[120px] h-[50px]">
              <img src={storiesLike} className="w-[50px]  h-[50px]" />
              <span className="font-normal text-base font-Archivo text-[#3C4E40]">88 Likes</span>
            </div>
            <div className="flex items-center gap-2">
              <img src={storiesComment} className="w-[27.54px]  h-[27.54px]" />
              <span className="font-normal text-base font-Archivo text-[#3C4E40]">50 Comments</span>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[280px] md:max-w-[928px] mx-auto font-normal text-base text-left">
        <p>{researchStory.paragraph}</p>
      </div>
    </div>
  );
};

export default ResearchProfile;
