import { video1 } from "assets/images";
import { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselComponent = () => {
  const videos = [
    { id: 1, description: "Coming Episode", img: video1 },
    { id: 2, description: "Recently streamed Episode", img: video1 },
    { id: 3, description: "Live Episode", img: video1 },
    { id: 4, description: "Past Episode", img: video1 },
    { id: 5, description: "Coming Episode", img: video1 },
    { id: 6, description: "Recently streamed Episode", img: video1 },
    { id: 7, description: "Live Episode", img: video1 },
    { id: 8, description: "Past Episode", img: video1 }
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className=" h-[341px] mt-10 md:bg-green m-5 md:shadow-none md:mb:0 mb-10 shadow-2xl md:rounded-none rounded-lg bg-white relative px-20 pt-12">
      <Carousel responsive={responsive}>
        {videos.map((video) => {
          return (
            <div className="relative" key={video.id}>
              <p className="absolute bottom-10 left-7 font-bold leading-5 text-[#FFFBE8]">
                {video.description}
              </p>
              <img src={video.img} alt="" />
            </div>
          );
        })}
      </Carousel>

      <div className="flex lg:justify-end justify-center lg:mt-0 mt-5">
        <button className="w-28 h-12 bg-orange rounded text-base font-medium leading-[17px] text-white">
          View All
        </button>
      </div>
    </div>
  );
};

export default memo(CarouselComponent);
