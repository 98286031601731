import { ResearchStoriesHero, ResearchProfile, SharedThoughts } from "components";
import { Helmet } from "react-helmet";

const StoriesView = () => {
  return (
    <>
      <Helmet>
        <title>BMHM | Stories</title>
        <meta name="description" content="Description of Home Page" />
        <meta name="keywords" content="home, page, react" />
      </Helmet>
      <ResearchStoriesHero />
      <ResearchProfile />
      <SharedThoughts />
    </>
  );
};

export default StoriesView;
