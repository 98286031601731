import { IndividualForm, IndividualFormHero } from "components";

const IndividualFormView = () => {
  return (
    <div className="container md:py-16 py-12">
      <IndividualFormHero />
      <IndividualForm />
    </div>
  );
};

export default IndividualFormView;
