import React from "react";

interface AmountButtonProps {
  amount: number;
  selected: boolean;
  onClick: (amount: number) => void;
}

const AmountButton: React.FC<AmountButtonProps> = ({ amount, selected, onClick }) => {
  const classes = `px-4 py-2 rounded-md md:h-[69px] w-full md:w-[173px] text-center focus:outline-none hover:bg-orange border-2 border-gray hover:border-orange hover:text-white ${
    selected ? "bg-orange text-white border-orange" : "bg-green text-gray"
  }`;

  return (
    <button type="button" className={classes} onClick={() => onClick(amount)}>
      ${amount}
    </button>
  );
};

export default AmountButton;
