import { PageWrapper } from "components";
import SearchArticleView from "./SearchArticleView";

export const SearchArticleContainer = () => {
  return (
    <PageWrapper>
      <SearchArticleView />
    </PageWrapper>
  );
};
