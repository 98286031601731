interface ButtonProps {
  children: string;
  onClick?: (values: any) => void;
}

const FormButton = ({ children, onClick }: ButtonProps) => {
  return (
    <button
      className="text-white md:text-xl text-lg bg-orange hover:bg-[#FFAC2A] py-3 px-4 md:w-72 w-full md:h-16 h-12 flex items-center justify-center rounded-md"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FormButton;
