import { CTextarea } from "components/modules";
import { Form, Formik } from "formik";

interface StepThreeProps {
  onNext: (values: any) => void;
}

const StepThree = ({ onNext }: StepThreeProps) => {
  const formikInitialValues = {
    importance: ""
  };

  const handleSubmit = (values: any) => {
    onNext(values);
  };
  return (
    <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      <Form>
        <CTextarea
          nameAndId="importance"
          label="Why is it important?"
          sublabel="Tell your story. People are more likely to support your petition if it's clear why you care."
          subtext="Explain how it will impact you, your family, or your community. Make it clear what happens if you win or lose. Describe the people involved and the problem they are facing."
        />
      </Form>
    </Formik>
  );
};

export default StepThree;
