import { Accordion } from "components/modules";

const FAQ = () => {
  const questions = [
    {
      question: "What is included in being a volunteer?",
      answer:
        "Volunteering with us encompasses a range of tasks, responsibilities, and roles that require reliability and timely completion. As you generously offer your time to support our mission, we sincerely appreciate your understanding that our success relies on your dependability."
    },
    {
      question: "What skills are required?",
      answer:
        "We welcome all experience levels, from entry-level to senior level. You don’t need to be an expert or have a minimum of experience, but we require you to have some knowledge of your field."
    },
    {
      question: "How do I apply?",
      answer: "Go to the Get Involve page and select the Volunteer button under Why volunteer?"
    },
    {
      question: "What is Black Mental Health?",
      answer:
        "Black mental health is the mental health of individuals who identify as Black or of African descent."
    }
  ];
  return (
    <>
      <section className="my-20 px-3">
        <div className="md:mx-20 bg-green md:py-10 py-6 px-2 rounded-2xl">
          <h2 className="text-white md:text-2xl text-lg md:text-left text-center font-bold">
            {" "}
            FAQ’s{" "}
          </h2>
        </div>
        <div className="flex flex-col justify-center md:mx-20 bg-green py-10 px-5 rounded-2xl my-10">
          {questions.map(({ question, answer }, index) => (
            <Accordion
              key={index}
              title={question}
              questionTextColor="text-[#170F49]"
              bgColor="bg-white"
              rounded="rounded-md"
              iconBg="bg-orange"
              border="border-white"
              childColor="text-[#6F6C90]"
            >
              {answer}
            </Accordion>
          ))}
        </div>
      </section>
    </>
  );
};

export default FAQ;
