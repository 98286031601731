import { LinkButton } from "components/widgets";
import { RESOURCES } from "routes/CONSTANTS";

const DigitalResources = () => {
  return (
    <div className="md:bg-[#F8F8F8]  bg-green flex justify-center md:mt-0 m-5 md:rounded-none rounded-xl items-center md:p-0 px-5">
      <div className="lg:max-w-[1000px] w-full my-14 flex flex-col md:items-center items-center">
        <h3 className="md:text-5xl text-3xl md:text-black text-orange font-bold leading-[68px] md:mb-6 mb-4 text-center">
          Digital Resources
        </h3>
        <p className="md:text-2xl text-base font-normal md:text-black text-white text-center md:leading-10 leading-8">
          Empowering you to spread awareness and support mental health through our digital
          resources, featuring customizable banners and a curated list of social media and website
          tools.
        </p>
        <div className="mt-8 w-full flex items-center justify-center">
          <LinkButton to={RESOURCES}>Click Here</LinkButton>
        </div>
      </div>
    </div>
  );
};

export default DigitalResources;
