import { BeAVoiceHero, OurPartners, Testimonials } from "components/layout/BeAVoice";
import { Helmet } from "react-helmet";

const BaAVoiceView = () => {
  return (
    <>
      <Helmet>
        <title>
          Amplify the conversations on Black mental health and be a vital voice for change
        </title>
        <meta
          name="description"
          content="join the movement to raise awareness about the importance of mental health within the Black community. Discover the unique challenges that Black people face in accessing mental health resources and promote mental health equity. Learn why Black mental health matters and how you can make a difference"
        />
        <meta name="keywords" content="amplify, promote, balck mental health matters" />
      </Helmet>
      <BeAVoiceHero />
      <Testimonials />
      <OurPartners />
    </>
  );
};

export default BaAVoiceView;
