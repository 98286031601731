import { AlsoDonate } from "components";
import StartConversation from "components/layout/Campaign/StartConversation";
import { PetitionHero } from "components/layout/Petition";
import FullPetition from "components/layout/Petition/FullPetition";
import PetitionRange from "components/layout/Petition/PetitionRange";

import { Helmet } from "react-helmet";

const PetitionView = () => {
  return (
    <>
      <Helmet>
        <title>BMHM | Petition</title>
        <meta name="description" content="" />
        <meta name="keywords" content="partner, black mental health" />
      </Helmet>
      <PetitionHero />
      <PetitionRange />
      <FullPetition />
      <StartConversation />
      <AlsoDonate />
    </>
  );
};

export default PetitionView;
