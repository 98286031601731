/* eslint-disable @typescript-eslint/strict-boolean-expressions */
const validation = (values: { first_name: string; last_name: string; email: string }) => {
  const errors = {
    first_name: "",
    last_name: "",
    email: ""
  };
  if (!values.first_name) {
    errors.first_name = "Please enter your First Name";
  } else if (values.first_name.length < 2) {
    errors.first_name = "Invalid First Name";
  }

  if (!values.last_name) {
    errors.last_name = "Please enter your Last Name";
  } else if (values.last_name.length < 2) {
    errors.last_name = "Invalid Last Name";
  }

  if (!values.email) {
    errors.email = "Please enter your Email Address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email or email";
  }

  return errors;
};
export default validation;
