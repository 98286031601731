import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import {
  Home,
  AboutUs,
  Services,
  ErrorPage,
  GetInvolved,
  Signup,
  Login,
  ForgotPassword,
  Privacy,
  Terms,
  Donate,
  GuestForm,
  HostForm,
  IndividualForm,
  OrganizationForm,
  Opportunity,
  Community,
  StoriesForm,
  Research,
  JournalForm,
  Campaign,
  BeAVoice,
  Stories,
  CampaignForm,
  SearchArticle,
  Partner,
  Volunteer,
  Publication,
  VolunteerForm,
  Faq,
  Contact,
  DonateForm,
  Resources,
  Appointments,
  Audiocasts,
  Trends,
  Groups,
  Events,
  Settings,
  ReferAFriend,
  DashboardHome,
  InternshipForm,
  Chats,
  SignupTherapist,
  SignupUser,
  LoginUser,
  LoginTherapist,
  ResetPassword,
  Product
} from "pages";
import {
  HOME,
  ABOUT_US,
  GET_INVOLVED,
  SERVICES,
  SIGNUP,
  TERMS,
  PRIVACY,
  FORGOT_PASSWORD,
  LOGIN,
  DONATE,
  HOSTFORM,
  GUESTFORM,
  INDIVIDUALFORM,
  ORGANIZATIONFORM,
  RESEARCH,
  OPPORTUNITY,
  COMMUNITY,
  STORIESFORM,
  JOURNALFORM,
  CAMPAIGN,
  BE_A_VOICE,
  CAMPAIGNFORM,
  STORIES,
  JOBS,
  SEARCHARTICLE,
  VOLUNTEER,
  PARTNER,
  PUBLICATION,
  ABSTRACT,
  VOLUNTEERFORM,
  FAQ,
  CONTACT,
  DONATEFORM,
  RESOURCES,
  STORY,
  APPOINTMENTS,
  DASHBOARD,
  AUDIOCASTS,
  TRENDS,
  GROUPS,
  EVENTS,
  SETTINGS,
  REFER_A_FRIEND,
  INTERNSHIP,
  PETITION,
  QUIZ,
  CHATS,
  SIGNUP_THERAPIST,
  SIGNUP_USER,
  LOGIN_USER,
  LOGIN_THERAPIST,
  RESET_PASSWORD,
  PRODUCT
} from "routes/CONSTANTS";

import {
  CenterLoader,
  JobDetail,
  JournalAbstract,
  ProtectedRoutes,
  PublicRoute
  // ResearchProfile
} from "components";
import Petition from "pages/Petition";
import { QuizContainer } from "pages/Quiz/QuizContainer";

const RouterConfig = () => {
  return (
    <div>
      <Suspense fallback={<CenterLoader />}>
        <Routes>
          {/* Public routes should be placed in here */}
          <Route path={HOME} element={<Home />} />
          <Route path={ABOUT_US} element={<AboutUs />} />
          <Route path={GET_INVOLVED} element={<GetInvolved />} />
          <Route path={SERVICES} element={<Services />} />
          <Route path={TERMS} element={<Terms />} />
          <Route path={PRIVACY} element={<Privacy />} />
          <Route path={PRODUCT} element={<Product />} />
          <Route path={DONATE} element={<Donate />} />
          <Route path={HOSTFORM} element={<HostForm />} />
          <Route path={GUESTFORM} element={<GuestForm />} />
          <Route path={INDIVIDUALFORM} element={<IndividualForm />} />
          <Route path={ORGANIZATIONFORM} element={<OrganizationForm />} />
          <Route path={STORIESFORM} element={<StoriesForm />} />
          <Route path={JOURNALFORM} element={<JournalForm />} />
          <Route path={RESEARCH} element={<Research />} />
          <Route path={CAMPAIGN} element={<Campaign />} />
          <Route path={BE_A_VOICE} element={<BeAVoice />} />
          <Route path={CAMPAIGNFORM} element={<CampaignForm />} />
          <Route path={STORIES} element={<Stories />} />
          <Route path={OPPORTUNITY} element={<Opportunity />} />
          <Route path={COMMUNITY} element={<Community />} />
          <Route path={JOBS} element={<JobDetail />} />
          <Route path={ABSTRACT} element={<JournalAbstract />} />
          <Route path={SEARCHARTICLE} element={<SearchArticle />} />
          <Route path={VOLUNTEER} element={<Volunteer />} />
          <Route path={PARTNER} element={<Partner />} />
          <Route path={PUBLICATION} element={<Publication />} />
          <Route path={VOLUNTEERFORM} element={<VolunteerForm />} />
          <Route path={FAQ} element={<Faq />} />
          <Route path={CONTACT} element={<Contact />} />
          <Route path={RESOURCES} element={<Resources />} />
          <Route path={DONATEFORM} element={<DonateForm />} />
          <Route path={STORY} element={<Stories />} />
          <Route path={INTERNSHIP} element={<InternshipForm />} />
          <Route path={PETITION} element={<Petition />} />

          <Route path="/" element={<PublicRoute />}>
            {/* Auth pages */}
            <Route path={SIGNUP} element={<Signup />} />
            <Route path={SIGNUP_THERAPIST} element={<SignupTherapist />} />
            <Route path={SIGNUP_USER} element={<SignupUser />} />
            <Route path={LOGIN} element={<Login />} />
            <Route path={LOGIN_USER} element={<LoginUser />} />
            <Route path={LOGIN_THERAPIST} element={<LoginTherapist />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={RESET_PASSWORD} element={<ResetPassword />} />
          </Route>

          {/* dashboard routes should be placed in here */}
          <Route>
            <Route path={DASHBOARD} element={<DashboardHome />} />
            <Route path={APPOINTMENTS} element={<Appointments />} />
            <Route path={CHATS} element={<Chats />} />
            <Route path={AUDIOCASTS} element={<Audiocasts />} />
            <Route path={TRENDS} element={<Trends />} />
            <Route path={GROUPS} element={<Groups />} />
            <Route path={EVENTS} element={<Events />} />
            <Route path={SETTINGS} element={<Settings />} />
            <Route path={REFER_A_FRIEND} element={<ReferAFriend />} />
            <Route path={QUIZ} element={<QuizContainer />} />
          </Route>

          <Route path="/" element={<ProtectedRoutes />}>
            {/* Protected routes should be placed in here */}
          </Route>

          {/* 404 page */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default RouterConfig;
