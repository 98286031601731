import React from "react";
import { Field, ErrorMessage } from "formik";
import { caution } from "assets/icons";

interface GroupSelectProps {
  nameAndId: string;
  label: string;
  labelClassName?: string;
  children: React.ReactNode;
  sublabel?: string;
  subtext?: string;
}

function CSelect({ nameAndId, label, children, sublabel, subtext }: GroupSelectProps) {
  return (
    <div className="flex flex-col">
      <label className="flex flex-col gap-2 mb-2" htmlFor={nameAndId}>
        <span className="text-[#555253] text-base md:text-2xl">{label}</span>
        <span className="text-[#555253] text-xs md:text-base">{sublabel}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        as={"select"}
        className="bg-white placeholder-gray text-black md:text-2xl text-sm font-normal py-1 px-2 md:h-[73px] h-[44px] focus:ring-0 border-2 border-gray rounded-md outline-none"
      >
        {children}
      </Field>

      <div className="flex items-center gap-2 md:mt-4 mt-2">
        <img src={caution} />
        <p className="text-[#A1A1A1] text-xs md:text-base">{subtext}</p>
      </div>

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default CSelect;
