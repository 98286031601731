import { Mission } from "assets/images";

const OurMission = () => {
  return (
    <div className="md:px-28 px-5 py-16">
      <h1 className="text-left text-4xl md:text-6xl font-semibold text-orange">Our Mission</h1>
      <div className="grid md:grid-cols-2 grid-cols-1 pt-12 items-center md:pl-20">
        <div className="">
          <img src={Mission} alt="our-mission" />
        </div>
        <div className="text-base md:text-2xl font-light md:leading-[50px] leading-10 md:mt-0 mt-12">
          Black Mental Health Matters vision is to promote mental health research, champion for
          mental health rights for people of African descent, combat medical racism, create the
          infrastructure that Africa needs for mental health and provide an environment where people
          with trauma can heal.
        </div>
      </div>
    </div>
  );
};

export default OurMission;
