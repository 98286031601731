import { JoinUs } from "assets/images";

const Join = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 md:py-16 py-12 md:px-12 xl:px-28 px-5 items-center justify-items-center md:mx-auto bg-[#F8F8F8]">
      <div className="grid grid-rows ">
        <div className=" border-l-4 md:border-l-8 border-green pl-4">
          <div className=" text-2xl md:text-4xl font-normal	mb-4">Want to join us?</div>
          <div className="text-orange  text-2xl lg:text-6xl font-semibold">Get Involved</div>
        </div>
        <p className=" hidden md:leading-10 md:pr-20 sm:text-base md:text-lg md:w-[550px] md:block mb-4 mt-8">
          You have searched our FAQ and could not find the answers to your question, you have a
          suggestions for us or you want to partner with us, Please type in your message and email
          address and you would be reached by our representative as soon as possible.
        </p>
      </div>

      <div className="grid grid-rows  gap-4 md:mt-0 mt-6">
        <img src={JoinUs} alt={JoinUs} />
        <div className="grid grid-cols-2 gap-2">
          <a
            href="/partner"
            className="bg-orange text-white text-base py-4 w-full rounded-md flex items-center justify-center"
          >
            Partner with us
          </a>
          <a
            href="/donate"
            className="w-full bg-white border-2 border-orange rounded-md text-orange flex items-center justify-center"
          >
            Donate
          </a>
        </div>
      </div>
    </div>
  );
};

export default Join;
