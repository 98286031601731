import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import SubmitModal from "./SubmitModal";
import SubmittedFormModal from "./SubmittedFormModal";

interface PetitionFormProps {
  id: number;
  totalSignatures: number;
  onFormSubmit: (id: number, values: PetitionFormValues) => void;
}

export interface PetitionFormValues {
  name: string;
  email: string;
}

const PetitionForm: React.FC<PetitionFormProps> = ({ id, onFormSubmit }) => {
  const [numSignatures, setNumSignatures] = useState(0);
  const [Submitted, setFormSubmitted] = useState(false);

  // const closeSubmitModal=()=>{
  //   setFormSubmitted(true);
  // }

  const initialValues: PetitionFormValues = { name: "", email: "" };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required")
  });

  const onSubmit = (values: PetitionFormValues, { setSubmitting }: any) => {
    onFormSubmit(id, values);
    setSubmitting(true);
    setNumSignatures(numSignatures + 1);
    setFormSubmitted(true);
  };

  return (
    <>
      {!Submitted && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mt-5">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  className="p-2 border w-full"
                />
                <ErrorMessage name="name" component="div" className="text-orange mt-2" />
              </div>
              <div className="mt-5">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className="p-2 border w-full"
                />
                <ErrorMessage name="email" component="div" className="text-orange mt-2" />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-orange rounded-md text-white mt-5 w-[7rem] h-10"
              >
                Sign
              </button>
            </Form>
          )}
        </Formik>
      )}
      {Submitted && <SubmittedFormModal />}
    </>
  );
};

export default PetitionForm;
